import * as React from "react";
import "./crypto-setting.scss";
import { Divider, Spin } from "antd";
import { BinanceSettings } from "./binance";
import { KucoinSettings } from "./kucoin";
import { PerfectMoneySettings } from "./perfect-money";
import { Tron24Settings } from "./tron24";
import { UVoucherSettings } from "./u-voucher";
import { BingXSettings } from "./bingx";

const { useState, useEffect } = React;

export const CryptoSetting: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-cryptosetting">
          <h3>تنظیمات سرویس های ارزی</h3>
          {
            <form>
              <BinanceSettings />
              <Divider />
              <KucoinSettings />
              <Divider />
              <PerfectMoneySettings />
              <Divider />
              <UVoucherSettings />
              <Divider />
              <Tron24Settings />
              <Divider />
              <BingXSettings />
            </form>
          }
        </div>
      </Spin>
    </>
  );
};
