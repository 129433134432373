import * as React from "react";
import { __API } from "apis/api";
import { Button, Card, Input, Modal } from "components";
import { Fiat, PartnerPrice } from "core/domains";
import "./fiats.scss";
import { getViaAuth, responseValidator } from "utils/scripts/api";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { digitSeparator, faNumToEnNum, IsNumeric } from "utils/scripts/decimal";
import { updateFiats } from "core/repos";
import { IsPersianChar } from "utils/scripts/stringExtentions";
import { TableColumn } from "react-data-table-component";
import { Table } from "components/table/table";

type FieldErrorItem = {
  symbol: string;
  buy?: boolean;
  sell?: boolean;
};

type PriceFieldErrorItem = {
  siteName: string;
  buy?: boolean;
  sell?: boolean;
};

type FieldError = FieldErrorItem[];
const defaultErrors = [
  {
    symbol: "PM",
  },
  {
    symbol: "CRYPTO",
  },
  {
    symbol: "USDT",
  },
];

const { useState, useEffect } = React;

export const EditFiats: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);

  const [usdtLoading, setUsdtLoading] = useState<boolean>(false);
  const [pmLoading, setPmLoading] = useState<boolean>(false);
  const [cryptoLoading, setCryptoLoading] = useState<boolean>(false);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [fiatData, setFiatData] = useState<Fiat[]>();
  const [partnersPrices, setPartnersPrices] = useState<PartnerPrice[]>();
  const [partnersPricesPM, setPartnersPricesPM] = useState<PartnerPrice[]>();
  const [partnersPricesTeth, setPartnersPricesTeth] =
    useState<PartnerPrice[]>();
  const [fieldError, setFieldError] = useState<FieldError>(defaultErrors);
  const [oldFiatPrices, setOldFiatPrices] = useState<Fiat[]>();

  const navigate = useNavigate();

  const updateFiatHandler = (fiats: Fiat[]) => {
    var isRequiredModal = false;
    fiats.map((fiat: Fiat, i) => {
      let oldPrice =
        oldFiatPrices &&
        oldFiatPrices.filter((q) => q.symbol === fiat.symbol)[0];

      if (oldPrice && fiat) {
        let sellPriceDiffrance = Math.abs(fiat.sellPrice - oldPrice.sellPrice);
        let buyPriceDiffrance = Math.abs(fiat.buyPrice - oldPrice.buyPrice);

        if (sellPriceDiffrance > 400 || buyPriceDiffrance > 400) {
          isRequiredModal = true;
        }
      }
    });

    if (isRequiredModal) {
      //  setModalIsOpen(true);
      updateFiats(fiats, setCryptoLoading, navigate);
    } else {
      updateFiats(fiats, setCryptoLoading, navigate);
    }
  };

  const getFiats = async () => {
    setIsCardLoading(true);
    await getViaAuth(__API.fiats.getAllFiatsAdmin, {}).then((response: any) => {
      if (responseValidator(response.status)) {
        setFiatData(response.value);
        setOldFiatPrices(response.value);
      }
    });
    setIsCardLoading(false);
  };

  const getPartnersPrices = async () => {
    setIsCardLoading(true);
    await getViaAuth(__API.fiats.getPartnersPrices).then((response: any) => {
      if (responseValidator(response.status)) {
        setPartnersPrices(response.value);
        setPartnersPricesPM(response.value);
        setPartnersPricesTeth(response.value);
      }
    });
    setIsCardLoading(false);
  };

  const fetchAllData = async () => {
    getFiats();
    getPartnersPrices();
  };

  const PMcolumns: TableColumn<PartnerPrice>[] = [
    {
      name: "نام سایت",
      id: "siteName",
      selector: (row) => row.siteName,
    },
    {
      name: "قیمت خرید پرفکت",
      id: "symbol",
      selector: (row) => digitSeparator(row?.prices.perfectMoney?.buy || ""),
    },
    {
      name: "قیمت فروش پرفکت",
      id: "symbol",
      selector: (row) => digitSeparator(row?.prices.perfectMoney?.sell || ""),
    },
  ];

  const TethColumns: TableColumn<PartnerPrice>[] = [
    {
      name: "نام سایت",
      id: "siteName",
      selector: (row) => row.siteName,
    },
    {
      name: "قیمت خرید تتر",
      id: "symbol",
      selector: (row) => digitSeparator(row?.prices.tether?.buy || ""),
    },
    {
      name: "قیمت فروش تتر",
      id: "symbol",
      selector: (row) => digitSeparator(row?.prices.tether?.sell || ""),
    },
  ];

  function onFormChange(
    value: string,
    field: string,
    symbol: string,
    fiat: Fiat
  ) {
    let oldPrice = oldFiatPrices?.filter((q) => q.symbol == fiat.symbol)[0];

    if (value !== "" && field !== "name") value = faNumToEnNum(value);

    if (
      value !== "" &&
      field !== "name" &&
      !IsNumeric(faNumToEnNum(value.split("")[value.split("").length - 1]))
    )
      return;

    if (
      value !== "" &&
      field === "name" &&
      !IsPersianChar(value.split("")[value.split("").length - 1])
    )
      return;

    const data = fiatData?.map((fiat) => {
      if (fiat.symbol === symbol)
        return {
          ...fiat,
          [field]: value,
        };
      else return fiat;
    });

    fiatData && setFiatData(data);

    if (oldPrice && fiatData) {
      let PriceDiffrance =
        field === "sellPrice"
          ? Math.abs(oldPrice.sellPrice - parseInt(value))
          : Math.abs(oldPrice.buyPrice - parseInt(value));

      setFieldError(defaultErrors);

      let rest = fieldError.filter((item) => item.symbol != fiat.symbol);

      let sellError = false;
      let buyError = false;

      if (PriceDiffrance > 1000) {
        field === "sellPrice" ? (sellError = true) : (buyError = true);
      }

      rest.push({
        symbol: fiat.symbol,
        buy: buyError,
        sell: sellError,
      });

      setFieldError(rest);
    }
  }
  function updateOnModal() {
    fiatData && updateFiats(fiatData, setCryptoLoading, navigate);
    setModalLoading(false);
    setModalIsOpen(false);
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <>
      <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
        <div className="confirm-box">
          <div className="head">
            <h4>آیا اطمینان دارید؟</h4>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              loading={modalLoading}
              onClick={() => updateOnModal()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-fiats">
          <h3>تغییر نرخ</h3>
          <div className="fiats">
            {fiatData &&
              fiatData.map((fiat) => (
                <React.Fragment key={fiat.id}>
                  <Card title={`${fiat.name}`}>
                    <form>
                      <Input
                        className={`${
                          fieldError?.filter(
                            (item) => item.symbol === fiat.symbol
                          )[0]?.sell
                            ? "has-error"
                            : ""
                        }`}
                        label="قیمت فروش"
                        type="text"
                        name="sellPrice"
                        value={fiat?.sellPrice.toString()}
                        onChange={(e) =>
                          onFormChange(
                            e.target.value,
                            "sellPrice",
                            fiat.symbol,
                            fiat
                          )
                        }
                        dir="ltr"
                        maxLength={80}
                      />
                      <Input
                        className={`${
                          fieldError?.filter(
                            (item) => item.symbol === fiat.symbol
                          )[0]?.buy
                            ? "has-error"
                            : ""
                        }`}
                        label="قیمت خرید از مشتری"
                        type="text"
                        name="buyFromCustomerPrice"
                        value={fiat?.buyPrice.toString()}
                        onChange={(e) =>
                          onFormChange(
                            e.target.value,
                            "buyPrice",
                            fiat.symbol,
                            fiat
                          )
                        }
                        dir="ltr"
                        maxLength={80}
                      />
                    </form>
                  </Card>
                </React.Fragment>
              ))}
            {fiatData && (
              <Button
                htmlType="button"
                type="info"
                text="اصلاح"
                onClick={() => updateFiatHandler(fiatData)}
                loading={cryptoLoading}
              />
            )}
          </div>

          <h3>قیمت های همکاران</h3>
          <div className="partners-prices">
            {partnersPrices && (
              <Table
                blur={isCardLoading}
                className="partners-prices-table"
                data={partnersPrices}
                column={PMcolumns}
              />
            )}
            {partnersPrices && (
              <Table
                blur={isCardLoading}
                className="partners-prices-table"
                data={partnersPrices}
                column={TethColumns}
              />
            )}
          </div>
        </div>
      </Spin>
    </>
  );
};
