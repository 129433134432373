import * as React from "react";
import { __API } from "apis/api";
import { Button, Input } from "components";
import "./edit.scss";
import { get, post, responseValidator } from "utils/scripts/api";
import { Spin, Switch } from "antd";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { deleteCommaSeparator, digitSeparator } from "utils/scripts/decimal";
import { getLevelById, updateLevel } from "../../../../../core/repos/level";
import { Level } from "core/domains";

const { useState, useEffect } = React;

export const EditLevel: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [level, setLevel] = useState<any>();
  const location = useLocation();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const navigate = useNavigate();

  useEffect(() => {
    !level &&
      getLevelById(
        setIsCardLoading,
        setLevel,
        urlSections[urlSections.length - 1]
      );
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-levels-edit'>
          <h3>اصلاح سطح</h3>
          {level && (
            <form>
              <Input
                type='text'
                label='نام'
                name='name'
                value={level?.name}
                onChange={(e) => setLevel({ ...level, name: e.target.value })}
              />

              <Input
                type='text'
                label='محدودیت روزانه ماندن در سطح'
                name='sumDaysStayInLevel'
                value={level?.sumDaysStayInLevel?.toString()}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    sumDaysStayInLevel: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              />
              {/* <Input
                type='text'
                label='مجموع واریز ریال در روز'
                name='sumIrtDepositInDay'
                value={digitSeparator(level?.sumIrtDepositInDay)}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    sumIrtDepositInDay: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              />
              <Input
                type='text'
                label='مجموع  واریز ریال در سطح'
                name='SumIrtDepositInLevel'
                value={digitSeparator(level?.sumIrtDepositInLevel)}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    sumIrtDepositInLevel: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              /> */}
              <Input
                type='text'
                label='حداکثر برداشت ریال'
                name='maxIrtWithdrawal'
                value={digitSeparator(level?.maxIrtWithdrawal)}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    maxIrtWithdrawal: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              />
              <Input
                type='text'
                label='حداکثر برداشت کریپتو به دلار'
                name='maxCryptoWithdrawal'
                value={digitSeparator(level?.maxCryptoWithdrawal)}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    maxCryptoWithdrawal: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              />

              {/* <Input
                type='text'
                label='حداقل میزان واریز ریال جهت ارتقا به سطح بعد'
                name='minDepositIrtForUpgrade'
                value={digitSeparator(level?.minDepositIrtForUpgrade)}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    minDepositIrtForUpgrade: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              /> */}

              <TextArea
                name='description'
                value={level?.description}
                onChange={(e) =>
                  setLevel({ ...level, description: e.target.value })
                }
              />
              <div className='row'>
                <span>بازنشاندن ماهانه:</span>
                <Switch
                  checkedChildren={"فعال"}
                  unCheckedChildren={"غیر فعال"}
                  checked={level.resetMonthly}
                  onChange={(checked) =>
                    setLevel({
                      ...level,
                      resetMonthly: checked,
                    })
                  }
                />
              </div>

              <div className='buttons'>
                <Button
                  htmlType='button'
                  type='info'
                  text='اصلاح'
                  onClick={() => updateLevel(setLoading, level)}
                  loading={loading}
                />

                <Button
                  htmlType='button'
                  type='outlined'
                  text='بازگشت'
                  onClick={() => navigate("/users/levels/")}
                />
              </div>
            </form>
          )}
        </div>
      </Spin>
    </>
  );
};
