import { SearchOutlined, CaretDownOutlined } from "@ant-design/icons";
import { message, Row } from "antd";
import { Button, Input, Dropdown } from "components";
import * as React from "react";
import { digitSeparator, ToIranCurrency } from "utils/scripts/decimal";
import "./index.scss";
import { Link } from "react-router-dom";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  responseValidator,
} from "../../../utils/scripts/api";
import { API } from "../../../apis";
import SkeletonAsset from "./skeletons/skeleton-asset";
import { Asset, AssetItem } from "core/domains/markets/asset";
import { Pagination } from "components/pagination/pagination";
import { getIconPath } from "../../../utils/scripts/getIconPath";

export const Wallets: React.FC = () => {
  const [chartIsVisible, setChartIsVisible] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<"asc" | "desc">();
  const [search, setSearch] = React.useState<string>("");
  const [results, setResults] = React.useState<any[]>();
  const [assets, setAssets] = React.useState<Asset>();
  const [loading, setLoading] = React.useState<boolean>();

  const onChangeSortHandler = (value: string) => {
    setSort("asc");
    setResults(
      results && results.sort((a, b) => (a.balance > b.balance ? 1 : -1))
    );
  };
  const onSearchChangeHandler = (value: string) => {
    setSearch(value);
    setResults(
      assets?.assets &&
        assets?.assets.filter((s) =>
          s.symbol.toUpperCase().includes(value.toUpperCase())
        )
    );
  };
  React.useEffect(() => {
    setLoading(true);
    getUsersAssets();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    assets && setResults(assets.assets);
  }, [assets]);

  const getUsersAssets = async () => {
    await getViaAuth<Asset>(API.wallet.getAllWallets).then((data: any) => {
      if (responseValidator(data.status) && data.value) {
        data.value && setAssets(data.value);
      }
    });
  };

  return (
    <div className='vt-wallet-page'>
      <Row className='assets-row'>
        <div className='head'>
          <div className='mobile-show'>
            <h4>سبد دارایی ها</h4>
            <Dropdown
              onChangeHandle={onChangeSortHandler}
              items={[
                {
                  id: 1,
                  title: "زیاد به کم",
                  icon: (
                    <svg
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11.6792 7.43821C12.9617 7.43821 14.0005 8.45947 14.0005 9.71947C14.0005 10.9787 12.9617 12 11.6792 12C10.3975 12 9.35801 10.9787 9.35801 9.71947C9.35801 8.45947 10.3975 7.43821 11.6792 7.43821ZM5.95671 8.74662C6.51845 8.74662 6.97445 9.19462 6.97445 9.74651C6.97445 10.2977 6.51845 10.7464 5.95671 10.7464H1.51823C0.956486 10.7464 0.500488 10.2977 0.500488 9.74651C0.500488 9.19462 0.956486 8.74662 1.51823 8.74662H5.95671ZM2.82172 0C4.10422 0 5.14296 1.02126 5.14296 2.28053C5.14296 3.54053 4.10422 4.56179 2.82172 4.56179C1.53998 4.56179 0.500488 3.54053 0.500488 2.28053C0.500488 1.02126 1.53998 0 2.82172 0ZM12.9835 1.28137C13.5445 1.28137 14.0005 1.72937 14.0005 2.28053C14.0005 2.83242 13.5445 3.28042 12.9835 3.28042H8.54502C7.98327 3.28042 7.52727 2.83242 7.52727 2.28053C7.52727 1.72937 7.98327 1.28137 8.54502 1.28137H12.9835Z'
                        fill='url(#paint0_linear_417_6211)'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_417_6211'
                          x1='-3.29934'
                          y1='6.72944e-07'
                          x2='16.8416'
                          y2='2.15319'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#3F62FE' />
                          <stop offset='1' stop-color='#678AF5' />
                        </linearGradient>
                      </defs>
                    </svg>
                  ),
                },
                {
                  id: 2,
                  title: "کم به زیاد",
                  icon: (
                    <svg
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11.6792 7.43821C12.9617 7.43821 14.0005 8.45947 14.0005 9.71947C14.0005 10.9787 12.9617 12 11.6792 12C10.3975 12 9.35801 10.9787 9.35801 9.71947C9.35801 8.45947 10.3975 7.43821 11.6792 7.43821ZM5.95671 8.74662C6.51845 8.74662 6.97445 9.19462 6.97445 9.74651C6.97445 10.2977 6.51845 10.7464 5.95671 10.7464H1.51823C0.956486 10.7464 0.500488 10.2977 0.500488 9.74651C0.500488 9.19462 0.956486 8.74662 1.51823 8.74662H5.95671ZM2.82172 0C4.10422 0 5.14296 1.02126 5.14296 2.28053C5.14296 3.54053 4.10422 4.56179 2.82172 4.56179C1.53998 4.56179 0.500488 3.54053 0.500488 2.28053C0.500488 1.02126 1.53998 0 2.82172 0ZM12.9835 1.28137C13.5445 1.28137 14.0005 1.72937 14.0005 2.28053C14.0005 2.83242 13.5445 3.28042 12.9835 3.28042H8.54502C7.98327 3.28042 7.52727 2.83242 7.52727 2.28053C7.52727 1.72937 7.98327 1.28137 8.54502 1.28137H12.9835Z'
                        fill='url(#paint0_linear_417_6211)'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_417_6211'
                          x1='-3.29934'
                          y1='6.72944e-07'
                          x2='16.8416'
                          y2='2.15319'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#3F62FE' />
                          <stop offset='1' stop-color='#678AF5' />
                        </linearGradient>
                      </defs>
                    </svg>
                  ),
                },
              ]}
            />
          </div>
          <Input
            type='text'
            value={search}
            placeholder='ارز مورد نظر خود را جست و جو کنید'
            onChange={(e) => onSearchChangeHandler(e.target.value)}
            name='searchValue'
            adorments={{
              startAdornment: {
                adornmentIcon: <SearchOutlined />,
              },
              endAdornment: {
                adornmentIcon: <CaretDownOutlined color='#3f62fe' />,
              },
            }}
          />
          <Dropdown
            items={[
              {
                id: 1,
                title: "زیاد به کم",
                icon: (
                  <svg
                    width='14'
                    height='12'
                    viewBox='0 0 14 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.6792 7.43821C12.9617 7.43821 14.0005 8.45947 14.0005 9.71947C14.0005 10.9787 12.9617 12 11.6792 12C10.3975 12 9.35801 10.9787 9.35801 9.71947C9.35801 8.45947 10.3975 7.43821 11.6792 7.43821ZM5.95671 8.74662C6.51845 8.74662 6.97445 9.19462 6.97445 9.74651C6.97445 10.2977 6.51845 10.7464 5.95671 10.7464H1.51823C0.956486 10.7464 0.500488 10.2977 0.500488 9.74651C0.500488 9.19462 0.956486 8.74662 1.51823 8.74662H5.95671ZM2.82172 0C4.10422 0 5.14296 1.02126 5.14296 2.28053C5.14296 3.54053 4.10422 4.56179 2.82172 4.56179C1.53998 4.56179 0.500488 3.54053 0.500488 2.28053C0.500488 1.02126 1.53998 0 2.82172 0ZM12.9835 1.28137C13.5445 1.28137 14.0005 1.72937 14.0005 2.28053C14.0005 2.83242 13.5445 3.28042 12.9835 3.28042H8.54502C7.98327 3.28042 7.52727 2.83242 7.52727 2.28053C7.52727 1.72937 7.98327 1.28137 8.54502 1.28137H12.9835Z'
                      fill='url(#paint0_linear_417_6211)'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_417_6211'
                        x1='-3.29934'
                        y1='6.72944e-07'
                        x2='16.8416'
                        y2='2.15319'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#3F62FE' />
                        <stop offset='1' stop-color='#678AF5' />
                      </linearGradient>
                    </defs>
                  </svg>
                ),
              },
              {
                id: 2,
                title: "کم به زیاد",
                icon: (
                  <svg
                    width='14'
                    height='12'
                    viewBox='0 0 14 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.6792 7.43821C12.9617 7.43821 14.0005 8.45947 14.0005 9.71947C14.0005 10.9787 12.9617 12 11.6792 12C10.3975 12 9.35801 10.9787 9.35801 9.71947C9.35801 8.45947 10.3975 7.43821 11.6792 7.43821ZM5.95671 8.74662C6.51845 8.74662 6.97445 9.19462 6.97445 9.74651C6.97445 10.2977 6.51845 10.7464 5.95671 10.7464H1.51823C0.956486 10.7464 0.500488 10.2977 0.500488 9.74651C0.500488 9.19462 0.956486 8.74662 1.51823 8.74662H5.95671ZM2.82172 0C4.10422 0 5.14296 1.02126 5.14296 2.28053C5.14296 3.54053 4.10422 4.56179 2.82172 4.56179C1.53998 4.56179 0.500488 3.54053 0.500488 2.28053C0.500488 1.02126 1.53998 0 2.82172 0ZM12.9835 1.28137C13.5445 1.28137 14.0005 1.72937 14.0005 2.28053C14.0005 2.83242 13.5445 3.28042 12.9835 3.28042H8.54502C7.98327 3.28042 7.52727 2.83242 7.52727 2.28053C7.52727 1.72937 7.98327 1.28137 8.54502 1.28137H12.9835Z'
                      fill='url(#paint0_linear_417_6211)'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_417_6211'
                        x1='-3.29934'
                        y1='6.72944e-07'
                        x2='16.8416'
                        y2='2.15319'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#3F62FE' />
                        <stop offset='1' stop-color='#678AF5' />
                      </linearGradient>
                    </defs>
                  </svg>
                ),
              },
            ]}
          />
        </div>
        <div className='mine-assets-list'>
          <ul className='desktop-show'>
            {results ? (
              results.map((coin) => {
                return (
                  <li>
                    <span className='coin-info'>
                      <img
                        src={`/coins/${coin.symbol.toLowerCase()}.svg`}
                        alt=''
                      />
                      <span>{coin.name}</span>
                      <span>{coin.symbol?.toUpperCase()}</span>
                    </span>

                    <span className='amount-balance'>
                      موجودی :{" "}
                      <b>{coin.sum === 0 ? "0" : digitSeparator(coin.sum)}</b>
                    </span>

                    <span className='actions'>
                      <Link
                        className='detail'
                        to={`detail/` + coin.symbol.toLowerCase()}
                      >
                        <Button
                          className='detail-button'
                          type={"info"}
                          text={"جزئیات"}
                        />
                      </Link>
                    </span>
                  </li>
                );
              })
            ) : (
              <>
                <SkeletonAsset device='desktop' />
                <SkeletonAsset device='desktop' />
                <SkeletonAsset device='desktop' />
                <SkeletonAsset device='desktop' />
                <SkeletonAsset device='desktop' />
              </>
            )}
          </ul>
          <ul className='mobile-show'>
            {results ? (
              results.map((coin) => {
                return (
                  <li>
                    <div>
                      <div className='right'>
                        <img
                          src={`/coins/${coin.symbol.toLowerCase()}.svg`}
                          alt=''
                        />
                        <p>{coin.name}</p>
                        <span>{coin.symbol.toUpperCase()}</span>
                      </div>
                      {coin.symbol === "irt" ? (
                        <div className='left'>
                          <span>موجودی:</span>
                          <p>
                            {coin.sum === 0 ? "0" : digitSeparator(coin.sum)}
                          </p>
                        </div>
                      ) : (
                        <div className='left'>
                          <span>قیمت جهانی:</span>
                          <p>{coin.lastPrice}$</p>
                        </div>
                      )}
                    </div>
                    {coin.symbol !== "irt" && (
                      <>
                        <div>
                          <p className='right'>موجودی شما:</p>
                          <p className='left'>{coin.balance}</p>
                        </div>
                      </>
                    )}

                    <div className='actions'>
                      <Link className='detail' to={`detail/${coin.symbol}`}>
                        <Button
                          className='detail-button'
                          type={"info"}
                          text={"جزئیات"}
                        />
                      </Link>
                    </div>
                  </li>
                );
              })
            ) : (
              <>
                <SkeletonAsset device='mobile' />
                <SkeletonAsset device='mobile' />
                <SkeletonAsset device='mobile' />
                <SkeletonAsset device='mobile' />
                <SkeletonAsset device='mobile' />
              </>
            )}
          </ul>
          {/* {assets && assets.assets && (
            <Pagination
              data={results ? results : []}
              setdata={(e: any) => setResults(e)}
              size={10}
            />
          )} */}
        </div>
      </Row>
    </div>
  );
};
