import * as React from "react";
import "./skeleton-asset.scss";

type Props = {
  device: "mobile" | "desktop";
};
const SkeletonAsset: React.FC<Props> = (props) => {
  return (
    <div className={`vt-skeleton-asset ${props.device}`}>
      {props.device === "desktop" ? (
        <>
          <div className='vt-skeleton box'></div>
          <div className='vt-skeleton rectengle'></div>
          <div className='vt-skeleton rectengle'></div>
          <div className='vt-skeleton rectengle'></div>
          <div className=' buttons'>
            <div className='vt-skeleton widthrawl'></div>
            <div className='vt-skeleton deposit'></div>
          </div>
        </>
      ) : (
        <>
          <ul>
            <li>
              <span className='vt-skeleton rectengle'></span>
              <span className='vt-skeleton rectengle'></span>
            </li>
            <li>
              <span className='vt-skeleton rectengle'></span>
              <span className='vt-skeleton rectengle'></span>
            </li>
            <li>
              <span className='vt-skeleton rectengle'></span>
              <span className='vt-skeleton rectengle'></span>
            </li>
            <li>
              <span className='vt-skeleton rectengle'></span>
              <span className='vt-skeleton rectengle'></span>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default SkeletonAsset;
