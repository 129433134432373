import {message} from "antd";
import {API} from "apis";
import {Button, Card, Input} from "components";
import {SettingGoogleAuthType} from "core/domains";
import * as React from "react";
import {getValidationErrors, getViaAuth, hasValidationError, put, responseValidator,} from "utils/scripts/api";
import {SettingGoogleAuth} from "../google-auth/google-auth";
import "./index.scss";

interface PerfectMoneyAcccount {
  accountId?: string;
  payeeAccount?: string;
  password?: string;
}

export const PerfectMoneySettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [settings, setSettings] = React.useState<PerfectMoneyAcccount>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [type, setType] = React.useState<number>(1);

  const getPerfectMoneySettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.getPMsettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changePMSettings, {
      accountId: settings?.accountId,
      payeeAccount: settings?.payeeAccount,
      password: settings?.password,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getPerfectMoneySettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getPerfectMoneySettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card
        blur={cardLoading}
        title='تنظیمات حساب پرفکت مانی'
        className='vt-dashboard-pm-settings'
      >
        <Input
          label='کیف پول'
          type='text'
          name='payeeAccount'
          value={settings?.payeeAccount}
          onChange={(e) =>
            setSettings({ ...settings, payeeAccount: e.target.value })
          }
          dir='ltr'
          maxLength={80}
        />
        <Input
          label='نام کاربری'
          type='text'
          dir='ltr'
          name='account-id'
          value={settings?.accountId}
          onChange={(e) =>
            setSettings({ ...settings, accountId: e.target.value })
          }
          maxLength={50}
        />
        <Input
          label='رمز عبور'
          type='text'
          name='password'
          dir='ltr'
          value={settings?.password}
          onChange={(e) =>
            setSettings({ ...settings, password: e.target.value })
          }
          maxLength={50}
        />
        <Button
          htmlType='button'
          type='info'
          text='ثبت تغییرات'
          onClick={() => setModalIsOpen(true)}
          loading={loading}
          disabled={loading}
        />
      </Card>
    </>
  );
};
