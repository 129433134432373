import { message } from "antd";
import { API } from "apis";
import { PaginationList, Ticket, TicketForm } from "core/domains";
import { NavigateFunction, NavigateOptions } from "react-router-dom";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";

export const getAllTickets = (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  getViaAuth<PaginationList<Ticket>>(
    `${API.ticket.getAll}/${page}/${pageSize}${query}`
  ).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getTicketById = (setloader: any, setResponse: any, id: string) => {
  setloader(true);
  getViaAuth<Ticket>(API.ticket.get + `/${id}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    }
  });
};

export const closeTicket = (
  setloader: any,
  navigate: NavigateFunction,
  id: string
) => {
  setloader(true);
  postViaAuth(API.ticket.close, { ticketId: id }).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      navigate("/tickets");
    }
  });
};

export const answerTicket = (
  setloader: any,
  data: TicketForm | undefined,
  id: string
) => {
  setloader(true);
  postViaAuth(API.ticket.answer, {
    ticketId: id,
    ...data,
  }).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
    }
  });
};
