import * as React from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Calendar, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import {
  Transaction,
  TransactionsCount,
  TransactionState,
} from "core/domains/transaction/transaction";
import {
  editTransactionState,
  getAllTransactions,
  payoutTranscation,
} from "core/repos/transaction";
import { digitSeparator } from "utils/scripts/decimal";
import { Enum, FilterValues, PaginationList } from "core/domains";
import { Tag, Tooltip } from "antd";
import { getIconPath } from "../../../../utils/scripts/getIconPath";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { CurrencyTransactionModal } from "routes/dashboard/transactions/modals/currency";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";

const { useState } = React;

const defaultStateItems: Enum[] = [
  {
    title: "وضعیت تراکنش",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "نوع تراکنش",
    id: 0,
  },
];

const defaultMarketTypeItems: Enum[] = [
  {
    title: "نوع ارز",
    id: 0,
  },
];

const defaultPlatformItems: Enum[] = [
  {
    title: "پلتفرم",
    id: 0,
  },
];

type Props = {
  state?: any;
  type?: number;
};
export const ReferralWithdrawals: React.FC<Props> = (Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] =
    useState<PaginationList<Transaction>>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
    marketType: defaultMarketTypeItems[0],
    platform: defaultPlatformItems[0],
  });
  const [selectedItem, setSelectedItem] = useState<Transaction>();
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState<boolean>(false);
  // states : approve , reject , cancel
  const [confirmModalState, setConfirmModalState] = useState<TransactionState>(
    TransactionState.WaitingForAdmin
  );
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [payoutLoading, setPayoutLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>("");
  const [transactionsCount, setTransactionsCount] =
    useState<TransactionsCount>();

  const closePayoutModal = () => setPayoutModalIsOpen(false);

  const openPayoutModal = (item: Transaction) => {
    setSelectedItem(item);
    setPayoutModalIsOpen(true);
  };

  const openConfirmModal = (item: Transaction, state: TransactionState) => {
    setSelectedItem(item);
    setConfirmModalState(state);
    setConfirmModal(true);
  };

  async function onApproveHandler() {
    selectedItem &&
      (await payoutTranscation(
        setPayoutLoading,
        selectedItem,
        setPayoutModalIsOpen
      ));
    fetchAllData(query);
  }

  const closeAppproveModal = () => {
    setDescriptionValue("");
    setConfirmModal(false);
  };

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "5%",
    },

    {
      name: "شناسه",
      id: "trackingCode",
      width: "10%",
      selector: (row: Transaction) => row.trackingCode,
      cell: (row: Transaction) => (
        <span className="en">{row.trackingCode}</span>
      ),
    },
    {
      name: "نام ارز",
      id: "market",
      width: "8%",
      selector: (row: Transaction) => row.market.name,
      cell: (row: Transaction) => (
        <>
          <img
            style={{ height: "24px", marginLeft: "6px" }}
            src={getIconPath(row.market.symbol)}
          />
          <span className="en">{row.market.symbol}</span>
        </>
      ),
    },
    {
      name: "نوع تراکنش",
      id: "transactionType",
      width: "9%",
      selector: (row: Transaction) => row.type.title,
      cell: (row: Transaction) => (
        <>
          <Tag color={`${row.type.id === 1 ? "green" : "red"}`}>
            {row.type.title}
          </Tag>
        </>
      ),
    },
    {
      name: "مقدار ",
      id: "amount",

      width: "8%",
      selector: (row: Transaction) => digitSeparator(row.amount),
    },
    {
      name: "نام کاربر",
      id: "createdBy",

      width: "12%",
      cell: (row: Transaction) => (
        <div className="column-createdBy">
          <span className="name">{row.createdBy}</span>
          <p className="phone">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "پلتفرم",
      id: "platform",
      width: "8%",
      selector: (row: Transaction) => row.platform.title,
      cell: (row: Transaction) => (
        <>
          <Tag color={`${row.platform.id === 1 ? "blue" : "green"}`}>
            {row.platform.title}
          </Tag>
        </>
      ),
    },
    {
      name: "زمان",
      id: "createdAt",
      width: "10%",
      selector: (row: Transaction) => row.createdAt,

      cell: (row: Transaction) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      width: "14%",
      selector: (row: Transaction) => row.state.id,
      cell: (row: Transaction) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 2
              ? "success"
              : row.state.id == 1 || row.state.id == 6
              ? "waiting"
              : "error"
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? "success"
              : row.state.id == 1 || row.state.id == 6
              ? "waiting"
              : "danger"
          }.svg`}
        />
      ),
    },
    {
      name: "جزئیات",
      id: "detail",
      width: "16%",
      cell: (row: Transaction) => (
        <div className="actions">
          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"تایید و تسویه تراکنش"}>
              <DollarCircleOutlined onClick={() => openPayoutModal(row)} />
            </Tooltip>
          )}
          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"تایید تراکنش"}>
              <CheckOutlined
                onClick={() => openConfirmModal(row, TransactionState.Succeed)}
              />
            </Tooltip>
          )}
          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"لغو تراکنش"}>
              <CloseOutlined
                onClick={() => openConfirmModal(row, TransactionState.Cancel)}
              />
            </Tooltip>
          )}

          <Tooltip placement="top" title={"ویرایش تراکنش"}>
            <EditOutlined
              onClick={() =>
                window.open(`/transactions/edit/${row.id}`, "_self")
              }
            />
          </Tooltip>
          <Tooltip placement="top" title={"جزئیات تراکنش"}>
            <InfoCircleOutlined onClick={() => handleModal(row)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }

  const filterHandle = () => {
    var _query = "?market=IRT&type=4";

    if (filterValues?.startDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }startDate=${convertFaToEnCalendar(filterValues?.startDate)}`;
    if (filterValues?.endDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }endDate=${convertFaToEnCalendar(filterValues?.endDate)}`;
    if (filterValues?.keywords) {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += filterValues?.keywords;
    }

    setQuery(_query);
    getAllTransactions(
      setLoading,
      setTransactions,
      1,
      pageSize,
      _query,
      setTransactionsCount
    );
  };

  const fetchAllData = (query: string) => {
    getAllTransactions(
      setCardLoading,
      setTransactions,
      getPageNum(),
      pageSize,
      query,
      setTransactionsCount
    );
  };

  React.useEffect(() => {
    filterHandle();
  }, [filterValues]);

  async function onConirmHandler() {
    selectedItem &&
      (await editTransactionState(
        setApproveLoading,
        selectedItem,
        closeAppproveModal,
        confirmModalState,
        (confirmModalState === TransactionState.Succeed ||
          confirmModalState === TransactionState.Cancel) &&
          descriptionValue !== ""
          ? descriptionValue
          : undefined
      ));
    fetchAllData(query);
  }

  return (
    <div className="currency-transactions">
      {selectedItem && selectedItem.market.symbol == "IRT" && (
        <CurrencyTransactionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          item={selectedItem}
        />
      )}

      {selectedItem && (
        <Modal
          setIsOpen={setConfirmModal}
          isOpen={confirmModal}
          title={`از ${
            confirmModalState === TransactionState.Succeed
              ? "تایید"
              : confirmModalState === TransactionState.Cancel
              ? "لغو"
              : "رد"
          } این تراکش مطمئن هستید؟`}
          closeAble
          className="transaction-approve-modal"
          onClose={closeAppproveModal}
        >
          {(confirmModalState === TransactionState.Succeed ||
            confirmModalState === TransactionState.Cancel) && (
            <Input
              name="description"
              label="توضیحات"
              placeholder="توضیحات را وارد کنید"
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={descriptionValue}
              type="text"
            />
          )}
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={approveLoading}
              disabled={approveLoading}
              htmlType="button"
              onClick={onConirmHandler}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closeAppproveModal()}
            />
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setPayoutModalIsOpen}
          isOpen={payoutModalIsOpen}
          closeAble
          title="آیا از تسویه تراکنش مطمین هستید؟"
          className="transaction-payout-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={payoutLoading}
              disabled={payoutLoading}
              htmlType="button"
              onClick={onApproveHandler}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closePayoutModal()}
            />
          </div>
        </Modal>
      )}
      <Card
        title="رفرال- برداشت ها"
        blur={cardLoading}
        className="desktop-show"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues?.keywords}
              placeholder="جستجو کنید ..."
              name="search"
              className="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />
          </div>
        </div>
        <div className="filter-row">
          <div className="fields">
            <Calendar
              value={filterValues?.startDate}
              placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues?.endDate}
              placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => filterHandle()}
            />
            <span
              onClick={() => {
                setFilterValues({
                  keywords: "",
                  startDate: "",
                  endDate: "",
                  type: defaultTypeItems[0],
                  state: defaultStateItems[0],
                  marketType: defaultMarketTypeItems[0],
                });
                getAllTransactions(
                  setLoading,
                  setTransactions,
                  getPageNum(),
                  pageSize,
                  "?market=IRT&type=4",
                  setTransactionsCount
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {transactions && (
          <Table
            filters={query}
            getData={getAllTransactions}
            setData={setTransactions}
            setLoader={setCardLoading}
            pagination={transactions.pagination}
            data={transactions.items}
            column={columns}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Card>
    </div>
  );
};
