import { message } from "antd";
import { API } from "apis";
import {
  AuthenticationScore,
  AuthenticationScoreAction,
} from "core/domains/authentication-score/authentication-score";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";

export const getAllAuthenticationScores = (setLoader: any, setData: any) => {
  setLoader(true);
  getViaAuth(API.authenticationScores.all).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      const authenticationScores: AuthenticationScore[] = response.value;
      setData(authenticationScores);
    }
  });
};

export const getAllAuthenticationScoreActions = (
  setLoader: any,
  setData: any,
  page: number,
  size: number,
  query?: string
) => {
  setLoader(true);
  getViaAuth(
    API.authenticationScores.allActions + `/${page}/${size}${query}`,
    {}
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      const scoreHistory: AuthenticationScoreAction[] = response.value;
      setData(scoreHistory);
    }
  });
};

export const editAuthenticationScorePoint = (
  setmodalloader: any,
  setisopen: any,
  data: any,
  onComplete: any
) => {
  setmodalloader(true);
  postViaAuth(API.authenticationScores.edit, data).then((response: any) => {
    setmodalloader(false);
    if (responseValidator(response.status)) {
      message.success("مقدار امتیاز ویرایش شد");
      onComplete();
      setisopen(false);
    }
  });
};
