import {API} from "apis";
import {message} from "antd";
import {Fiat, PartnerPrice} from "core/domains";
import {getViaAuth, postViaAuth, responseValidator,} from "utils/scripts/api";

export const getFiatById = (setloader: any, setdata: any, id: string) => {
  setloader(true);
  getViaAuth(API.fiats.getById + `/${id}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateFiats = async (data: Fiat[], setloader: any, navigate: any) => {
  if (data) {
    setloader(true);
    await postViaAuth(API.fiats.editFiat, {
      'Fiats': data
    }).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        message.success("ارز ها با موفقیت اصلاح شد.");
        navigate("/fiats");
      }
    });
  }
};

export const updatePartnerPrice = async (
  data: PartnerPrice,
  setloader: any,
  navigate: any
) => {
  if (data) {
    setloader(true);
    await postViaAuth(API.fiats.editFiat, {
      siteName: data.siteName,
      prices: data.prices,
    }).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        message.success("قیمت همکار مورد نظر با موفقیت اصلاح شد.");
        navigate("/fiats");
      }
    });
  }
};
