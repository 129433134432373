import {message} from "antd";
import {API} from "apis";
import {Button, Card, Input} from "components";
import * as React from "react";
import {getViaAuth, put, responseValidator,} from "utils/scripts/api";
import "./index.scss";

interface PayStarSettings {
    key?: string,
    walletHashId?: string,
    password?: string,
    withdrawlSign?:string,
    withdrawlApiKey?:string,
    refreshTokenWallet?:string,

}

export const PayStarSettings: React.FC = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [cardLoading, setCardLoading] = React.useState<boolean>(false);
    const [payStarSettings, setPayStarSettings] = React.useState<PayStarSettings>();

    const getPaystarSettings = async () => {
        setCardLoading(true);
        await getViaAuth(API.settings.payStarSettings).then((response: any) => {
            setCardLoading(false);
            if (responseValidator(response.status)) {
                setPayStarSettings(response.value);
            }
        });
    };

    const onConfirmHandler = () => {
        setLoading(true);

        put(API.settings.changePaystarSettings, {
            key: payStarSettings?.key,
            password: payStarSettings?.password,
            walletHashId: payStarSettings?.walletHashId,
            withdrawlSign:payStarSettings?.withdrawlSign,
            withdrawlApiKey:payStarSettings?.withdrawlApiKey,
            refreshTokenWallet:payStarSettings?.refreshTokenWallet
        }).then((response: any) => {
            setLoading(false);
            if (responseValidator(response.status)) {
                message.success(response.data.message);
                getPaystarSettings();
            }
        });
    };

    React.useEffect(() => {
        getPaystarSettings();
    }, []);

    return (
        <>

            <Card blur={cardLoading} className='vt-dashboard-paystar-settings'>
                <Card title={`کانفیگ درگاه پی استار`} className='gateway-item'>

                    <Input
                        label='کلید'
                        type='text'
                        name='refreshToken'
                        value={payStarSettings?.key}
                        onChange={(e) =>
                            setPayStarSettings({
                                ...payStarSettings,
                                key: e.target.value,
                            })
                        }
                        dir='ltr'
                    />
                    <Input
                        label='کلید کیف پول'
                        type='text'
                        name='withdrawlSign'
                        value={payStarSettings?.withdrawlSign}
                        onChange={(e) =>
                            setPayStarSettings({
                                ...payStarSettings,
                                withdrawlSign: e.target.value,
                            })
                        }
                        dir='ltr'
                    />
                    <Input
                        label='رفرش توکن کیف پول'
                        type='text'
                        name='refreshTokenWallet'
                        value={payStarSettings?.refreshTokenWallet}
                        onChange={(e) =>
                            setPayStarSettings({
                                ...payStarSettings,
                                refreshTokenWallet: e.target.value,
                            })
                        }
                        dir='ltr'
                    />
                    <Input
                    label='ای پی ای کی کیف پول'
                    type='text'
                    name='withdrawlApiKey'
                    value={payStarSettings?.withdrawlApiKey}
                    onChange={(e) =>
                        setPayStarSettings({
                            ...payStarSettings,
                            withdrawlApiKey: e.target.value,
                        })
                    }
                    dir='ltr'
                    />
                    <Input
                        label='هش کیف پول'
                        type='text'
                        name='walletHashId'
                        value={payStarSettings?.walletHashId}
                        onChange={(e) =>
                            setPayStarSettings({
                                ...payStarSettings,
                                walletHashId: e.target.value,
                            })
                        }
                        dir='ltr'
                    />
                    <Input
                        label='رمز کیف پول'
                        type='text'
                        name='password'
                        value={payStarSettings?.password}
                        onChange={(e) =>
                            setPayStarSettings({
                                ...payStarSettings,
                                password: e.target.value,
                            })
                        }
                        dir='ltr'
                    />

                    <Button
                        htmlType='button'
                        type='info'
                        text='ثبت تغییرات'
                        onClick={() => onConfirmHandler()}
                        loading={loading}
                        disabled={
                            loading ||
                            payStarSettings?.key === ""
                        }
                    />
                </Card>
            </Card>
        </>
    );
};
