import * as React from "react";
import {Button, Input} from "components";
import "./edit.scss";
import {Col, Row, Select, Spin, Switch, Upload, UploadProps,} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {faNumToEnNum, IsNumeric} from "utils/scripts/decimal";
import {getPopUpById, updatePopUp} from "core/repos/popup";
import {AddPopUp} from "core/domains";
import {RcFile, UploadFile} from "antd/lib/upload";
import {message} from "components/toast/toast";
import {UploadChangeParam} from "antd/es/upload";
import {getAllLevelsAsSelectOption} from "core/repos/level";
import {DefaultOptionType} from "antd/es/select";

export const UpdatePopUp: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [popup, setPopUp] = React.useState<AddPopUp>();
  const [levels, setLevels] = React.useState<DefaultOptionType[]>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    DefaultOptionType[]
  >([]);

  const location = useLocation();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  var id = urlSections[urlSections.length - 1];

  const navigate = useNavigate();

  React.useEffect(() => {
    levels.length === 0 &&
      getAllLevelsAsSelectOption(setIsCardLoading, setLevels);
    !popup && getPopUpById(setIsCardLoading, setPopUp, setSelectedOptions, id);
  }, []);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setPopUp({
        ...popup,
        file: info.file.originFileObj,
      });
    }
  };

  const onNumberChange = (value: string, field: string) => {
    if (value !== "") {
      value = faNumToEnNum(value);
    }

    if (
      value !== "" &&
      !IsNumeric(faNumToEnNum(value.split("")[value.split("").length - 1]))
    ) {
      return;
    }

    setPopUp({
      ...popup,
      [field]: value,
    });
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "video/mp4";

    if (!isJpgOrPng) {
      message.error("فقط فرمت های mp4 قابل قبول میباشد");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("حجم عکس باید کمتر از ۲ مگابایت باشد.");
    }

    return setPopUp({ ...popup, imageUrl: undefined, file: file });
  };

  const onRemoveFile = (file: UploadFile) => {
    setPopUp({
      ...popup,
      imageUrl: undefined,
      file: undefined,
    });
  };

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-popups-edit-popup'>
          <h3>اصلاح پاپ آپ</h3>

          <form>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='عنوان'
                  name='title'
                  value={popup?.title}
                  onChange={(e) =>
                    setPopUp({
                      ...popup,
                      title: e.target.value || "",
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  type='text'
                  label='فاصله بین هر نمایش (روز)'
                  name='intervalView'
                  value={popup?.intervalView}
                  onChange={(e) =>
                    onNumberChange(e.target.value, "intervalView")
                  }
                  maxLength={10}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='لینک شدن به صفحه'
                  name='linkTo'
                  value={popup?.linkTo}
                  onChange={(e) =>
                    setPopUp({
                      ...popup,
                      linkTo: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  type='text'
                  label='نمایش در صفحه'
                  name='urlPage'
                  value={popup?.urlPage}
                  onChange={(e) =>
                    setPopUp({
                      ...popup,
                      urlPage: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              {levels.length != 0 && (
                <div className='select'>
                  <label htmlFor=''>سطح‌های مورد نمایش</label>
                  <Select
                    mode='multiple'
                    allowClear
                    value={selectedOptions}
                    placeholder='انتخاب کنید'
                    onChange={(value, options) => {
                      let mapOptions = options.map((i: DefaultOptionType) => ({
                        value: i.value,
                        label: i.label,
                      }));
                      setSelectedOptions(mapOptions);
                    }}
                    defaultValue={selectedOptions}
                    options={levels}
                  />
                </div>
              )}
            </Row>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='نمایش بعد از (ثانیه)'
                  name='viewAfterSecond'
                  value={popup?.viewAfterSecond}
                  onChange={(e) =>
                    onNumberChange(e.target.value, "viewAfterSecond")
                  }
                  maxLength={10}
                />
              </Col>
              <Col>
                <Input
                  type='text'
                  label='تعداد نمایش'
                  name='viewCount'
                  value={popup?.viewCount}
                  maxLength={10}
                  onChange={(e) => onNumberChange(e.target.value, "viewCount")}
                />
              </Col>
            </Row>
            <Row>
              <div className='textarea'>
                <label htmlFor='description'>توضیحات</label>
                <TextArea
                  name='description'
                  value={popup?.description}
                  onChange={(e) =>
                    setPopUp({ ...popup, description: e.target.value })
                  }
                />
              </div>
            </Row>
            <Row>
              <div className='browse-field'>
                <label htmlFor='documents-photo'>تصویر</label>

                <div id='documents-photo' className='browse'>
                  <Upload
                    name='avatar'
                    listType='picture-card'

                    className='file-uploader'
                    showUploadList={true}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    fileList={popup?.file && [popup.file]}
                    onRemove={onRemoveFile}
                  >
                    <>
                      <button onClick={(el) => el.preventDefault()}>
                        آپلود فایل
                      </button>
                    </>
                  </Upload>
                </div>
              </div>
            </Row>
            <Row>
              <label htmlFor='documents-photo'>وضعیت</label>

              <Switch
                checked={popup?.isActive}
                checkedChildren={"فعال"}
                unCheckedChildren={"غیر فعال"}
                onChange={(checked) =>
                  setPopUp({
                    ...popup,
                    isActive: checked,
                  })
                }
              />
            </Row>

            <div className='buttons'>
              <Button
                htmlType='button'
                type='info'
                text='اصلاح'
                onClick={() => {
                  updatePopUp(setLoading, popup, selectedOptions, id);
                }}
                loading={loading}
              />

              <Button
                htmlType='button'
                type='outlined'
                text='بازگشت'
                onClick={() => navigate("/popups")}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
