import { DownOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { Enum, FilterValues, PaginationList } from "core/domains";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import "./index.scss";
import { getPageNum } from "utils/scripts/paggingHelper";
import { getAllReferralInfos } from "core/repos/referral";
import { ReferralInfo } from "core/domains/referral/referral";
import { digitSeparator } from "../../../../utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

const defaultStateItems: Enum[] = [
  {
    title: "همه",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "همه",
    id: 100,
  },
];

export const ReferralCoWorkers: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [coWorkers, setActions] = useState<PaginationList<ReferralInfo>>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [types, setTypes] = useState<Enum[]>(defaultTypeItems);
  const [states, setStates] = useState<Enum[]>(defaultStateItems);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
  });

  useEffect(() => {
    !coWorkers &&
      getAllReferralInfos(
        setCardLoading,
        setActions,
        getPageNum(),
        pageSize,
        ""
      );
    // states.length === 1 && getActionStates(setCardLoading, setStates);
    // types.length === 1 && getActionTypes(setCardLoading, setTypes);
  }, []);

  const onFilterHandler = () => {
    var query = "";
    if (filterValues?.type?.id !== 100)
      query += `${query.includes("?") ? "&" : "?"}entity=${
        filterValues?.type?.id
      }`;
    if (filterValues?.state?.id !== 0)
      query += `${query.includes("?") ? "&" : "?"}state=${
        filterValues?.state?.id
      }`;
    if (filterValues?.keywords)
      query += `${query.includes("?") ? "&" : "?"}keywords=${
        filterValues?.keywords
      }`;
    getAllReferralInfos(setLoading, setActions, 1, pageSize, query);
  };
  const columns: TableColumn<any>[] = [
    {
      name: "کد",
      id: "code",
      selector: (row: ReferralInfo) => row?.code,
    },
    {
      name: "نام کاربر",
      id: "createdBy",
      width: "12%",
      cell: (row: ReferralInfo) => (
        <div className="column-createdBy">
          <span className="name">{row.fullName}</span>
          <p className="phone">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "موجودی",
      id: "balance",
      selector: (row: ReferralInfo) => digitSeparator(row.balance.toFixed(0)),
    },
    {
      name: "سطح",
      id: "referralLevelTitle",
      selector: (row: ReferralInfo) => row.referralLevelTitle,
    },
    {
      name: "درصد پاداش",
      id: "referralLevelRewardPercent",
      selector: (row: ReferralInfo) => row.referralLevelRewardPercent + "٪",
    },
    {
      name: "موجودی کل",
      id: "totalBalance",
      selector: (row: ReferralInfo) =>
        digitSeparator(row.totalBalance.toFixed(0)),
    },
    {
      name: "کاربران دعوت شده",
      id: "userCount",
      selector: (row: ReferralInfo) => row.userCount + " نفر",
    },
    {
      name: "تعداد فعالیت",
      id: "actionCount",
      selector: (row: ReferralInfo) => row.actionCount,
    },
    {
      name: "زمان همکار شدن",
      id: "createdOn",
      selector: (row: ReferralInfo) => row.createdOn,
      cell: (row: ReferralInfo) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "آخرین تغییر",
      id: "lastModifiedDate",
      selector: (row: ReferralInfo) => row.lastModifiedDate,
      cell: (row: ReferralInfo) => (
        <div className="column-date">
          <span className="day">
            {row.lastModifiedDate &&
              moment
                .utc(row.lastModifiedDate)
                .locale("fa")
                .local()
                .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {row.lastModifiedDate &&
              moment.utc(row.lastModifiedDate).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "coWorker",
      cell: (record: ReferralInfo) => (
        <div className="coWorkers">
          <Link to={`details/${record.code}`}>
            <FileSearchOutlined />
          </Link>
        </div>
      ),
      width: "2%",
    },
  ];

  return (
    <Card
      title="رفرال- همکاران"
      className="vt-dashboard-referral-co-workers"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            name="search"
            onPressEnter={() => onFilterHandler()}
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />

          {types.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    types.map((type) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, type })
                        }
                      >
                        {type.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.type?.title}
              />
            </Dropdown>
          )}

          {states.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    states.map((state) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, state })
                        }
                      >
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.state?.title}
              />
            </Dropdown>
          )}
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
                type: defaultTypeItems[0],
                state: defaultStateItems[0],
              });
              getAllReferralInfos(
                setLoading,
                setActions,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="referral-co-workers-table"
        data={coWorkers?.items}
        pagination={coWorkers?.pagination}
        column={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Card>
  );
};
