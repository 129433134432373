import { API } from "apis";
import { message } from "antd";
import { Cart, Enum } from "core/domains";
import {
  del,
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  put,
  responseValidator,
} from "utils/scripts/api";
import { getPageNum } from "../../utils/scripts/paggingHelper";
import { Dispatch, SetStateAction } from "react";

export const getUserCarts = (setloader: any, setdata: any, id: string) => {
  setloader(true);
  getViaAuth(API.users.carts.getAll + id).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const removeUserBankAccount = (
  setLoader: any,
  id: string,
  onComplete: any
) => {
  setLoader(true);
  del(API.users.carts.remove + `/${id}`, {}).then((response: any) => {
    if (responseValidator(response.status)) {
      setLoader(false);
      onComplete();
    }
  });
};

export const getCartStates = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth<Enum>(API.enums.cartStates).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getAllCarts = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(API.banks.list + `/${page}/${size}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const updateCart = (
  setcardloader: any,
  setloader: any,
  setdata: any,
  cart: Cart,
  setClose: any
) => {
  setloader(true);
  put(API.banks.update, {
    cardNumber: cart.cardNumber,
    iBan: cart.iBan.replace("IR", ""),
    isRemoved: cart.isRemoved,
  }).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("حساب بانکی مورد نظر با موفقیت تایید شد.");
      setClose();
      setdata(getAllCarts(setcardloader, setdata, getPageNum(), 25, ""));
    }
  });
};

export const editCartState = (
  setLoader: Dispatch<SetStateAction<boolean>>,
  data: { state: number; id: string },
  onComplete?: () => void
) => {
  setLoader(true);
  postViaAuth(API.banks.editState, data).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      message.success("وضعیت با موفقیت ویرایش شد.");
      onComplete && onComplete();
    }
  });
};
