import * as React from "react";
import Transfer, { TransferDirection } from "antd/lib/transfer";
import { __API } from "apis/api";
import { Button, Input } from "components";
import { Permission, Role } from "core/domains";

import "./create.scss";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "components/modal/modal";
import ReactCodeInput from "react-verification-code-input";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { useState, useRef, useEffect } = React;

type GoogleAuth = {
  code?: string;
  error?: string;
};

const permissionsData: any[] = Array.from({ length: 10 }).map((_, i) => ({
  key: i.toString(),
  displayText: `دسترسی ${i + 1}`,
}));

const initialTargetKeys = permissionsData
  .filter((item) => Number(item.id) > 10)
  .map((item) => item.id.toString());

export const CreateRole: React.FC = () => {
  // States
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [roleData, setRoleData] = useState<Role>();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const [role, setRole] = useState<any>();

  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [googleAuthData, setGoogleAthData] = useState<GoogleAuth>();

  const location = useLocation();
  const navigate = useNavigate();

  const createRoleHandle = async (code: any) => {
    setLoading(true);
    await postViaAuth(__API.roles.createRole, {
      name: roleData?.title,
      title: roleData?.title,
      claims: targetKeys,
      description: roleData?.description,
      googleAuthCode: code,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success("نقش مورد نظر با موفقیت ایجاد شد.");
        navigate("/users/roles/");
      } else {
        if (response.status === 400)
          setGoogleAthData({
            ...googleAuthData,
            error: response.message,
            code: "",
          });
      }
    });
  };

  const onChange = (
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedPermissions([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onScroll = (
    direction: TransferDirection,
    e: React.SyntheticEvent<HTMLUListElement>
  ) => {};

  useEffect(() => {
    (async () => {
      setIsCardLoading(true);
      await getViaAuth(__API.roles.getAllPermissions).then((response: any) => {
        setIsCardLoading(false);
        if (responseValidator(response.status)) {
          setPermissions(response.value);
        }
      });
    })();
  }, []);

  return (
    <>
      <Modal
        closeAble
        title={"کد تایید Google Atuh"}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        className='create-role-modal'
      >
        <div className='google-auth code app-code'>
          <ReactCodeInput
            className={`input `}
            fieldWidth={42}
            values={googleAuthData?.code ? googleAuthData?.code?.split("") : []}
            autoFocus={true}
            onChange={(value: any) => {
              setGoogleAthData({
                ...googleAuthData,
                code: value,
              });
            }}
            onComplete={(e) => createRoleHandle(e)}
          />
          <div className='stats'>
            <div className='errors'>
              {googleAuthData?.error && (
                <>
                  <QuestionCircleOutlined />
                  <span>{googleAuthData?.error}</span>
                </>
              )}
            </div>
          </div>
        </div>

        <Button
          type='info'
          text='تایید'
          onClick={() => createRoleHandle(googleAuthData?.code)}
          disabled={loading ? true : false}
          loading={loading}
        />
      </Modal>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-roles-create'>
          <h3>ایجاد نقش</h3>

          <form>
            <Input
              type='text'
              label='عنوان'
              value={roleData?.title}
              onChange={(e) =>
                setRoleData({ ...roleData, title: e.target.value })
              }
              name='title'
              placeholder='عنوان نقش را وارد کنید'
            />

            <TextArea
              name='description'
              value={roleData?.description}
              onChange={(e) =>
                setRoleData({ ...roleData, description: e.target.value })
              }
              placeholder='توضیحات نقش را وارد کنید'
            />
            {permissions.length > 0 && (
              <Transfer
                className='roles-transfer'
                dataSource={permissions}
                titles={["لیست دسترسی ها", "دسترسی های نقش"]}
                targetKeys={targetKeys}
                selectedKeys={selectedPermissions}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onScroll={onScroll}
                render={(item) => item.displayText}
                locale={{
                  itemUnit: "مورد",
                  itemsUnit: "مورد",
                  selectAll: "انتخاب همه",
                  selectCurrent: "تایید موارد انتخاب شده",
                  selectInvert: "معکوس کردن انتخاب ها",
                }}
              />
            )}

            <div className='buttons'>
              <Button
                type='info'
                text='ایجاد'
                onClick={() => setModalIsOpen(true)}
                loading={loading}
                htmlType='button'
              />
              <Button
                type='outlined'
                text='بازگشت'
                onClick={() => navigate("/users/roles/")}
                htmlType='button'
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
