import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  FileSearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  MenuProps,
  message,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { API } from "apis";
import { __API } from "apis/api";
import { Button, Card, Input } from "components";
import { Modal } from "components/modal/modal";
import { Table } from "components/table/table";
import {
  Enum,
  EnumAsString,
  FinancialSettings,
  PaginationList,
  SortDirection,
  User,
} from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
  IsNumeric,
} from "utils/scripts/decimal";
import "./index.scss";
import { getAllUsers, updateUserActivation } from "core/repos/user";
import { getAllRolesAsEnum } from "core/repos/role";
import { getAllLevelsAsEnum } from "core/repos/level";
import moment from "moment";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";

const { useState, useRef, useEffect } = React;

export interface FilterValues {
  sort?: SortDirection;
  level: Enum;
  role: EnumAsString;
  keywords?: string;
}

const defaultLevelItems: Enum[] = [
  {
    title: "همه سطوح",
    id: 0,
  },
];
const defaultRoleItems: EnumAsString[] = [
  {
    title: "همه نقش ها",
    id: "",
  },
];

export const VIPUsers: React.FC = () => {
  const [financialModal, setFinancialModal] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [financialSettings, setFinancialSettings] =
    useState<FinancialSettings>();
  const [loading, setLoading] = useState<boolean>(false);
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<PaginationList<User>>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [roles, setRoles] = useState<EnumAsString[]>([]);
  const [levels, setLevels] = useState<Enum[]>([]);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    role: defaultRoleItems[0],
    level: defaultLevelItems[0],
  });
  const [selectedItem, setSelectedItem] = useState<User>();

  const [removeModalIsOpen, setRemoveModalIsOpen] = useState<{
    state: boolean;
    record: User | null;
  }>({
    state: false,
    record: null,
  });

  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState<{
    state: boolean;
    record: User | null;
  }>({
    state: false,
    record: null,
  });

  const onLevelClick: MenuProps["onClick"] = ({ key }) => {
    setFilterValues({
      ...filterValues,
      level: levels?.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const onRoleClick: MenuProps["onClick"] = ({ key }) => {
    setFilterValues({
      ...filterValues,
      role: roles?.filter((t) => t.id === key)[0],
    });
  };

  const handleNumChange = (value: string, field: string) => {
    // all number Fa to En
    if (value !== "") value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== "" && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setFinancialSettings({
      ...financialSettings,
      [field]: digitSeparator(value),
    });
  };

  const removeUser = () => {
    postViaAuth(__API.users.get, {}).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت اصلاح شد.");
      }
    });
  };

  const openFinancialSettingsModal = () => setFinancialModal(true);
  const closeFinancialSettingsModal = () => {
    setFinancialSettings({});
    setFinancialModal(false);
  };

  const onFinancialSettingsHandler = (id: string) => {
    setModalLoading(true);
    openFinancialSettingsModal();
    getViaAuth<FinancialSettings>(API.financialSettings.get + `/${id}`).then(
      (data: any) => {
        setModalLoading(false);
        if (responseValidator(data.status) && data.value) {
          data.value && setFinancialSettings(data.value);
          data.value.lockAllWithdrawal == null &&
            setFinancialSettings({ ...data.value, lockAllWithdrawal: false });
        } else {
        }
      }
    );
  };

  const isActiveHandle = (id: string, checked: boolean) => {
    updateUserActivation(
      setCardLoading,
      { id, isActive: checked },
      setUsersList
    );
  };

  const onUpdateFinancialSettings = (id: string) => {
    setLoading(true);
    postViaAuth(__API.financialSettings.update, {
      userId: id,
      maxIrtWithdrawlInDay: parseFloat(
        deleteCommaSeparator(financialSettings?.maxIrtWithdrawlInDay || 0)
      ),
      maxIrtDepositInDay: parseFloat(
        deleteCommaSeparator(financialSettings?.maxIrtDepositInDay || 0)
      ),
      lockAllWithdrawal: financialSettings?.lockAllWithdrawal,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت اصلاح شد.");
      }
    });
  };

  const onRemoveFinancialSettings = (id: string) => {
    setLoading(true);

    postViaAuth(__API.financialSettings.remove, {
      userId: id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت حذف شد.");
      }
    });
  };

  const query = () => {
    var q = "";
    q += "?viponly=true";
    if (filterValues?.role.id !== "")
      q += `${q.includes("?") ? "&" : "?"}role=${filterValues?.role.title}`;
    if (filterValues?.level.id !== 0)
      q += `${q.includes("?") ? "&" : "?"}level=${filterValues?.level.id}`;
    if (filterValues?.keywords) {
      q += `${q.includes("?") ? "&" : "?"}keywords=`;
      q += filterValues?.keywords;
    }
    return q;
  };

  const onFilterHandler = () => {
    getAllUsers(setLoading, setUsersList, 1, pageSize, query());
  };

  const columns: TableColumn<User>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: User) => row.fullName,
      cell: (record: User) =>
        record.fullName === "" ? "احراز نشده" : record.fullName,
    },
    {
      name: "نام کاربری",
      id: "userName",
      selector: (row: User) => row?.userName,
    },
    {
      name: "نقش ها",
      id: "roles",
      cell: (record: User) =>
        record?.roles?.map((role) => (
          <Tag color={role === "کاربر" ? "green" : "gold"}>{role}</Tag>
        )),
    },
    {
      name: "سطح کاربری",
      id: "level",
      selector: (row: User) => row.level,
      cell: (record: User) => <Tag color={"orange"}>{record.level}</Tag>,
    },

    {
      name: "تاریخ عضویت",
      id: "registeredOn",
      cell: (row: User) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.registeredOn)
              .local()
              .toDate()
              .toLocaleDateString("fa-IR")}
          </span>
          <span className="time">
            {moment
              .utc(row.registeredOn)
              .local()
              .toDate()
              .toLocaleTimeString("fa-IR")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت حساب",
      id: "isActive",
      cell: (record: User) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.isActive}
          onChange={(checked) => isActiveHandle(record.id, checked)}
        />
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: User) => (
        <div className="actions">
          <Link to={`/users/details/${record.id}`}>
            <Tooltip placement="top" title="ویرایش">
              <UserOutlined />
            </Tooltip>
          </Link>
          <Tooltip placement="top" title="تنظیمات مالی کاربر">
            <FileSearchOutlined
              onClick={() => onFinancialSettingsHandler(record.id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    !usersList &&
      getAllUsers(
        setCardLoading,
        setUsersList,
        getPageNum(),
        pageSize,
        query()
      );
    roles.length === 0 && getAllRolesAsEnum(setCardLoading, setRoles);
    levels.length === 0 && getAllLevelsAsEnum(setCardLoading, setLevels);
  }, []);

  return (
    <>
      <Modal
        isOpen={financialModal}
        setIsOpen={(e: any) => setFinancialModal(e)}
        className="financial-settings-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>تنظیمات مالی کاربر</h4>
          </div>
          <Spin spinning={modalLoading}>
            <div className="content">
              <p>
                از این قسمت می توانید حداکثر مقدار برداشتی کاربر را محدود نمایید
              </p>
              <Input
                type="text"
                name="maxIrtWithdrawlInDay"
                value={financialSettings?.maxIrtWithdrawlInDay}
                onChange={(e) =>
                  handleNumChange(e.target.value, "maxIrtWithdrawlInDay")
                }
                label="حداکثر مقدار برداشت تومانی در روز"
                placeholder="به تومان وارد کنید"
                dir={
                  financialSettings?.maxIrtWithdrawlInDay?.length
                    ? "ltr"
                    : "rtl"
                }
              />
              <Input
                type="text"
                name="maxIrtDepositInDay"
                placeholder="به تومان وارد کنید"
                value={financialSettings?.maxIrtDepositInDay}
                onChange={(e) =>
                  handleNumChange(e.target.value, "maxIrtDepositInDay")
                }
                label="حداکثر مقدار واریز تومانی در روز"
                dir={
                  financialSettings?.maxIrtDepositInDay?.length ? "ltr" : "rtl"
                }
              />

              <Switch
                checked={financialSettings?.lockAllWithdrawal}
                checkedChildren={"بسته شدن برداشت"}
                unCheckedChildren={"باز شدن برداشت "}
                onChange={(checked) =>
                  setFinancialSettings({
                    ...financialSettings,
                    lockAllWithdrawal: checked,
                  })
                }
              />

              <Switch
                checked={financialSettings?.manualWithdrawal}
                unCheckedChildren={"برداشت‌های درستی"}
                checkedChildren={"برداشت‌های اتوماتیک"}
                onChange={(checked) =>
                  setFinancialSettings({
                    ...financialSettings,
                    manualWithdrawal: checked,
                  })
                }
              />

              <Button
                type="error"
                onClick={() =>
                  onRemoveFinancialSettings(financialSettings?.userId || "")
                }
                text="حذف تنظیمات مالی کاربر"
              />
            </div>
            <div className="buttons">
              <Button
                type="info"
                loading={loading}
                disabled={loading}
                text="ویرایش"
                htmlType="button"
                onClick={() =>
                  onUpdateFinancialSettings(financialSettings?.userId || "")
                }
              />
              <Button
                type="outlined"
                text="بستن"
                onClick={() => closeFinancialSettingsModal()}
              />
            </div>
          </Spin>
        </div>
      </Modal>

      <Modal
        isOpen={detailsModalIsOpen.state}
        setIsOpen={(e: any) =>
          setDetailsModalIsOpen({ ...detailsModalIsOpen, state: e })
        }
        className="user-details-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>اطلاعات کاربر</h4>
          </div>
          <div className="content">
            <ul>
              <li>
                <span>نام و نام خانوادگی</span>
                <span>{detailsModalIsOpen.record?.fullName}</span>
              </li>
              <li>
                <span>نام کاربری</span>
                <span>{detailsModalIsOpen.record?.userName}</span>
              </li>
              <li>
                <span>سطح کاربری</span>
                <span>{detailsModalIsOpen.record?.level}</span>
              </li>
              <li>
                <span>نقش ها</span>
                <span>{detailsModalIsOpen.record?.roles[0]}</span>
              </li>
              <li>
                <span>تاریخ عضویت</span>
                <span>
                  {detailsModalIsOpen.record?.registeredOn.toString()}
                </span>
              </li>
            </ul>
          </div>
          <div className="buttons">
            <Button
              type="secondary"
              text="بستن"
              onClick={() =>
                setDetailsModalIsOpen({ ...detailsModalIsOpen, state: false })
              }
            />
          </div>
        </div>
      </Modal>

      <Card
        blur={cardLoading}
        title="مدیریت کاربران vip"
        className="vt-dashboard-vip"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues?.keywords}
              className="search"
              placeholder="جستجو کنید ..."
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            {levels.length != 0 && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {levels &&
                      levels.map((level) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({ ...filterValues, level })
                          }
                        >
                          {level.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues?.level.title}
                />
              </Dropdown>
            )}

            {roles.length != 0 && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {roles &&
                      roles.map((role) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({ ...filterValues, role })
                          }
                        >
                          {role.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues?.role.title}
                />
              </Dropdown>
            )}
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => onFilterHandler()}
            />
            <span
              onClick={() => {
                setFilterValues({
                  role: defaultRoleItems[0],
                  level: defaultLevelItems[0],
                  keywords: "",
                });
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>

        <Table
          className="vip-table"
          data={usersList?.items}
          pagination={usersList?.pagination}
          setData={setUsersList}
          getData={getAllUsers}
          setLoader={setCardLoading}
          column={columns}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Card>
    </>
  );
};
