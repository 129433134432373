import "./index.scss";
import React from "react";
import { getViaAuth, post, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import { digitSeparator } from "utils/scripts/decimal";
import { message } from "antd";
import { Button, Card, Input } from "components";

interface FiatLimitsSettings {
  id: string;
  name: string;
  minDepositAmountWallet: number;
  minDepositAmountDirect: number;
}

export const FiatLimitsSettings = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [fiatLimits, setFiatLimits] = React.useState<FiatLimitsSettings[]>([]);

  const getFiatLimits = async () => {
    setCardLoading(true);
    await getViaAuth(API.fiats.getAllBuyFiatLimits, {}).then(
      (response: any) => {
        if (responseValidator(response.status)) {
          setFiatLimits(response.value);
        }
      }
    );
    setCardLoading(false);
  };

  const handleInputChange = (
    index: number,
    property: string,
    value: string
  ) => {
    setFiatLimits((prevLimits) =>
      prevLimits.map((limit, i) =>
        i === index ? { ...limit, [property]: +value || 0 } : limit
      )
    );
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const requestBody = fiatLimits.map((fiat) => ({
        id: fiat.id,
        minDepositAmountWallet: fiat.minDepositAmountWallet,
        minDepositAmountDirect: fiat.minDepositAmountDirect,
      }));

      const response: any = await post(API.fiats.updateBuyFiatLimits, {
        fiats: requestBody,
      });

      setLoading(false);

      if (responseValidator(response.status)) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getFiatLimits();
  }, []);

  return (
    <>
      <Card
        title="تنظیمات محدودیت های فیات"
        blur={cardLoading}
        className="vt-dashboard-fiat-limits-settings"
      >
        {fiatLimits &&
          fiatLimits.map((fiatLimit, index) => (
            <>
              <div key={fiatLimit.id} className="fiat-limit">
                <h3>{fiatLimit.name}</h3>
                <div>
                  <Input
                    label="حداقل مقدار واریز (کیف پول)"
                    name="minDepositAmountWallet"
                    type="text"
                    value={digitSeparator(fiatLimit.minDepositAmountWallet)}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "minDepositAmountWallet",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div>
                  <Input
                    label="حداقل مقدار واریز (مستقیم)"
                    name="minDepositAmountDirect"
                    type="text"
                    value={digitSeparator(fiatLimit.minDepositAmountDirect)}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "minDepositAmountDirect",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </>
          ))}
      </Card>
      <Button
        text="ثبت تغییرات"
        type="primary"
        onClick={handleSaveChanges}
        disabled={loading}
      />
    </>
  );
};
