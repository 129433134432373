import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { Enum, FilterValues, Market, PaginationList } from "core/domains";
import * as React from "react";
import { Link } from "react-router-dom";
import "./market.scss";
import {
  getAllMarkets,
  getMarketTypesEnum,
  syncMarkets,
  updateMarket,
  updateMarketPriority,
} from "core/repos";
import { Dropdown, Menu, Switch, Tooltip } from "antd";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { getIconPath } from "utils/scripts/getIconPath";
import { digitSeparator, faNumToEnNum } from "../../../utils/scripts/decimal";
import { MarketConfirmBulkSelectModal } from "./bulk-select-modal/bulk-select-modal";

const { useState, useEffect } = React;

interface EditableCellProps<T> {
  cell: T;
}

const defaultTypeItems: Enum[] = [
  {
    title: "نوع ارز",
    id: 0,
  },
];

const initial_filters = {
  keywords: "",
  marketType: defaultTypeItems[0],
};

const actionItems: Enum[] = [
  {
    id: 0,
    title: "انتخاب عملیات",
  },
  {
    id: 1,
    title: "فعالسازی واریز",
  },
  {
    id: 2,
    title: "غیر فعالسازی واریز",
  },
  {
    id: 3,
    title: "فعالسازی برداشت",
  },
  {
    id: 4,
    title: "غیر فعالسازی برداشت",
  },
  {
    id: 5,
    title: "فعالسازی خرید",
  },
  {
    id: 6,
    title: "غیر فعالسازی خرید",
  },
  {
    id: 7,
    title: "فعالسازی فروش",
  },
  {
    id: 8,
    title: "غیر فعالسازی فروش",
  },
];

export const MarketManagement: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [markets, setMarkets] = useState<PaginationList<Market>>();
  const [marketTypes, setMarketTypes] = useState<Enum[]>();
  const [filterValues, setFilterValues] =
    useState<FilterValues>(initial_filters);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Market>();
  const [query, setQuery] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [isEditing, setIsEditing] = useState<boolean>();
  const [selectedRows, setSelectedRows] = React.useState<Market[]>();
  const [selectedActionItem, setSelectedActionItem] = React.useState<Enum>(
    actionItems[0]
  );
  const [isOpenConfirmBulkActionModal, setIsOpenConfirmBulkActionModal] =
    React.useState<boolean>(false);
  const [actionApproveLoading, setActionApproveLoading] =
    React.useState<boolean>(false);

  const [syncLoading, setSyncLoading] = useState<boolean>(false);

  const onNumChange = (value: string) => {
    if (value !== "") value = faNumToEnNum(value);

    var item = selectedItem;
    var newObj = item && {
      ...item,
      priority: value,
    };

    setSelectedItem(newObj);
  };

  const EditableCell: React.FC<EditableCellProps<Market>> = (props) =>
    isEditing ? (
      selectedItem?.id === props.cell.id ? (
        <Input
          className={`editable-cell-input `}
          value={selectedItem?.priority?.toString()}
          onChange={(e) => onNumChange(e.target.value)}
          name="editing"
          type="number"
          dir="ltr"
          autoFocus
        />
      ) : (
        <>{props.cell.priority}</>
      )
    ) : (
      <>{props.cell.priority}</>
    );

  const fetchAll = async () => {
    !markets &&
      getAllMarkets(setCardLoading, setMarkets, getPageNum(), pageSize, query);
    getMarketTypesEnum(setCardLoading, setMarketTypes);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const filterHandle = () => {
    var _query = "";
    if (filterValues?.keywords !== "") {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += filterValues?.keywords;
    }
    if (filterValues?.marketType?.id !== 0) {
      _query += `${_query.includes("?") ? "&" : "?"}marketType=`;
      _query += filterValues?.marketType?.id;
    }

    setQuery(_query);
    getAllMarkets(setLoading, setMarkets, 1, pageSize, _query);
  };

  const onEditPriorityClick = (item: Market) => {
    setSelectedItem(item);
    setIsEditing(true);
  };

  const onUpdateMarketPriorityComplete = () => {
    getAllMarkets(setCardLoading, setMarkets, getPageNum(), pageSize, query);
    setIsEditing(false);
    setSelectedItem(undefined);
  };

  const onEditPrioritySubmit = (item: Market) => {
    if (isEditing && selectedItem?.priority !== "") {
      updateMarketPriority(
        setCardLoading,
        {
          ...item,
          id: item.id,
          priority: faNumToEnNum(selectedItem?.priority ?? ""),
        },
        onUpdateMarketPriorityComplete
      );
    }
  };

  const columns: any[] = [
    {
      name: "نام",
      id: "name",
      selector: (row: Market) => row.name,
      width: "6%",
    },
    {
      name: "نماد",
      id: "symbol",
      selector: (row: Market) => row?.symbol,
      cell: (row: Market) => (
        <>
          <img src={getIconPath(row?.symbol ?? "")} alt={row.symbol} />
          <span>{row?.symbol}</span>
        </>
      ),
      width: "9%",
    },
    {
      name: "قابلیت خرید از ما",
      id: "isBuyActive",
      selector: (row: any) => row.isBuyActive,
      width: "8%",
      cell: (row: Market) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={row.isBuyActive}
          onChange={(checked) =>
            updateMarket(
              setCardLoading,
              setMarkets,
              {
                ...row,
                isBuyActive: checked,
                id: row.id,
              },
              getPageNum(),
              query
            )
          }
        />
      ),
    },
    {
      name: "قابلیت فروش به ما",
      id: "isSellActive",
      width: "8.5%",

      selector: (row: any) => row.isSellActive,
      cell: (row: Market) => (
        <Switch
          checked={row.isSellActive}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) =>
            updateMarket(
              setCardLoading,
              setMarkets,
              {
                ...row,
                isSellActive: checked,
                id: row.id,
              },
              getPageNum(),
              query
            )
          }
        />
      ),
    },
    {
      name: "قابلیت واریز",
      id: "depositEnabled",
      width: "7%",
      selector: (row: any) => row.depositEnabled,
      cell: (row: any) => (
        <Switch
          checked={row.depositEnabled}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) =>
            updateMarket(
              setCardLoading,
              setMarkets,
              {
                ...row,
                depositEnabled: checked,
                id: row.id,
              },
              getPageNum(),
              query
            )
          }
        />
      ),
    },
    {
      name: "قابلیت برداشت",
      id: "widthrawEnabled",
      width: "8.5%",

      selector: (row: any) => row.widthrawEnabled,
      cell: (row: any) => (
        <Switch
          checked={row.widthrawEnabled}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) =>
            updateMarket(
              setCardLoading,
              setMarkets,
              {
                ...row,
                widthrawEnabled: checked,
                id: row.id,
              },
              getPageNum(),
              query
            )
          }
        />
      ),
    },
    {
      name: "وضعیت نمایش",
      id: "isActive",
      width: "8%",

      selector: (row: any) => row.isActive,
      cell: (row: Market) => (
        <Switch
          checked={row.isActive}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) =>
            updateMarket(
              setCardLoading,
              setMarkets,
              {
                ...row,
                isActive: checked,
                id: row.id,
              },
              getPageNum(),
              query
            )
          }
        />
      ),
    },
    {
      name: "ترتیب",
      id: "priority",
      selector: (row: Market) => row.priority,
      cell: (record: Market) => record && <EditableCell cell={record} />,
      width: "5%",
    },
    {
      name: "قیمت دلاری ",
      id: "dollar-price",
      selector: (row: Market) => digitSeparator(row.dollarPrice) + " $ ",
      width: "9%",
    },
    {
      name: "قیمت خرید (تومان)",
      id: "buy-price",
      selector: (row: Market) => digitSeparator(row.buyPrice),
      width: "10%",
    },
    {
      name: "قیمت فروش (تومان)",
      id: "sell-price",
      selector: (row: Market) => digitSeparator(row.sellPrice),
      width: "10%",
    },
    {
      name: "عملیات",
      id: "action",
      width: "6%",
      cell: (record: Market) => (
        <div className="actions">
          <Link to={`edit/${record.id}`}>
            <Tooltip placement="top" title="اصلاح ارز">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip placement="top" title="اصلاح ترتیب">
            {isEditing ? (
              selectedItem?.id === record.id ? (
                <CheckOutlined
                  className={`edit-priority-icon ${
                    selectedItem.priority == "" ? "disabled" : ""
                  }`}
                  onClick={() => onEditPrioritySubmit(record)}
                />
              ) : (
                <></>
              )
            ) : (
              <SortAscendingOutlined
                onClick={() => onEditPriorityClick(record)}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const openActionConfirmModal = () => {
    setActionApproveLoading(false);
    setIsOpenConfirmBulkActionModal(true);
  };

  return (
    <>
      {selectedRows && (
        <MarketConfirmBulkSelectModal
          isOpen={isOpenConfirmBulkActionModal}
          setIsOpen={setIsOpenConfirmBulkActionModal}
          buttonLoading={actionApproveLoading}
          selectedActionItem={selectedActionItem}
          selectedRows={selectedRows}
          setButtonLoading={setActionApproveLoading}
          setSelectedRows={setSelectedRows}
          fetchData={async () =>
            await getAllMarkets(
              setCardLoading,
              setMarkets,
              getPageNum(),
              pageSize,
              query
            )
          }
          actionItem={selectedActionItem}
        />
      )}
      <Card
        blur={cardLoading}
        title="مدیریت ارز ها"
        className="vt-dashboard-market"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues?.keywords}
              className="search"
              placeholder="جستجو کنید ..."
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
              onPressEnter={() => filterHandle()}
            />
            {marketTypes?.length && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {marketTypes?.length &&
                      marketTypes.map((marketType) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({
                              ...filterValues,
                              marketType: marketType,
                            })
                          }
                        >
                          {marketType.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues?.marketType?.title}
                />
              </Dropdown>
            )}
          </div>
          <div className="buttons">
            <Button
              loading={syncLoading}
              disabled={syncLoading}
              text="بروزرسانی ارز ها"
              type="secondary"
              onClick={() =>
                syncMarkets(setSyncLoading, () =>
                  getAllMarkets(
                    setCardLoading,
                    setMarkets,
                    getPageNum(),
                    pageSize,
                    query
                  )
                )
              }
            />

            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => filterHandle()}
            />
            <span
              onClick={() => {
                setFilterValues(initial_filters);
                getAllMarkets(
                  setCardLoading,
                  setMarkets,
                  getPageNum(),
                  pageSize,
                  ""
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {selectedRows && selectedRows?.length > 1 && (
          <div className="action-row">
            <label htmlFor="">عملیات: </label>
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {actionItems &&
                    actionItems.map((state) => (
                      <Menu.Item onClick={() => setSelectedActionItem(state)}>
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={selectedActionItem.title}
              />
            </Dropdown>
            <Button
              disabled={selectedActionItem.id === 0}
              type="info"
              text="اصلاح ارز‌های انتخاب شده"
              onClick={() => openActionConfirmModal()}
            />
            <Button
              type="outlined"
              text={`${selectedRows?.length} آیتم انتخاب شده`}
            />
          </div>
        )}
        {markets && (
          <Table
            blur={cardLoading}
            setLoader={setCardLoading}
            getData={getAllMarkets}
            setData={setMarkets}
            pagination={markets.pagination}
            className="market-table"
            data={markets.items}
            column={columns}
            filters={query}
            pageSize={pageSize}
            setPageSize={setPageSize}
            selectableRows
            onSelectRowsChange={(selected) => {
              setSelectedRows(selected.selectedRows);
            }}
          />
        )}
      </Card>
    </>
  );
};
