import { API } from "apis";
import { message } from "antd";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";
import { AddPopUp, Enum } from "core/domains";
import { DefaultOptionType } from "antd/es/select";
import { SERVER_ADDRESS } from "apis/constants";
import { UploadFile } from "antd/es/upload/interface";

export const getAllPopUps = (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  getViaAuth(`${API.popup.getAll}/${page}/${pageSize}${query || ""}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

function base64ToArrayBuffer(base64: any) {
  var binaryString = atob(base64.split(",")[1]);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
export const getPopUpById = async (
  setloader: any,
  setdata: any,
  setselectedoptions: React.Dispatch<React.SetStateAction<DefaultOptionType[]>>,
  id: string
) => {
  let popupData: any = {};
  let mapLevels = [];
  setloader(true);
  await getViaAuth(API.popup.getById + `/${id}`).then(async (response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      let xa: UploadFile = {
        uid: id,
        name: "rrr",
      };
      Object.assign(popupData, {
        title: response.value.title,
        description: response.value.description,
        intervalView: response.value.intervalView,
        forLevelIds: response.value.forLevelUniqname.map((i: any) =>
          i.id.toString()
        ),
        file: response.value.file,
        viewAfterSecond: response.value.viewAfterSecond,
        viewCount: response.value.viewCount,
        isActive: response.value.isActive,
        urlPage: response.value.urlPage,
        linkTo: response.value.linkTo,
        imageUrl: `${SERVER_ADDRESS}${response.value.imageUrl}`,
      });

      mapLevels = response.value.forLevelUniqname.map((i: Enum) => ({
        label:
          i.id == 1
            ? "سطح پایه"
            : i.id == 2
            ? "سطح 1"
            : i.id == 3
            ? "سطح 2"
            : i.id == 4
            ? "سطح 3"
            : i.id == 5
            ? "سطح 4"
            : "",
        value: i.id,
      }));
      setselectedoptions(mapLevels);
      setdata(popupData);

      if (response.value.imageUrl) {
        const base64 = await fetch(popupData.imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
              reader.onloadend = () => {
                res(reader.result);
              };
            });
          });
        popupData.file = base64;
        const file = new File(
          [base64ToArrayBuffer(base64)],
          `${
            response.value.imageUrl
              .split("/")
              [response.value.imageUrl.split("/").length - 1].split(".")[0]
          }`
        );
        setdata({ ...popupData, file });
      }
    }
  });
};

export const updatePopUp = (
  setloader: any,
  data: AddPopUp | undefined,
  selectedOptions: DefaultOptionType[],
  id: string
) => {
  let levelIds = selectedOptions.map((item: any) => item.value);
  setloader(true);
  const fromForm = new FormData();

  fromForm.append("id", id);
  data?.file && fromForm.append("file", data.file);
  data?.title && fromForm.append("Title", data.title);
  data?.description && fromForm.append("Description", data.description);
  data?.urlPage && fromForm.append("UrlPage", data.urlPage);
  data?.linkTo && fromForm.append("LinkTo", data.linkTo);
  data?.viewAfterSecond &&
    fromForm.append("ViewAfterSecond", data.viewAfterSecond);
  data?.viewCount && fromForm.append("ViewCount", data.viewCount);
  data?.intervalView && fromForm.append("IntervalView", data.intervalView);
  selectedOptions &&
    levelIds.forEach((i) => fromForm.append("forLevelIds[]", i));
  data?.isActive && fromForm.append("isActive", data.isActive.toString());

  postViaAuth(API.popup.edit, fromForm, true).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
    }
  });
};

export const addPopUp = (
  setloader: any,
  data: AddPopUp | undefined,
  selectedOptions: DefaultOptionType[],
  onSuccess: any
) => {
  let mappedOptions = selectedOptions.map((i: any) => ({
    id: i.value || "",
    title: i.label || "",
  }));
  let levelIds = selectedOptions.map((item: any) => item.value);
  setloader(true);
  const fromForm = new FormData();
  data?.file && fromForm.append("file", data.file);
  data?.title && fromForm.append("Title", data.title);
  data?.description && fromForm.append("Description", data.description);
  data?.urlPage && fromForm.append("UrlPage", data.urlPage);
  data?.linkTo && fromForm.append("LinkTo", data.linkTo);
  data?.viewAfterSecond &&
    fromForm.append("ViewAfterSecond", data.viewAfterSecond);
  data?.viewCount && fromForm.append("ViewCount", data.viewCount);
  data?.intervalView && fromForm.append("IntervalView", data.intervalView);
  selectedOptions &&
    levelIds.forEach((i) => fromForm.append("ForLevelIds[]", i));

  postViaAuth(API.popup.add, fromForm, true).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      onSuccess();
    }
  });
};
