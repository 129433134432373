import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { CashIn, PaginationList } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { getAllCashIns } from "core/repos/cash-in";
import moment from "jalali-moment";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import "./cash-in-management.scss";
import { Tag } from "antd";

const { useState, useEffect } = React;

export const CashInManagement: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [filterButtonLoading, setFilterButtonLoading] =
    useState<boolean>(false);
  const [cashInData, setCashInData] = useState<PaginationList<CashIn>>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>("");

  const filterHandle = () => {
    const _query = searchValue ? `?keyword=${searchValue}` : "";
    setQuery(_query);
    getAllCashIns(
      setCardLoading,
      setCashInData,
      getPageNum(),
      pageSize,
      _query
    );
  };

  const handleFilterButtonClick = () => {
    const _query = searchValue ? `?keyword=${searchValue}` : "";
    setQuery(_query);
    getAllCashIns(
      setFilterButtonLoading,
      setCashInData,
      getPageNum(),
      pageSize,
      _query
    );
  };

  useEffect(() => {
    filterHandle();
  }, []);

  const columns: TableColumn<CashIn>[] = [
    {
      name: "نوع",
      id: "mode",
      width: "10%",
      selector: (row) => row?.mode?.title || "",
      cell: (row) => (
        <Tag color={`${row.mode.id === 1 ? "blue" : "green"}`}>
          {row.mode.title}
        </Tag>
      ),
    },
    {
      name: "شناسه واریز",
      id: "depositId",
      selector: (row) => row?.depositId || "",
    },
    {
      name: "توسط",
      id: "createdBy",
      cell: (row) => (
        <div className="column-createdBy">
          <span className="name">{row.createdBy.fullName}</span>
          <p className="phone">{row.createdBy.userName}</p>
        </div>
      ),
    },
    {
      name: "زمان و تاریخ",
      id: "createdOn",
      selector: (row) => row.createdOn,
      cell: (row) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdOn).locale("fa").local().format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <Card
      blur={cardLoading}
      title="مدیریت شناسه های پرداخت"
      className="vt-dashboard-cash-in-management"
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={searchValue}
            className="search"
            placeholder="جستجو کنید ..."
            name="search"
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => filterHandle()}
          />
        </div>
        <div className="buttons">
          <Button
            loading={filterButtonLoading}
            disabled={filterButtonLoading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => handleFilterButtonClick()}
          />
          <span
            onClick={() => {
              setSearchValue("");
              getAllCashIns(
                setCardLoading,
                setCashInData,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      {cashInData && (
        <Table
          blur={cardLoading}
          setLoader={setCardLoading}
          getData={getAllCashIns}
          setData={setCashInData}
          pagination={cashInData.pagination}
          className="cash-in-management-datatable"
          data={cashInData.items}
          column={columns}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
