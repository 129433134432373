import {getViaAuth, responseValidator} from "../../utils/scripts/api";
import {API} from "../../apis";

export const getAllCashIns = (
    setloader: any,
    setdata: any,
    page: number,
    size: number,
    query: string
) => {
    setloader(true);
    getViaAuth(API.cashInManagement.getAll + `/${page}/${size}${query ? query : ''}`).then(
        (response: any) => {
            setloader(false);
            if (responseValidator(response.status)) {
                setdata(response.value);
            }
        }
    );
};