import { Col, Row, Select, Spin } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Card, Modal } from "../../../../../../components";
import { Role, User, UserDetail } from "../../../../../../core/domains";
import { DefaultOptionType } from "antd/es/select";
import {
  getViaAuth,
  isNotFound,
  responseValidator,
} from "../../../../../../utils/scripts/api";
import { API } from "../../../../../../apis";
import "./referral-details.scss";
import { getAllReferralLevels } from "../../../../../../core/repos/referral";
import {
  ReferralLevel,
  ReferralReport,
} from "../../../../../../core/domains/referral/referral";
import ReactCodeInput from "react-verification-code-input";
import { updateUser } from "../../../../../../core/repos/user";
import {
  BookOutlined,
  CopyOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { digitSeparator } from "../../../../../../utils/scripts/decimal";
import { copyToClipboard } from "../../../../../../utils/scripts/copyToClipboard";

interface Props {
  cardLoading: boolean;
  userInfo: UserDetail | undefined;
  setUserInfo: React.Dispatch<UserDetail>;
  roleList: Role[];
}

export const ReferralDetails = (props: Props): React.ReactElement => {
  const [googleAuthData, setGoogleAthData] = React.useState<GoogleAuth>();
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<DefaultOptionType[]>();
  const [selectedUser, setSelectedUser] = React.useState<DefaultOptionType>();
  const [selectedLevel, setSelectedLevel] = React.useState<DefaultOptionType>();

  const [search, setSearch] = useState<string>("");
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [levels, setLevels] = useState<ReferralLevel[]>();

  const [referralReports, setReferralReports] = useState<ReferralReport>();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  const getAllUsers = async (query: string) => {
    setIsDropdownLoading(true);
    await getViaAuth(`${API.users.getAllUsers}/${1}/${50}${query}`).then(
      (response: any) => {
        setIsDropdownLoading(false);
        if (responseValidator(response.status)) {
          var newArr = response.value.items.map((user: User) => ({
            value: user.id,
            label: user.fullName ?? user.userName,
          }));
          setOptions(newArr);
        } else {
          isNotFound(response.status) &&
            setSelectedUser({ label: "", value: "" });
        }
      }
    );
  };

  const getReferralDetails = async () => {
    setCardLoading(true);
    await getViaAuth(`${API.referral.getReferralDetails}/${id}`).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setReferralReports(response.value);
        }
      }
    );
  };

  const filterHandle = () => {
    var _query = "";

    if (search !== "") {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += search;
    }

    getAllUsers(_query);
  };

  const onUserIdsChange = (value: any) => {
    props.setUserInfo({
      ...props.userInfo,
      introducer: value,
    });
  };

  const fetchData = async () => {
    await filterHandle();
    !referralReports && getReferralDetails();
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  useEffect(() => {
    !levels &&
      getAllReferralLevels(setCardLoading, setLevels, (data) =>
        setSelectedLevel({
          value: data?.filter(
            (e) =>
              e.referralLevelUniqueName === props.userInfo?.referralUniqueName
          )[0]?.referralLevelUniqueName,
          label: data?.filter(
            (e) =>
              e.referralLevelUniqueName === props.userInfo?.referralUniqueName
          )[0]?.title,
        })
      );
  }, []);

  useEffect(() => {
    if (props.userInfo?.introducer) {
      var _query = "";

      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        props.userInfo.introducer
      }`;
      getViaAuth(`${API.users.getAllUsers}/${1}/${50}${_query}`).then(
        (response: any) => {
          setIsDropdownLoading(false);
          if (responseValidator(response.status)) {
            if (response.value.items.length > 0)
              setSelectedUser({
                label: response.value.items[0].fullName,
                value: response.value.items[0].id,
              });
          } else {
            isNotFound(response.status) &&
              setSelectedUser({ label: "خالی", value: "" });
          }
        }
      );
    }
  }, []);
  const onOpenModalHanlde = () => {
    setGoogleAthData({
      code: "",
      error: "",
    });
    setModalIsOpen(true);
  };

  return (
    <>
      <Spin className="main-stage" spinning={cardLoading}>
        <Modal
          closeAble
          title={"کد تایید Google Atuh"}
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          className="edit-user-data-modal"
        >
          <div className="google-auth code app-code">
            <ReactCodeInput
              className={`input `}
              fieldWidth={42}
              values={
                googleAuthData?.code ? googleAuthData?.code?.split("") : []
              }
              autoFocus={true}
              onChange={(value: any) => {
                setGoogleAthData({
                  ...googleAuthData,
                  code: value,
                });
              }}
              onComplete={(code) =>
                updateUser(
                  setCardLoading,
                  props.userInfo,
                  id,
                  code,
                  setGoogleAthData,
                  setModalIsOpen,
                  props?.roleList
                )
              }
            />
            <div className="stats">
              <div className="errors">
                {googleAuthData?.error && (
                  <>
                    <QuestionCircleOutlined />
                    <span>{googleAuthData?.error}</span>
                  </>
                )}
              </div>
            </div>
          </div>

          <Button
            type="info"
            text="تایید"
            onClick={() =>
              updateUser(
                setCardLoading,
                props.userInfo,
                id,
                googleAuthData?.code,
                setGoogleAthData,
                setModalIsOpen
              )
            }
            disabled={cardLoading ? true : false}
            loading={cardLoading}
          />
        </Modal>
        <Row>
          <div className="vt-field">
            <label htmlFor="message">کاربر رفرال</label>
            <Select
              className="select-referral"
              allowClear
              value={selectedUser}
              placeholder="از لیست  کاربر را انتخاب کنید"
              onChange={(value: any, options) => {
                setSelectedUser(options as DefaultOptionType);
                onUserIdsChange(value);
              }}
              options={options}
              showSearch
              onSearch={(value) => setSearch(value)}
              filterOption={false}
            />
          </div>
          <div className="vt-field">
            <label>سطح کاربری</label>
            {levels && levels.length > 0 && props.userInfo && (
              <Select
                value={
                  levels.filter(
                    (level) =>
                      level.referralLevelUniqueName ===
                      props.userInfo?.referralUniqueName
                  )[0]?.title
                }
                onChange={(value) => {
                  setSelectedLevel({
                    value: levels?.filter(
                      (e) =>
                        e.referralLevelUniqueName ===
                        props.userInfo?.referralUniqueName
                    )[0].referralLevelUniqueName,
                    label: levels?.filter(
                      (e) =>
                        e.referralLevelUniqueName ===
                        props.userInfo?.referralUniqueName
                    )[0]?.title,
                  });
                  props.setUserInfo({
                    ...props.userInfo,
                    referralUniqueName: parseInt(value),
                  });
                }}
              >
                {levels.map((level) => (
                  <Select.Option key={level.referralLevelUniqueName}>
                    {level.title}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
        </Row>

        <Row>
          <Button
            loading={loading}
            type={"info"}
            text={"ویرایش اطلاعات رفرال"}
            onClick={() => onOpenModalHanlde()}
          />
        </Row>
      </Spin>
      <div className="vt-user-referral-reports">
        <Spin spinning={cardLoading}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={12}>
              <Card
                className="active"
                tags={<BookOutlined />}
                title="اطلاعات اولیه"
              >
                <p className="prices">
                  <span>
                    کد دعوت : {referralReports?.code}{" "}
                    <CopyOutlined
                      onClick={() =>
                        referralReports?.code &&
                        copyToClipboard(referralReports?.code, "کد دعوت")
                      }
                    />
                  </span>

                  <span>تعداد فعالیت : {referralReports?.actionCount}</span>
                </p>
                <p className="prices">
                  <span>سطح رفرال : {referralReports?.referralLevelTitle}</span>

                  <span>
                    درصد پاداش سطح :
                    {referralReports?.referralLevelRewardPercent}
                  </span>
                </p>
              </Card>
            </Col>

            <Col className="gutter-row" span={12}>
              <Card className="active" tags={<DollarOutlined />} title="موجودی">
                <p className="prices">
                  <span>
                    موجودی : {digitSeparator(referralReports?.balance)}{" "}
                  </span>

                  <span>
                    موجودی کل : {digitSeparator(referralReports?.totalBalance)}{" "}
                  </span>
                </p>
                <p className="prices">
                  <span>
                    تعداد کاربر دعوت شده: {referralReports?.userCount}
                  </span>

                  <span>
                    تعداد کاربر دعوت و احراز هویت شده:{" "}
                    {referralReports?.authenticatedUsersCount}{" "}
                  </span>
                </p>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

type GoogleAuth = {
  code?: string;
  error?: string;
};
