import { Button, Card, Input, Modal } from "components";
import { Table } from "components/table/table";
import { FilterValues, PaginationList } from "core/domains";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import "./index.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { WithdrawalDisabledUser } from "core/domains/withdrawal-disabled-users/withdrawal-disabled-users";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import {
  getAllWithdrawalDisabledUsers,
  updateUserWithdrawalDisabled,
} from "core/repos/withdrawal-disabled-users";
import { InputNumber, Switch, Tooltip } from "antd";
import { updateUserWithdrawalEnable } from "core/repos/user";

export const WithdrawalDisabledUSers: React.FC = () => {
  const { useState, useRef, useEffect } = React;
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<PaginationList<WithdrawalDisabledUser>>();
  const [selectedItem, setSelectedItem] = useState<WithdrawalDisabledUser>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [query, setQuery] = useState("");
  const [
    isWithdrawalDisabledTillModalOpen,
    setIsWithdrawalDisabledTillModalOpen,
  ] = useState<boolean>(false);
  const [withdrawalHour, setWithdrawalHour] = useState<number | null>(0);
  const [withdrawalMinute, setWithdrawalMinute] = useState<number | null>(0);
  const [disableTime, setDisableTime] = useState<number | null>(null);
  const [filterValues, setFilterValues] = useState<FilterValues>({});

  useEffect(() => {
    !users &&
      getAllWithdrawalDisabledUsers(
        setCardLoading,
        setUsers,
        getPageNum(),
        pageSize,
        ""
      );
  }, []);

  useEffect(() => {
    if (withdrawalHour !== null || withdrawalMinute !== null) {
      const nullableWithdrawalHour = withdrawalHour ? withdrawalHour : 0;
      const nullableWithdrawalMinute = withdrawalMinute ? withdrawalMinute : 0;
      const newDisableTime =
        nullableWithdrawalHour * 60 + nullableWithdrawalMinute;
      setDisableTime(newDisableTime);
    } else {
      setDisableTime(null);
    }
  }, [withdrawalHour, withdrawalMinute]);

  const onFilterHandler = () => {
    let query = "";
    if (filterValues?.keywords) {
      query = `?keywords=${filterValues.keywords}`;
    }
    setQuery(query);
    getAllWithdrawalDisabledUsers(setLoading, setUsers, 1, pageSize, query);
  };

  const withdrawalEnableHandle = (
    item: WithdrawalDisabledUser,
    checked: boolean
  ) => {
    setSelectedItem(item);
    updateUserWithdrawalEnable(
      setCardLoading,
      { userId: item?.id, withdrawalEnable: checked },
      setUsers,
      query
    );
  };

  const onSaveWithdrawalDisableTime = () => {
    updateUserWithdrawalDisabled(
      setLoading,
      {
        userId: selectedItem?.id,
        disableFor: disableTime,
      },
      setUsers,
      () => {
        setSelectedItem(undefined);
        setIsWithdrawalDisabledTillModalOpen(false);
      }
    );
  };

  const onEditWithdrawalDisabled = (item: WithdrawalDisabledUser) => {
    setSelectedItem(item);
    setIsWithdrawalDisabledTillModalOpen(true);
  };

  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: WithdrawalDisabledUser) =>
        row?.fullName && row?.phoneNumber,
      cell: (row: WithdrawalDisabledUser) => (
        <div className="column-fullName">
          <span>{row?.fullName}</span>
          <span>{row?.phoneNumber}</span>
        </div>
      ),
    },
    {
      name: "وضعیت برداشت",
      id: "withdrawalEnable",
      cell: (record: WithdrawalDisabledUser) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.withdrawalEnable}
          onChange={(checked) => withdrawalEnableHandle(record, checked)}
        />
      ),
    },
    {
      name: "برداشت غیر فعال تا تاریخ",
      id: "withdrawalDisabledTill",
      selector: (row: WithdrawalDisabledUser) => row?.withdrawalDisableTill,
      cell: (row: WithdrawalDisabledUser) => (
        <>
          {row?.withdrawalDisableTill && (
            <div className="column-date">
              <span className="day">
                {moment
                  .utc(row?.withdrawalDisableTill)
                  .locale("fa")
                  .local()
                  .format("jYYYY/MM/DD")}
              </span>
              <span className="time">
                ساعت{" "}
                {row?.withdrawalDisableTill != null &&
                  moment
                    .utc(row?.withdrawalDisableTill)
                    .local()
                    .format("HH:mm")}
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: WithdrawalDisabledUser) => (
        <div className="actions">
          <Tooltip placement="top" title="تعیین تاریخ غیر فعال بودن برداشت">
            <EditOutlined onClick={() => onEditWithdrawalDisabled(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="کاربران با برداشت غیر فعال"
      className="vt-dashboard-withdrawal-disabled-users"
      blur={cardLoading}
    >
      <Modal
        isOpen={isWithdrawalDisabledTillModalOpen}
        setIsOpen={setIsWithdrawalDisabledTillModalOpen}
        title="تعیین تاریخ غیر فعال بودن برداشت"
        className="withdrawal-disabled-till-modal"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            maxWidth: "180px",
            marginInline: "auto",
          }}
        >
          <div
            className="input-hour"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label>ساعت</label>
            <InputNumber
              min={0}
              placeholder="ساعت"
              onChange={(value) => setWithdrawalHour(value)}
            />
          </div>

          <div
            className="input-minute"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label>دقیقه</label>
            <InputNumber
              min={0}
              max={59}
              placeholder="دقیقه"
              onChange={(value) => setWithdrawalMinute(value)}
            />
          </div>
        </div>
        {(withdrawalMinute !== 0 || withdrawalHour !== 0) &&
          disableTime !== null && (
            <div className="disable-time-info">
              تاریخ غیر فعال شدن برداشت کاربر پس از اعمال:
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginInline: "auto",
                  gap: "16px",
                  marginTop: "16px",
                }}
              >
                <span>
                  {moment(Date.now() + disableTime * 60 * 1000)
                    .locale("fa")
                    .format("jYYYY/MM/DD")}
                </span>

                <span>
                  ساعت{" "}
                  {moment(Date.now() + disableTime * 60 * 1000)
                    .local()
                    .format("HH:mm")}
                </span>
              </p>
            </div>
          )}

        <Button
          type="primary"
          text="اعمال"
          onClick={onSaveWithdrawalDisableTime}
          loading={loading}
        />
      </Modal>
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            onPressEnter={() => onFilterHandler()}
            className="search"
            name="search"
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
              });
              getAllWithdrawalDisabledUsers(
                setCardLoading,
                setUsers,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="withdrawal-disabled-users-table"
        data={users?.items}
        pagination={users?.pagination}
        column={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setData={setUsers}
        getData={getAllWithdrawalDisabledUsers}
        setLoader={setCardLoading}
        filters={query}
      />
    </Card>
  );
};
