import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { FilterValues, PaginationList } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import "./index.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { getAllUsersPersonalWallets } from "core/repos/users-personal-wallets";
import { AuthenticationScoreAction } from "core/domains/authentication-score/authentication-score";
import { getAllAuthenticationScoreActions } from "core/repos/authentication-score";

const { useState, useRef, useEffect } = React;

export const AuthenticationScoresHistory: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [scoreHistory, setScoreHistory] =
    useState<PaginationList<AuthenticationScoreAction>>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [query, setQuery] = useState("");

  const [filterValues, setFilterValues] = useState<FilterValues>({});

  useEffect(() => {
    !scoreHistory &&
      getAllAuthenticationScoreActions(
        setCardLoading,
        setScoreHistory,
        getPageNum(),
        pageSize,
        ""
      );
  }, []);

  const onFilterHandler = () => {
    let query = "";
    if (filterValues?.keywords) {
      query = `?keyword=${filterValues.keywords}`;
    }
    setQuery(query);
    getAllAuthenticationScoreActions(
      setLoading,
      setScoreHistory,
      1,
      pageSize,
      query
    );
  };

  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: AuthenticationScoreAction) =>
        row?.fullName && row?.phoneNumber,
      cell: (row: AuthenticationScoreAction) => (
        <div className="column-fullName">
          <span>{row?.fullName}</span>
          <span>{row?.phoneNumber}</span>
        </div>
      ),
    },
    {
      name: "عنوان امتیاز",
      id: "scoreTitle",
      selector: (row: AuthenticationScoreAction) => row?.type.title,
      cell: (row: AuthenticationScoreAction) => (
        <div className="score-title">{row?.type?.title}</div>
      ),
    },
    {
      name: "مقدار امتیاز",
      id: "scorePoint",
      selector: (row: AuthenticationScoreAction) => row?.point,
      cell: (row: AuthenticationScoreAction) => (
        <div className="score-point">{row?.point}</div>
      ),
    },
  ];

  return (
    <Card
      title="تاریخچه ی امتیازات"
      className="vt-dashboard-authentication-score-history"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            onPressEnter={() => onFilterHandler()}
            className="search"
            name="search"
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
              });
              getAllAuthenticationScoreActions(
                setCardLoading,
                setScoreHistory,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="score-history-table"
        data={scoreHistory?.items}
        pagination={scoreHistory?.pagination}
        column={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setData={setScoreHistory}
        getData={getAllUsersPersonalWallets}
        setLoader={setCardLoading}
        filters={query}
      />
    </Card>
  );
};
