import { API } from "apis";
import { message } from "antd";
import {
  getViaAuth,
  isNotFound,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { AddSlideType, SingleSliderDetail } from "core/domains";
import { SERVER_ADDRESS } from "apis/constants";

export const getAllSlides = (
  setLoader: any,
  setResponse: any,
  page: number,
  pageSize: number,
  query?: string
) => {
  setLoader(true);

  getViaAuth(
    API.sliders.getAllSlides +
      `/${page ? page : 1}/${pageSize ? pageSize : 20}` +
      (query || "")
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    } else {
      isNotFound(response.status) && setResponse([]);
    }
  });
};

export const createSlide = (
  setloader: any,
  data: AddSlideType | undefined,
  onComplete: any
) => {
  setloader(true);
  const fromForm = new FormData();
  data?.file && fromForm.append("File", data.file);
  data?.title && fromForm.append("Title", data.title);
  data?.description && fromForm.append("Description", data.description);
  data?.link && fromForm.append("Link", data.link);
  data?.buttonName && fromForm.append("ButtonName", data.buttonName);

  postViaAuth(API.sliders.addSlide, fromForm, true).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      onComplete();
    }
  });
};

export const updateSlide = (
  setloader: any,
  data: SingleSliderDetail | undefined,
  id: string,
  onComplete: any
) => {
  setloader(true);
  const fromForm = new FormData();
  data?.file && fromForm.append("File", data.file);
  data?.title && fromForm.append("Title", data.title);
  data?.description && fromForm.append("Description", data.description);
  data?.link && fromForm.append("Link", data.link);
  data?.buttonName && fromForm.append("ButtonName", data.buttonName);
  id && fromForm.append("slideId", id);

  postViaAuth(API.sliders.editSlide, fromForm, true).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      onComplete();
    }
  });
};

function base64ToArrayBuffer(base64: any) {
  var binaryString = atob(base64.split(",")[1]);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
export const getSlideById = async (
  setloader: any,
  setdata: any,
  id: string
) => {
  let popupData: any = {};
  let mapLevels = [];
  setloader(true);
  await getViaAuth<SingleSliderDetail>(
    API.sliders.getSlideDetails + `/${id}`
  ).then(async (response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      Object.assign(popupData, {
        title: response.value.title,
        description: response.value.description,
        fileName: response.value.fileName,
        fileContentType: response.value.fileContentType,
        file: response.value.file,
        buttonName: response.value.buttonName,
        link: response.value.link,
        modifiedBy: response.value.modifiedBy,
        lastModifiedDate: response.value.lastModifiedDate,
        createdBy: response.value.createdBy,
        createdOn: response.value.createdOn,
        filePath: `${SERVER_ADDRESS}${response.value.filePath}`,
      });

      setdata(popupData);

      if (response.value.filePath) {
        const base64 = await fetch(popupData.filePath)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
              reader.onloadend = () => {
                res(reader.result);
              };
            });
          });
        popupData.file = base64;
        const file = new File(
          [base64ToArrayBuffer(base64)],
          `${
            response.value.filePath
              .split("/")
              [response.value.filePath.split("/").length - 1].split(".")[0]
          }`
        );
        setdata({ ...popupData, file });
      }
    }
  });
};

export const deleteSlide = async (
  setLoading: any,
  data: any,
  onComplete: any
) => {
  setLoading(true);
  await postViaAuth(API.sliders.deleteSlide + `/${data}`, {}).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("اسلاید مورد نظر حذف شد");
        onComplete();
      }
    }
  );
};
