import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Checkbox, Empty, message, Spin, Tag } from "antd";
import { API } from "apis";
import { Button, Input } from "components";
import { Modal } from "components/modal/modal";
import { Cart } from "core/domains";
import { getUserCarts, removeUserBankAccount } from "core/repos/cart";
import * as React from "react";
import { postViaAuth, put, responseValidator } from "utils/scripts/api";
import "./user-carts.scss";

type CreateCart = {
  iBan: string;
  cardNumber: string;
};

type Props = {
  id: string;
};

var initialValues = {
  cardNumber: "",
  iBan: "",
};

export const UserCartsList: React.FC<Props> = ({ id }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [carts, setCarts] = React.useState<Cart[]>([]);
  const [selectedCart, setSelectedCart] = React.useState<Cart>();
  const [createdCart, setCreatedCart] =
    React.useState<CreateCart>(initialValues);

  const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
  const [createModalIsOpen, setCreateModalIsOpen] =
    React.useState<boolean>(false);

  const [removeModalIsOpen, setRemoveModalIsOpen] =
    React.useState<boolean>(false);
  const [selectedRemoveCart, setSelectedRemoveCart] = React.useState<Cart>();

  function removeDuplicates(arr: string[]) {
    const filtered = arr.filter((item, index) => arr.indexOf(item) === index);
    return filtered.join(" ");
  }

  const closeEditModal = () => {
    setSelectedCart(undefined);
    setEditModalIsOpen(false);
  };

  const closeCreateModal = () => {
    setCreatedCart(initialValues);
    setCreateModalIsOpen(false);
  };

  const onCreateCartHandle = () => {
    setLoading(true);
    postViaAuth(API.banks.add, {
      cardNumber: createdCart?.cardNumber,
      iBan: createdCart?.iBan.toLowerCase().replace("ir", ""),
      userId: id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        getUserCarts(
          setCardLoading,
          setCarts,
          window.location.href.split("/")[5]
        );
        closeCreateModal();
        message.success("حساب بانکی کاربر ایجاد شد.");
      }
    });
  };

  const onUpdateCartHandle = () => {
    setLoading(true);
    put(API.banks.update, {
      cardNumber: selectedCart?.cardNumber,
      iBan: selectedCart?.iBan.toLowerCase().replace("ir", ""),
      isRemoved: selectedCart?.isRemoved,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        getUserCarts(
          setCardLoading,
          setCarts,
          window.location.href.split("/")[5]
        );
        closeEditModal();
        message.success("حساب بانکی کاربر ویرایش شد.");
      }
    });
  };

  const openRemoveModal = (cart: Cart) => {
    setSelectedRemoveCart(cart);
    setRemoveModalIsOpen(true);
  };

  const closeRemoveModal = () => {
    setSelectedRemoveCart(undefined);
    setRemoveModalIsOpen(false);
  };

  const onRemoveCartHandle = () => {
    setLoading(true);
    if (selectedRemoveCart) {
      removeUserBankAccount(setLoading, selectedRemoveCart?.id, () => {
        closeRemoveModal();
        message.success("حساب بانکی کاربر حذف شد.");
        getUserCarts(
          setCardLoading,
          setCarts,
          window.location.href.split("/")[5]
        );
      });
    }
  };

  React.useEffect(() => {
    carts.length === 0 &&
      getUserCarts(
        setCardLoading,
        setCarts,
        window.location.href.split("/")[5]
      );
  }, []);

  return (
    <div className="user-carts-list">
      <Spin spinning={cardLoading}>
        <Modal
          isOpen={createModalIsOpen}
          setIsOpen={setCreateModalIsOpen}
          title="اضافه کردن حساب بانکی"
          closeAble
          className="create-user-cart-modal"
        >
          <div className="modal-box">
            <div className="content">
              <Input
                type="text"
                label="شماره کارت"
                name="cardNumber"
                placeholder="لطفا شماره کارت را وارد کنید"
                dir={createdCart.cardNumber === "" ? "rtl" : "ltr"}
                onChange={(e) =>
                  setCreatedCart({
                    ...createdCart,
                    cardNumber: e.target.value,
                  })
                }
                value={createdCart.cardNumber}
              />
              <Input
                type="text"
                label="شماره شبا"
                name="iBan"
                placeholder="لطفا شماره شبا را وارد کنید"
                dir={createdCart.iBan === "" ? "rtl" : "ltr"}
                onChange={(e) =>
                  setCreatedCart({ ...createdCart, iBan: e.target.value })
                }
                value={createdCart.iBan}
              />
            </div>
            <div className="buttons">
              <Button
                type="info"
                text="ثبت"
                htmlType="button"
                onClick={() => onCreateCartHandle()}
              />
              <Button
                type="outlined"
                text="انصراف"
                onClick={() => setCreateModalIsOpen(false)}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={editModalIsOpen}
          setIsOpen={setEditModalIsOpen}
          title="ویرایش کارت بانکی"
          closeAble
          className="edit-user-cart-modal"
        >
          {selectedCart && (
            <div className="modal-box">
              <div className="content">
                <Input
                  type="text"
                  label="شماره کارت"
                  name="cardNumber"
                  placeholder="لطفا شماره کارت را وارد کنید"
                  dir={selectedCart.cardNumber === "" ? "rtl" : "ltr"}
                  onChange={(e) =>
                    setSelectedCart({
                      ...selectedCart,
                      cardNumber: e.target.value,
                    })
                  }
                  value={selectedCart.cardNumber}
                />
                <Input
                  type="text"
                  label="شماره شبا"
                  name="iBan"
                  placeholder="لطفا شماره شبا را وارد کنید"
                  dir={selectedCart.iBan === "" ? "rtl" : "ltr"}
                  onChange={(e) =>
                    setSelectedCart({ ...selectedCart, iBan: e.target.value })
                  }
                  value={selectedCart.iBan}
                />
                <div className="checkbox">
                  <Checkbox
                    checked={selectedCart.isRemoved}
                    onChange={(e) =>
                      setSelectedCart({
                        ...selectedCart,
                        isRemoved: e.target.checked,
                      })
                    }
                  />
                  <span>کارت حذف شود.</span>
                </div>
              </div>
              <div className="buttons">
                <Button
                  type="info"
                  text="ویرایش"
                  htmlType="button"
                  onClick={() => onUpdateCartHandle()}
                />
                <Button
                  type="outlined"
                  text="انصراف"
                  onClick={() => setEditModalIsOpen(false)}
                />
              </div>
            </div>
          )}
        </Modal>
        <Modal
          isOpen={removeModalIsOpen}
          setIsOpen={setRemoveModalIsOpen}
          title="حذف حساب بانکی"
          closeAble
          className="remove-user-cart-modal"
        >
          {selectedRemoveCart && (
            <div className="modal-box">
              <div className="content">
                <p>آیا از حذف حساب بانکی اطمینان دارید؟</p>
              </div>
              <div className="buttons">
                <Button
                  loading={loading}
                  type="info"
                  text="حذف"
                  htmlType="button"
                  onClick={() => onRemoveCartHandle()}
                />
                <Button
                  type="outlined"
                  text="انصراف"
                  onClick={() => closeRemoveModal()}
                />
              </div>
            </div>
          )}
        </Modal>

        <div className="row">
          <Button
            type="info"
            text="اضافه کردن حساب بانکی"
            htmlType="button"
            onClick={() => setCreateModalIsOpen(true)}
          />
        </div>
        <div className="carts">
          {carts?.length > 0 ? (
            <>
              {carts.map((card) => {
                return (
                  <div className="cart-item">
                    <div className="bank-info">
                      <span>
                        {removeDuplicates(card.bank?.name.split(" "))}
                      </span>
                    </div>
                    <div className="cart-info">
                      <span>{card.cardNumber}</span>
                      <span>{card.iBan}</span>
                    </div>
                    <Tag color={"success"} className="cart-state">
                      {card.state?.title}
                    </Tag>
                    <div className="actions">
                      <EditOutlined
                        onClick={() => {
                          setEditModalIsOpen(true);
                          setSelectedCart(card);
                        }}
                      />
                      <DeleteOutlined onClick={() => openRemoveModal(card)} />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <Empty description="داده ای وجود ندارد" />
          )}
        </div>
      </Spin>
    </div>
  );
};
