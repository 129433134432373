import * as React from "react";
import {
  CheckOutlined,
  DollarCircleOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Calendar, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import { Divider, Dropdown, Menu, MenuProps, Tag, Tooltip } from "antd";
import {
  Transaction,
  TransactionList,
  TransactionState,
} from "core/domains/transaction/transaction";
import { digitSeparator } from "utils/scripts/decimal";
import { Enum, FilterValues } from "core/domains";
import {
  editTransactionState,
  getAllPerfectMoneyTransactions,
  getTransactionStates,
  getTransactionTypes,
  payoutTranscation,
} from "core/repos";
import { getIconPath } from "../../../../utils/scripts/getIconPath";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import { TransactionOrderDetailModal } from "../../../../components/transaction-order-modal/transaction-order-modal";

const { useState } = React;
const defaultStateItems: Enum[] = [
  {
    title: "وضعیت تراکنش",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "نوع تراکنش",
    id: 0,
  },
];

export const PerfectMoneyTransactions: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TransactionList>();

  const [approveModalIsOpen, setApproveModalIsOpen] = useState<boolean>(false);
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [payoutLoading, setPayoutLoading] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(20);
  const [selectedItem, setSelectedItem] = useState<Transaction>();
  const [cardLoading, setCardLoading] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    state: defaultStateItems[0],
    type: defaultTypeItems[0],
  });

  const closeAppproveModal = () => {
    setDescriptionValue("");
    setApproveModalIsOpen(false);
  };
  const closePayoutModal = () => setPayoutModalIsOpen(false);

  const openPayoutModal = (item: Transaction) => {
    setSelectedItem(item);
    setPayoutModalIsOpen(true);
  };
  const openApproveModal = (item: Transaction) => {
    setSelectedItem(item);
    setApproveModalIsOpen(true);
  };

  const onTypeClick: MenuProps["onClick"] = ({ key }) => {
    setFilterValues({
      ...filterValues,
      type: transactionTypes?.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const onStateClick: MenuProps["onClick"] = ({ key }) => {
    setFilterValues({
      ...filterValues,
      state: transactionStates?.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const [transactionStates, setTransactionStates] = useState<Enum[]>([
    {
      id: 0,
      title: "همه",
    },
  ]);
  const [transactionTypes, setTransactionTypes] = useState<Enum[]>([
    {
      id: 0,
      title: "همه",
    },
  ]);

  var query = "&pageSize=25";
  const filterHandle = () => {
    if (filterValues.type?.id !== 0) query += `&type=${filterValues.type?.id}`;
    if (filterValues.state?.id !== 0)
      query += `&state=${filterValues.state?.id}`;
    if (filterValues.startDate)
      query += `&startDate=${convertFaToEnCalendar(filterValues.startDate)}`;
    if (filterValues.endDate)
      query += `&endDate=${convertFaToEnCalendar(filterValues.endDate)}`;
    if (filterValues.keywords) query += `&keywords=${filterValues.keywords}`;
    getAllPerfectMoneyTransactions(
      setLoading,
      setTransactions,
      1,
      pageSize,
      query
    );
  };

  React.useEffect(() => {
    !transactions &&
      getAllPerfectMoneyTransactions(
        setCardLoading,
        setTransactions,
        getPageNum(),
        pageSize,
        ""
      );
    transactionStates?.length === 1 &&
      getTransactionStates(setCardLoading, setTransactionStates);
    transactionTypes?.length === 1 &&
      getTransactionTypes(setCardLoading, setTransactionTypes);
  }, []);

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "50px",
    },
    {
      name: "نام ارز",
      id: "currencyName",
      selector: (row: Transaction) => row.market.symbol,
      cell: (row: Transaction) => (
        <>
          <img
            style={{ height: "24px", marginLeft: "6px" }}
            src={getIconPath(row.market.symbol)}
          />
          <span>{row.market.name}</span>
        </>
      ),
    },
    {
      name: "شناسه",
      id: "trackingCode",
      selector: (row: Transaction) => row.trackingCode,
      cell: (row: Transaction) => (
        <span className="en">{row.trackingCode}</span>
      ),
    },
    {
      name: "نوع تراکنش",
      id: "transactionType",
      selector: (row: Transaction) => row.type.title,
      cell: (row: Transaction) => (
        <>
          <Tag color={`${row.type.id === 1 ? "green" : "red"}`}>
            {row.type.title}
          </Tag>
        </>
      ),
    },
    {
      name: "مقدار",
      id: "price",
      selector: (row: Transaction) => digitSeparator(row.amount),
    },
    {
      name: "پلتفرم",
      id: "platform",
      width: "100px",
      selector: (row: Transaction) => row.platform.title,
      cell: (row: Transaction) => (
        <>
          <Tag color={`${row.platform.id === 1 ? "blue" : "green"}`}>
            {row.platform.title}
          </Tag>
        </>
      ),
    },
    {
      name: "زمان",
      id: "createdAt",
      selector: (row: Transaction) => row.createdAt,
      cell: (row: any) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row: Transaction) => row.state.id,
      cell: (row: any) => (
        <Badge
          text={row.state.id == 2 ? "موفق" : row.state.title}
          type={
            row.state.id == 2
              ? "success"
              : row.state.id == 1
              ? "waiting"
              : "error"
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? "success"
              : row.state.id == 1
              ? "waiting"
              : "danger"
          }.svg`}
        />
      ),
      width: "200px",
    },
    {
      name: "جزئیات",
      id: "detail",

      cell: (row: Transaction) => (
        <div className="actions">
          {row.state.id === TransactionState.WaitingForAdmin && (
            <Tooltip placement="top" title={"تایید و تسویه تراکنش"}>
              <DollarCircleOutlined onClick={() => openPayoutModal(row)} />
            </Tooltip>
          )}
          {row.state.id === TransactionState.WaitingForAdmin && (
            <Tooltip placement="top" title={"تایید تراکنش"}>
              <CheckOutlined onClick={() => openApproveModal(row)} />
            </Tooltip>
          )}
          <Tooltip placement="top" title={"جزئیات تراکنش"}>
            <InfoCircleOutlined onClick={() => handleModal(row)} />
          </Tooltip>
          <Tooltip placement="top" title={"ویرایش تراکنش"}>
            <EditOutlined
              onClick={() =>
                window.open(`/transactions/edit/${row.id}`, "_self")
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }

  async function onApproveHandler() {
    selectedItem &&
      (await editTransactionState(
        setCardLoading,
        selectedItem,
        closeAppproveModal,
        TransactionState.Succeed
      ));
    getAllPerfectMoneyTransactions(
      setLoading,
      setTransactions,
      getPageNum(),
      pageSize,
      query
    );
  }

  return (
    <Card
      title="واریز و برداشت پرفکت"
      blur={cardLoading}
      className="perfect-money-transactions"
    >
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={selectedItem}
        />
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setApproveModalIsOpen}
          isOpen={approveModalIsOpen}
          closeAble
          title="آیا از تایید این تراکش مطمین هستید ؟"
          className="transaction-approve-modal"
          onClose={closeAppproveModal}
        >
          <div className="modal-box">
            <Input
              name="description"
              label="توضیحات"
              placeholder="توضیحات را وارد کنید"
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={descriptionValue}
              type="text"
            />
            <div className="buttons">
              <Button
                type="info"
                text="بله مطمئنم"
                loading={approveLoading}
                disabled={approveLoading}
                htmlType="button"
                onClick={(e) => onApproveHandler}
              />
              <Button
                type="outlined"
                text="بازگشت"
                onClick={() => closeAppproveModal()}
              />
            </div>
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setPayoutModalIsOpen}
          closeAble
          isOpen={payoutModalIsOpen}
          title={"آیا از تسویه تراکنش مطمین هستید ؟"}
          className="transaction-payout-modal"
        >
          <div className="modal-box">
            <div className="buttons">
              <Button
                type="info"
                text="بله مطمئنم"
                loading={payoutLoading}
                disabled={payoutLoading}
                htmlType="button"
                onClick={async () => {
                  await payoutTranscation(
                    setCardLoading,
                    selectedItem,
                    closePayoutModal
                  );
                  getAllPerfectMoneyTransactions(
                    setLoading,
                    setTransactions,
                    getPageNum(),
                    20,
                    query
                  );
                }}
              />
              <Button
                type="outlined"
                text="بازگشت"
                onClick={() => closePayoutModal()}
              />
            </div>
          </div>
        </Modal>
      )}
      <div className="desktop-show">
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues.keywords}
              placeholder="جستجو کنید ..."
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {transactionTypes &&
                    transactionTypes.map((type) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, type })
                        }
                      >
                        {type.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues.type?.title}
              />
            </Dropdown>

            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {transactionStates &&
                    transactionStates.map((state) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, state })
                        }
                      >
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues.state?.title}
              />
            </Dropdown>

            <Calendar
              value={filterValues.startDate}
              placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues.endDate}
              placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>
          <Divider type="vertical" />
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={filterHandle}
            />
            <span
              onClick={() => {
                setFilterValues({
                  keywords: "",
                  startDate: "",
                  endDate: "",
                  state: transactionStates[0],
                  type: transactionTypes[0],
                });
                getAllPerfectMoneyTransactions(
                  setLoading,
                  setTransactions,
                  getPageNum(),
                  pageSize,
                  ""
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {transactions && (
          <Table
            pagination={transactions.pagination}
            blur={cardLoading}
            setLoader={setCardLoading}
            setData={setTransactions}
            getData={getAllPerfectMoneyTransactions}
            data={transactions.items}
            column={columns}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </Card>
  );
};
