import { message } from "antd";
import { API } from "apis";
import {
  Transaction,
  TransactionState,
  TransactionStateLabel,
  TransactionUpdateModel,
} from "core/domains/transaction/transaction";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";

export const getSumAndCountCurrencyTransactions = (
  setLoader: any,
  setData: any,
  query: string
) => {
  setLoader(true);
  getViaAuth(API.transaction.getCountAndSum + "?" + query + "&symbol=IRT").then(
    (response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        setData(response.value);
      }
    }
  );
};

export const getAllCurrencyTransactions = async (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string,
  setCount: any
) => {
  setloader(true);
  await getAllTransactionsCount(
    setloader,
    setCount,
    page,
    pageSize,
    `?market=IRT${query.startsWith("&") ? query : `&${query}`}`
  );
  await getViaAuth(
    `${API.transaction.getAll}/${page}/${pageSize}?market=IRT${
      query.startsWith("&") ? query : `&${query}`
    }`
  ).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getAllTransactions = async (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string,
  setCount: any
) => {
  setloader(true);
  getAllTransactionsCount(() => {}, setCount, page, pageSize, query);
  getViaAuth(`${API.transaction.getAll}/${page}/${pageSize}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getAllTransactionsCount = async (
  setLoader: any,
  setData: any,
  page: number,
  pageSize: number,
  query: string
) => {
  await getViaAuth(
    `${API.transaction.getCount}/${page}/${pageSize}${query}`
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setData(response.value);
    }
  });
};

export const getAllCryptoTransactions = (
  setloader: any,
  setdata: any,
  page: number,
  pagesize: number,
  query: string
) => {
  getViaAuth(`${API.transaction.getAll}/${page}/${pagesize}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getAllPerfectMoneyTransactions = (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  getViaAuth(
    `${API.transaction.getAll}/${page}/${pageSize}?market=PM${query}`
  ).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getTransactionById = (
  setloader: any,
  setdata: any,
  id: string
) => {
  setloader(true);
  getViaAuth(API.transaction.getById + `${id}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateTransaction = (
  setloader: any,
  entity: TransactionUpdateModel,
  success: any
) => {
  setloader(true);
  postViaAuth(API.transaction.edit, entity).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("تراکنش مورد نظر با موفقیت اصلاح شد.");
      success();
    }
  });
};

export const getTransactionStates = async (setloader: any, setdata: any) => {
  setloader(true);
  await getViaAuth(API.transaction.transactionState).then((response: any) => {
    setloader(false);

    if (responseValidator(response.status)) {
      setdata([{ id: 0, title: "همه وضعیت تراکنش" }, ...response.value]);
    }
  });
};

export const getTransactionTypes = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.transaction.transactionTypes).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: 0, title: "همه نوع تراکنش" }, ...response.value]);
    }
  });
};

export const getTransactionPlatforms = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.transaction.transactionPlatforms).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: 0, title: "همه پلتفرم ها" }, ...response.value]);
    }
  });
};

export const getTransactionMarketsTypes = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.transaction.transactionMarketTypes).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: 0, title: "همه" }, ...response.value]);
    }
  });
};

export const getTransactionDepositCurrencyTypes = (
  setloader: any,
  setdata: any
) => {
  setloader(true);
  getViaAuth(API.enums.depositCurrencyTypes).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: -1, title: "همه" }, ...response.value]);
    }
  });
};

export const editTransactionState = async (
  setloader: any,
  transaction: Transaction | undefined,
  onClose: () => any,
  state: TransactionState,
  description?: string
) => {
  setloader(true);
  await postViaAuth(API.transaction.edit, {
    id: transaction?.id,
    state: state,
    description: description,
  }).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(
        `تراکنش مورد نظر با موفقیت   "${TransactionStateLabel.get(state)}"  شد.`
      );
      onClose();
    }
  });
};

export const editSingleTransactionsState = async (
  setloader: any,
  transaction: Transaction | undefined,
  state: TransactionState,
  onComplete: () => void,
  onFail: (response: any) => any
) => {
  await postViaAuth(API.transaction.edit, {
    id: transaction?.id,
    state: state,
  }).then((response: any) => {
    if (responseValidator(response.status)) {
      onComplete();
    } else {
      onFail(response);
    }
  });
};

export const payoutSingleTranscation = async (
  setloader: any,
  transaction: Transaction | undefined,
  onComplete: () => void,
  onFail: (response: any) => any
) => {
  setloader(true);
  await postViaAuth(API.transaction.payout, {
    transactionId: transaction?.id,
  }).then((response: any) => {
    if (responseValidator(response.status)) {
      onComplete();
    } else {
      onFail(response);
    }
  });
};

export const payoutTranscation = async (
  setloader: any,
  transaction: Transaction | undefined,
  setClose: any,
  getData?: any
) => {
  setloader(true);
  await postViaAuth(API.transaction.payout, {
    transactionId: transaction?.id,
  }).then((response: any) => {
    setloader(false);
    setClose();
    if (responseValidator(response.status)) {
      message.success("تراکنش مورد نظر با موفقیت تایید و تسویه شد.");

      getData && getData();
    }
  });
};
