import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  put,
  responseValidator,
} from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface BingXSetting {
  secretKey?: string;
  accessId?: string;
}

export const BingXSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [bingXSettings, setBingXSettings] = React.useState<BingXSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getBingXSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.bingXCredentials).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setBingXSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeBingXCredentials, {
      ...bingXSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getBingXSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getBingXSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-bingx-credentials">
        <Card title={`کانفیگ بینگ ایکس`} className="gateway-item">
          <Input
            label="کلید دسترسی"
            type="text"
            name="apiKey"
            value={bingXSettings?.accessId}
            onChange={(e) =>
              setBingXSettings({ ...bingXSettings, accessId: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="سکرت"
            type="text"
            name="secretKey"
            value={bingXSettings?.secretKey}
            onChange={(e) =>
              setBingXSettings({
                ...bingXSettings,
                secretKey: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              bingXSettings?.accessId === "" ||
              bingXSettings?.secretKey === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
