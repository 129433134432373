export const __API = {
  token: "general/signin/refresh-token",
  login: "general/signin",
  verify: "general/verify",
  signup: "general/signup",
  verify_2fa: "general/verify-2fa",
  signupVerify: "general/signup-verify",
  forgotPassowrd: "general/forgot-password",
  verifyForgotPassword: "general/verify-forgot-password",
  setPassword: "general/set-password",
  enums: {
    validationStates: "enum/validationstates",
    cartStates: "enum/bankaccountstates",
    cashinModes: "enum/cashinmodes",
    sliderTypes: "enum/slidertype",
    bonusTypes: "enum/bonustype",
    depositCurrencyTypes: "enum/depositcurrencytype",
    marketTypes: "enum/markettypes",
    userCategories: "enum/usercategory",
    gender: "enum/gender",
  },
  serviceLogs: {
    getAll: "admin/serviceslogs/all",
  },
  notification: {
    getAll: "admin/notification/all",
    details: "admin/notification/all-details",
    getNotificationById: "admin/notification",
    edit: "admin/notification/edit",
    add: "admin/notification/add",
    reasonsEnum: "enum/notificationreasons",
  },
  popup: {
    getAll: "admin/popup/getallpopups",
    add: "admin/popup/add",
    edit: "admin/popup/edit",
    getAllActives: "admin/popup/getactivepopups",
    getById: "admin/popup/getpopupbyid",
  },
  referral: {
    getAllActions: "admin/referral/get-all-referral-actions",
    getAllReferralInfos: "admin/referral/get-all-referral-infos",
    getAllLevels: "admin/referral/level/getall",
    getLevelById: "admin/referral/level",
    editLevel: "admin/referral/level/edit",
    addLevel: "admin/referral/level/add",
    deleteLevel: "admin/referral/level/delete",
    getReferralDetails: "admin/referral/get-referral-details",
  },
  marketing: {
    queries: "marketing/queries",
  },
  dashboard: {
    index: "admin/dashboard",
    gateways: "admin/dashboard/gatewaystransactionssum",
    trxUsdtReport: "admin/dashboard/report/trx-usdt",
  },
  surveys: {
    get: "admin/user/get-user-documents",
    getDoc: "admin/user/get-user-document",
  },
  manualValidation: {
    list: "admin/manualvalidations",
    details: "admin/manualvalidations/details",
    accept: "admin/manualvalidations/accept",
    reject: "admin/manualvalidations/reject",
    states: "enum/validationstates",
    types: "enum/validationentities",
  },
  usersPersonalWallets: {
    getAll: "admin/userspersonalwallets/getall",
  },
  authenticationScores: {
    all: "admin/authenticationscores/all",
    edit: "admin/authenticationscores/editauthenticationscore",
    allActions: "admin/authenticationscores/all-actions-histories",
    editUser: "admin/authenticationscores/edituserauthenticationscore",
  },
  settings: {
    currency: "sitesettings/currency",
    changeCurrencyAmounts: "admin/sitesettings/changecurrencyamounts",
    gateways: "admin/sitesettings/gateway",
    commisions: "admin/sitesettings/ordercommission",
    binanceCredentials: "admin/sitesettings/binancecredentials",
    kucoinCredentials: "admin/sitesettings/kucoincredentials",
    minDeposit: "admin/sitesettings/mindepositcoin",
    firstBuyDelay: "admin/sitesettings/firstbuydelay",
    changeCryptoWithdraw: "admin/sitesettings/changecryptowithdraw",
    changeCurrencyWithdraw: "admin/sitesettings/changecurrencywidthrawlconfigs",
    changeUserValidation: "admin/sitesettings/changeuservalidation",
    getUserValidationSetting: "admin/sitesettings/uservalidationsetting",
    getCurrencyWithdrawl: "admin/sitesettings/currencywithdrawalmethodsettings",
    getOrderCommission: "admin/sitesettings/ordercommission",
    getPMsettings: "admin/sitesettings/perfectmoneysettings",
    currencyAmountSettings: "admin/sitesettings/currencyamountssettings",
    dangerousAmounts: "admin/sitesettings/dangerousamountssetting",
    changeDangerousAmounts: "admin/sitesettings/changedangerousamountsettings",
    allowedIpSettings: "admin/sitesettings/allowedipssettings",
    changeIpSettings: "admin/sitesettings/changeallowedipssettings",
    changeCashInSettings: "admin/sitesettings/changecashinsettings",
    cashInSettings: "admin/sitesettings/cashinsettings",
    changeCashInMinLevel: "admin/sitesettings/changeCashInMinimumLevelSettings",
    changeCashInSettingsActivation:
      "admin/sitesettings/changecashinactivationsettings",
    currencyWithdrawlMethodSettings:
      "admin/sitesettings/currencywithdrawalmethodsettings",
    cryptoWithdrawlMethodSettings: "admin/sitesettings/cryptowithdrawal",
    changeOrderCommission: "admin/sitesettings/changeordercommission",
    changeMinDepositCoin: "admin/sitesettings/changemindepositcoin",
    changeFirstBuyDelay: "admin/sitesettings/changefirstbuydelay",
    changeGateway: "admin/sitesettings/changegateway",
    changePMSettings: "admin/sitesettings/changeperfectmoneyconfigs",
    vandarSettings: "admin/sitesettings/vandarsettings",
    changeVandarSettings: "admin/sitesettings/changevandarsettings",
    changeBinanceCredentials: "admin/sitesettings/changebinancecredentials",
    payStarSettings: "Admin/SiteSettings/PayStarSettings",
    changePaystarSettings: "Admin/SiteSettings/ChangePayStarSettings",
    changeKucoinCredentials: "admin/sitesettings/changekucoincredentials",
    zibalSettings: "admin/sitesettings/zibalsettings",
    changeZibalSettings: "admin/sitesettings/changezibalsettings",
    jibimoSettings: "admin/sitesettings/jibimosettings",
    changeJibimoSettings: "admin/sitesettings/changejibimosettings",
    callOrOtpSettings: "admin/sitesettings/callorotpsettings",
    changeCallOrOtpSettings: "admin/sitesettings/changecallorotpsettings",
    jibitPaymentSettings: "admin/sitesettings/jibitpaymentsettings",
    changeJibitPaymentSettings: "admin/sitesettings/changejibitpaymentsettings",
    changeJibitKycSetting: "admin/sitesettings/changejibitkyccredentials",
    jibitKycCredentials: "admin/sitesettings/jibitkyccredentials",
    changeTelegramSettings: "admin/sitesettings/changetelegramsettings",
    telegramSettings: "admin/sitesettings/telegramSettings",
    manualConfirmTxid: "admin/sitesettings/manualtxidconfirmsettings",
    changeManualConfirmTxid: "admin/sitesettings/changemanualconfirmtxid",
    registerActivationSetting: "admin/sitesettings/registersettings",
    changeRegisterActivationSetting:
      "admin/sitesettings/changeregistersettings",
    changeTron24Setting: "admin/sitesettings/changetron24setting",
    tron24Setting: "admin/sitesettings/tron24setting",
    withdrawalCommmission: "admin/sitesettings/withdrawalcommissionsetting",
    changeWithdrawalCommission: "admin/sitesettings/changewithdrawalcommission",
    cryptoWithdrawalCommission:
      "admin/sitesettings/cryptowithdrawalfeesettings",
    changeCryptoWithdrawalCommission:
      "admin/sitesettings/changecryptowithdrawalfeesetting",
    getUVoucherSettings: "admin/sitesettings/uvouchersetting",
    changeUVoucherSetting: "admin/sitesettings/changeuvouchersetting",
    changeTransferTronSetting: "admin/sitesettings/changetransfertronsettings",
    getTransferTronSettings: "admin/sitesettings/transfertronsettings",
    getEveryoneFinancialSettings:
      "admin/sitesettings/financialsettingsforeveryone",
    changeEveryoneFinancialSettings:
      "admin/sitesettings/changefinancialsettingsforeveryonesettings",
    bingXCredentials: "admin/sitesettings/bingxcredentials",
    changeBingXCredentials: "admin/sitesettings/changebingxcredentials",
  },
  users: {
    getAllUsers: "admin/user/all",
    getUserWallet: "admin/user/get-user-wallet",
    editActivation: "admin/user/edit-activation",
    editLockoutUser: "admin/user/lockout-user",
    editWithdrawalEnable: "admin/user/edit-withdrawal-activation",
    create: "admin/user/create",
    get: "admin/user/get-details",
    carts: {
      getAll: "admin/bankaccounts/list/",
      remove: "admin/bankaccounts/remove",
    },
    edit: "admin/user/edit-user",
    roles: "enum/roles",
    levels: "enum/levels",
    editDoc: "admin/user/edit-user-docs",
    getSummery: "admin/user/get-summary/",
    getAllWithdrawalDisabled: "admin/user/getallwithdrawaldisableduser",
    editWithdrawalDisabledTill: "admin/user/edit-withdrawaldisabletill",
    changeWhiteList: "admin/user/change-whitelist-status",
  },
  ticket: {
    getAll: "admin/ticket/all",
    get: "admin/ticket/details",
    close: "admin/ticket/close",
    answer: "admin/ticket/answer",
  },
  authSurvey: {
    list: "admin/authsurvey/list",
    detials: "admin/authsurvey/details",
    accept: "admin/authsurvey/accept",
    reject: "admin/authsurvey/reject",
  },
  financialSettings: {
    update: "admin/financialSettings/update",
    remove: "admin/financialSettings/remove",
    get: "admin/financialSettings/get",
  },
  roles: {
    getAllRoles: "admin/role/get-all",
    getAllPermissions: "admin/role/get-all-permissions",
    createRole: "admin/role/create",
    editRole: "admin/role/edit",
    getRoleById: "admin/role",
    deleteRole: "admin/role/delete",
  },
  levels: {
    getAllLevels: "admin/level/get-all",
    getLevelById: "admin/level/",
    editLevel: "admin/level/edit",
  },
  pm: {
    voucherInfo: "admin/perfectmoney/voucher-tracking",
    charge: "admin/perfectmoney/charge",
  },
  cashInManagement: {
    getAll: "admin/transaction/user-cashin",
  },
  blacklist: {
    list: "admin/blacklist/list",
    update: "admin/blacklist/update",
    delete: "admin/blacklist/delete",
    add: "admin/blacklist/add",
    getById: "admin/blacklist/getblackitembyid",
  },
  bonuses: {
    getAll: "admin/bonus/list",
    details: "admin/bonus/getbonusedetail",
    update: "admin/bonus/update",
  },
  banks: {
    list: "admin/bankaccounts/list",
    update: "admin/bankaccounts/update",
    add: "admin/bankaccounts/add",
    detail: "admin/bankaccounts/details",
    allBanks: "admin/bank/list",
    editState: "admin/bankaccounts/editbankaccountstate",
  },
  kucoin: {
    kucoinHistory: "admin/kucoin/history",
  },
  market: {
    getAllMarkets: "admin/market/get-all",
    getMarketById: "admin/market",
    editMarket: "admin/market/edit",
    syncMarket: "admin/market/sync",
  },
  bankAccountTracking: {
    cardInfo: "admin/bankaccounts/cardinfo",
  },
  transaction: {
    getAll: "admin/transaction/all",
    getCount: "admin/transaction/paginated",
    edit: "admin/transaction/edit",
    getById: "admin/transaction/",
    getCountAndSum: "admin/transaction/getsumandcount",
    transactionState: "enum/transactionstates",
    transactionTypes: "enum/transactiontypes",
    transactionPlatforms: "enum/platforms",
    transactionMarketTypes: "enum/markettypes",
    payout: "admin/transaction/payout",
    crypto: {
      detail: "transaction/crypto-details",
    },
  },
  order: {
    getAll: "admin/order/get-all",
    getCount: "admin/order/paginated",
    states: "enum/orderstates",
    types: "enum/ordertypes",
    fullReport: "admin/order/fullreport",
  },
  fiats: {
    getAllFiats: "getallfiats",
    editFiat: "admin/fiat/edit",
    getAllFiatsAdmin: "admin/fiat/get-all",
    getById: "admin/fiat/get",
    updatePartnerPrice: "admin/fiat/update-partner-price",
    getPartnersPrices: "admin/fiat/get-partners-prices",
    getAllBuyFiatLimits: "admin/fiat/get-all-buy-fiat-limits",
    updateBuyFiatLimits: "admin/fiat/edit-buy-fiat-limits",
  },
  wallet: {
    getAllWallets: "admin/wallet/get-all",
    getAllBySymbol: "admin/wallet/get-all-by-symbol",
    userWallet: "admin/wallet/userwallet",
    updateWallet: "admin/wallet/updatewallet",
    syncWallet: "admin/wallet/syncwallet",
  },
  gateways: {
    getAllGateways: "admin/gateway/getall",
    editGateway: "admin/gateway/update",
  },
  profile: {
    get: "general/get-profile",
  },
  awaitingconfirmation: {
    getawaitingList: "admin/awaitingconfirmation/getawaitinglist",
  },
  sliders: {
    getAllSliders: "admin/slider/getallsliders",
    getSliderDetails: "admin/slider/getsliderdetails",
    getSlideDetails: "admin/slider/getslidedetails",
    addSlider: "admin/slider/addslider",
    editSlider: "admin/slider/editslider",
    getAllSlides: "admin/slider/getallslides",
    addSlide: "admin/slider/addslide",
    editSlide: "admin/slider/editslide",
    deleteSlide: "admin/slider/deleteslide",
    deleteSlider: "admin/slider/deleteslider",
  },
};
