import * as React from "react";
import "./gateway-setting.scss";
import { Divider, Spin } from "antd";
import { GatewaySettings } from "./gateway";
import { VandarSettings } from "./vandar";
import { JibitKycSettings } from "./jibit-kyc";
import { ZibalSettings } from "./zibal";
import { JibimoSettings } from "./jibimo";
import { JibitPaymentSettings } from "./jibit-payment";
import { PayStarSettings } from "./payStarSettings";

const { useState, useEffect } = React;

export const GatewaySetting: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-gatewaysetting">
          <h3>تنظیمات درگاه ها</h3>
          {
            <form>
              <GatewaySettings />
              <Divider />
              <div className="row col3">
                <VandarSettings />
                <PayStarSettings />
                <JibimoSettings />
              </div>
              <Divider />
              <div className="row col3">
                <ZibalSettings />
                <JibitKycSettings />
                <JibitPaymentSettings />
              </div>
            </form>
          }
        </div>
      </Spin>
    </>
  );
};
