import { getIconPath } from "../../../utils/scripts/getIconPath";
import { copyToClipboard } from "../../../utils/scripts/copyToClipboard";
import {
  detect_bank,
  formatCardNumber,
} from "../../../utils/scripts/bankDetecter";
import moment from "jalali-moment";
import * as React from "react";

import { ReactComponent as CopyOutlined } from "assets/icons/copy-outlined.svg";
import {
  Transaction,
  TransactionState,
  TransactionType,
} from "../../../core/domains/transaction/transaction";
import { Spin } from "antd";
import { digitSeparator } from "../../../utils/scripts/decimal";

interface Props {
  transactionItem: Transaction;
  loading: boolean;
}

export const TransactionDetailModalData: React.FC<Props> = ({
  transactionItem,
  loading,
}) => {
  return (
    <>
      {transactionItem?.market && (
        <div className="row double-column">
          <div className="col">
            <span>نام ارز</span>
            <div className="coin-info">
              <img src={getIconPath(transactionItem?.market?.symbol)} />
              <span>{transactionItem?.market.name}</span>
            </div>
          </div>
          {transactionItem.network && (
            <div className="col">
              <span>نوع شبکه</span>
              <div className="en">
                <span>
                  {transactionItem?.network.name.toUpperCase()} |{" "}
                  {transactionItem?.network.network.toUpperCase()}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {transactionItem?.amount && (
        <div className="row double-column has-divider">
          <div className="col">
            <span>مقدار تراکنش</span>
            <div>
              <span>
                {digitSeparator(transactionItem?.amount)}{" "}
                {transactionItem?.market.symbol === "IRT"
                  ? "تومان"
                  : transactionItem.market.name}
              </span>
            </div>
          </div>
          {transactionItem.type.id === TransactionType.Withdrawal &&
            transactionItem.network &&
            transactionItem.network?.fee !== null && (
              <div className="col">
                <span> کارمزد شبکه</span>
                <div>
                  <span>
                    {digitSeparator(transactionItem?.network.fee)}{" "}
                    {transactionItem.market.name}
                  </span>
                </div>
              </div>
            )}
        </div>
      )}
      {transactionItem?.trackingCode && (
        <div className="row has-divider">
          <span>شناسه تراکنش</span>
          <div className="en">
            <span>{transactionItem?.trackingCode}</span>
            <CopyOutlined
              onClick={() =>
                transactionItem?.trackingCode &&
                copyToClipboard(transactionItem?.trackingCode, "شناسه تراکنش")
              }
            />
          </div>
        </div>
      )}
      {transactionItem?.type && (
        <div className="row has-divider">
          <span>نوع تراکنش</span>
          <div>
            <span>{transactionItem?.type.title}</span>
          </div>
        </div>
      )}
      {transactionItem.onPrice !== 0 &&
        transactionItem.market.symbol !== "IRT" && (
          <div className="row has-divider">
            <span>قیمت لحظه</span>
            <div>
              <span>{digitSeparator(transactionItem.onPrice.toFixed(0))}</span>
            </div>
          </div>
        )}

      {transactionItem?.voucher && (
        <>
          <div className="row has-divider">
            <span>کد ووچر</span>
            <div>
              <span>{transactionItem?.voucher.voucherCode || ""}</span>
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(
                    transactionItem?.voucher.voucherCode || "",
                    "کد ووچر"
                  )
                }
              />
            </div>
          </div>
          {transactionItem.voucher.voucerhActivationCode && (
            <div className="row has-divider">
              <span>کد فعال ساز ووچر</span>
              <div>
                <span>
                  {transactionItem?.voucher.voucerhActivationCode || ""}
                </span>
                <CopyOutlined
                  onClick={() =>
                    copyToClipboard(
                      transactionItem?.voucher.voucerhActivationCode || "",
                      "کد فعال ساز ووچر"
                    )
                  }
                />
              </div>
            </div>
          )}
          <div className="row has-divider">
            <span>بچ نامبر</span>
            <div>
              <span>{transactionItem?.voucher.batchNumber || ""}</span>
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(
                    transactionItem?.voucher.batchNumber || "",
                    "بچ نامبر"
                  )
                }
              />
            </div>
          </div>
        </>
      )}

      {transactionItem?.network &&
        transactionItem.type.id === TransactionType.Withdrawal && (
          <>
            <div className="row has-divider">
              <span>مقدار نهایی پس از کسر کارمزد</span>
              <div>
                <span>
                  {transactionItem?.amount - transactionItem?.network.fee}{" "}
                  {transactionItem.market.name}
                </span>
              </div>
            </div>
          </>
        )}
      {transactionItem?.balanceAfterTransaction !== null && (
        <div className="row has-divider">
          <span>موجودی بعد از تراکنش</span>
          <div>
            <span>
              {digitSeparator(transactionItem?.balanceAfterTransaction)}{" "}
              {transactionItem?.market.symbol === "IRT"
                ? "تومان"
                : transactionItem.market.name}
            </span>
          </div>
        </div>
      )}
      {transactionItem.bankAccount &&
      transactionItem.trackingCode.startsWith("D") ? (
        <div className="row has-divider">
          <span>حساب بانکی</span>
          <div className="bankaccount">
            {transactionItem.bankAccount &&
              transactionItem.bankAccount.cardNumber &&
              detect_bank(transactionItem.bankAccount.cardNumber)
                ?.bank_logo && (
                <img
                  className="bankLogo"
                  src={
                    detect_bank(transactionItem.bankAccount?.cardNumber)
                      ?.bank_logo
                  }
                />
              )}{" "}
            <span className="">
              {formatCardNumber(transactionItem.bankAccount?.cardNumber) ?? "-"}
            </span>
          </div>
        </div>
      ) : (
        transactionItem.bankAccount && (
          <div className="row has-divider">
            <span>شماره شبا</span>
            <div>
              <span>
                {transactionItem.bankAccount.iBan.toUpperCase().startsWith("IR")
                  ? transactionItem.bankAccount?.iBan
                  : "IR" + transactionItem.bankAccount?.iBan}
              </span>
            </div>
          </div>
        )
      )}
      {transactionItem.paymentIdentifier && (
        <div className="row has-divider">
          <span>شناسه یکتا تراکنش</span>
          <div>
            <span className="copy">
              {transactionItem.paymentIdentifier}
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(
                    transactionItem.paymentIdentifier || "",
                    "شناسه یکتا تراکنش"
                  )
                }
              />
            </span>
          </div>
        </div>
      )}

      {transactionItem.ip && (
        <div className="row has-divider">
          <span>آیپی کاربر</span>
          <div>
            <span>{transactionItem.ip}</span>
          </div>
        </div>
      )}
      {transactionItem?.amount &&
      transactionItem.type.id === TransactionType.Withdrawal &&
      transactionItem?.network?.fee ? (
        <div className="row has-divider ">
          <span>مقدار نهایی</span>
          <div className="en">
            <span>
              {transactionItem?.amount - (transactionItem?.network?.fee ?? 0)}
              {transactionItem?.market.symbol}
            </span>
          </div>
        </div>
      ) : null}
      {transactionItem?.trackingCode.startsWith("W") &&
        transactionItem?.wallet?.walletAddress && (
          <div className="row has-divider ">
            <span>آدرس کیف پول</span>
            <div>
              <span className="long-text">
                {transactionItem?.wallet?.walletAddress}
              </span>
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(
                    transactionItem?.wallet?.walletAddress || "",
                    "آدرس کیف پول"
                  )
                }
              />
            </div>
          </div>
        )}
      {transactionItem?.trackingCode.startsWith("W") &&
        transactionItem?.wallet?.memo && (
          <div className="row has-divider ">
            <span>آدرس تگ یا ممو</span>
            <div>
              <span>{transactionItem?.wallet?.memo} </span>
            </div>
          </div>
        )}
      {(loading ||
        (transactionItem?.txId !== null && transactionItem?.txId !== "")) && (
        <div className="row has-divider">
          <span>هش تراکنش (تی ایکس آیدی)</span>

          {loading ? (
            <Spin size={"large"} spinning={loading}></Spin>
          ) : (
            <div>
              {transactionItem?.network?.network.toUpperCase() === "TRX" ? (
                <a
                  className="long-text"
                  href={`https://tronscan.org/#/transaction/${transactionItem.txId}`}
                >{`https://tronscan.org/#/transaction/${transactionItem.txId}`}</a>
              ) : (
                <>
                  <span className="long-text">
                    {transactionItem?.txId ?? "-"}
                  </span>
                </>
              )}
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(transactionItem?.txId ?? "", "هش")
                }
              />
            </div>
          )}
        </div>
      )}
      {transactionItem.digitalReceipt && (
        <div className="row has-divider">
          <span>رسید دیجیتالی</span>
          <div>
            <span>
              <a target="_blank" href={transactionItem.digitalReceipt}>
                {transactionItem.digitalReceipt}
              </a>
            </span>
            <CopyOutlined
              onClick={() =>
                copyToClipboard(
                  transactionItem?.digitalReceipt || "",
                  "رسید دیجیتالی"
                )
              }
            />
          </div>
        </div>
      )}
      {transactionItem.gateWayName && (
        <div className="row has-divider">
          <span>درگاه پرداخت</span>
          <div>
            <span>{transactionItem.gateWayName}</span>
          </div>
        </div>
      )}

      {transactionItem?.createdAt && (
        <div className="row has-divider">
          <span>تاریخ و ساعت</span>
          <span>
            {moment
              .utc(transactionItem.createdAt)
              .locale("fa")
              .local()
              .format("  HH:mm  -  jYYYY/MM/DD  ")}
          </span>
        </div>
      )}

      {transactionItem?.state && (
        <div className="row has-divider">
          <span>وضعیت</span>
          <span
            className={`${
              transactionItem?.state.id === TransactionState.Succeed
                ? "success"
                : transactionItem?.state.id == TransactionState.Rejected ||
                  transactionItem?.state.id == TransactionState.Cancel
                ? "error"
                : "waiting"
            }`}
          >
            {transactionItem?.state.title}
          </span>
        </div>
      )}

      {transactionItem?.description && (
        <div className="row has-divider ">
          <span>توضیحات</span>
          <span>{transactionItem?.description}</span>
        </div>
      )}
    </>
  );
};
