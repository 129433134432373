import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  put,
  responseValidator,
} from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface BinanceSetting {
  secret?: string;
  key?: string;
}

export const BinanceSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [binanceSettings, setBinanceSettings] =
    React.useState<BinanceSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getBinanceSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.binanceCredentials).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setBinanceSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeBinanceCredentials, {
      ...binanceSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getBinanceSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getBinanceSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className='vt-dashboard-binance-settings'>
        <Card title={`کانفیگ صرافی بایننس`} className='gateway-item'>
          <Input
            label='کلید وب سرویس'
            type='text'
            name='key'
            value={binanceSettings?.key}
            onChange={(e) =>
              setBinanceSettings({ ...binanceSettings, key: e.target.value })
            }
            dir='ltr'
          />
          <Input
            label='سکرت'
            type='text'
            name='secret'
            value={binanceSettings?.secret}
            onChange={(e) =>
              setBinanceSettings({
                ...binanceSettings,
                secret: e.target.value,
              })
            }
            dir='ltr'
          />

          <Button
            htmlType='button'
            type='info'
            text='ثبت تغییرات'
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              binanceSettings?.key === "" ||
              binanceSettings?.secret === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
