import * as React from "react";
import {Card} from "components";
import "./index.scss";
import {Col, Divider, Row, Spin} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {ManualValidation} from "core/domains";
import {getManualValidationDetail,} from "core/repos/manual-validations";
import moment from "jalali-moment";
import {AuthInfoConfirmation} from "./authinfo";
import {CartConfirmation} from "./cart";
import {AuthMediaConfirmation} from "./authmedia";

const { useState, useEffect } = React;

export const ManualValidationDetails: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ManualValidation>();

  const location = useLocation();
  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  useEffect(() => {
    getManualValidationDetail(setIsCardLoading, setData, id);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <Card className='vt-dashboard-mannual-validations-detail'>
          <Row>
            <Col>
              <span>وضعیت تایید</span>
              <p>{data?.state.title}</p>
            </Col>
            <Col>
              <span>نوع</span>
              <p>{data?.entity.title}</p>
            </Col>
            <Col>
              <span>تاریخ ایجاد</span>
              <p>
                <div className='column-date'>
                  <span className='day'>
                    {moment
                        .utc(data?.createdOn)
                        .locale("fa-IR")
                        .format("jYYYY/jM/jD")}{" "}
                    | {moment.utc(data?.createdOn).local().format("HH:mm")}
                  </span>
                </div>
              </p>
            </Col>
          </Row>
        </Card>
        <Divider />
        {data && (
          <>
            {data.entity.id === 0 ? (
              <CartConfirmation data={data} />
            ) : data?.entity.id === 1 || data?.entity.id === 4 ? (
              <AuthInfoConfirmation data={data} />
            ) : (
              <AuthMediaConfirmation data={data} />
            )}
          </>
        )}
      </Spin>
    </>
  );
};
