import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  put,
  responseValidator,
} from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface UVoucherAcccount {
  baseUrl?: string;
  token?: string;
}

export const UVoucherSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [settings, setSettings] = React.useState<UVoucherAcccount>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [type, setType] = React.useState<number>(1);

  const getUVoucherSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.getUVoucherSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeUVoucherSetting, {
      token: settings?.token,
      baseUrl: settings?.baseUrl,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getUVoucherSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getUVoucherSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card
        blur={cardLoading}
        title="تنظیمات حساب یو ووچر"
        className="vt-dashboard-pm-settings"
      >
        <Input
          label="توکن"
          type="text"
          name="token"
          value={settings?.token}
          onChange={(e) => setSettings({ ...settings, token: e.target.value })}
          dir="ltr"
          maxLength={256}
        />
        <Input
          label="آدرس اینترنتی پایه (base url)"
          type="text"
          dir="ltr"
          name="baseUrl"
          value={settings?.baseUrl}
          onChange={(e) =>
            setSettings({ ...settings, baseUrl: e.target.value })
          }
          maxLength={50}
        />
        <Button
          htmlType="button"
          type="info"
          text="ثبت تغییرات"
          onClick={() => setModalIsOpen(true)}
          loading={loading}
          disabled={loading}
        />
      </Card>
    </>
  );
};
