import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "core/redux/store";
import { Link } from "react-router-dom";
import { useAppDispatch } from "core/redux/hooks";

import "components/bottom-menu/bottom-menu-item/bottom-menu-item.scss";
import { setMenuItem, setSidebar } from "core/redux/actions";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  id: number;
  link: string;
  icon: React.ReactElement;
  title: string;
  sidebar?: boolean;
}

const BottomMenuItem: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  return (
    <Link
      to={props.link}
      onClick={() =>
        props.sidebar
          ? props.state.sidebar === "close"
            ? dispatch(setSidebar("open"))
            : dispatch(setSidebar("close"))
          : dispatch(setMenuItem(props.id))
      }
      className={`bottom-menu-item ${
        props.state.menu === props.id ? "active" : ""
      }`}
    >
      <div className='bottom-menu-icon'>{props.icon}</div>
      <span>{props.title}</span>
    </Link>
  );
};

export default connector(BottomMenuItem);
