import * as React from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { message } from "antd";

import { Button, Input } from "components";

import "routes/pages/signin/stages/login/login.scss";
import { phoneRegex } from "utils/constants/constants";
import { post, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "core/redux/store";
import { setUserData } from "core/redux/actions";
import moment from "moment";
import { Enum } from "core/domains";
import ReactCodeInput from "react-verification-code-input";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FieldErrorState = {
  phone: string[];
  password: string[];
};

type HasErrorState = {
  phone: boolean;
  password: boolean;
};

interface Props extends PropsFromRedux {
  actions: {
    setHasError: React.Dispatch<React.SetStateAction<HasErrorState>>;
    setRememberMeIsChecked: any;
    setPasswordIsHidden: any;
    setPhoneValue: React.Dispatch<React.SetStateAction<string>>;
    setFieldError: React.Dispatch<React.SetStateAction<FieldErrorState>>;
    setStage: any;
    setPasswordValue: React.Dispatch<React.SetStateAction<string>>;
  };
  values: {
    hasError: any;
    rememberMeIsChecked: boolean;
    passwordIsHidden: boolean;
    phoneValue: string;
    passwordValue: string;
    fieldError: any;
  };
}

const { useState, useEffect } = React;

const LoginStage: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<"login" | "code">("login");
  const [twoFactorType, setTwoFactorType] = useState<Enum>();
  const [code, setCode] = useState<string>("");

  const dispatch = useDispatch();

  function handlePhoneChange(value: string): void {
    props.actions.setPhoneValue("");
    props.actions.setHasError((prev) => ({
      ...prev,
      phone: false,
    }));
    if (value.search(/^\d*$/g) === 0) {
      props.actions.setPhoneValue(value);
    } else {
      props.actions.setPhoneValue(value.replace(/\D/g, ""));
    }
  }

  const handleCodeSubmit = (code: any) => {
    if (code.length === 6 && stage === "code") {
      setIsLoading(true);
      post(API.verify_2fa, {
        phoneNumber: props.values.phoneValue,
        code: code,
      })
        .then(function (response: any) {
          setIsLoading(false);
          if (responseValidator(response.status)) {
            if (
              response.data.value.roles &&
              response.data.value.roles.length > 0
            ) {
              localStorage.clear();
              dispatch(
                setUserData({
                  ...props.state.userData,
                  isTwoFactorEnabled: response.data.value.isTwoFactorEnabled,
                  twoFactorMethod: response.data.value.twoFactorMethod,
                  accessToken: response.data.value.access_token,
                  refreshToken: response.data.value.refresh_token,
                  tokenType: response.data.value.token_type,
                  expiresIn: response.data.value.expires_in,
                  roles: response.data.value.roles,
                  isAuthorized: response.data.value.isAuthorized,
                })
              );
              localStorage.setItem(
                "accessToken",
                response.data.value.access_token
              );

              localStorage.setItem("phone", props.values.phoneValue);
              localStorage.setItem("name", response.data.value.fullName);
              localStorage.setItem("auth", response.data.value.isAuthorized);
              localStorage.setItem("tokenDate", moment.utc().toString());
              localStorage.setItem(
                "refToken",
                response.data.value.refresh_token
              );
              localStorage.setItem("roles", response.data.value.roles);

              window.open("/", "_self");
            } else message.error("403");
          }
        })
        .catch(function (error: any) {
          setIsLoading(false);
        });
    } else {
      message.error("کد وارد شده صحیح نمی باشد.");
    }
  };

  function handleLoginSubmit(): void {
    setIsLoading(true);
    props.actions.setHasError({
      phone: false,
      password: false,
    });
    props.actions.setFieldError({
      phone: [""],
      password: [""],
    });
    if (
      props.values.phoneValue.search(phoneRegex) === 0 &&
      props.values.passwordValue.length !== 0
    ) {
      post(API.login, {
        phoneNumber: props.values.phoneValue,
        password: props.values.passwordValue,
      })
        .then(function (response: any) {
          setIsLoading(false);
          if (responseValidator(response.status)) {
            if (response.data.value.isTwoFactorEnabled) {
              setTwoFactorType(response.data.value.twoFactorMethod);
              setStage("code");
            } else {
              if (
                response.data.value.roles &&
                response.data.value.roles.length > 0
              ) {
                dispatch(
                  setUserData({
                    ...props.state.userData,
                    isTwoFactorEnabled: response.data.value.isTwoFactorEnabled,
                    twoFactorMethod: response.data.value.twoFactorMethod,
                    accessToken: response.data.value.access_token,
                    refreshToken: response.data.value.refresh_token,
                    tokenType: response.data.value.token_type,
                    expiresIn: response.data.value.expires_in,
                    roles: response.data.value.roles,
                    isAuthorized: response.data.value.isAuthorized,
                  })
                );
                localStorage.setItem(
                  "accessToken",
                  response.data.value.access_token
                );
                localStorage.setItem("phone", props.values.phoneValue);
                localStorage.setItem("name", response.data.value.fullName);
                localStorage.setItem("auth", response.data.value.isAuthorized);
                localStorage.setItem("roles", response.data.value.roles);

                localStorage.setItem("tokenDate", moment.utc().toString());
                localStorage.setItem(
                  "refToken",
                  response.data.value.refresh_token
                );

                window.open("/", "_self");
              } else message.error("403");
            }
          }
        })
        .catch(function (error: any) {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      if (props.values.passwordValue.length === 0) {
        props.actions.setHasError((prev) => ({
          ...prev,
          password: true,
        }));
        props.actions.setFieldError((prev) => ({
          ...prev,
          password: ["لطفا پسورد را وارد کنید."],
        }));
      }
      if (props.values.phoneValue.search(phoneRegex) !== 0) {
        props.actions.setHasError((prev) => ({
          ...prev,
          phone: true,
        }));
        props.actions.setFieldError((prev) => ({
          ...prev,
          phone: ["شماره موبایل وارد شده نادرست است."],
        }));
      }
    }
  }

  function handlePasswordChange(value: string): void {
    if (value.length > 0) {
      props.actions.setFieldError((prev) => ({
        ...prev,
        password: [""],
      }));
      props.actions.setHasError((prev) => ({
        ...prev,
        password: false,
      }));
    }
    props.actions.setPasswordValue(value);
  }

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <h3>ورود به حساب کاربری</h3>
      <form className="signin-stage">
        <Input
          name="phone"
          type="text"
          disabled={stage === "code"}
          className="signin-input"
          onPressEnter={() => handleLoginSubmit()}
          value={props.values.phoneValue}
          onChange={(el) => handlePhoneChange(el.target.value)}
          placeholder="موبایل"
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img className="start-adornment" src="/assets/icons/Call.svg" />
              ),
            },
          }}
          hasError={props.values.hasError.phone}
          errors={props.values.fieldError.phone}
          maxLength={11}
        />
        <Input
          name="password"
          type={props.values.passwordIsHidden ? "password" : "text"}
          className="signin-input"
          value={props.values.passwordValue}
          onPressEnter={() => handleLoginSubmit()}
          onChange={(el) => handlePasswordChange(el.target.value)}
          placeholder="رمز عبور"
          disabled={stage === "code"}
          errors={props.values.fieldError.password}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img
                  className="start-adornment"
                  src="/assets/icons/unlock.svg"
                />
              ),
            },
            endAdornment: {
              adornmentIcon: props.values.passwordIsHidden ? (
                <EyeOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => props.actions.setPasswordIsHidden(false)}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => props.actions.setPasswordIsHidden(true)}
                />
              ),
            },
          }}
        />

        {stage === "code" && (
          <ReactCodeInput
            className={`input `}
            fieldWidth={48}
            values={code ? code.split("") : []}
            autoFocus={true}
            onChange={(value) => {
              setCode(value);
            }}
            onComplete={(e) => handleCodeSubmit(e)}
          />
        )}
        <Button
          type="info"
          htmlType="button"
          text="ورود"
          disabled={
            stage === "login"
              ? props.values.hasError.phone ||
                props.values.hasError.password ||
                props.values.passwordValue.length === 0 ||
                isLoading
                ? true
                : false
              : code.length !== 6
          }
          className={`signin-button`}
          onClick={() =>
            stage === "login" ? handleLoginSubmit() : handleCodeSubmit(code)
          }
          loading={isLoading ? true : false}
        />
      </form>
    </>
  );
};

export default connector(LoginStage);
