import * as React from "react";

import "./transaction-modal.scss";
import {Modal} from "components";
import {KucoinHistory} from "core/domains/kucoin/kucoin-history";
import {CopyOutlined} from "@ant-design/icons";
import {copyToClipboard} from "utils/scripts/copyToClipboard";
import {digitSeparator} from "utils/scripts/decimal";
import moment from "jalali-moment";
import {Enum} from "core/domains";
import {Tooltip} from "antd";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: KucoinHistory;
  loading: boolean;
  states: Enum[];
}

export const KucoinTransactionModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
  states,
}) => {
  return (
    <Modal
      className='kucoin-transaction-detail-modal'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title='جزئیات تراکنش'
      closeAble
    >
      <div className='content'>
        <div className='row '>
          <span>شناسه تراکنش</span>
          <div>
            <span className='en'>{item.transaction.trackingCode}</span>
            <CopyOutlined
                onClick={() => copyToClipboard(item.transaction.trackingCode, "شناسه سفارش")}
            />
          </div>
        </div>
        <div className='row has-divider'>
          <span>مقدار</span>
          <div>
            <span>{digitSeparator(item.transaction.amount)}{" " + item.asset}</span>
          </div>
        </div>
        <div className='row has-divider'>
          <span>شماره تماس</span>
          <div>
            <span>{item.transaction.createdBy.userName}</span>
            <CopyOutlined
                onClick={() => copyToClipboard(item.transaction.createdBy.userName ?? "", "شماره تماس")}
            />
          </div>
        </div>
        <div className='row has-divider'>
          <span>نام کامل</span>
          <div>
            <span>{item.transaction.createdBy.fullName}</span>
          </div>
        </div>
        {item.network && (
          <div className='row has-divider '>
            <span>شبکه تراکنش</span>
            <div>
              <span className='en'>
                {item.transaction.network?.network} | {item.transaction.network?.name}
              </span>
            </div>
          </div>
        )}
        {item.network && (
          <div className='row has-divider '>
            <span>کارمزد شبکه</span>
            <div>
              <span className='en'>{item.transaction.network.fee ?? 0} {" " + item.asset}</span>
            </div>
          </div>
        )}

        <div className='row has-divider txid'>
          <span>هش تراکنش</span>
          <div>
            <span className='en'>{item.transaction.txId ?? "-"}</span>
          <CopyOutlined
              onClick={() => copyToClipboard(item.transaction.txId ?? "", "هش")}
          />
          </div>
        </div>

        <div className='row has-divider'>
          <span>تاریخ و ساعت</span>
          <span>
            {moment
                .utc(item?.transaction.createdOn)
              .locale("fa")
              .format("  HH:mm  -  jYYYY/MM/DD  ")}
          </span>
        </div>
        <div className='row has-divider'>
          <span>تغییر توسط</span>
          <div>
            <span>{item.transaction.modifiedBy ? item.transaction.modifiedBy : " - "}</span>
          </div>
        </div>
        <div className='row has-divider state'>
          <span>وضعیت</span>
          <span
            className={`${
                item.transaction.state === 2
                ? "success"
                    : item.transaction.state === 3
                ? "error"
                : "waiting"
            }`}
          >
            {states?.find((state) => item.transaction.state === state.id)?.title}
          </span>
        </div>
        {item.transaction.description && (
          <div className='row has-divider '>
            <span>توضیحات</span>
            <span>
              <Tooltip placement='top' title={"جزئیات تراکنش"}>
                {item.transaction.description}
              </Tooltip>
            </span>
          </div>
        )}
      </div>
    </Modal>
  );
};
