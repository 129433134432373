import {message} from "antd";
import {API} from "apis";
import {Button, Card, Input} from "components";
import * as React from "react";
import {getViaAuth, put, responseValidator} from "utils/scripts/api";
import "./index.scss";
import {deleteCommaSeparator, digitSeparator, faNumToEnNum,} from "../../../../utils/scripts/decimal";
import {SettingGoogleAuthType} from "core/domains";
import {SettingGoogleAuth} from "../google-auth/google-auth";

interface Setting {
  value?: number;
  minWidthrawlAmount?: number;
  minDepositlAmount?: number;
  minDepositCurrencyForBuyPerfectMoney?: number;
}

interface LoadingSetting {
  currency: boolean;
  coin: boolean;
}

export const MinDepositSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<LoadingSetting>({
    currency: false,
    coin: false,
  });
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<Setting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [type, setType] = React.useState<number>(1);

  const onOpenModalHanlde = (typeNumber: number) => {
    setGoogleAuth({
      code: "",
      error: "",
    });
    setType(typeNumber);
    setModalIsOpen(true);
  };

  const getMinDepositSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.minDeposit).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setValue({ ...value, value: response.value.value });
      }
    });
  };

  const getMinCurrencyDepositSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.currency).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setValue({
          ...value,
          minDepositlAmount: response.value.minDepositlAmount,
          minWidthrawlAmount: response.value.minWidthrawlAmount,
          minDepositCurrencyForBuyPerfectMoney:
            response.value.minDepositCurrencyForBuyPerfectMoney,
        });
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.message,
        });
      }
    });
  };

  const onCoinConfirmHandler = (googleAuthCode: string) => {
    setLoading({ ...loading, coin: true });
    put(API.settings.changeMinDepositCoin, {
      value: value?.value,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading({ ...loading, coin: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getMinDepositSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const onCurrencyConfirmHandler = (code: string) => {
    setLoading({ ...loading, currency: true });
    put(API.settings.changeCurrencyAmounts, {
      minWidthrawlAmount: value?.minWidthrawlAmount,
      minDepositlAmount: value?.minDepositlAmount,
      minDepositCurrencyForBuyPerfectMoney: value?.minDepositCurrencyForBuyPerfectMoney,
      googleAuthCode: code,
    }).then((response: any) => {
      setLoading({ ...loading, currency: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getMinCurrencyDepositSettings();
      }
    });
  };

  const fetchAllData = async () => {
    await getMinDepositSettings();
    await getMinCurrencyDepositSettings();
  };

  React.useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={type == 1 ? onCoinConfirmHandler : onCurrencyConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading.coin || loading.currency}
      />
      <Card blur={cardLoading} className="vt-dashboard-mindeposit-settings">
        <div className="row col3">
          <Card title="حداقل واریز ارز" className="min-crypto-deposit-amount">
            <Input
              label="حداقل میزان واریز ارز به دلار"
              type="text"
              name="coin-deposit"
              value={value?.value?.toString()}
              onChange={(e) =>
                setValue({
                  ...value,
                  value: parseInt(faNumToEnNum(e.target.value)),
                })
              }
              dir="ltr"
              maxLength={10}
            />

            <Button
              htmlType="button"
              type="info"
              text="ثبت تغییرات"
              onClick={() => onOpenModalHanlde(1)}
              loading={loading.coin}
              disabled={loading.coin}
            />
          </Card>
          <Card title="واریز و برداشت ریال" className="currency-amount">
            {value &&
              (value.minDepositlAmount || value.minDepositlAmount == 0) && (
                <Input
                  label="حداقل میزان واریز ریالی"
                  type="text"
                  name="deposit-amount"
                  value={
                    digitSeparator(value?.minDepositlAmount?.toString()) ?? "0"
                  }
                  onChange={(e) =>
                    setValue({
                      ...value,
                      minDepositlAmount: parseInt(faNumToEnNum(e.target.value)),
                    })
                  }
                  dir="ltr"
                  maxLength={10}
                />
              )}
            {value &&
              (value.minWidthrawlAmount || value.minWidthrawlAmount == 0) && (
                <Input
                  label="حداقل میزان برداشت ریالی"
                  type="text"
                  name="widthrawl-amount"
                  value={
                    digitSeparator(value?.minWidthrawlAmount.toString()) ?? "0"
                  }
                  onChange={(e) =>
                    setValue({
                      ...value,
                      minWidthrawlAmount: parseInt(
                        faNumToEnNum(deleteCommaSeparator(e.target.value))
                      ),
                    })
                  }
                  dir="ltr"
                  maxLength={10}
                />
              )}

            <Button
              htmlType="button"
              type="info"
              text="ثبت تغییرات"
              onClick={() => onOpenModalHanlde(2)}
              loading={loading.currency}
              disabled={loading.currency}
            />
          </Card>
        </div>
      </Card>
    </>
  );
};
