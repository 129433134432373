import * as React from "react";
import {Button, Input} from "components";
import "./edit.scss";
import {Spin, Switch} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {deleteCommaSeparator, digitSeparator} from "utils/scripts/decimal";
import {getLevelByIdReferral, updateLevelReferral} from "core/repos/referral";
import {ReferralLevelEdit,} from "core/domains/referral/referral";

const { useState, useEffect } = React;

export const EditLevelReferral: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [level, setLevel] = useState<ReferralLevelEdit>();
  const location = useLocation();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const navigate = useNavigate();

  useEffect(() => {
    !level &&
      getLevelByIdReferral(
        setIsCardLoading,
        setLevel,
        urlSections[urlSections.length - 1]
      );
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-levels-edit-referral">
          <h3>رفرال- اصلاح سطح</h3>
          {level && (
            <form>
              <Input
                type="text"
                label="عنوان"
                name="title"
                value={level?.title}
                onChange={(e) => setLevel({ ...level, title: e.target.value })}
              />

              <Input
                type="text"
                label="حداقل درامد جهت ورود به سطح(تومان)"
                name="minimumAmountForEnter"
                value={digitSeparator(level?.minimumAmountForEnter?.toString())}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    minimumAmountForEnter: parseInt(
                      deleteCommaSeparator(e.target.value)
                    ),
                  })
                }
              />
              <Input
                type="text"
                label="درصد پاداش از هر تراکنش"
                name="rewardPercent"
                value={digitSeparator(level?.rewardPercent)}
                onChange={(e) =>
                  setLevel({
                    ...level,
                    rewardPercent:
                      deleteCommaSeparator(e.target.value)
                    ,
                  })
                }
              />
              <TextArea
                name="description"
                title="توضیحات"
                value={level?.description}
                onChange={(e) =>
                  setLevel({ ...level, description: e.target.value })
                }
              />

              <div className="vt-switch-row">
                <label>وضعیت: </label>
                <Switch
                  checkedChildren={"فعال"}
                  unCheckedChildren={"غیر فعال"}
                  checked={level?.isActive}
                  onChange={(checked) =>
                    setLevel({ ...level, isActive: checked })
                  }
                />
              </div>

              <div className="buttons">
                <Button
                  htmlType="button"
                  type="info"
                  text="اصلاح"
                  onClick={() => updateLevelReferral(setLoading, level)}
                  loading={loading}
                  disabled={
                    !level?.title ||
                    !level.rewardPercent ||
                    loading
                  }
                />

                <Button
                  htmlType="button"
                  type="outlined"
                  text="بازگشت"
                  onClick={() => navigate("/referral/levels/")}
                />
              </div>
            </form>
          )}
        </div>
      </Spin>
    </>
  );
};
