const USER_DATA = "USER_DATA";
const ORDER_DATA = "ORDER_DATA";
const MENU = "MENU";
const EXPANDED_MENU = "EXPANDED_MENU";
const SIDEBAR = "SIDEBAR";

const setUserData = (payload: any) => {
  return { type: USER_DATA, payload: payload };
};

const setOrderData = (payload: any) => {
  return { type: ORDER_DATA, payload: payload };
};

const setMenuItem = (payload: number) => {
  return { type: MENU, payload: payload };
};

const setExpandedMenuItem = (payload: number) => {
  return { type: EXPANDED_MENU, payload: payload };
};

const setSidebar = (payload: "close" | "open") => {
  return { type: SIDEBAR, payload: payload };
};

export {
  USER_DATA,
  setUserData,
  ORDER_DATA,
  setOrderData,
  MENU,
  setMenuItem,
  SIDEBAR,
  setSidebar,
  EXPANDED_MENU,
  setExpandedMenuItem,
};
