import * as React from "react";
import "./slides.scss";
import { Button, Card, Modal } from "components";
import { Table } from "components/table/table";
import { Slide } from "core/domains/slider/slider";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  FileSearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TableColumn } from "react-data-table-component";
import { deleteSlide, getAllSlides } from "core/repos/slide";
import { PaginationList } from "core/domains";
import { getPageNum } from "utils/scripts/paggingHelper";
import { Popover, Tooltip } from "antd";
import moment from "jalali-moment";

export const Slides: React.FC = () => {
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [slides, setSlides] = React.useState<PaginationList<Slide>>();
  const [selectedItem, setSelectedItem] = React.useState<Slide>();
  const [removeModalIsOpen, setRemoveModalIsOpen] =
    React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [removeButtonLoading, setRemoveButtonLoading] =
    React.useState<boolean>(false);

  const navigate = useNavigate();

  const onRemoveIconClick = (item: Slide) => {
    setSelectedItem(item);
    setRemoveModalIsOpen(true);
  };

  React.useEffect(() => {
    !slides &&
      getAllSlides(setCardLoading, setSlides, getPageNum(), pageSize, "");
  }, []);

  const columns: TableColumn<Slide>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row) => row?.title,
    },

    {
      name: "توضیحات",
      id: "description",
      selector: (row: Slide) => row.description,
      cell: (record) => (
        <Popover content={record.description}>
          <span className="description-column">{record.description}</span>
        </Popover>
      ),
    },
    {
      name: "تاریخ آخرین بروز رسانی",
      id: "lastModifiedDate",
      selector: (row: Slide) => row.lastModifiedDate,
      cell: (row: Slide) =>
        row.lastModifiedDate ? (
          <div className="column-date">
            <span className="day">
              {moment
                .utc(row.lastModifiedDate)
                .locale("fa")
                .local()
                .format("jYYYY/MM/DD")}
            </span>
            <span className="time">
              {moment.utc(row.lastModifiedDate).local().format("HH:mm")}
            </span>
          </div>
        ) : (
          "-"
        ),
    },
    {
      name: "عملیات",
      id: "actions",
      cell: (record) => (
        <div className="actions">
          <Link to={`edit/${record.id}`}>
            <Tooltip placement="top" title="اصلاح اسلاید">
              <FileSearchOutlined />
            </Tooltip>
          </Link>
          <Tooltip placement="top" title="حذف اسلاید">
            <DeleteOutlined onClick={() => onRemoveIconClick(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Card title="اسلاید ها" className="vt-dashboard-slides" blur={cardLoading}>
      {selectedItem && (
        <Modal
          title={"آیا از حذف اسلاید اطمینان دارید؟"}
          isOpen={removeModalIsOpen}
          setIsOpen={setRemoveModalIsOpen}
          className="delete-slide-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              loading={removeButtonLoading}
              disabled={removeButtonLoading}
              onClick={() =>
                deleteSlide(setRemoveButtonLoading, selectedItem.id, () => {
                  setRemoveModalIsOpen(false);
                  getAllSlides(
                    setCardLoading,
                    setSlides,
                    getPageNum(),
                    pageSize,
                    ""
                  );
                })
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setRemoveModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      <div className="row">
        <Button
          type="primary"
          text="افزودن اسلاید"
          icon={<PlusOutlined />}
          onClick={() => navigate("add")}
        />
      </div>
      {slides && (
        <Table
          className="slide-slides-table"
          data={slides.items}
          column={columns}
          pagination={slides.pagination}
          getData={getAllSlides}
          setData={setSlides}
          setLoader={setCardLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
