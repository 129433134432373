import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { Enum, FilterValues, PaginationList } from "core/domains";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import "./index.scss";
import { getPageNum } from "utils/scripts/paggingHelper";
import { getAllReferralActions } from "core/repos/referral";
import { ReferralAction } from "core/domains/referral/referral";
import { digitSeparator } from "utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

const defaultStateItems: Enum[] = [
  {
    title: "همه",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "همه",
    id: 100,
  },
];

export const ReferralActions: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [actions, setActions] = useState<PaginationList<ReferralAction>>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [types, setTypes] = useState<Enum[]>(defaultTypeItems);
  const [states, setStates] = useState<Enum[]>(defaultStateItems);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
  });

  useEffect(() => {
    !actions &&
      getAllReferralActions(
        setCardLoading,
        setActions,
        getPageNum(),
        pageSize,
        ""
      );
    // states.length === 1 && getActionStates(setCardLoading, setStates);
    // types.length === 1 && getActionTypes(setCardLoading, setTypes);
  }, []);

  const onFilterHandler = () => {
    var query = "";
    if (filterValues?.type?.id !== 100)
      query += `${query.includes("?") ? "&" : "?"}entity=${
        filterValues?.type?.id
      }`;
    if (filterValues?.state?.id !== 0)
      query += `${query.includes("?") ? "&" : "?"}state=${
        filterValues?.state?.id
      }`;
    if (filterValues?.keywords)
      query += `${query.includes("?") ? "&" : "?"}keywords=${
        filterValues?.keywords
      }`;
    getAllReferralActions(
      setLoading,
      setActions,
      1,
      pageSize,
      query
    );
  };
  const columns: TableColumn<any>[] = [
    {
      name: "کاربر دعوت شده",
      id: "invitedUserName",
      selector: (row: ReferralAction) => row.invitedUserName || "",
      cell: (record: ReferralAction) => (
        <div className="column-fullname">
          <span className="name">{record.invitedUserName}</span>
          <p className="phone">{record.invitedPhoneNumber}</p>
        </div>
      ),
    },
    {
      name: "کاربر دعوت کننده",
      id: "inviterUserName",
      selector: (row: ReferralAction) => row.inviterUserName || "",
      cell: (record: ReferralAction) => (
        <div className="column-fullname">
          <span className="name">{record.inviterUserName}</span>
          <p className="phone">{record.inviterPhoneNumber}</p>
        </div>
      ),
    },
    {
      name: "مقدار",
      id: "amount",
      selector: (row: ReferralAction) => digitSeparator(row.amount) + " تومان ",
    },
    {
      name: "کد پیگیری مربوط",
      id: "relatedTrackingCode",
      cell: (record: ReferralAction) => (
        <span className="en">{record.relatedTrackingCode}</span>
      ),
      selector: (row: ReferralAction) => row.relatedTrackingCode,
    },
    {
      name: "زمان",
      id: "createdOn",
      selector: (row: ReferralAction) => row.createdOn,
      cell: (row: ReferralAction) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="رفرال- فعالیت ها"
      className="vt-dashboard-referral-actions"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            name="search"
            onPressEnter={() => onFilterHandler()}
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />

          {types.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    types.map((type) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, type })
                        }
                      >
                        {type.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.type?.title}
              />
            </Dropdown>
          )}

          {states.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    states.map((state) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, state })
                        }
                      >
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.state?.title}
              />
            </Dropdown>
          )}
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
                type: defaultTypeItems[0],
                state: defaultStateItems[0],
              });
              getAllReferralActions(
                setLoading,
                setActions,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="referral-actions-table"
        data={actions?.items}
        pagination={actions?.pagination}
        column={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Card>
  );
};
