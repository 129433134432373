import * as React from "react";
import { Button, Modal } from "components";
import { Enum } from "core/domains";
import { Progress } from "antd";
import {
  Transaction,
  TransactionAction,
  TransactionActionLabel,
} from "core/domains/transaction/transaction";
import { message } from "components/toast/toast";
import {
  editSingleTransactionsState,
  payoutSingleTranscation,
} from "core/repos";
import "./bulk-select-modal.scss";
import { digitSeparator } from "utils/scripts/decimal";

interface Props {
  selectedRows: Transaction[];
  selectedActionItem: Enum;
  setButtonLoading: any;
  buttonLoading: boolean;
  setSelectedRows: any;
  isOpen: boolean;
  setIsOpen: any;
  fetchData: () => void;
  actionItem: Enum;
  selectedTotalAmount?: number;
}

export const ConfirmBulkSelectModal: React.FC<Props> = ({
  selectedRows,
  selectedActionItem,
  buttonLoading,
  setButtonLoading,
  setSelectedRows,
  setIsOpen,
  isOpen,
  fetchData,
  actionItem,
  selectedTotalAmount,
}) => {
  const [completedTransactionsCount, setCompletedTransactionsCount] =
    React.useState<number>(0);
  const [isProccessSucceed, setIsProccessSucceed] = React.useState(true);

  React.useEffect(() => {
    completedTransactionsCount === selectedRows?.length &&
      onCompleteTransaction();
  }, [completedTransactionsCount]);

  async function onConfirmActionHandle() {
    if (selectedRows) {
      for (const e of selectedRows) {
        try {
          setButtonLoading(true);
          actionItem.id === TransactionAction.ConfirmAndPayout
            ? await payoutSingleTranscation(
                setButtonLoading,
                e,
                () => setCompletedTransactionsCount((prev) => prev + 1),
                (response) => {
                  throw new Error(response.message);
                }
              )
            : await editSingleTransactionsState(
                setButtonLoading,
                e,
                selectedActionItem.id,
                () => setCompletedTransactionsCount((prev) => prev + 1),
                (response) => {
                  throw new Error(response.message);
                }
              );
        } catch {
          closeActionModal();
          break;
        }
      }
    }
  }

  const closeActionModal = () => {
    setSelectedRows(undefined);
    setCompletedTransactionsCount(0);
    setButtonLoading(false);
    setIsOpen(false);
    fetchData();
  };

  const onCompleteTransaction = async () => {
    setButtonLoading(false);
    message.success("همه تراکنش ها با موفقیت به روز شدند");
    fetchData();
    selectedRows && setCompletedTransactionsCount(selectedRows?.length);
    setCompletedTransactionsCount(0);
    setSelectedRows(undefined);
    setIsOpen(false);
    setIsProccessSucceed(true);
  };

  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      title={`از ${TransactionActionLabel.get(selectedActionItem.id)} ${
        selectedRows.length
      }\u0020تراکنش انتخاب شده مطمئن هستید؟`}
      className="transaction-action-approve-modal"
      onClose={closeActionModal}
      disabledClose={buttonLoading}
    >
      <div className="modal-content">
        {selectedTotalAmount && (
          <p>
            مجموع مقدار موارد انتخابی: {digitSeparator(selectedTotalAmount)}{" "}
            تومان
          </p>
        )}
        <Progress
          type="line"
          percent={Math.round(
            (completedTransactionsCount * 100) / selectedRows.length
          )}
        />
        <span>
          {completedTransactionsCount} از {selectedRows.length} تراکنش انجام شده
        </span>

        <div className="buttons">
          <Button
            type="info"
            text="بله مطمئنم"
            loading={buttonLoading}
            disabled={buttonLoading}
            htmlType="button"
            onClick={onConfirmActionHandle}
          />
          <Button
            type="outlined"
            text="بازگشت"
            onClick={closeActionModal}
            disabled={buttonLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
