import {message} from "antd";
import {API} from "apis";
import {Button, Card, Input} from "components";
import {SettingGoogleAuthType} from "core/domains";
import * as React from "react";
import {getValidationErrors, getViaAuth, hasValidationError, put, responseValidator,} from "utils/scripts/api";
import {SettingGoogleAuth} from "../google-auth/google-auth";
import "./index.scss";

interface ZibalSetting {
  apiKey?: string;
  walletId?: string;
}

export const ZibalSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [zibalSettings, setZibalSettings] = React.useState<ZibalSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getZibalSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.zibalSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setZibalSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeZibalSettings, {
      ...zibalSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getZibalSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getZibalSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className='vt-dashboard-zibal-settings'>
        <Card title={`کانفیگ درگاه زیبال`} className='gateway-item'>
          <Input
            label='کلید API'
            type='text'
            name='apiKey'
            value={zibalSettings?.apiKey}
            onChange={(e) =>
              setZibalSettings({ ...zibalSettings, apiKey: e.target.value })
            }
            dir='ltr'
          />
          <Input
            label='آیدی کیف پول'
            type='text'
            name='walletId'
            value={zibalSettings?.walletId}
            onChange={(e) =>
              setZibalSettings({ ...zibalSettings, walletId: e.target.value })
            }
            dir='ltr'
          />

          <Button
            htmlType='button'
            type='info'
            text='ثبت تغییرات'
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={loading || zibalSettings?.apiKey === ""}
          />
        </Card>
      </Card>
    </>
  );
};
