import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface KucoinSetting {
  apiSecret?: string;
  apiKey?: string;
  apiPassPhrase?: string;
}

export const KucoinSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [kucoinSettings, setKucoinSettings] = React.useState<KucoinSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getKucoinSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.kucoinCredentials).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setKucoinSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeKucoinCredentials, {
      ...kucoinSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getKucoinSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getKucoinSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-kucoin-settings">
        <Card title={`کانفیگ صرافی کوکوین`} className="gateway-item">
          {kucoinSettings && (
            <>
              <Input
                label="کلید وب سرویس"
                type="text"
                name="apikey"
                value={kucoinSettings.apiKey}
                onChange={(e) =>
                  setKucoinSettings({
                    ...kucoinSettings,
                    apiKey: e.target.value,
                  })
                }
                dir="ltr"
              />
              <Input
                label="سکرت"
                type="text"
                name="apisecret"
                value={kucoinSettings.apiSecret}
                onChange={(e) =>
                  setKucoinSettings({
                    ...kucoinSettings,
                    apiSecret: e.target.value,
                  })
                }
                dir="ltr"
              />
              <Input
                label="پسوورد ای پی آی"
                type="text"
                name="apisecret"
                value={kucoinSettings.apiPassPhrase}
                onChange={(e) =>
                  setKucoinSettings({
                    ...kucoinSettings,
                    apiPassPhrase: e.target.value,
                  })
                }
                dir="ltr"
              />

              <Button
                htmlType="button"
                type="info"
                text="ثبت تغییرات"
                onClick={() => setModalIsOpen(true)}
                loading={loading}
                disabled={
                  loading ||
                  kucoinSettings?.apiKey === "" ||
                  kucoinSettings?.apiSecret === "" ||
                  kucoinSettings?.apiPassPhrase === ""
                }
              />
            </>
          )}
        </Card>
      </Card>
    </>
  );
};
