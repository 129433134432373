import * as React from "react";
import { Button, Card, Input } from "components";
import "./index.scss";
import { Col, Divider, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { ManualValidation } from "core/domains";
import {
  approveManualValidation,
  rejectManualValidation,
} from "core/repos/manual-validations";
import moment from "jalali-moment";

const { useState, useEffect } = React;

export const CartConfirmation: React.FC<{ data: any }> = ({ data }: any) => {
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cardInfo, setCardInfo] = useState<ManualValidation>(data);

  const location = useLocation();
  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  const approveHandle = async () => {
    approveManualValidation(setLoading, cardInfo, id, navigate);
  };
  const rejectHandle = async () => {
    rejectManualValidation(setLoading, cardInfo, id, navigate);
  };

  return (
    <Card
      title='تایید کارت بانکی'
      className='vt-dashboard-mannual-carts-detail'
    >
      <Input
        type='text'
        label='شماره کارت'
        name='cardNumber'
        value={cardInfo?.bankAccount?.cardNumber ?? ""}
        onChange={() => setCardInfo({ ...cardInfo })}
        dir='rtl'
      />

      <Input
        type='text'
        label='شماره شبا'
        name='iBan'
        value={cardInfo?.bankAccount?.iBan ?? ""}
        onChange={() => setCardInfo({ ...cardInfo })}
        dir='rtl'
      />

      <Divider />

      <Row>
        <label htmlFor='description'>توضیحات</label>
        <TextArea
          name='description'
          value={cardInfo?.message}
          onChange={(e) =>
            setCardInfo({
              ...cardInfo,
              message: e.target.value,
            })
          }
        />
      </Row>

      {cardInfo?.state.id != 0 && (
        <Row>
          <Col>
            <span>تغییر داده شده توسط </span>
            <span>{cardInfo?.modifiedBy}</span>
          </Col>
          <Col>
            <span>تغییر داده شده در تاریخ </span>
            <span>
              {moment.utc(cardInfo?.modifiedAt).local().format("HH:mm")}
            </span>
          </Col>
        </Row>
      )}

      <Row className='buttons'>
        <Button
          htmlType='button'
          type='info'
          text='تایید'
          onClick={() => approveHandle()}
          loading={loading}
          disabled={loading}
        />

        <Button
          htmlType='button'
          type='outlined'
          text='رد'
          onClick={() => rejectHandle()}
          loading={loading}
          disabled={loading}
        />
      </Row>
    </Card>
  );
};
