import * as React from "react";
import { __API } from "apis/api";
import { Button, Card, Input } from "components";
import "./edit.scss";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  put,
  responseValidator,
} from "utils/scripts/api";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { BankAccount } from "core/domains";

const { useState, useEffect } = React;

export const EditBankAccount: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bankAccountData, setBankAccountData] = useState<BankAccount>();

  const location = useLocation();
  const navigate = useNavigate();

  const editBankAccountHandle = async () => {
    setLoading(true);
    await put(__API.banks.update, {
      cardNumber: bankAccountData?.cardNumber,
      iBan:
        bankAccountData?.iBan?.search("IR") === 0
          ? bankAccountData?.iBan?.slice(2)
          : bankAccountData?.iBan,
      isRemoved: bankAccountData?.isRemoved,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("حساب بانکی مورد نظر با موفقیت اصلاح شد.");
        navigate("/bank-accounts/");
      }
    });
  };

  useEffect(() => {
    setIsCardLoading(true);
    (async () => {
      await getViaAuth(
        __API.banks.detail + `/${location.pathname.slice(20)}`
      ).then((response: any) => {
        setIsCardLoading(false);
        if (responseValidator(response.status)) {
          setBankAccountData(response.value);
        }
      });
    })();
  }, []);

  return (
    <>
      <Card
        className='vt-dashboard-bank-accounts-edit '
        blur={isCardLoading}
        title='اصلاح حساب بانکی'
      >
        <Input
          type='text'
          label='شماره کارت'
          disabled={true}
          name='cardNumber'
          maxLength={16}
          dir='ltr'
          value={bankAccountData?.cardNumber}
          onChange={(e) =>
            setBankAccountData({
              ...bankAccountData,
              cardNumber: e.target.value,
            })
          }
        />
        <Input
          type='text'
          label='شماره شبا'
          maxLength={24}
          dir='ltr'
          name='iBan'
          value={bankAccountData?.iBan?.replace("IR", "")}
          onChange={(e) =>
            setBankAccountData({
              ...bankAccountData,
              iBan: e.target.value,
            })
          }
        />

        <div className='row'>
          <Button
            htmlType='button'
            type='info'
            text='اصلاح'
            onClick={() => editBankAccountHandle()}
            loading={loading}
            disabled={!bankAccountData?.cardNumber ? true : false}
          />

          <Button
            htmlType='button'
            type='outlined'
            text='بازگشت'
            onClick={() => navigate(-1)}
          />
        </div>
      </Card>
    </>
  );
};
