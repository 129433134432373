import {EditOutlined} from "@ant-design/icons";
import {Table} from "components/table/table";
import {ManualValidation, UserDetail} from "core/domains";
import * as React from "react";
import {TableColumn} from "react-data-table-component";
import {Link} from "react-router-dom";
import "./surveys.scss";
import {Tag, Tooltip} from "antd";
import {Badge} from "components/badge/badge";
import {getUserDocuments} from "core/repos/user";
import {EditSurvey} from "./edit/edit";
import {Card} from "../../../../../../components";
import moment from "jalali-moment";

const { useState, useRef, useEffect } = React;

type Props = {
  userInfo: UserDetail | undefined;
};

export const UserSurveys: React.FC<Props> = (props) => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [surveysList, setSurveysList] = useState<ManualValidation[]>();
  const [cardLoading, setCardLoading] = useState<boolean>(false);

  const id = props?.userInfo?.id;
  useEffect(() => {
    !surveysList && id && getUserDocuments(setCardLoading, setSurveysList, id);
  }, []);

  const columns: TableColumn<ManualValidation>[] = [
    {
      name: "دلیل آپلود",
      id: "uploadReason",
      cell: (record) => <Tag color={"orange"}>{record.entity.title}</Tag>,
    },
    {
      name: "وضعیت",
      id: "state",
      cell: (row) => (
        <Badge
          text={row.state.title}
          type={
            row?.state.id == 1
              ? "success"
              : row?.state.id == 2
              ? "error"
              : "waiting"
          }
        />
      ),
    },
    {
      name: "دلیل رد",
      id: "description",
      width: "30%",
      selector: (row) => row?.message ?? "-",
    },
    {
      name: "تاریخ",
      id: "createdOn",
      selector: (row) =>
          moment
          .utc(row?.createdOn)
              .locale("fa")
              .format("  HH:mm  -  jYYYY/MM/DD  "),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record) => (
        <div className='actions'>
          <Link to={`/manual-validations/details/${record.id}`}>
            <Tooltip placement='top' title='جزئیات مدارک'>
              <EditOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <Card blur={cardLoading}>
      <EditSurvey />
      <Table
        className='user-info-surveys-table'
        data={surveysList}
        column={columns}
      />
    </Card>
  );
};
