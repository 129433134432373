import { Enum, Pagination } from "../commons/common";

export interface Order {
  id: Identifier;
  trackingCode: string;
  pair: Pair;
  state: Enum;
  type: Enum;
  side: Enum;
  sourceQuantity: number;
  destinationQuantity: number;
  createdAt: string;
  createdBy: string;
  phoneNumber: string;
  platform: Enum;
  source: Enum;
  onPrice: number;
  baseBalanceAfterOrder?: number;
  quoteBalanceAfterOrder: number;
  commission: number;
}

export interface OrdersCount {
  pageSize: number,
  pageIndex: number,
  totalPages: number,
  totalCount: number,
  hasPreviousPage: boolean,
  hasNextPage: boolean
}

export type Pair = {
  base: string;
  quote: string;
};

export interface OrderList {
  items: Order[];
  pagination: Pagination;
}

export type Identifier = {
  timestamp: number;
  creationTime: string;
};

export enum OrderState {
  Open = 1,
  Expired,
  Filled,
  Canceled,
  Rejected,
}

export enum OrderSide {
  Buy = 1,
  Sell,
  Trade,
}
