import * as React from "react";
import { Button, Input } from "components";
import "./add.scss";
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
  UploadProps,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createSlider,
  getAllSlidesAsOptions,
  getSliderById,
  getSliderTypes,
  updateSlider,
} from "core/repos/slider";

import { DefaultOptionType } from "antd/es/select";
import { Enum, SliderDetail } from "core/domains";
import { DownOutlined } from "@ant-design/icons";
import moment from "jalali-moment";

export const AddSlider: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [slider, setSlider] = React.useState<SliderDetail>({
    type: {
      id: -1,
      title: "انتخاب نوع",
    },
  });
  const [types, setTypes] = React.useState<Enum[]>([
    {
      id: -1,
      title: "انتخاب نوع",
    },
  ]);
  const [slides, setSlides] = React.useState<DefaultOptionType[]>([]);

  const location = useLocation();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  var id = urlSections[urlSections.length - 1];

  const navigate = useNavigate();

  React.useEffect(() => {
    getSliderTypes(setIsCardLoading, setTypes);
    slides?.length === 0 && getAllSlidesAsOptions(setIsCardLoading, setSlides);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-sliders-add-slider'>
          <h3>افزودن اسلایدر</h3>

          <form>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='عنوان'
                  name='title'
                  value={slider?.title}
                  onChange={(e) =>
                    setSlider({
                      ...slider,
                      title: e.target.value || "",
                    })
                  }
                />
              </Col>
              <Col>
                {types && (
                  <Dropdown
                    trigger={["click", "hover"]}
                    overlay={
                      <Menu>
                        {types.length &&
                          types?.map((type) => (
                            <Menu.Item
                              onClick={() =>
                                setSlider({
                                  ...slider,
                                  type: type,
                                })
                              }
                            >
                              {type.title}
                            </Menu.Item>
                          ))}
                      </Menu>
                    }
                    placement='bottom'
                  >
                    <Button
                      icon={<DownOutlined />}
                      type='outlined'
                      text={slider?.type?.title}
                    />
                  </Dropdown>
                )}
              </Col>
            </Row>

            <Row>
              <div className='select'>
                <label htmlFor=''>اسلاید های مورد نمایش</label>
                <Select
                  mode='multiple'
                  allowClear
                  value={slider?.slides}
                  placeholder='انتخاب کنید'
                  onChange={(value, options) => {
                    setSlider({ ...slider, slides: value });
                  }}
                  options={slides}
                  defaultValue={slider?.slides}
                />
              </div>
            </Row>

            <Row>
              <Switch
                checked={slider?.isActive}
                checkedChildren={"فعال"}
                unCheckedChildren={"غیر فعال"}
                title='وضعیت'
                onChange={(checked) =>
                  setSlider({
                    ...slider,
                    isActive: checked,
                  })
                }
              />
            </Row>

            <div className='buttons'>
              <Button
                htmlType='button'
                type='info'
                text='افزودن'
                onClick={() => {
                  createSlider(
                    setLoading,
                    {
                      slidesId: slider?.slides || [],
                      title: slider?.title,
                      type: slider?.type?.id,
                      isActive: slider?.isActive,
                    },
                    () => navigate("/sliders")
                  );
                }}
                loading={loading}
                disabled={
                  loading ||
                  slider.slides?.length == 0 ||
                  !slider.title ||
                  slider?.type?.id === -1
                }
              />

              <Button
                htmlType='button'
                type='outlined'
                text='بازگشت'
                onClick={() => navigate("/sliders")}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
