import * as React from "react";
import {Button, Card, Input} from "components";
import "./index.scss";
import {Col, Divider, Row} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {ManualValidation} from "core/domains";
import {approveManualValidation, rejectManualValidation,} from "core/repos/manual-validations";
import moment from "jalali-moment";

const { useState, useEffect } = React;

export const AuthInfoConfirmation: React.FC<{ data: any }> = ({
  data,
}: any) => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [authInfo, setAuthInfo] = useState<ManualValidation>(data);

  const location = useLocation();
  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  const approveHandle = async () => {
    approveManualValidation(setLoading, authInfo, id, navigate);
  };
  const rejectHandle = async () => {
    rejectManualValidation(setLoading, authInfo, id, navigate);
  };

  return (
    <Card
      title='تایید اطالاعات هویتی'
      className='vt-dashboard-mannual-authinfo-detail'
    >
      <Input
        type='text'
        label='نام کامل'
        name='fullName'
        value={
          authInfo.user.fullName.firstName +
          " " +
          authInfo?.user.fullName.lastName
        }
        onChange={() => setAuthInfo({ ...authInfo })}
        dir='rtl'
        placeholder='نام کامل کاربر را وارد کنید'
      />

      <Input
        type='text'
        label='کد ملی'
        name='nationalCode'
        value={authInfo?.user?.nationalCode}
        onChange={() => setAuthInfo({ ...authInfo })}
        dir='rtl'
        placeholder='کد ملی کاربر را وارد کنید'
      />
      <Input
        type='text'
        label='تاریخ تولد'
        name='birthDate'
        value={moment
            .utc(authInfo.user.birthDate)
            .locale("fa").local().format("jYYYY/MM/DD")}
        onChange={() => setAuthInfo({ ...authInfo })}
        dir='rtl'
      />
      <Input
        type='text'
        label='موبایل'
        name='phoneNumber'
        disabled
        value={authInfo?.user.phoneNumber}
        onChange={() => setAuthInfo({ ...authInfo })}
        dir='rtl'
      />

      <Input
        type='text'
        label='تلفن ثابت'
        name='landline'
        value={authInfo?.user?.landline}
        onChange={() => setAuthInfo({ ...authInfo })}
        dir='rtl'
      />
      <Input
        type='text'
        label='آدرس'
        name='address'
        value={authInfo?.user?.address}
        onChange={() => setAuthInfo({ ...authInfo })}
        dir='rtl'
      />
      <Divider />

      <Row>
        <label htmlFor='description'>توضیحات</label>
        <TextArea
          name='description'
          value={authInfo?.message}
          onChange={(e) =>
            setAuthInfo({
              ...authInfo,
              message: e.target.value,
            })
          }
        />
      </Row>

      {authInfo?.state.id != 0 && (
        <Row>
          <Col>
            <span>تغییر داده شده توسط </span>
            <span>{authInfo?.modifiedBy}</span>
          </Col>
          <Col>
            <span>تغییر داده شده در تاریخ </span>
            <span>{moment.utc(authInfo?.modifiedAt).local().format("HH:mm")}</span>
          </Col>
        </Row>
      )}

      <Row className='buttons'>
        <Button
          htmlType='button'
          type='info'
          text='تایید'
          onClick={() => approveHandle()}
          loading={loading}
          disabled={loading}
        />

        <Button
          htmlType='button'
          type='outlined'
          text='رد'
          onClick={() => rejectHandle()}
          loading={loading}
          disabled={loading || !authInfo?.message ? true : false}
        />
      </Row>
    </Card>
  );
};
