import * as React from "react";
import Transfer, {TransferDirection} from "antd/lib/transfer";
import {__API} from "apis/api";
import {Button, Input} from "components";
import {Permission} from "core/domains";
import "./edit.scss";
import {getViaAuth, patch, responseValidator,} from "utils/scripts/api";
import {Spin} from "antd";
import {useLocation} from "react-router-dom";
import {message} from "../../../../../components/toast/toast";

const { useState, useEffect } = React;

export const EditRole: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [roleData, setRoleData] = useState<any>();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const location = useLocation();

  const onChange = (
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onScroll = (
    direction: TransferDirection,
    e: React.SyntheticEvent<HTMLUListElement>
  ) => {};

  const editRoleHandle = async () => {
    setLoading(true);
    await patch(__API.roles.editRole, {
      id: location.pathname.slice(18),
      name: roleData.name,
      title: roleData.name,
      claims: targetKeys,
      description: roleData.description,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("نقش مورد نظر با موفقیت اصلاح شد.");
        window.open("/users/roles", "_self");
      }
    });
  };

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  var id = urlSections[urlSections.length - 1];

  useEffect(() => {
    setIsCardLoading(true);
    (async () => {
      await getViaAuth(
          __API.roles.getRoleById + `/${id}`
      ).then((response: any) => {
        if (responseValidator(response.status)) {
          setRoleData({
            ...roleData,
            name: response.value?.name ?? "",
            permissions: response.value.permissions,
            description: response.value.description,
          });
          setTargetKeys(response.value.permissions);
        }
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getViaAuth(__API.roles.getAllPermissions).then((response: any) => {
        setIsCardLoading(false);
        if (responseValidator(response.status)) {
          setPermissions(response.value);
        }
      });
    })();
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-roles-edit'>
          <h3>اصلاح نقش</h3>
          {roleData && (
            <form>
              <Input
                type='text'
                label='عنوان'
                name='title'
                value={roleData?.name}
                onChange={(e) =>
                  setRoleData({ ...roleData, name: e.target.value })
                }
              />

              <Input
                type='text'
                name='description'
                label='توضیحات'
                value={roleData?.description}
                onChange={(e) =>
                  setRoleData({ ...roleData, description: e.target.value })
                }
              />

              {permissions.length > 0 && (
                <Transfer
                  className='roles-transfer'
                  dataSource={permissions}
                  titles={["لیست دسترسی ها", "دسترسی های نقش"]}
                  targetKeys={targetKeys}
                  selectedKeys={selectedKeys}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                  onScroll={onScroll}
                  render={(item) => item.displayText}
                  locale={{
                    itemUnit: "مورد",
                    itemsUnit: "مورد",
                    selectAll: "انتخاب همه",
                    selectCurrent: "تایید موارد انتخاب شده",
                    selectInvert: "معکوس کردن انتخاب ها",
                  }}
                />
              )}

              <div className='buttons'>
                <Button
                  htmlType='button'
                  type='info'
                  text='اصلاح'
                  onClick={() => editRoleHandle()}
                  loading={loading}
                />

                <Button
                  htmlType='button'
                  type='outlined'
                  text='بازگشت'
                  onClick={() => window.open("/users/roles/", "_self")}
                />
              </div>
            </form>
          )}
        </div>
      </Spin>
    </>
  );
};
