import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";
import {
  digitSeparator,
  faNumToEnNum,
} from "../../../../utils/scripts/decimal";
import { SettingGoogleAuthType } from "core/domains";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import { deleteCommaSperator } from "utils/scripts/exchange";

interface WithdrawalCommissionSetting {
  withdrawCommissionUnderAmount?: string;
  withdrawCommissionAboveAmount?: string;
}

export const WithdrawCommissionSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setPmLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [withdrawCommissionSettings, setWithdrawCommissionSettings] =
    React.useState<WithdrawalCommissionSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const onConfirmHandle = () => {
    setGoogleAuth({
      code: "",
      error: "",
    });
    setModalIsOpen(true);
  };

  const getWithdrawCommissionSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.withdrawalCommmission).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setWithdrawCommissionSettings(response.value);
        }
      }
    );
  };

  const onUpdateWithdrawCommissionsHandler = () => {
    setPmLoading(true);
    put(API.settings.changeWithdrawalCommission, {
      aboveAmount: parseInt(
        deleteCommaSperator(
          withdrawCommissionSettings?.withdrawCommissionAboveAmount || ""
        )
      ),
      underAmount: parseInt(
        deleteCommaSperator(
          withdrawCommissionSettings?.withdrawCommissionUnderAmount
        )
      ),
      googleAuthCode: googleAuth?.code,
    }).then((response: any) => {
      setPmLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getWithdrawCommissionSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getWithdrawCommissionSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onUpdateWithdrawCommissionsHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card
        title="کارمزد های برداشت ریالی"
        blur={cardLoading}
        className="vt-dashboard-withdrawal-commission-settings"
      >
        <Card className="delay-item">
          <Input
            label="کارمزد برداشت بالای مقدار (5 میلیون تومان)"
            type="text"
            name="withdrawal-commission-above-amount"
            value={digitSeparator(
              withdrawCommissionSettings?.withdrawCommissionAboveAmount || ""
            )}
            onChange={(e) =>
              setWithdrawCommissionSettings({
                ...withdrawCommissionSettings,
                withdrawCommissionAboveAmount: digitSeparator(
                  faNumToEnNum(e.target.value)
                ),
              })
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="کارمزد برداشت پایین مقدار (5 میلیون تومان)"
            type="text"
            name="withdrawal-commission-under-amount"
            value={digitSeparator(
              withdrawCommissionSettings?.withdrawCommissionUnderAmount || ""
            )}
            onChange={(e) =>
              setWithdrawCommissionSettings({
                ...withdrawCommissionSettings,
                withdrawCommissionUnderAmount: digitSeparator(
                  faNumToEnNum(e.target.value)
                ),
              })
            }
            dir="ltr"
            maxLength={32}
          />
          <Button
            htmlType="button"
            type="info"
            onClick={() => onConfirmHandle()}
            loading={loading}
            disabled={loading}
            text={"ثبت تغییرات"}
          />
        </Card>
      </Card>
    </>
  );
};
