import {DeleteOutlined, EditOutlined, PlusOutlined,} from "@ant-design/icons";
import {Button, Card, Modal} from "components";
import {Table} from "components/table/table";
import {Enum} from "core/domains";
import * as React from "react";
import {TableColumn} from "react-data-table-component";
import {Link, useNavigate} from "react-router-dom";
import "./index.scss";
import {deleteLevelReferral, getAllReferralLevels} from "core/repos/referral";
import {ReferralLevel} from "core/domains/referral/referral";
import {digitSeparator} from "../../../../utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

const defaultStateItems: Enum[] = [
  {
    title: "همه",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "همه",
    id: 100,
  },
];

export const ReferralLevels: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [levels, setLevels] = useState<ReferralLevel>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [deleteModalIsLoading, setDeleteModalIsLoading] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ReferralLevel>();

  const navigate = useNavigate();

  useEffect(() => {
    !levels && getAllReferralLevels(setCardLoading, setLevels);
  }, []);

  const onDeleteIconClick = (item: ReferralLevel) => {
    setSelectedItem(item);
    setDeleteModalIsOpen(true);
  };

  const columns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: ReferralLevel) => row?.title,
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row: ReferralLevel) => row.description || "",
    },
    {
      name: "حداقل مقدار مورد نیاز برای ورود",
      id: "minimumAmountForEnter",
        selector: (row: ReferralLevel) => digitSeparator(row.minimumAmountForEnter),
    },
    {
      name: "درصد پاداش",
      id: "rewardPercent",
      selector: (row: ReferralLevel) => row.rewardPercent,
    },
    {
      name: "عملیات",
      id: "level",
      cell: (record: ReferralLevel) => (
        <div className="levels">
          <Link to={`edit/${record.id}`}>
              <EditOutlined/>
          </Link>
          <DeleteOutlined onClick={() => onDeleteIconClick(record)} />
        </div>
      ),
    },
  ];

  return (
    <Card
      title="رفرال- سطوح"
      className="vt-dashboard-referral-levels"
      blur={cardLoading}
    >
      <div className="buttons">
        <Button
          type="primary"
          text="افزودن سطح رفرال"
          icon={<PlusOutlined />}
          onClick={() => navigate("add")}
        />
      </div>
      {selectedItem && (
        <Modal
          title={"آیا از حذف سطح اطمینان دارید؟"}
          isOpen={deleteModalIsOpen}
          setIsOpen={setDeleteModalIsOpen}
          className="delete-slide-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              loading={deleteModalIsLoading}
              disabled={deleteModalIsLoading}
              onClick={() =>
                deleteLevelReferral(
                  setDeleteModalIsLoading,
                  selectedItem?.id,
                  () => {
                    setDeleteModalIsOpen(false);
                    getAllReferralLevels(setCardLoading, setLevels);
                  }
                )
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setDeleteModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}

      <Table className="referral-levels-table" data={levels} column={columns} />
    </Card>
  );
};
