import { ReactComponent as CloseOutlined } from "assets/icons/Close.svg";
import * as React from "react";
import ReactModal from "react-modal";
import "./modal.scss";

type Props = {
  title?: string;
  isOpen: boolean;
  setIsOpen: any;
  className?: string;
  closeAble?: boolean;
  children?: any;
  onClose?: () => void;
  disabledClose?: boolean;
};

export const Modal: React.FC<Props> = (props) => {
  return (
    <ReactModal
      style={{
        overlay: {
          zIndex: 999,
        },
      }}
      shouldCloseOnEsc
      className={`vt-modal ${props.className}`}
      isOpen={props.isOpen}
      onAfterClose={() => {
        if (props.disabledClose !== true) {
          props.setIsOpen(false);
          props.onClose && props.onClose();
        }
      }}
      onRequestClose={() => {
        if (props.disabledClose !== true) {
          props.setIsOpen(false);
          props.onClose && props.onClose();
        }
      }}
    >
      <div className='modal-box'>
        {props.title && (
          <div className='head'>
            <b>{props.title}</b>
            {props.closeAble && (
              <CloseOutlined onClick={() => props.setIsOpen(false)} />
            )}
          </div>
        )}

        {props.children}
      </div>
    </ReactModal>
  );
};
