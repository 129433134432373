import { message } from "antd";
import { API } from "apis";
import {
  WithdrawalDisabledUser,
  WithdrawalDisabledTill,
} from "core/domains/withdrawal-disabled-users/withdrawal-disabled-users";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";
import { getPageNum } from "utils/scripts/paggingHelper";

export const getAllWithdrawalDisabledUsers = (
  setLoader: any,
  setData: any,
  page: number,
  size: number,
  query?: string
) => {
  setLoader(true);
  getViaAuth(
    API.users.getAllWithdrawalDisabled + `/${page}/${size}${query}`,
    {}
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      const withdrawalDisabledUsers: WithdrawalDisabledUser[] = response.value;
      setData(withdrawalDisabledUsers);
    }
  });
};

export const updateUserWithdrawalEnable = (
  setloader: any,
  data: any,
  setdata: any,
  oncomplete?: any,
  queries?: string
) => {
  setloader(true);
  postViaAuth(API.users.editWithdrawalEnable, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("وضعیت برداشت کاربر تغییر یافت");
      getAllWithdrawalDisabledUsers(setloader, setdata, getPageNum(), 20, queries ?? "");
      oncomplete && oncomplete();
    }
  });
};

export const updateUserWithdrawalDisabled = (
  setloader: any,
  data: any,
  setdata: any,
  oncomplete?: any,
  queries?: string
) => {
  setloader(true);
  postViaAuth(API.users.editWithdrawalDisabledTill, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("مقدار غیر فعال بودن زمان برداشت اعمال شد");
      getAllWithdrawalDisabledUsers(setloader, setdata, getPageNum(), 10, queries ?? "");
      oncomplete && oncomplete();
    }
  });
};
