import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";
import React from "react";
import { API } from "apis";
import { message, Switch } from "antd";
import { Button, Card, Input } from "components";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "utils/scripts/decimal";

interface EveryoneFinancialSetting {
  maxIrtWithdrawlInDay?: number | null;
  maxDollarWithdrawlInDay?: number | null;
  maxIrtDepositInDay?: number | null;
  lockAllWithdrawal?: boolean;
  manualWithdrawal?: boolean;
}

export const EveryoneFinancialSettings = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [everyoneFinancialSettings, setEveryoneFinancialSettings] =
    React.useState<EveryoneFinancialSetting>();
  const clearEveryoneFinancialSetting = () => {
    if (everyoneFinancialSettings) {
      setEveryoneFinancialSettings(everyoneFinancialSettings);
      setEveryoneFinancialSettings({
        ...everyoneFinancialSettings,
        maxIrtWithdrawlInDay: null,
        manualWithdrawal: false,
        lockAllWithdrawal: false,
        maxDollarWithdrawlInDay: null,
        maxIrtDepositInDay: null,
      });
    }
  };
  const getEveryoneFinancialSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.getEveryoneFinancialSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setEveryoneFinancialSettings(response.value);
          console.log(everyoneFinancialSettings);
        }
      }
    );
  };

  const onUpdateEveryoneFinancialHandler = () => {
    setLoading(true);
    put(API.settings.changeEveryoneFinancialSettings, {
      maxIrtWithdrawlInDay: everyoneFinancialSettings?.maxIrtWithdrawlInDay
        ? deleteCommaSeparator(everyoneFinancialSettings?.maxIrtWithdrawlInDay)
        : null,
      maxDollarWithdrawlInDay:
        everyoneFinancialSettings?.maxDollarWithdrawlInDay
          ? deleteCommaSeparator(
              everyoneFinancialSettings?.maxDollarWithdrawlInDay
            )
          : null,
      maxIrtDepositInDay: everyoneFinancialSettings?.maxIrtDepositInDay
        ? deleteCommaSeparator(everyoneFinancialSettings?.maxIrtDepositInDay)
        : null,
      lockAllWithdrawal: everyoneFinancialSettings?.lockAllWithdrawal || false,
      manualWithdrawal: everyoneFinancialSettings?.manualWithdrawal || false,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getEveryoneFinancialSettings();
      } else {
        message.error(response.data.message);
      }
    });
  };

  React.useEffect(() => {
    getEveryoneFinancialSettings();
  }, []);

  return (
    <>
      <Card
        title="تنظیمات مالی همگانی کاربران"
        blur={cardLoading}
        className="vt-dashboard-everyone-financial-settings"
      >
        <Card className="delay-item">
          <Input
            label="حداکثر مقدار برداشت تومانی"
            type="text"
            name="everyone-financial-maxIrtWithdrawlInDay"
            value={digitSeparator(
              everyoneFinancialSettings?.maxIrtWithdrawlInDay
            )}
            onChange={(e) =>
              setEveryoneFinancialSettings({
                ...everyoneFinancialSettings,
                maxIrtWithdrawlInDay:
                  digitSeparator(faNumToEnNum(e.target.value)) || null,
              })
            }
            dir="ltr"
            maxLength={32}
          />

          <Input
            label="حداکثر مقدار واریز تومانی"
            type="text"
            name="everyone-financial-maxIrtDepositInDay"
            value={digitSeparator(
              everyoneFinancialSettings?.maxIrtDepositInDay
            )}
            onChange={(e) =>
              setEveryoneFinancialSettings({
                ...everyoneFinancialSettings,
                maxIrtDepositInDay:
                  digitSeparator(faNumToEnNum(e.target.value)) || null,
              })
            }
            dir="ltr"
            maxLength={32}
          />

          <Input
            label="حداکثر مقدار برداشت دلاری (دلار)"
            type="text"
            name="everyone-financial-maxDollarWithdrawlInDay"
            value={digitSeparator(
              everyoneFinancialSettings?.maxDollarWithdrawlInDay
            )}
            onChange={(e) =>
              setEveryoneFinancialSettings({
                ...everyoneFinancialSettings,
                maxDollarWithdrawlInDay:
                  digitSeparator(faNumToEnNum(e.target.value)) || null,
              })
            }
            dir="ltr"
            maxLength={32}
          />
        </Card>

        <Card className="automation-item">
          <span>وضعیت برداشت دستی:</span>
          <Switch
            checkedChildren={"برداشت دستی روشن می باشد"}
            unCheckedChildren={"برداشت دستی خاموش می باشد"}
            checked={everyoneFinancialSettings?.manualWithdrawal}
            onChange={(checked) =>
              checked
                ? setEveryoneFinancialSettings({
                    ...everyoneFinancialSettings,
                    manualWithdrawal: true,
                  })
                : setEveryoneFinancialSettings({
                    ...everyoneFinancialSettings,
                    manualWithdrawal: false,
                  })
            }
          />
        </Card>
        <Card className="automation-item">
          <span>وضعیت قفل </span>
          <Switch
            checkedChildren={"قفل تمامی برداشت ها روشن می باشد"}
            unCheckedChildren={"قفل تمامی برداشت ها خاموش می باشد"}
            checked={everyoneFinancialSettings?.lockAllWithdrawal}
            onChange={(checked) =>
              checked
                ? setEveryoneFinancialSettings({
                    ...everyoneFinancialSettings,
                    lockAllWithdrawal: true,
                  })
                : setEveryoneFinancialSettings({
                    ...everyoneFinancialSettings,
                    lockAllWithdrawal: false,
                  })
            }
          />
        </Card>
        <Button
          htmlType="button"
          type="error"
          onClick={() => clearEveryoneFinancialSetting()}
          text={"ریست"}
        />
        <br />
        <Button
          htmlType="button"
          type="info"
          onClick={() => onUpdateEveryoneFinancialHandler()}
          loading={loading}
          disabled={loading}
          text={"ثبت تغییرات"}
        />
      </Card>
    </>
  );
};
