import {message} from "antd";
import {API} from "apis";
import {Button, Card, Input} from "components";
import {SettingGoogleAuthType} from "core/domains";
import * as React from "react";
import {getValidationErrors, getViaAuth, hasValidationError, put, responseValidator,} from "utils/scripts/api";
import {SettingGoogleAuth} from "../google-auth/google-auth";
import "./index.scss";

interface JibimoSetting {
  username?: string;
  password?: string;
  secret_key?: string;
  batch?: string;
}

export const JibimoSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [jibimoSettings, setJibimoSettings] = React.useState<JibimoSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getJibimoSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.jibimoSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setJibimoSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeJibimoSettings, {
      ...jibimoSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getJibimoSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getJibimoSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className='vt-dashboard-jibimo-settings'>
        <Card title={`کانفیگ درگاه جیبیمو`} className='gateway-item'>
          <Input
            label='نام کاربری'
            type='text'
            name='username'
            value={jibimoSettings?.username}
            onChange={(e) =>
              setJibimoSettings({ ...jibimoSettings, username: e.target.value })
            }
            dir='ltr'
          />
          <Input
            label='رمز عبور'
            type='text'
            name='password'
            value={jibimoSettings?.password}
            onChange={(e) =>
              setJibimoSettings({ ...jibimoSettings, password: e.target.value })
            }
            dir='ltr'
          />
          <Input
            label='کلید امنیتی'
            type='text'
            name='secretKey'
            value={jibimoSettings?.secret_key}
            onChange={(e) =>
              setJibimoSettings({
                ...jibimoSettings,
                secret_key: e.target.value,
              })
            }
            dir='ltr'
          />
          <Input
            label='بچ'
            type='text'
            name='batch'
            value={jibimoSettings?.batch}
            onChange={(e) =>
              setJibimoSettings({
                ...jibimoSettings,
                batch: e.target.value,
              })
            }
            dir='ltr'
          />

          <Button
            htmlType='button'
            type='info'
            text='ثبت تغییرات'
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              jibimoSettings?.secret_key === "" ||
              jibimoSettings?.username === "" ||
              jibimoSettings?.password === "" ||
              jibimoSettings?.batch === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
