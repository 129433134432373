import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import { Cart, Enum, PaginationList } from "core/domains";
import {
  editCartState,
  getAllCarts,
  getCartStates,
  updateCart,
} from "core/repos/cart";
import * as React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import moment from "jalali-moment";
import { Select, Tooltip } from "antd";
import { faNumToEnNum } from "../../../utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

export interface FilterValues {
  keywords?: string;
}

export const BankAccountsManagement: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelcetedItem] = useState<Cart>();

  const [carts, setCarts] = useState<PaginationList<Cart>>();
  const [cartStates, setCartStates] = useState<Enum[]>();
  const [cartStatesLoading, setCartStatesLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  const [query, setQuery] = useState<string>("?sort=2");
  const [pageSize, setPageSize] = useState<number>(20);
  const [editingCartId, setEditingCartId] = useState<string | null>(null);

  useEffect(() => {
    !carts &&
      getAllCarts(setCardLoading, setCarts, getPageNum(), pageSize, query);
    !cartStates && getCartStates(setCardLoading, setCartStates);
  }, []);

  useEffect(() => {
    if (selectedItem) setIsOpen(true);
    else setIsOpen(false);
  }, [selectedItem]);

  const [filterValues, setFilterValues] = useState<FilterValues>({});

  function removeDuplicates(arr: string[]) {
    const filtered = arr.filter((item, index) => arr.indexOf(item) === index);
    return filtered.join(" ");
  }

  const onFilterHandler = () => {
    var _query = "?sort=1";

    if (filterValues?.keywords) {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += filterValues?.keywords;
    }
    setQuery(_query);
    getAllCarts(setLoading, setCarts, 1, pageSize, _query);
  };

  const handleStateChange = async (newStateId: number, cart: Cart) => {
    if (cart.state.id === newStateId) {
      setEditingCartId(null);
      return;
    }

    try {
      setEditingCartId(null);
      editCartState(setCardLoading, { state: newStateId, id: cart.id });
      await getAllCarts(
        setCardLoading,
        setCarts,
        getPageNum(),
        pageSize,
        query
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderStateBadges = (row: Cart) => {
    const isEditing = editingCartId === row.id;

    if (isEditing) {
      return (
        <Select
          defaultValue={row.state.id}
          style={{ width: 120 }}
          onBlur={() => setEditingCartId(null)}
          onChange={(newStateId) => handleStateChange(newStateId, row)}
          dropdownMatchSelectWidth={false}
        >
          {cartStates?.map((state) => (
            <Select.Option key={state.id} value={state.id}>
              <Badge
                text={state.title}
                type={
                  state.id === 1
                    ? "success"
                    : state.id === 2
                    ? "error"
                    : "waiting"
                }
              />
            </Select.Option>
          ))}
        </Select>
      );
    }

    return (
      <Badge
        text={row.state.title}
        type={
          row.state.id === 1
            ? "success"
            : row.state.id === 2
            ? "error"
            : "waiting"
        }
        onClick={() => setEditingCartId(row.id)}
      />
    );
  };

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Cart, rowIndex: number) => rowIndex + 1,
      width: "5%",
    },
    {
      name: "شماره کارت",
      id: "cardNumber",
      selector: (row: Cart) => row?.cardNumber,
      cell: (row: Cart) => <span className="en">{row?.cardNumber}</span>,
      width: "15%",
    },
    {
      name: "شماره شبا",
      id: "iBan",
      selector: (row: Cart) => row?.iBan,
      cell: (row: Cart) => (
        <span className="en">{row?.iBan.replaceAll("IR", "")}</span>
      ),
      width: "20%",
    },
    {
      name: "صاحب حساب",
      id: "owner",
      selector: (row: Cart) => row?.fullName || "",
      cell: (row: Cart) => (
        <div className="column-fullname">
          <span className="name">{row.fullName}</span>
          <p className="phone">{row.phoneNumber}</p>
        </div>
      ),

      width: "13%",
    },
    {
      name: "وضعیت",
      id: "state",
      width: "15%",
      selector: (row: Cart) => row.state.id,
      cell: (row: Cart) =>
        cartStates ? renderStateBadges(row) : <LoadingOutlined />,
    },
    {
      name: "بانک",
      id: "bank",
      width: "7%",
      selector: (row: Cart) => row?.bank?.name.replaceAll("بانک", ""),
    },
    {
      name: "زمان",
      id: "createdOn",
      width: "10%",
      selector: (row: Cart) => row.createdOn,

      cell: (row: Cart) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (row: Cart) => (
        <div className="actions">
          <Tooltip placement="top" title="حذف کارت بانکی">
            <DeleteOutlined onClick={() => setSelcetedItem(row)} />
          </Tooltip>
          <Link to={`edit/${row.id}`}>
            <Tooltip placement="top" title="اصلاح کارت بانکی">
              <EditOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="مدیریت حساب های بانکی"
      className="vt-dashboard-banks"
      blur={cardLoading}
    >
      {selectedItem && (
        <Modal
          title={"آیا از حذف کارت بانکی اطمینان دارید؟"}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className="delete-cart-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              loading={loading}
              disabled={loading}
              onClick={() =>
                updateCart(
                  setCardLoading,
                  setLoading,
                  setCarts,
                  { ...selectedItem, isRemoved: true },
                  setIsOpen
                )
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            className="search"
            placeholder="جستجو کنید ..."
            name="search"
            onPressEnter={() => onFilterHandler()}
            onChange={(e) =>
              setFilterValues({
                ...filterValues,
                keywords: faNumToEnNum(e.target.value),
              })
            }
          />
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
              });
              getAllCarts(
                setCardLoading,
                setCarts,
                getPageNum(),
                pageSize,
                "?sort=2"
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      {carts && (
        <Table
          pagination={carts.pagination}
          className="banks-table"
          data={carts?.items}
          column={columns}
          filters={query}
          getData={getAllCarts}
          setData={setCarts}
          setLoader={setCardLoading}
          setLoading={setCardLoading}
          sort={{ sort: sortDir, setsort: setSortDir }}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
