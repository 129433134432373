import * as React from "react";
import { Button, Card, Input } from "components";
import "./perfectmoney-voucher-tracking.scss";
import { getViaAuth, responseValidator } from "utils/scripts/api";
import { Col, DatePicker, message, Row } from "antd";
import { API } from "apis";
import moment from "jalali-moment";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../utils/scripts/copyToClipboard";

const { RangePicker } = DatePicker;

type VoucherTracking = {
  payeeAccount: string;
  batch: string;
  amount: number;
  activatedDateTime: string;
  voucherCode: string;
};

const { useState, useEffect } = React;

export const PerfectmoneyVoucherTracking: React.FC = () => {
  // States
  const [voucherCode, setVoucherCode] = useState<string>();
  const [voucherInfo, setVoucherInfo] = useState<VoucherTracking>();
  const [loading, setLoading] = useState<boolean>(false);

  const getVoucherInfo = async () => {
    if (voucherCode) {
      setLoading(true);
      await getViaAuth<VoucherTracking>(
        API.pm.voucherInfo + `/${voucherCode}`
      ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          message.success("استعلام با موفقیت انجام شد.");
          setVoucherInfo(response.value);
        } else {
        }
      });
    }
  };
  useEffect(() => {}, [voucherInfo]);
  return (
    <div className="vt-dashboard-bank-account-tracking">
      <h3>استعلام ووچر پرفکت مانی</h3>
      <div className="tracking-info">
        <Card className="tracking" title={`استعلام`}>
          <div className="form">
            <label className="rangePicker-lbl">محدوده زمانی</label>
            <RangePicker className="rangePicker" />

            <Input
              label="کد ووچر"
              type="text"
              name="voucher"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              dir="ltr"
              onPressEnter={() => getVoucherInfo()}
              maxLength={80}
            />
            <Button
              htmlType="button"
              type="info"
              text="استعلام"
              onClick={() => getVoucherInfo()}
              loading={loading}
              disabled={loading}
            />
          </div>
        </Card>
        <Card className="card-info" title="اطلاعات ووچر">
          <Row>
            <Col>
              <span>بچ</span>
            </Col>
            <Col>
              {voucherInfo && (
                <>
                  {" "}
                  <span>{voucherInfo.batch}</span>
                  <CopyOutlined
                    onClick={() => copyToClipboard(voucherInfo.batch, "شناسه")}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>تاریخ استفاده</span>
            </Col>
            <Col>
              {voucherInfo && (
                <p dir="ltr">
                  {moment(voucherInfo.activatedDateTime).format(
                    "YYYY/MM/DD  -  HH:mm"
                  )}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>مقدار</span>
            </Col>
            <Col>
              {voucherInfo && (
                <div className="d-flex">
                  <span>{voucherInfo.amount}</span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(voucherInfo.amount.toString(), "مقدار")
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>اکانت فعال شده</span>
            </Col>
            <Col>
              {voucherInfo && (
                <>
                  <span className="en">{voucherInfo.payeeAccount}</span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(voucherInfo.payeeAccount, "اکانت")
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
