import * as React from "react";
import { Button, Input } from "components";
import "./add.scss";
import { Col, Dropdown, Menu, Row, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {
  createNotification,
  getNotificationReasons,
} from "core/repos/notification";
import { AddNotification, Enum, User } from "core/domains";
import { DefaultOptionType } from "antd/es/select";
import { getViaAuth, isNotFound, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import { DownOutlined } from "@ant-design/icons";

export const CreateNotification: React.FC = () => {
  // States
  const [optionsIsOpen, setOptionsIsOpen] = React.useState<boolean>(false);
  const [isCardLoading, setIsCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDropdownLoading, setIsDropdownLoading] =
    React.useState<boolean>(false);
  const [notification, setNotification] = React.useState<AddNotification>({
    reason: {
      id: 0,
      title: "انتخاب دلیل",
    },
  });
  const [options, setOptions] = React.useState<DefaultOptionType[]>();
  const [selectedOptions, setSelectedOptions] =
    React.useState<DefaultOptionType[]>();
  const [search, setSearch] = React.useState<string>("");
  const [reasons, setReasons] = React.useState<Enum[]>();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const navigate = useNavigate();

  const getAllUsers = async (query: string) => {
    setIsDropdownLoading(true);
    await getViaAuth(`${API.users.getAllUsers}/${1}/${50}${query}`).then(
      (response: any) => {
        setIsDropdownLoading(false);
        if (responseValidator(response.status)) {
          var newArr = response.value.items.map((user: User) => ({
            value: user.id,
            label: user.fullName ?? user.userName,
          }));
          setOptions(newArr);
        } else {
          isNotFound(response.status) && setSelectedOptions([]);
        }
      }
    );
  };

  const filterHandle = () => {
    var _query = "";

    if (search !== "") {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += search;
    }

    getAllUsers(_query);
  };

  const onUserIdsChange = (value: string[]) => {
    setNotification({
      ...notification,
      userIds: value,
    });
  };

  React.useEffect(() => {
    filterHandle();
  }, [search]);

  React.useEffect(() => {
    getNotificationReasons(setIsCardLoading, setReasons);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-notifications-add-notification'>
          <h3>افزودن اعلان</h3>

          <form>
            <Row>
              <Input
                type='text'
                label='عنوان'
                name='title'
                value={notification?.title}
                onChange={(e) =>
                  setNotification({
                    ...notification,
                    title: e.target.value || "",
                  })
                }
              />
            </Row>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='لینک'
                  name='link'
                  value={notification?.link}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      link: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                {reasons && (
                  <Dropdown
                    trigger={["click", "hover"]}
                    overlay={
                      <Menu>
                        {reasons.length &&
                          reasons.map((reason) => (
                            <Menu.Item
                              onClick={() =>
                                setNotification({
                                  ...notification,
                                  reason: reason,
                                })
                              }
                            >
                              {reason.title}
                            </Menu.Item>
                          ))}
                      </Menu>
                    }
                    placement='bottom'
                  >
                    <Button
                      icon={<DownOutlined />}
                      type='outlined'
                      text={notification?.reason?.title}
                    />
                  </Dropdown>
                )}
              </Col>
            </Row>

            <Row>
              <Spin className='antspin' spinning={isDropdownLoading}>
                <label htmlFor='message'>
                  کاربران دریافت کننده اعلان (در صورت انتخاب هیچ کاربر برای تمام
                  کاربران اعمال میشود)
                </label>
                <Select
                  mode='multiple'
                  allowClear
                  value={selectedOptions}
                  placeholder='لیست کاربران را انتخاب کنید'
                  onChange={(value, options) => {
                    var arr: string[] = [];
                    value.map((i: any) => arr.push(i));
                    onUserIdsChange(arr);
                  }}
                  options={options}
                  showSearch
                  onSearch={(value) => setSearch(value)}
                  filterOption={false}
                />
              </Spin>
            </Row>

            <Row>
              <div className='textarea'>
                <label htmlFor='message'>پیام</label>
                <TextArea
                  name='message'
                  value={notification?.message}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      message: e.target.value,
                    })
                  }
                />
              </div>
            </Row>

            <div className='buttons'>
              <Button
                htmlType='button'
                type='info'
                text='افزودن'
                onClick={() => {
                  createNotification(setLoading, {
                    ...notification,
                    reason: notification?.reason?.id,
                    userIds:
                      notification.userIds && notification.userIds?.length > 0
                        ? notification.userIds
                        : [],
                  });
                }}
                loading={loading}
                disabled={
                  !notification?.title ||
                  !notification?.message ||
                  notification?.reason?.id == 0 ||
                  loading
                }
              />

              <Button
                htmlType='button'
                type='outlined'
                text='بازگشت'
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
