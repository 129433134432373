export function isNumeric(num: any) {
  if (
    num == "." ||
    num == "" ||
    num == "0" ||
    num == "1" ||
    num == "2" ||
    num == "3" ||
    num == "4" ||
    num == "5" ||
    num == "6" ||
    num == "7" ||
    num == "8" ||
    num == "9"
  ) {
    return true;
  } else return false;
}

export function deleteCommaSperator(value: any): any {
  return value.toString().replaceAll(",", "");
}
