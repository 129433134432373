import * as React from "react";
import {CloseOutlined, LeftOutlined} from "@ant-design/icons";
import {Button, Card} from "components";
import "./detail.scss";
import {Empty, message, Upload} from "antd";
import {Badge} from "components/badge/badge";
import TextArea from "antd/lib/input/TextArea";
import {RcFile, UploadChangeParam, UploadFile, UploadProps,} from "antd/lib/upload/interface";
import {answerTicket, closeTicket, getTicketById} from "core/repos/ticket";
import {Ticket, TicketForm} from "core/domains";
import moment from "jalali-moment";
import {useNavigate} from "react-router-dom";

const { useState } = React;

export const TicketDetail: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [ticketResult, setTicketResult] = useState<Ticket>();
  const [ticketData, setTicketData] = useState<TicketForm>();

  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  React.useEffect(() => {
    !ticketResult && getTicketById(setCardLoading, setTicketResult, id);
  }, []);

  const closeTicketHandle = () => {
    closeTicket(setLoading, navigate, id);
  };

  const answerTicketHandle = () => {
    answerTicket(setLoading, ticketData, id);
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "video/mp4";

    if (!isJpgOrPng) {
      message.error("فقط فرمت های mp4 قابل قبول میباشد");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("حجم عکس باید کمتر از ۲ مگابایت باشد.");
    }

    return setTicketData({ ...ticketData, attachedMessage: file });
  };
  return (
    <div className='vt-support-detail'>
      <Card blur={cardLoading} className='details'>
        {ticketResult && (
          <>
            <div className='head'>
              <h3>عنوان: {ticketResult?.title}</h3>
              <div className='buttons'>
                {ticketResult.state.id != 3 && (
                  <Button
                    text='بستن تیکت'
                    icon={<CloseOutlined />}
                    type='info'
                    onClick={() => closeTicketHandle()}
                  />
                )}
                <Button
                  text='بازگشت'
                  icon={<LeftOutlined />}
                  type='outlined'
                  onClick={() => navigate("/tickets")}
                />
              </div>
            </div>
            <div className='data-row'>
              <div>
                <div className='col'>
                  <div>
                    <p>تاریخ ایجاد:</p>
                    <span>
                      {moment
                        .utc(ticketResult?.createdOn)
                        .locale("fa")
                        .format("YYYY/MM/DD")}
                    </span>
                  </div>
                </div>
                <div className='col'>
                  <div>
                    <p>تاریخ بروز رسانی:</p>
                    <span>1401/06/09</span>
                  </div>
                  <Badge
                    text={ticketResult?.state.title ?? " "}
                    type={
                      ticketResult?.state.id == 1
                        ? "success"
                        : ticketResult?.state.id == 2
                        ? "waiting"
                        : "error"
                    }
                    icon={`/assets/icons/table/${
                      ticketResult?.state.id == 1
                        ? "success"
                        : ticketResult?.state.id == 2
                        ? "waiting"
                        : "danger"
                    }.svg`}
                  />
                </div>
              </div>
            </div>
            <div className='chat'>
              <div className='messages'>
                <div className='overlay'></div>

                {ticketResult?.ticketMessages?.length ? (
                  ticketResult?.ticketMessages?.map((message) => (
                    <div className='message self'>
                      <div className='avatar'>
                        <span>{message?.sender}</span>
                        <img src='/assets/images/support/man.svg' alt='' />
                      </div>
                      <div className='content'>
                        <p>{message?.text}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <Empty description='پیامی یافت نشد' />
                )}
              </div>
              <TextArea
                placeholder='پیام خود را تایپ کنید...'
                onChange={(e) =>
                  setTicketData({
                    ...ticketData,
                    messageText: e.target.value,
                  })
                }
              />
              <div className='browse-field'>
                <div id='documents-photo' className='browse'>
                  <Upload
                    name='avatar'
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    <button onClick={(el) => el.preventDefault()}>
                      آپلود فایل
                    </button>
                  </Upload>
                </div>
              </div>

              <Button
                loading={loading}
                disabled={loading}
                text='ثبت پیام'
                type='info'
                onClick={() => answerTicketHandle()}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};
