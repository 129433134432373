import { XpayComponent } from "core/domains";
import * as React from "react";
import "./badge.scss";

interface Props extends XpayComponent {
  type: "success" | "error" | "primary" | "info" | "secondary" | "waiting";
  text?: string | number;
  icon?: string;
  style?: any;
  onClick?: () => void;
}

export const Badge: React.FC<Props> = (props) => {
  return (
    <div
      style={props.style}
      className={`vt-badge${
        props.className && props.className !== "" ? props.className : ""
      }`}
      onClick={props.onClick}
    >
      <div className={props.type}>
        {props.icon && <img src={props.icon} alt="Badge-Icon" />}
        <span>{props?.text}</span>
      </div>
    </div>
  );
};
