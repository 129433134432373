import "./index.scss";
import React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "utils/scripts/decimal";
import { message, Switch } from "antd";
import { Button, Card, Input } from "components";

interface TransferTronSetting {
  transferTRXFromKucoinToHotState?: boolean;
  transferTRXFromHotToKucoinState?: boolean;
  graterThanForTransferValueFromKucoinToHot?: number;
  minKucoinRequirementValueFromKucoinToHot?: number;
}

export const TransferTronSettings = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [transferTronSettings, setTransferTronSettings] =
    React.useState<TransferTronSetting>();

  const getTransferTronSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.getTransferTronSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setTransferTronSettings(response.value);
        }
      }
    );
  };

  const onUpdateTransferTronHandler = () => {
    setLoading(true);
    put(API.settings.changeTransferTronSetting, {
      transferTRXFromKucoinToHotState:
        transferTronSettings?.transferTRXFromKucoinToHotState || false,
      transferTRXFromHotToKucoinState:
        transferTronSettings?.transferTRXFromHotToKucoinState || false,
      graterThanForTransferValueFromKucoinToHot: parseFloat(
        transferTronSettings?.graterThanForTransferValueFromKucoinToHot
          ? deleteCommaSeparator(
              transferTronSettings?.graterThanForTransferValueFromKucoinToHot
            )
          : 0
      ),
      minKucoinRequirementValueFromKucoinToHot: parseFloat(
        transferTronSettings?.minKucoinRequirementValueFromKucoinToHot
          ? deleteCommaSeparator(
              transferTronSettings?.minKucoinRequirementValueFromKucoinToHot
            )
          : 0
      ),
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getTransferTronSettings();
      } else {
        message.error(response.data.message);
      }
    });
  };

  React.useEffect(() => {
    getTransferTronSettings();
  }, []);

  return (
    <>
      <Card
        title="تنظیمات انتقال ترون"
        blur={cardLoading}
        className="vt-dashboard-transfer-tron-settings"
      >
        <Card className="delay-item">
          <Input
            label="انتقال مقدار بالا"
            type="text"
            name="transfer-tron-greater"
            value={digitSeparator(
              transferTronSettings?.graterThanForTransferValueFromKucoinToHot
            )}
            onChange={(e) =>
              setTransferTronSettings({
                ...transferTronSettings,
                graterThanForTransferValueFromKucoinToHot:
                  digitSeparator(faNumToEnNum(e.target.value)) || 0,
              })
            }
            dir="ltr"
            maxLength={32}
          />

          <Input
            label="میزان مورد نیاز مانده ترون در کوکوین"
            type="text"
            name="transfer-tron-min"
            value={digitSeparator(
              transferTronSettings?.minKucoinRequirementValueFromKucoinToHot
            )}
            onChange={(e) =>
              setTransferTronSettings({
                ...transferTronSettings,
                minKucoinRequirementValueFromKucoinToHot:
                  digitSeparator(faNumToEnNum(e.target.value)) || 0,
              })
            }
            dir="ltr"
            maxLength={32}
          />
        </Card>
        <Card className="automation-item">
          <span>وضعیت انتقال ترون از کوکوین به هات ولت:</span>
          <Switch
            checkedChildren={"انتقال از کوکوین به هات ولت روشن می باشد"}
            unCheckedChildren={"انتقال از کوکوین به هات ولت خاموش می باشد"}
            checked={transferTronSettings?.transferTRXFromKucoinToHotState}
            onChange={(checked) =>
              checked
                ? setTransferTronSettings({
                    ...transferTronSettings,
                    transferTRXFromKucoinToHotState: true,
                  })
                : setTransferTronSettings({
                    ...transferTronSettings,
                    transferTRXFromKucoinToHotState: false,
                  })
            }
          />
        </Card>
        <Card className="automation-item">
          <span>وضعیت انتقال ترون از هات ولت به کوکوین</span>
          <Switch
            checkedChildren={"انتقال از هات ولت به کوکوین روشن می باشد"}
            unCheckedChildren={"انتقال از هات ولت به کوکوین خاموش می باشد"}
            checked={transferTronSettings?.transferTRXFromHotToKucoinState}
            onChange={(checked) =>
              checked
                ? setTransferTronSettings({
                    ...transferTronSettings,
                    transferTRXFromHotToKucoinState: true,
                  })
                : setTransferTronSettings({
                    ...transferTronSettings,
                    transferTRXFromHotToKucoinState: false,
                  })
            }
          />
        </Card>
        <Button
          htmlType="button"
          type="info"
          onClick={() => onUpdateTransferTronHandler()}
          loading={loading}
          disabled={loading}
          text={"ثبت تغییرات"}
        />
      </Card>
    </>
  );
};
