import { Enum, Pagination } from "../commons/common";
import { Market, Network, Wallet } from "../markets/market";

export interface Transaction {
  id: string;
  state: Enum;
  type: Enum;
  depositIrtType: Enum;
  market: Market;
  trackingCode: string;
  platform: Enum;
  amount: number;
  createdAt: string;
  createdBy: string;
  txId?: string;
  description?: string;
  payoutAmount?: number;
  gateWayName?: string;
  network: Network;
  onPrice: number;
  wallet: Wallet;
  bankAccount: BankAccountViewModel;
  voucher: Voucher;
  paymentIdentifier?: string;
  phoneNumber?: string;
  ip?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  digitalReceipt?: string;
  balanceAfterTransaction?: number;
}

export interface TransactionsCount {
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface Voucher {
  voucherCode: string;
  voucerhActivationCode: string;
  batchNumber?: string;
}

export interface TransactionUpdateModel {
  id?: string;
  state?: number;
  txId?: string;
  description?: string;
  payoutAmount?: number;
}
export interface BankAccountViewModel {
  cardNumber: string;
  iBan: string;
  bankName: string;
}

export interface TransactionList {
  items: Transaction[];
  pagination: Pagination;
}
export enum TransactionState {
  Waiting = 1,
  Succeed,
  Rejected,
  Pending,
  WaitingForOtp,
  WaitingForAdmin,
  WaitingForProvider,
  Cancel,
  ManualDoing,
  Doing,
  ReadyToPayout = 11,
  WaitingForUpgradeLevel = 12,
  WaitingForTimeAndUpgradeLevel = 13,
  WaitingForTime = 14,
}

export const TransactionStateLabel = new Map<number, string>([
  [TransactionState.Waiting, "در انتظار پرداخت"],
  [TransactionState.Succeed, "انجام شده"],
  [TransactionState.Rejected, "رد شده"],
  [TransactionState.Pending, "در انتظار تایید"],
  [TransactionState.WaitingForOtp, "در انتظار تایید کد"],
  [TransactionState.WaitingForAdmin, "در انتظار تایید ادمین"],
  [TransactionState.WaitingForProvider, "در انتظار تسویه شبکه"],
  [TransactionState.Cancel, "لغو شده"],
  [TransactionState.ManualDoing, "درحال انجام دستی"],
  [TransactionState.Doing, "درحال انجام"],
]);

export enum TransactionAction {
  ConfirmAndPayout = 1,
  Succeed = 2,
  Cancel = 8,
}

export const TransactionActionLabel = new Map<number, string>([
  [TransactionAction.ConfirmAndPayout, "تایید و تسویه"],
  [TransactionAction.Succeed, "تایید"],
  [TransactionAction.Cancel, "لغو"],
]);

export enum TransactionType {
  Deposit = 1,
  Withdrawal,
  AffiliateDeposit,
  RefferralWithdrawal,
}
