import * as React from "react";
import { Button, Input } from "components";
import "./edit.scss";
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
  UploadProps,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { faNumToEnNum, IsNumeric } from "utils/scripts/decimal";
import {
  getAllSlidesAsOptions,
  getSliderById,
  getSliderTypes,
  updateSlider,
} from "core/repos/slider";
import { RcFile, UploadFile } from "antd/lib/upload";
import { message } from "components/toast/toast";
import { UploadChangeParam } from "antd/es/upload";
import { getAllLevelsAsSelectOption } from "core/repos/level";
import { DefaultOptionType } from "antd/es/select";
import { Enum, SliderDetail } from "core/domains";
import { DownOutlined } from "@ant-design/icons";
import moment from "jalali-moment";

export const UpdateSlider: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [slider, setSlider] = React.useState<SliderDetail>();
  const [types, setTypes] = React.useState<Enum[]>([
    {
      id: -1,
      title: "انتخاب نوع",
    },
  ]);
  const [slides, setSlides] = React.useState<DefaultOptionType[]>([]);

  const location = useLocation();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  var id = urlSections[urlSections.length - 1];

  const navigate = useNavigate();

  React.useEffect(() => {
    getSliderTypes(setIsCardLoading, setTypes);
    slides?.length === 0 && getAllSlidesAsOptions(setIsCardLoading, setSlides);
    !slider && getSliderById(setIsCardLoading, setSlider, id);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-sliders-edit-slider'>
          <h3>اصلاح اسلایدر</h3>

          <form>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='عنوان'
                  name='title'
                  value={slider?.title}
                  onChange={(e) =>
                    setSlider({
                      ...slider,
                      title: e.target.value || "",
                    })
                  }
                />
              </Col>
              <Col>
                {types && (
                  <Dropdown
                    trigger={["click", "hover"]}
                    overlay={
                      <Menu>
                        {types.length &&
                          types?.map((type) => (
                            <Menu.Item
                              onClick={() =>
                                setSlider({
                                  ...slider,
                                  type: type,
                                })
                              }
                            >
                              {type.title}
                            </Menu.Item>
                          ))}
                      </Menu>
                    }
                    placement='bottom'
                  >
                    <Button
                      icon={<DownOutlined />}
                      type='outlined'
                      text={slider?.type?.title}
                    />
                  </Dropdown>
                )}
              </Col>
            </Row>

            <Row>
              {slider?.slides && (
                <div className='select'>
                  <label htmlFor=''>اسلاید های مورد نمایش</label>
                  <Select
                    mode='multiple'
                    allowClear
                    value={slider.slides}
                    placeholder='انتخاب کنید'
                    onChange={(value, options) => {
                      setSlider({
                        ...slider,
                        slides: options.map((e: DefaultOptionType) => e),
                      });
                    }}
                    options={slides}
                    defaultValue={slider?.slides}
                  />
                </div>
              )}
            </Row>

            <Row>
              <div className='vt-switch-row'>
                <label>وضعیت:</label>
                <Switch
                  checked={slider?.isActive}
                  checkedChildren={"فعال"}
                  unCheckedChildren={"غیر فعال"}
                  title='وضعیت'
                  onChange={(checked) =>
                    setSlider({
                      ...slider,
                      isActive: checked,
                    })
                  }
                />
              </div>
            </Row>

            {slider?.lastModifiedDate && (
              <Row>
                <span>آخرین تغییر در تاریخ</span>
                <span>
                  {moment
                    .utc(slider?.lastModifiedDate)
                    .local()
                    .format("jYYYY/jMM/jDD")}
                </span>
              </Row>
            )}

            <div className='buttons'>
              <Button
                htmlType='button'
                type='info'
                text='اصلاح'
                onClick={() => {
                  updateSlider(
                    setLoading,
                    {
                      slidesId: slider?.slides?.map((e) => e.value) || [],
                      sliderId: id,
                      title: slider?.title,
                      type: slider?.type?.id,
                      isActive: slider?.isActive,
                    },
                    () => navigate("/sliders")
                  );
                }}
                loading={loading}
                disabled={
                  loading ||
                  slider?.slides?.length == 0 ||
                  !slider?.title ||
                  slider?.type?.id === -1
                }
              />

              <Button
                htmlType='button'
                type='outlined'
                text='بازگشت'
                onClick={() => navigate("/sliders")}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
