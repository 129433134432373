import { API } from "apis";
import { getViaAuth, responseValidator } from "utils/scripts/api";

export const getAllServiceLogs = (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  getViaAuth(
    `${API.serviceLogs.getAll}/${page}/${pageSize}${query || ""}`
  ).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};
