import * as React from "react";
import {Button, Input} from "components";
import {Transaction, TransactionType} from "core/domains/transaction/transaction";
import "./update.scss";
import {Col, Row, Select, Spin} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {deleteCommaSeparator, digitSeparator,} from "utils/scripts/decimal";
import {getTransactionById, getTransactionStates, updateTransaction,} from "core/repos/transaction";
import {Enum} from "core/domains";
import moment from "jalali-moment";

const { useState, useEffect } = React;

export const UpdateTransaction: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Transaction>();
  const [states, setStates] = useState<Enum[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];
  function handleFormChange(value: string, field: string) {
    transaction && setTransaction({ ...transaction, [field]: value });
  }

  function update() {
    updateTransaction(
      setLoading,
      {
        txId: transaction?.txId,
        id: transaction?.id,
        state: transaction?.state.id,
        payoutAmount: transaction?.payoutAmount,
        description: transaction?.description,
      },

        () => {
        navigate(-1)
      }
    );
  }
  useEffect(() => {
    !transaction && getTransactionById(setIsCardLoading, setTransaction, id);
    getTransactionStates(setIsCardLoading, setStates);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-transactions-edit'>
          <h3>{`اصلاح تراکنش`} <span className="en">{transaction?.trackingCode}</span></h3>
          {transaction?.state && (
            <form>
              <div className='row'>
                {
                  transaction.market.symbol !== 'IRT' && <Input
                    type='text'
                    label='Txid'
                    name='name'
                    value={transaction?.txId}
                    onChange={(e) => handleFormChange(e.target.value, "txId")}
                  />
                }
                <Select
                  placeholder={"وضعیت"}
                  defaultValue={transaction.state.title}
                  onChange={(value) =>
                    transaction &&
                    setTransaction({
                      ...transaction,
                      state: { id: parseInt(value), title: "انتخاب" },
                    })
                  }
                >
                  <Select.Option key={transaction.state.id}>
                    {transaction?.state.title}
                  </Select.Option>

                  {states.map((state: Enum) => {
                    return (
                      state.id !== transaction?.state.id && (
                        <Select.Option key={state.id}>
                          {state.title}
                        </Select.Option>
                      )
                    );
                  })}
                </Select>
              </div>

              <div className='text-area-field'>
                <label htmlFor='description'>توضیحات</label>
                <TextArea
                  name='description'
                  value={transaction?.description}
                  onChange={(e) =>
                    handleFormChange(e.target.value, "description")
                  }
                  maxLength={100}
                />
              </div>
              <div className='row'>
                {transaction.type.id == TransactionType.Withdrawal && transaction.market.symbol === "IRT" && <Input
                    type='text'
                    label='مقدار جهت تسویه'
                    name='name'
                    value={digitSeparator(transaction?.payoutAmount?.toString())}
                    onChange={(e) =>
                        handleFormChange(
                            deleteCommaSeparator(e.target.value),
                            "payoutAmount"
                        )
                    }
                    maxLength={25}
                />}

                <Input
                  type='text'
                  label='مقدار '
                  name='name'
                  value={digitSeparator(transaction?.amount)}
                  onChange={(e) =>
                    handleFormChange(e.target.value, "payoutAmount")
                  }
                  disabled={true}
                  maxLength={25}
                />
              </div>
              <Row>
                <Col>
                  <span>ویرایش توسط :</span>
                  <span>
                {transaction.lastModifiedBy}
              </span>
                </Col>
                <Col>
 <span> ویرایش شده در تاریخ :
   {transaction.lastModifiedDate && moment.utc(transaction.lastModifiedDate).locale("fa").local().format("jYYYY/MM/DD")}
   | {transaction.lastModifiedDate && moment.utc(transaction.lastModifiedDate).local().format("HH:mm")}
          </span>

                </Col>
              </Row>

              <div className='row'>
                <Button
                  htmlType='button'
                  type='info'
                  text='اصلاح'
                  onClick={() => update()}
                  loading={loading}
                />

                <Button
                  htmlType='button'
                  type='outlined'
                  text='بازگشت'
                  onClick={() =>
                      navigate(-1)
                  }
                />
              </div>
            </form>
          )}
        </div>
      </Spin>
    </>
  );
};
