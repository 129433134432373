import { FileSearchOutlined } from "@ant-design/icons";
import { Card } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import {
  Enum,
  FilterValues,
  ManualValidation,
  ManualValidationListItem,
  PaginationList,
} from "core/domains";
import {
  getAllSurveys,
  getSurveyStates,
  getSurveyTypes,
} from "core/repos/manual-validations";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import "./index.scss";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { Tooltip } from "antd";

const { useState, useRef, useEffect } = React;

const defaultStateItems: Enum[] = [
  {
    title: "همه",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "همه",
    id: 0,
  },
];

export const BankAccountsPending: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [cards, setCards] = useState<PaginationList<ManualValidation>>();
  const [pageSize, setPageSize] = useState<number>(10);

  const [types, setTypes] = useState<Enum[]>(defaultTypeItems);
  const [states, setStates] = useState<Enum[]>(defaultStateItems);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
  });

  useEffect(() => {
    !cards &&
      getAllSurveys(
        setCardLoading,
        setCards,
        getPageNum(),
        pageSize,
        "?entity=0&state=0"
      );
    states.length === 1 && getSurveyStates(setCardLoading, setStates);
    types.length === 1 && getSurveyTypes(setCardLoading, setTypes);
  }, []);

  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: ManualValidationListItem) =>
        row.fullName + row.phoneNumber,
      cell: (row: ManualValidationListItem) => (
        <div className='column-createdBy'>
          <span className='day'>{row.fullName + row.phoneNumber}</span>
          <p className=''>{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "نوع",
      id: "entity",
      selector: (row: ManualValidationListItem) => row.entity.title,
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row: ManualValidationListItem) => row.state.title,
      cell: (row: ManualValidationListItem) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 1
              ? "success"
              : row.state.id == 2
              ? "error"
              : "waiting"
          }
        />
      ),
    },
    {
      name: "زمان",
      id: "createdOn",
      selector: (row: ManualValidationListItem) => row.createdOn,
      cell: (row: ManualValidationListItem) => (
        <div className='column-date'>
          <span className='day'>
            {moment(row.createdOn).locale("fa").local().format("jYYYY/MM/DD")}
          </span>
          <span className='time'>
            {moment(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: ManualValidationListItem) => (
        <div className='actions'>
          <Link to={`/manual-validations/details/${record.id}`}>
            <Tooltip placement='top' title='جزئیات کارت بانکی'>
              <FileSearchOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      title='کارت بانکی در انتظار'
      className='vt-dashboard-bankaccounts-pending'
      blur={cardLoading}
    >
      <Table
        className='bankaccounts-pending-table'
        data={cards?.items}
        pagination={cards?.pagination}
        column={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Card>
  );
};
