import * as React from "react";
import "./dropdown.scss";
import { CaretDownOutlined } from "@ant-design/icons";
import useOnBlur from "utils/scripts/useOnBlur";
import { useRef, useState } from "react";
import { XpayComponent } from "core/domains";
import { Divider } from "antd";

interface DropdownItem {
  id: number;
  title: string;
  icon?: React.ReactElement;
}
interface Props extends XpayComponent {
  disabled?: boolean;
  onChangeHandle?: (value: string) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  items: DropdownItem[];
  defaultSelected?: DropdownItem;
  label?: string;
  dropDownButtonIcon?: React.ReactNode;
}

export const Dropdown: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedItemm, setSelectedItem] = useState<DropdownItem>(
    props.defaultSelected ? props.defaultSelected : props.items[0]
  );

  const dropdowRef = useRef<any>();

  useOnBlur(dropdowRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div
      className={`vt-dropdown ${props.className && props.className !== "" ? props.className : ""
        }`}
    >
      <span className='label'>{props.label}</span>
      <div onClick={() => setIsOpen(!isOpen)} className='dropdown-selector'>
        <a role='button'>
          {selectedItemm?.icon}
          <span> {selectedItemm?.title}</span>
        </a>
        {props.dropDownButtonIcon ? (
          props.dropDownButtonIcon
        ) : (
          <CaretDownOutlined />
        )}
      </div>
      <div
        ref={dropdowRef}
        className={`dropdown-items ${isOpen ? "" : "d-none"}`}
      >
        {/* <ul>
          {
            props.items &&
            props.items.length > 0 &&
            props.items
              .filter((i) => i.id !== selectedItemm.id)
              .map((item, idx, arr) => {
                return (
                  <>
                    <li
                      className={`dropdown-item ${item.id === selectedItemm?.id ? "selected" : ""
                        }`}
                      onClick={() => {
                        setSelectedItem(item);
                        props.onChangeHandle &&
                          props.onChangeHandle(item.title);
                        setIsOpen(false);
                      }}
                    >
                      {item.icon} <span>{item.title}</span>
                    </li>
                    {idx !== arr.length - 1 && <Divider />}
                  </>
                );
              })}
        </ul> */}
      </div>
    </div>
  );
};
