import { Button } from "components";
import * as React from "react";
import { ToIranCurrency, digitSeparator } from "utils/scripts/decimal";
import "./index.scss";
import { Link } from "react-router-dom";
import { Asset, AssetItem } from "core/domains/markets/asset";
import { WalletFilterValues } from "core/domains/wallet/wallet";
import { getIconPath } from "utils/scripts/getIconPath";
import { API } from "apis";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  responseValidator,
} from "utils/scripts/api";
import { message } from "antd";

type Props = {
  isPinned: boolean;
  asset?: AssetItem;
  device: "mobile" | "desktop";
  detailsHandle: (id: string) => any;
};

export const AssetCart: React.FC<Props> = ({
  asset,
  isPinned,
  device,
  detailsHandle,
}) => {
  return (
    <div
      className={`vt-wallet-asset-cart ${device} ${
        isPinned ? "pinned-coin" : ""
      }`}
    >
      {device === "mobile" ? (
        <div className='mobile-show'>
          <span className='asset-info'>
            <img src={getIconPath(asset?.symbol || "")} alt='' />
            <div className='coin-name'>
              <span>{asset?.name}</span>
              <span className='en'>{asset?.symbol.toLowerCase()}</span>
            </div>
          </span>

          <div className='stats'>
            {asset?.symbol !== "PM" && (
              <li className='amount-balance'>
                <span>موجودی شما :</span>
                <span>
                  {asset?.symbol === "IRT"
                    ? digitSeparator(asset.balance.toFixed(0))
                    : asset?.balance}{" "}
                  {asset?.symbol === "IRT" && " تومان"}
                </span>
              </li>
            )}

            {asset?.symbol === "PM" ||
            asset?.symbol === "USDT" ||
            asset?.symbol === "IRT" ? (
              <></>
            ) : (
              <li className='price'>
                <span> قیمت جهانی :</span>
                <span>{asset?.lastPrice}</span>
              </li>
            )}

            {asset?.symbol === "PM" ||
            asset?.symbol === "USDT" ||
            asset?.symbol === "IRT" ? (
              <></>
            ) : (
              <li className='irt-balance'>
                <span> معادل تومان موجودی :</span>
                <span>{ToIranCurrency(asset?.value)} تومان</span>
              </li>
            )}
          </div>

          <span className='actions'>
            {asset && (
              <Button
                className='detail-button'
                type={"info"}
                text={"جزئیات"}
                onClick={() => detailsHandle(asset?.id)}
              />
            )}
          </span>
        </div>
      ) : (
        <div className='desktop-show'>
          <span className='asset-info'>
            <img src={getIconPath(asset?.symbol || "")} alt='' />
            <div className='coin-name'>
              <span>{asset?.name}</span>
              <span className='en'>{asset?.symbol.toUpperCase()}</span>
            </div>
          </span>

          <div className='stats'>
            {asset?.symbol !== "PM" && (
              <li className='amount-balance'>
                <span>موجودی شما :</span>
                <span>
                  {asset?.symbol === "IRT"
                    ? digitSeparator(asset.balance.toFixed(0))
                    : asset?.balance}{" "}
                  {asset?.symbol === "IRT" && " تومان"}
                </span>
              </li>
            )}

            {asset?.symbol === "PM" ||
            asset?.symbol === "USDT" ||
            asset?.symbol === "IRT" ? (
              <></>
            ) : (
              <li className='price'>
                <span> قیمت جهانی :</span>
                <span>{asset?.lastPrice}</span>
              </li>
            )}

            {asset?.symbol === "PM" ||
            asset?.symbol === "USDT" ||
            asset?.symbol === "IRT" ? (
              <></>
            ) : (
              <li className='irt-balance'>
                <span> معادل تومان موجودی :</span>
                <span>{ToIranCurrency(asset?.value || 0)} تومان</span>
              </li>
            )}
          </div>

          <span className='actions'>
            {asset && (
              <Button
                className='detail-button'
                type={"info"}
                text={"جزئیات"}
                onClick={() => detailsHandle(asset?.id)}
              />
            )}
          </span>
        </div>
      )}
    </div>
  );
};
