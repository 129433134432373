import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface JibitSetting {
  secretKey?: string;
  widhtrawApiKey?: string;
  withdrawsecretKey?: string;
  cashInSecretKey?: string;
  cashInApiKey?: string;
}

export const JibitPaymentSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [jibitSettings, setJibitSettings] = React.useState<JibitSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getJibitSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.jibitPaymentSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setJibitSettings(response.value);
        }
      }
    );
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeJibitPaymentSettings, {
      ...jibitSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getJibitSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getJibitSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-jibit-payment-settings">
        <Card title={`تنظیمات درگاه پرداخت جیبیت`} className="gateway-item">
          <Input
            label="Secret Key"
            type="text"
            name="secret-key"
            value={jibitSettings?.secretKey}
            onChange={(e) =>
              setJibitSettings({
                ...jibitSettings,
                secretKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="Widthraw API Key"
            type="text"
            name="widthraw-api-key"
            value={jibitSettings?.widhtrawApiKey}
            onChange={(e) =>
              setJibitSettings({
                ...jibitSettings,
                widhtrawApiKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="Widthraw Secret Key"
            type="text"
            name="widthraw-secret-key"
            value={jibitSettings?.withdrawsecretKey}
            onChange={(e) =>
              setJibitSettings({
                ...jibitSettings,
                withdrawsecretKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="Cash In Api Key"
            type="text"
            name="Cash-Api-key"
            value={jibitSettings?.cashInApiKey}
            onChange={(e) =>
              setJibitSettings({
                ...jibitSettings,
                cashInApiKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="Cash In Secret Key"
            type="text"
            name="cash-secret-key"
            value={jibitSettings?.cashInSecretKey}
            onChange={(e) =>
              setJibitSettings({
                ...jibitSettings,
                cashInSecretKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              jibitSettings?.secretKey === "" ||
              jibitSettings?.withdrawsecretKey === "" ||
              jibitSettings?.widhtrawApiKey === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
