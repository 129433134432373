import { API } from "apis";
import { ReportsGateway } from "core/domains/reports-gateway/reports-gateway";
import { getViaAuth, responseValidator } from "utils/scripts/api";

export const getAllReportsGateway = (
    setLoader: any,
    setData: any,
    query?: any,
  ) => {
    setLoader(true);
    getViaAuth(API.dashboard.gateways + `${query || ''}`, {}).then(
      (response: any) => {
        setLoader(false);
        if (responseValidator(response.status)) {
            const gatewayReports: ReportsGateway[] = response.value;
            setData(gatewayReports);
        }
      }
    );
  };