import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import { __API } from "apis/api";
import { Button, Card } from "components";
import { Modal } from "components/modal/modal";
import { Table } from "components/table/table";
import { Role } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  del,
  get,
  getValidationErrors,
  hasValidationError,
  responseValidator,
} from "utils/scripts/api";
import "./roles.scss";

const { useState, useRef, useEffect } = React;

export const Roles: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [rolesList, setRolesList] = useState<Role[]>([]);

  const [removeModalIsOpen, setRemoveModalIsOpen] = useState<{
    state: boolean;
    record: Role | null;
  }>({
    state: false,
    record: null,
  });

  const getRoles = () => {
    setCardLoading(true);
    get(__API.roles.getAllRoles).then((response: any) => {
      if (responseValidator(response.status)) {
        setRolesList(response.data.value.items);
      }
    });
    setCardLoading(false);
  };

  const removeRole = () => {
    setLoading(true);
    removeModalIsOpen.state &&
      del(`${__API.roles.deleteRole}/${removeModalIsOpen.record?.id}`, {}).then(
        (response: any) => {
          if (responseValidator(response.status)) {
            setRemoveModalIsOpen({
              record: null,
              state: false,
            });
            message.success("نقش مورد نظر با موفقیت حذف شد.");
          }
        }
      );
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  const columns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row) => row?.title,
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row) => row?.description,
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: Role) => (
        <div className='actions'>
          <Link to={`edit/${record.id}`}>
            <Tooltip placement='top' title='اصلاح نقش'>
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip placement='top' title='حذف نقش'>
            <DeleteOutlined
              onClick={() =>
                setRemoveModalIsOpen({ record: record, state: true })
              }
            />
          </Tooltip>
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <Card
      tags={
        <Button
          text='ایجاد نقش'
          type='info'
          onClick={() => window.open("/users/roles/create", "_self")}
        />
      }
      title='مدیریت نقش ها'
      className='vt-dashboard-roles'
      blur={cardLoading}
    >
      <Modal
        isOpen={removeModalIsOpen.state}
        setIsOpen={(e: any) => setRemoveModalIsOpen(e)}
        className='remove-role-modal'
      >
        <div className='modal-box'>
          <div className='head'>
            <h4>آیا در حذف نقش مطمئنید؟</h4>
          </div>
          <div className='text'>
            <p>
              در صورت حذف شدن نقش، برای اضافه کردن مجدد نقش باید دوباره روند
              ایجاد نقش طی شود.
            </p>
          </div>
          <div className='buttons'>
            <Button
              type='info'
              text='بله مطمئنم'
              htmlType='button'
              onClick={() => removeRole()}
            />
            <Button
              type='outlined'
              text='بازگشت'
              onClick={() =>
                setRemoveModalIsOpen({ state: false, record: null })
              }
            />
          </div>
        </div>
      </Modal>

      {rolesList && rolesList.length > 0 && (
        <Table className='roles-table' data={rolesList} column={columns} />
      )}
    </Card>
  );
};
