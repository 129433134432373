import { Spin } from "antd";
import { Card } from "../../card/card";
import { getIconPath } from "../../../utils/scripts/getIconPath";
import { CopyOutlined } from "@ant-design/icons";
import { detect_bank } from "../../../utils/scripts/bankDetecter";
import moment from "jalali-moment";
import { Modal } from "../../modal/modal";
import * as React from "react";
import { Order } from "../../../core/domains";
import { TransactionDetailModalData } from "../transaction-data/transaction-modal-data";
import { OrderDetailModalData } from "../order-data/order-modal-data";
import { Transaction } from "../../../core/domains/transaction/transaction";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transactionItem?: Transaction;
  orderItem?: Order;
  modalLoading?: boolean;
}

export const TransactionOrderDetailModalDesktopContent: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  transactionItem,
  orderItem,
  modalLoading,
}) => {
  return (
    <Modal
      className="transaction-order-detail-modal"
      isOpen={isOpen}
      title={`جزئیات ${orderItem ? "سفارش" : transactionItem && "تراکنش"}`}
      closeAble
      setIsOpen={setIsOpen}
    >
      <Spin spinning={modalLoading ?? false}>
        <Card className="detail-card">
          {orderItem ? (
            <div className="content">
              <OrderDetailModalData orderItem={orderItem} />
            </div>
          ) : (
            transactionItem && (
              <div className="content">
                <TransactionDetailModalData transactionItem={transactionItem} loading={modalLoading || false}/>
              </div>
            )
          )}
        </Card>
      </Spin>
    </Modal>
  );
};
