import * as React from "react";
import "./sidebar-item.scss";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "core/redux/store";
import {
  setExpandedMenuItem,
  setMenuItem,
  setSidebar,
} from "core/redux/actions";
import { useAppDispatch } from "core/redux/hooks";
import { Link } from "react-router-dom";
import { Badge } from "components/badge/badge";
import { waitingCounts } from "core/domains";

const mapState = (state: RootState) => ({
  state: state,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface SidebarProps extends PropsFromRedux {
  id: number;
  title: string;
  icon: React.ReactNode;
  link: string;
  badge: any;
  filled: boolean;
  waitingCountBadge?: any;
  waitingCounts?: waitingCounts;
  childrens?: {
    id: number;
    title: string;
    link: string;
    itemKey?: string;
  }[];
}

const { useState } = React;

const SidebarItem: React.FC<SidebarProps> = ({
  id,
  state,
  badge,
  title,
  icon,
  link,
  childrens,
  filled,
  waitingCountBadge,
  waitingCounts,
}) => {
  const dispatch = useAppDispatch();

  var waitingCountKeys = waitingCounts && Object.keys(waitingCounts);
  var waitingCountValues = waitingCounts && Object.values(waitingCounts);

  return (
    <div
      className={`sidebar-item ${
        !childrens && state.menu === id ? "active" : ""
      } ${filled ? "filled" : ""}`}
    >
      <Link
        to={`${childrens ? "" : link}`}
        className='head'
        onClick={() => {
          !childrens
            ? dispatch(setMenuItem(id)) && dispatch(setSidebar("close"))
            : state.expandedMenu === id
            ? dispatch(setExpandedMenuItem(-1))
            : state.expandedMenu !== id
            ? dispatch(setExpandedMenuItem(id))
            : dispatch(setExpandedMenuItem(-1));
        }}
      >
        <div className='content'>
          {icon}
          <p className='title'>{title}</p>
          {badge}
          {waitingCountBadge}
        </div>

        {childrens && (
          <svg
            className={`${!childrens && state.menu === id ? "open" : ""} ${
              state.expandedMenu === id ? "expanded" : ""
            }`}
            width='10'
            height='12'
            viewBox='0 0 10 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.356857 4.869C0.411469 4.811 0.617674 4.563 0.809755 4.359C1.93588 3.076 4.87359 0.976 6.41119 0.335C6.6447 0.232 7.23507 0.014 7.55049 0C7.85274 0 8.14086 0.072 8.4158 0.218C8.75854 0.422 9.03347 0.743 9.18413 1.122C9.28111 1.385 9.43176 2.172 9.43176 2.186C9.58241 3.047 9.66527 4.446 9.66527 5.992C9.66527 7.465 9.58241 8.807 9.45907 9.681C9.44494 9.695 9.29429 10.673 9.12952 11.008C8.82727 11.62 8.2369 12 7.60511 12H7.55049C7.13903 11.985 6.27372 11.605 6.27372 11.591C4.81898 10.949 1.94906 8.952 0.795631 7.625C0.795631 7.625 0.470788 7.284 0.329551 7.071C0.109223 6.765 0 6.386 0 6.007C0 5.584 0.123346 5.19 0.356857 4.869Z'
              fill='white'
            />
          </svg>
        )}
      </Link>
      {childrens && state.expandedMenu === id && (
        <ul className={`item-body`}>
          {childrens?.map((item) => {
            return (
              <Link to={item.link}>
                <li
                    className={`${state.menu === item.id ? "active" : ""}`}
                    onClick={() => {
                      dispatch(setMenuItem(item.id));
                      dispatch(setSidebar("close"));
                    }}
                >
                  {item.title}
                  {waitingCounts && item.itemKey && (
                      <Badge
                        style={{"fontSize":"15px"}}
                      type='waiting'
                      text={
                        waitingCountValues?.filter(
                          (i, index) =>
                            waitingCountKeys &&
                            waitingCountKeys.filter(
                              (a, idx) => a === item.itemKey && index === idx
                            )[0]
                        )[0]
                      }
                    />
                  )}
                </li>
              </Link>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default connector(SidebarItem);
