import { SERVER_URL } from "apis/constants";
import moment from "moment";
import { Response } from "../../core/domains/interfaces/index";
import axios from "axios";
import { API } from "../../apis";
import { message as messageToast } from "antd";

export const SERVER = SERVER_URL;
const expireToken = 60 * 12000000008;
const expireRef = 12000000008 * 100;

export async function isLogin() {
  try {
    if (localStorage.getItem("tokenDate"))
      if (
        localStorage.getItem("tokenDate") &&
        Math.abs(
          moment(localStorage.getItem("tokenDate")).diff(
            moment.utc(),
            "seconds"
          )
        ) < expireToken
      )
        return true;
      else {
        await axios
          .get(SERVER + `${API.token}/${localStorage.getItem("refToken")}`)
          .then((res) => {
            localStorage.setItem("refToken", res.data.data.refresh_token);
            localStorage.setItem("accessToken", res.data.data.access_token);
            localStorage.setItem("tokenDate", moment.utc().toString());
          })
          .catch((err) => {
            window.location.href = "/signin";
          });
        return true;
      }
  } catch {
    return false;
  }
}
function generateHeader(object?: any) {
  const accessToken = localStorage.getItem("accessToken");
  const header: any = {};
  if (accessToken && accessToken.length > 0) {
    header["Authorization"] = "Bearer " + accessToken;
  }
  for (const key of Object.keys(object)) {
    header[key] = object[key];
  }
  return header;
}

export async function del(url: string, body: any) {
  await isLogin();
  let status: number;
  return new Promise((resolve, reject) => {
    fetch(SERVER + url, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: generateHeader({ "Content-Type": "application/json" }),
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function post(url: string, body: any) {
  await isLogin();

  let status: number;
  return new Promise((resolve, reject) => {
    fetch(SERVER + url, {
      method: "POST",
      headers: generateHeader({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function form(url: string, body: any) {
  await isLogin();

  let status: number;
  return new Promise((resolve, reject) => {
    fetch(SERVER + url, {
      method: "POST",
      body: body,
      headers: generateHeader(),
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function put(url: string, body: any) {
  await isLogin();

  let status: number;
  return new Promise((resolve, reject) => {
    fetch(SERVER + url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: generateHeader({ "Content-Type": "application/json" }),
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function patch(url: string, body: any) {
  await isLogin();

  let status: number;
  return new Promise((resolve, reject) => {
    fetch(SERVER + url, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: generateHeader({ "Content-Type": "application/json" }),
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function get(url: string, params: any = {}) {
  await isLogin();

  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  return new Promise((resolve, reject) => {
    fetch(generatedUrl.href, {
      method: "GET",
      headers: generateHeader({ "Content-Type": "application/json" }),
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function postViaAuth<R>(
  url: string,
  body: any,
  fromForm: boolean = false
): Promise<Response<R | null>> {
  await isLogin();

  let status: number;
  let message: string;
  const header =
    fromForm === true
      ? await generateHeader({})
      : await generateHeader({ "Content-Type": "application/json" });

  return new Promise((resolve, reject) => {
    fetch(SERVER + url, {
      method: "POST",
      headers: header,
      body: fromForm === true ? body : JSON.stringify(body),
    })
      .then((response: any) => {
        status = response.status;
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;

        handleErrors(data);
        resolve(data);
      })
      .catch((err) => {
        message = " خطا در برقراری ارتباط با سرور";
        resolve({ value: null, message, status });
      });
  });
}

function handleErrors(data: any) {
  if (!responseValidator(data.status)) {
    let message = data.message;
    if (hasValidationError(data.status) && data.errors != null) {
      var validationError = false;
      getValidationErrors(data.errors).map((error) => {
        messageToast.error(error);
        validationError = true;
      });
      if (!validationError) messageToast.error(message);
    } else if (data.status === 401) window.location.replace("/signin/");
    else if (data.status === 403) messageToast.error("دسترسی غیر مجاز .");
    else if (data && data.status && data.status != 200)
      messageToast.error(message);
  }
}

export function responseValidator(status: number) {
  return status >= 200 && status < 300;
}

export async function getViaAuth<R>(
  url: string,
  params: { [k: string]: any } = {}
): Promise<Response<R | null>> {
  await isLogin();

  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  let message: string;
  const header = await generateHeader({ "Content-Type": "application/json" });
  return new Promise((resolve, reject) => {
    fetch(generatedUrl.href, {
      method: "GET",
      headers: header,
    })
      .then((response: any) => {
        status = response.status;

        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;
        handleErrors(data);
        resolve(data);
      })
      .catch((err) => {
        message = " خطا در برقراری ارتباط با سرور";
        messageToast.error(message);
        resolve({ value: null, message, status });
      });
  });
}

export async function getGeneric<R>(
  url: string,
  params: { [k: string]: any } = {}
): Promise<Response<R | null>> {
  await isLogin();

  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  let message: string;

  const header: { [k: string]: any } = {};
  header["Content-Type"] = "application/json";
  return new Promise((resolve, reject) => {
    fetch(generatedUrl.href, {
      method: "GET",
      headers: header,
    })
      .then((response: any) => {
        status = response.status;

        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;
        handleErrors(data);
        resolve(data);
      })
      .catch((err) => {
        message = " خطا در برقراری ارتباط با سرور";
        resolve({ value: null, message, status });
      });
  });
}

export function hasValidationError(status: number) {
  return status == 400;
}

export function getValidationErrors(errors: any): any[] {
  let list = [];

  for (let key in errors) {
    let value = errors[key];
    list.push(value);
  }

  return list;
}

export function isNotFound(status: number) {
  return status == 404;
}
