import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "components";
import "./google-auth.scss";
import * as React from "react";
import ReactCodeInput from "react-verification-code-input";
import { SettingGoogleAuthType } from "core/domains";

type Props = {
  submitData: (e: string) => any;
  data?: SettingGoogleAuthType;
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  setData: React.Dispatch<SettingGoogleAuthType>;
  loading: boolean;
};

export const SettingGoogleAuth: React.FC<Props> = ({
  submitData,
  data,
  isOpen,
  setIsOpen,
  setData,
  loading,
}) => {
  return (
    <Modal
      closeAble
      title={"کد تایید Google Auth"}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className='create-role-modal'
      onClose={() =>
        setData({
          code: "",
          error: undefined,
        })
      }
    >
      <div className='google-auth code app-code'>
        <ReactCodeInput
          className={`input `}
          fieldWidth={42}
          values={data?.code ? data?.code?.split("") : []}
          autoFocus={true}
          onComplete={(e) => {
            !loading && submitData(e);
          }}
          onChange={(value: any) => {
            value != "" &&
              setData({
                ...data,
                code: value,
              });
          }}
        />
        <div className='stats'>
          <div className='errors'>
            {data?.error && (
              <>
                <QuestionCircleOutlined />
                <span>{data?.error}</span>
              </>
            )}
          </div>
        </div>
      </div>

      <Button
        type='info'
        text='تایید'
        onClick={() => data?.code && !loading && submitData(data.code)}
        disabled={!loading && data?.code?.length != 6}
        loading={loading}
      />
    </Modal>
  );
};
