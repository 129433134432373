import * as React from "react";
import {
  CheckOutlined,
  DollarCircleOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Divider, Dropdown, Menu, MenuProps, Tooltip } from "antd";
import { Button, Calendar, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import { Enum, Market, PaginationList, SortDirection } from "core/domains";
import {
  Transaction,
  TransactionState,
  TransactionStateLabel,
} from "core/domains/transaction/transaction";
import { getAllMarkets } from "core/repos/market";
import {
  editTransactionState,
  getAllCryptoTransactions,
  getTransactionTypes,
  payoutTranscation,
} from "core/repos";
import { CryptoTransactionModal } from "routes/dashboard/transactions/modals/crypto";
import { getIconPath } from "../../../../utils/scripts/getIconPath";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import TextArea from "antd/lib/input/TextArea";

type Props = {
  markets?: Market[];
};

export interface FilterValues {
  startDate?: string;
  endDate?: string;
  sort?: SortDirection;
  type: Enum;
  market?: string;
  keywords?: string;
}

const defaultTypeItems: Enum[] = [
  {
    title: "نوع تراکنش",
    id: 0,
  },
];
const { useState } = React;

export const CryptoTransactionAction: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [transactions, setTransactions] =
    useState<PaginationList<Transaction>>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
  });
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<Transaction>();
  const [markets, setMarkets] = useState<Market[]>();
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState<boolean>(false);
  const [approveModalIsOpen, setApproveModalIsOpen] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [payoutLoading, setPayoutLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalTargetState, setModalTargetState] = useState<TransactionState>(
    TransactionState.WaitingForAdmin
  );

  const closePayoutModal = () => setPayoutModalIsOpen(false);

  const openPayoutModal = (item: Transaction) => {
    setSelectedItem(item);
    setPayoutModalIsOpen(true);
  };
  const openConfirmModal = (item: Transaction, state: TransactionState) => {
    setSelectedItem(item);
    setModalTargetState(state);
    setApproveModalIsOpen(true);
  };

  const closeAppproveModal = () => {
    setDescriptionValue("");
    setApproveModalIsOpen(false);
  };

  const [transactionTypes, setTransactionTypes] = useState<Enum[]>([
    {
      id: 0,
      title: "همه",
    },
  ]);

  React.useEffect(() => {
    !markets && getAllMarkets(setCardLoading, setMarkets, getPageNum(), 10, "");
    !transactions &&
      getAllCryptoTransactions(
        setCardLoading,
        setTransactions,
        getPageNum(),
        pageSize,
        query
      );
    transactionTypes?.length === 1 &&
      getTransactionTypes(setCardLoading, setTransactionTypes);
  }, []);

  const onTypeClick: MenuProps["onClick"] = ({ key }) => {
    setFilterValues({
      ...filterValues,
      type: transactionTypes?.filter((t) => t.id === parseInt(key))[0],
    });
  };

  async function onApproveHandler() {
    selectedItem &&
      (await editTransactionState(
        setApproveLoading,
        selectedItem,
        closeAppproveModal,
        modalTargetState,
        (modalTargetState === TransactionState.Succeed ||
          modalTargetState === TransactionState.Cancel) &&
          descriptionValue !== ""
          ? descriptionValue
          : undefined
      ));

    getAllCryptoTransactions(
      setCardLoading,
      setTransactions,
      getPageNum(),
      pageSize,
      query
    );
  }

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "50px",
    },
    {
      name: "شناسه",
      id: "trackingCode",
      selector: (row: Transaction) => row.trackingCode,
      cell: (row: Transaction) => (
        <span className="en">{row.trackingCode}</span>
      ),
    },
    {
      name: "نام ارز",
      id: "market",
      selector: (row: Transaction) => row.market.name,
      cell: (row: Transaction) => (
        <>
          <img src={getIconPath(row.market.symbol)} />
          <span className="en">{row.market.symbol}</span>
        </>
      ),
    },
    {
      name: "توسط",
      id: "createdBy",
      selector: (row: Transaction) => row.createdBy,
      cell: (row: Transaction) => (
        <div className="column-createdBy">
          <span className="day">{row.createdBy}</span>
          <p className="">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "نوع تراکنش",
      id: "type",
      selector: (row: Transaction) => row.type.title,
    },
    {
      name: "مقدار",
      id: "amount",
      selector: (row: Transaction) => row.amount,
      cell: (row: Transaction) => <>{row.amount}</>,
    },
    {
      name: "زمان",
      id: "createdAt",
      selector: (row: Transaction) => row.createdAt,

      cell: (row: Transaction) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row: Transaction) => row.state.id,
      cell: (row: Transaction) => (
        <Badge
          text={row.state.id == 2 ? "موفق" : row.state.title}
          type={
            row.state.id == 2
              ? "success"
              : row.state.id == 6
              ? "waiting"
              : "error"
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? "success"
              : row.state.id == 6
              ? "waiting"
              : "danger"
          }.svg`}
        />
      ),
      width: "200px",
    },
    {
      name: "جزئیات",
      id: "detail",
      cell: (row: Transaction) => (
        <div className="actions">
          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"تایید و تسویه تراکنش"}>
              <DollarCircleOutlined onClick={() => openPayoutModal(row)} />
            </Tooltip>
          )}
          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"تایید تراکنش"}>
              <CheckOutlined
                onClick={() => openConfirmModal(row, TransactionState.Succeed)}
              />
            </Tooltip>
          )}

          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"لغو تراکنش"}>
              <StopOutlined
                onClick={() => openConfirmModal(row, TransactionState.Cancel)}
              />
            </Tooltip>
          )}
          <Tooltip placement="top" title={"جزئیات تراکنش"}>
            <InfoCircleOutlined onClick={() => handleModal(row)} />
          </Tooltip>
          <Tooltip placement="top" title={"ویرایش تراکنش"}>
            <EditOutlined
              onClick={() =>
                window.open(`/transactions/edit/${row.id}`, "_self")
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }

  var query = "?state=6&isNotCurrency=true";
  const filterHandle = () => {
    setLoading(true);
    if (filterValues?.type?.id !== 0)
      query += `&type=${filterValues?.type?.id}`;
    if (filterValues?.startDate)
      query += `&startDate=${convertFaToEnCalendar(filterValues?.startDate)}`;
    if (filterValues?.endDate)
      query += `&endDate=${convertFaToEnCalendar(filterValues?.endDate)}`;
    if (filterValues?.keywords) query += `&keywords=${filterValues?.keywords}`;
    getAllCryptoTransactions(setLoading, setTransactions, 1, 25, query);
  };

  return (
    <div className="crypto-transactions-action">
      {selectedItem && (
        <CryptoTransactionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          item={selectedItem}
          loading={false}
        />
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setApproveModalIsOpen}
          isOpen={approveModalIsOpen}
          title={`از تغییر وضعیت به  "${TransactionStateLabel.get(
            modalTargetState
          )}" تراکش   ${selectedItem.trackingCode}   مطمئن هستید؟`}
          closeAble
          className="transaction-approve-modal"
          onClose={closeAppproveModal}
        >
          {(modalTargetState === TransactionState.Succeed ||
            modalTargetState === TransactionState.Cancel) && (
            <TextArea
              name="description"
              placeholder="توضیحات را وارد کنید"
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={descriptionValue}
            />
          )}
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={approveLoading}
              disabled={approveLoading}
              htmlType="button"
              onClick={(e) => onApproveHandler()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closeAppproveModal()}
            />
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setPayoutModalIsOpen}
          isOpen={payoutModalIsOpen}
          closeAble
          title="آیا از تسویه تراکنش مطمین هستید؟"
          className="transaction-payout-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={payoutLoading}
              disabled={payoutLoading}
              htmlType="button"
              onClick={async (e) => {
                await payoutTranscation(
                  setPayoutLoading,
                  selectedItem,
                  closePayoutModal
                );
                getAllCryptoTransactions(
                  setLoading,
                  setTransactions,
                  getPageNum(),
                  25,
                  query
                );
              }}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closePayoutModal()}
            />
          </div>
        </Modal>
      )}
      <Card
        title="واریز و برداشت ارزی در انتظار تایید"
        blur={cardLoading}
        className="desktop-show"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues?.keywords}
              placeholder="جستجو کنید ..."
              className="search"
              name="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {transactionTypes &&
                    transactionTypes.map((type) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, type })
                        }
                      >
                        {type.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.type?.title}
              />
            </Dropdown>

            <Calendar
              placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues?.endDate}
              placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>

          <Divider type="vertical" />
          <div className="buttons">
            <Button
              loading={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={filterHandle}
            />
            <span
              onClick={() => {
                setCardLoading(true);
                setFilterValues({
                  keywords: "",
                  startDate: "",
                  endDate: "",
                  type: defaultTypeItems[0],
                });
                setTransactions(transactions);
                getAllCryptoTransactions(
                  setCardLoading,
                  setTransactions,
                  getPageNum(),
                  pageSize,
                  "?state=6&isCrypto=true"
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {transactions && (
          <Table
            getData={getAllCryptoTransactions}
            setData={setTransactions}
            setLoader={setCardLoading}
            blur={cardLoading}
            pagination={transactions.pagination}
            data={transactions.items}
            column={columns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            conditionalRowStyles={[
              {
                when: (row: any) =>
                  row.state.id === TransactionState.ManualDoing,
                style: {
                  backgroundColor: "#fac89861",
                },
              },
            ]}
          />
        )}
      </Card>
    </div>
  );
};
