import {__API} from "apis/api";
import {Card} from "components";
import {Table} from "components/table/table";
import * as React from "react";
import {TableColumn} from "react-data-table-component";
import {getViaAuth, responseValidator,} from "utils/scripts/api";
import "./detail.scss";
import {digitSeparator} from "../../../../utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

export const WalletsDetail: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<any[]>([]);

  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: any) => row.fullName,
      cell: (row: any) => <>{row.user?.firstName + " " + row.user?.lastName}</>,
    },
    {
      name: "نام کاربری",
      id: "userName",
      selector: (row: any) => row.user?.userName,
    },
    {
      name: "موجودی",
      id: "balance",
      selector: (row: any) => digitSeparator(row.available),
    },
  ];
  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const _symbol = urlSections[urlSections.length - 1];
  useEffect(() => {
    (() =>
      getViaAuth(`${__API.wallet.getAllBySymbol}/${_symbol}`).then(
        (response: any) => {
          if (responseValidator(response.status))
            setUsersList(response.value.filter((i: any) => i.available !== 0));

        }
      ))();
  }, []);


  return (
      <Card
          blur={loading}
          title={`لیست موجودی همه کاربران ارز  ${_symbol}`}
          className='vt-dashboard-wallet-detail'
      >
        {usersList &&
            <p> جمع کل : {digitSeparator(usersList.reduce((sum, current) => sum + current.available, 0))}</p>
        }

        {usersList && usersList.length > 0 && (
            <Table className='users-table' data={usersList} column={columns}/>
        )}
      </Card>
  );
};
