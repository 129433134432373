import { API } from "apis";
import { message } from "antd";
import { ManualValidation } from "core/domains";
import { NavigateFunction } from "react-router-dom";
import {
  del,
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { ReferralLevel } from "../domains/referral/referral";

export const getManualValidationDetail = (
  setLoader: any,
  setResponse: any,
  id: string
) => {
  setLoader(true);
  getViaAuth(API.manualValidation.details + `?Id=${id}`).then(
    (response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        setResponse(response.value);
      }
    }
  );
};

export const getLevelByIdReferral = async (
  setloader: any,
  setdata: any,
  id: string
) => {
  setloader(true);
  await getViaAuth(API.referral.getLevelById + `/${id}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getAllReferralActions = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(API.referral.getAllActions + `/${page}/${size}${query}`, {}).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getAllReferralLevels = (
  setloader: any,
  setdata: any,
  setSelectedOption?: (levels: ReferralLevel[]) => void
) => {
  setloader(true);
  getViaAuth(API.referral.getAllLevels).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
      setSelectedOption && setSelectedOption(response.data);
    }
  });
};

export const getAllReferralInfos = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(
    API.referral.getAllReferralInfos + `/${page}/${size}${query}`,
    {}
  ).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateLevelReferral = (setloader: any, level: any) => {
  setloader(true);
  postViaAuth(API.referral.editLevel, level).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("سطح مورد نظر با موفقیت اصلاح شد.");
      window.open("/referral/levels/", "_self");
    }
  });
};
export const deleteLevelReferral = (
  setloader: any,
  id: number,
  onComplete: any
) => {
  setloader(true);
  del(API.referral.deleteLevel + `/${id}`, { id: id }).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("سطح مورد نظر با موفقیت حذف شد.");
      onComplete();
    }
  });
};

export const addLevelReferral = (setloader: any, level: any) => {
  setloader(true);
  postViaAuth(API.referral.addLevel, level).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("سطح مورد نظر با موفقیت افزوده شد.");
      window.open("/referral/levels/", "_self");
    }
  });
};
