import { API } from "apis";
import { message } from "antd";
import { Bonus } from "core/domains";
import { NavigateFunction } from "react-router-dom";
import {
  del,
  getViaAuth,
  postViaAuth,
  put,
  responseValidator,
} from "utils/scripts/api";
import { getPageNum } from "../../utils/scripts/paggingHelper";

export const getAllBonuses = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(API.bonuses.getAll + `/${page}/${size}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getBonusById = (setloader: any, setdata: any, id: string) => {
  setloader(true);
  getViaAuth(API.bonuses.details + `/${id}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getBonusTypes = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.enums.bonusTypes).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateBonus = (setcardloader: any, data: any, navigate: any) => {
  setcardloader(true);
  put(API.bonuses.update, data).then((response: any) => {
    setcardloader(false);
    if (responseValidator(response.status)) {
      message.success("پاداش مورد نظر با موفقیت اصلاح شد.");
      navigate("/bonuses");
    }
  });
};
