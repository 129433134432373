import "./index.scss";
import React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "utils/scripts/decimal";
import { message } from "antd";
import { Button, Card, Input } from "components";

interface CryptoWithdrawalCommissionSetting {
  usdt?: number;
  trx?: number;
}

export const CryptoWithdrawalCommissionSettings = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [
    cryptoWithdrawCommissionSettings,
    setCryptoWithdrawCommissionSettings,
  ] = React.useState<CryptoWithdrawalCommissionSetting>();

  const getCryptoWithdrawCommissionSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.cryptoWithdrawalCommission).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setCryptoWithdrawCommissionSettings(response.value);
        }
      }
    );
  };

  const onUpdateCryptoWithdrawCommissionsHandler = () => {
    setLoading(true);
    put(API.settings.changeCryptoWithdrawalCommission, {
      usdt: parseFloat(
        cryptoWithdrawCommissionSettings?.usdt
          ? deleteCommaSeparator(cryptoWithdrawCommissionSettings?.usdt)
          : 0
      ),
      trx: parseFloat(
        cryptoWithdrawCommissionSettings?.trx
          ? deleteCommaSeparator(cryptoWithdrawCommissionSettings?.trx)
          : 0
      ),
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getCryptoWithdrawCommissionSettings();
      } else {
        message.error(response.data.message);
      }
    });
  };

  React.useEffect(() => {
    getCryptoWithdrawCommissionSettings();
  }, []);

  return (
    <>
      <Card
        title="کارمزد های برداشت رمزارزی"
        blur={cardLoading}
        className="vt-dashboard-crypto-withdrawal-commission-settings"
      >
        <Card className="delay-item">
          <Input
            label="کارمزد برداشت تتر"
            type="text"
            name="crypto-withdrawal-comission-usdt"
            value={digitSeparator(
              cryptoWithdrawCommissionSettings?.usdt || "0"
            )}
            onChange={(e) =>
              setCryptoWithdrawCommissionSettings({
                ...cryptoWithdrawCommissionSettings,
                usdt: digitSeparator(faNumToEnNum(e.target.value)) || 0,
              })
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="کارمزد برداشت ترون"
            type="text"
            name="crypto-withdrawal-comission-trx"
            value={digitSeparator(cryptoWithdrawCommissionSettings?.trx || "0")}
            onChange={(e) =>
              setCryptoWithdrawCommissionSettings({
                ...cryptoWithdrawCommissionSettings,
                trx: digitSeparator(faNumToEnNum(e.target.value)) || 0,
              })
            }
            dir="ltr"
            maxLength={32}
          />
          <Button
            htmlType="button"
            type="info"
            onClick={() => onUpdateCryptoWithdrawCommissionsHandler()}
            loading={loading}
            disabled={loading}
            text={"ثبت تغییرات"}
          />
        </Card>
      </Card>
    </>
  );
};
