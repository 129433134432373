import * as React from "react";
import {Button, Input} from "components";
import "./add.scss";
import {Spin} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {deleteCommaSeparator, digitSeparator, faNumToEnNum,} from "utils/scripts/decimal";
import {addLevelReferral,} from "core/repos/referral";
import {ReferralLevelAdd} from "core/domains/referral/referral";
import {isNumeric} from "../../../../../utils/scripts/exchange";

const { useState, useEffect } = React;

export const AddLevelReferral: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [level, setLevel] = useState<ReferralLevelAdd>();
  const location = useLocation();

  function onNumChangeHandle(value: string, field: string) {
    if (value !== "") {
      value = faNumToEnNum(deleteCommaSeparator(value));
    }

    if (
      value !== "" &&
      !isNumeric(value.split("")[value.split("").length - 1])
    ) {
      return;
    }

    setLevel({
      ...level,
      [field]: value,
    });
  }

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const navigate = useNavigate();

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-levels-add-referral">
          <h3>رفرال- افزودن سطح</h3>

          <form>
            <Input
                type="number"
                label="شماره سطح (عدد)"
              name="referralLevelUniqueName"
              value={level?.referralLevelUniqueName?.toString()}
              onChange={(e) =>
                onNumChangeHandle(e.target.value, "referralLevelUniqueName")
              }
            />
            <Input
              type="text"
              label="عنوان"
              name="title"
              value={level?.title}
              onChange={(e) => setLevel({ ...level, title: e.target.value })}
            />

            <Input
              type="text"
              label="حداقل درآمد جهت ورود (تومان)"
              name="minimumAmountEnter"
              value={digitSeparator(level?.minimumAmountEnter?.toString())}
              onChange={(e) =>
                onNumChangeHandle(e.target.value, "minimumAmountEnter")
              }
            />
            <Input
              type="text"
              label="درصد پاداش "
              name="rewardPercent"
              value={level?.rewardPercent?.toString()}
              onChange={(e) =>
                onNumChangeHandle(e.target.value, "rewardPercent")
              }
            />

            <TextArea
              name="description"
              value={level?.description}
              onChange={(e) =>
                setLevel({ ...level, description: e.target.value })
              }
            />

            <div className="buttons">
              <Button
                htmlType="button"
                type="info"
                text="افزودن"
                onClick={() =>
                  addLevelReferral(setLoading, {
                    ...level,
                    minimumAmountEnter: parseInt(
                      deleteCommaSeparator(level?.minimumAmountEnter)
                    ),
                    rewardPercent: parseInt(
                      level?.rewardPercent?.toString() || "0"
                    ),
                    referralLevelUniqueName: parseInt(
                      level?.referralLevelUniqueName?.toString() || ""
                    ),
                  } as ReferralLevelAdd)
                }
                disabled={
                  !level?.title ||
                  !level.minimumAmountEnter ||
                  !level.rewardPercent ||
                  !level.referralLevelUniqueName ||
                  loading
                }
                loading={loading}
              />

              <Button
                htmlType="button"
                type="outlined"
                text="بازگشت"
                onClick={() => navigate("/referral/levels/")}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
