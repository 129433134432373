import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import { BlackList, BlackListList } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss";
import { Tooltip } from "antd";
import { deleteBlackList, getAllBlackLists } from "core/repos/black-list";
import moment from "jalali-moment";
import { getPageNum } from "../../../utils/scripts/paggingHelper";

const { useState, useEffect } = React;

export const BlackListManagement: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [blacklistData, setBlackList] = useState<BlackListList>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const navigate = useNavigate();

  useEffect(() => {
    !blacklistData &&
      getAllBlackLists(
        setCardLoading,
        setBlackList,
        getPageNum(),
        pageSize,
        ""
      );
  }, []);

  // const onFilterHandler = () => {
  //   var query = "";
  //   if (searchValue !== "") {
  //     query += `${query.includes("?") ? "&" : "?"}keywords=${searchValue}`;
  //   }
  //   getAllBlackList(setCardLoading, setBlackList, page, pageSize, query);
  // };

  const columns: TableColumn<BlackList>[] = [
    {
      name: "نوع",
      id: "type",
      selector: (row) => row?.type?.title || "",
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row) => row?.description || "",
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row) => row.enabled || false,
      cell: (row) => (
        <Badge
          text={row.enabled ? "فعال" : "غیر فعال"}
          type={row.enabled ? "success" : "error"}
        />
      ),
    },
    {
      name: "مقدار",
      id: "value",
      selector: (row) => row.value || "",
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row) => row?.createdOn || "",
      cell: (row) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdOn).toDate().toLocaleDateString("fa-IR")}
          </span>
          <span className="time">
            {moment(row.createdOn).toDate().toLocaleTimeString("fa-IR")}
          </span>
        </div>
      ),
    },
    // {
    //   name: "قابلیت خرید",
    //   id: "isBuyActive",
    //   selector: (row: any) => row.isBuyActive,
    //   cell: (row) => (
    //     <Switch
    //       checked={row.isBuyActive ? true : false}
    //       onChange={(checked) =>
    //         updateBlackList(setCardLoading, setBlackList, {
    //           ...row,
    //           isBuyActive: checked,
    //           id: row.id,
    //         })
    //       }
    //     />
    //   ),
    // },
    // {
    //   name: "قابلیت فروش",
    //   id: "isSellActive",
    //   selector: (row: any) => row.isSellActive,
    //   cell: (row) => (
    //     <Switch
    //       checked={row.isSellActive ? true : false}
    //       onChange={(checked) => [
    //         console.log(row),
    //         updateBlackList(setCardLoading, setBlackList, {
    //           ...row,
    //           isSellActive: checked,
    //           id: row.id,
    //         }),
    //       ]}
    //     />
    //   ),
    // },
    {
      name: "عملیات",
      id: "action",
      cell: (record) => (
        <div className="actions">
          <Link to={`edit/${record.id}`}>
            <Tooltip placement="top" title="اصلاح بلک لیست آیتم">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Link
            to={``}
            onClick={() =>
              deleteBlackList(setCardLoading, record.id, setBlackList)
            }
          >
            <Tooltip placement="top" title="حذف بلک لیست آیتم">
              <DeleteOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Card
        blur={cardLoading}
        title="مدیریت لیست سیاه"
        className="vt-dashboard-blacklistData"
      >
        <div className="row">
          <Button
            type="primary"
            text="افزودن بلک آیتم"
            icon={<PlusOutlined />}
            onClick={() => navigate("create")}
          />
        </div>
        {blacklistData && (
          <Table
            blur={cardLoading}
            setLoader={setCardLoading}
            getData={getAllBlackLists}
            setData={setBlackList}
            pagination={blacklistData.pagination}
            className="black-list-datatable"
            data={blacklistData.items}
            column={columns}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Card>
    </>
  );
};
