import * as React from "react";
import "./sliders.scss";
import { Button, Card, Modal } from "components";
import { Table } from "components/table/table";
import { Slider } from "core/domains/slider/slider";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  FileSearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TableColumn } from "react-data-table-component";
import { deleteSlider, getAllSliders } from "core/repos/slider";
import { PaginationList } from "core/domains";
import { getPageNum } from "utils/scripts/paggingHelper";
import { Tag, Tooltip } from "antd";
import { Badge } from "../../../components/badge/badge";

export const Sliders: React.FC = () => {
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [sliders, setSliders] = React.useState<PaginationList<Slider>>();
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [selectedItem, setSelectedItem] = React.useState<Slider>();
  const [removeModalIsOpen, setRemoveModalIsOpen] =
    React.useState<boolean>(false);
  const [removeButtonLoading, setRemoveButtonLoading] =
    React.useState<boolean>(false);
  const onRemoveIconClick = (item: Slider) => {
    setSelectedItem(item);
    setRemoveModalIsOpen(true);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    !sliders &&
      getAllSliders(setCardLoading, setSliders, getPageNum(), pageSize, "");
  }, []);

  const columns: TableColumn<Slider>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row) => row?.title,
    },

    {
      name: "نوع اسلایدر",
      id: "type",
      selector: (row: Slider) => row.type.title,
      cell: (row: Slider) => (
        <>
          <Tag color={`${"green"}`}>{row.type.title}</Tag>
        </>
      ),
    },
    {
      name: "وضعیت",
      id: "isActive",
      selector: (row) => row?.isActive,
      cell: (record) => (
        <Badge
          text={record.isActive ? "فعال" : "غیر فعال"}
          type={record.isActive ? "success" : "error"}
        />
      ),
    },
    {
      name: "عملیات",
      id: "actions",
      cell: (record) => (
        <div className="actions">
          <Link to={`edit/${record.id}`}>
            <Tooltip placement="top" title="اصلاح اسلایدر">
              <FileSearchOutlined />
            </Tooltip>
          </Link>
          <Tooltip placement="top" title="حذف اسلایدر">
            <DeleteOutlined onClick={() => onRemoveIconClick(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="اسلایدر ها"
      className="vt-dashboard-sliders"
      blur={cardLoading}
    >
      {selectedItem && (
        <Modal
          title={"آیا از حذف اسلایدر اطمینان دارید؟"}
          isOpen={removeModalIsOpen}
          setIsOpen={setRemoveModalIsOpen}
          className="delete-slider-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              loading={removeButtonLoading}
              disabled={removeButtonLoading}
              onClick={() =>
                deleteSlider(setRemoveButtonLoading, selectedItem.id, () => {
                  setRemoveModalIsOpen(false);
                  getAllSliders(
                    setCardLoading,
                    setSliders,
                    getPageNum(),
                    pageSize,
                    ""
                  );
                })
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setRemoveModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      <div className="row">
        <Button
          type="primary"
          text="افزودن اسلایدر"
          icon={<PlusOutlined />}
          onClick={() => navigate("add")}
        />
      </div>
      {sliders && (
        <Table
          className="slider-sliders-table"
          data={sliders.items}
          column={columns}
          pagination={sliders.pagination}
          getData={getAllSliders}
          setData={setSliders}
          setLoader={setCardLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
