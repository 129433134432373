import * as React from "react";
import "./security-settings.scss";
import { Divider, Spin } from "antd";
import { AllowedIpsSettings } from "./allowed-ips";

const { useState, useEffect } = React;

export const SecuritySettings: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-securitysettings'>
          <h3>تنظیمات امنیتی</h3>
          {
            <form>
              <AllowedIpsSettings />
            </form>
          }
        </div>
      </Spin>
    </>
  );
};
