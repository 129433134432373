import { API } from "apis";
import { message } from "antd";
import {
  getViaAuth,
  isNotFound,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { Enum } from "core/domains";
import { DefaultOptionType } from "rc-select/lib/Select";

export const getAllSliders = (
  setLoader: any,
  setResponse: any,
  page: number,
  pageSize: number,
  query?: string
) => {
  setLoader(true);

  getViaAuth(
    API.sliders.getAllSliders +
      `/${page ? page : 1}/${pageSize ? pageSize : 20}` +
      (query || "")
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    } else {
      isNotFound(response.status) && setResponse([]);
    }
  });
};

export const getSliderById = async (
  setloader: any,
  setdata: any,
  id: string
) => {
  let options: any[] = [];
  setloader(true);
  await getViaAuth(API.sliders.getSliderDetails + `/${id}`).then(
    async (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        response.value.slides.forEach((i: any) =>
          options.push({
            label: i.title,
            value: i.id,
            link: i.link,
          })
        );
        setdata({ ...response.value, slides: options });
      }
    }
  );
};

export const getSliderTypes = (setLoader: any, setResponse: any) => {
  setLoader(true);

  getViaAuth(API.enums.sliderTypes).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    }
  });
};

export const getAllSlidesAsOptions = (setLoader: any, setResponse: any) => {
  setLoader(true);
  var options: any[] = [];
  getViaAuth(API.sliders.getAllSlides + `/1/20`).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      response.value.items.forEach((e: Enum) =>
        options.push({
          value: e.id,
          label: e.title,
        })
      );
      setResponse(options);
    }
  });
};

export const updateSlider = (
  setloader: any,
  data: any,
  onCompelete: () => void
) => {
  setloader(true);
  postViaAuth(API.sliders.editSlider, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      onCompelete();
    }
  });
};

export const createSlider = (
  setloader: any,
  data: any,
  onCompelete: () => void
) => {
  setloader(true);
  postViaAuth(API.sliders.addSlider, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      onCompelete();
    }
  });
};

export const deleteSlider = async (
  setLoading: any,
  data: any,
  onComplete: any
) => {
  setLoading(true);
  await postViaAuth(API.sliders.deleteSlider + `/${data}`, {}).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("اسلایدر مورد نظر حذف شد");
        onComplete();
      }
    }
  );
};
