import * as React from "react";
import {Button, Input} from "components";
import "./add.scss";
import {Col, Row, Spin, Upload, UploadProps} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {faNumToEnNum, IsNumeric} from "utils/scripts/decimal";
import {createSlide} from "core/repos/slide";
import {AddSlideType} from "core/domains";
import {RcFile, UploadFile} from "antd/lib/upload";
import {message} from "components/toast/toast";
import {UploadChangeParam} from "antd/es/upload";
import {getAllLevelsAsSelectOption} from "core/repos/level";
import {DefaultOptionType} from "antd/es/select";

export const CreateSlide: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [slide, setSlide] = React.useState<AddSlideType>();
  const [levels, setLevels] = React.useState<DefaultOptionType[]>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    DefaultOptionType[]
  >([]);

  const location = useLocation();

  React.useEffect(() => {
    levels.length === 0 &&
      getAllLevelsAsSelectOption(setIsCardLoading, setLevels);
  }, []);

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const navigate = useNavigate();

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setSlide({
        ...slide,
        file: info.file.originFileObj,
      });
    }
  };

  const onNumberChange = (value: string, field: string) => {
    if (value !== "") {
      value = faNumToEnNum(value);
    }

    if (
      value !== "" &&
      !IsNumeric(faNumToEnNum(value.split("")[value.split("").length - 1]))
    ) {
      return;
    }

    setSlide({
      ...slide,
      [field]: value,
    });
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "video/mp4";

    if (!isJpgOrPng) {
      message.error("فقط فرمت های mp4 قابل قبول میباشد");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("حجم عکس باید کمتر از ۲ مگابایت باشد.");
    }

    return setSlide({
      ...slide,
      file: file,
    });
  };

  const onRemoveFile = (file: UploadFile) => {
    setSlide({
      ...slide,
      file: undefined,
    });
  };

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-slides-add-slide'>
          <h3>افزودن اسلاید</h3>

          <form>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='عنوان'
                  name='title'
                  value={slide?.title}
                  onChange={(e) =>
                    setSlide({
                      ...slide,
                      title: e.target.value || "",
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  type='text'
                  label='نام دکمه'
                  name='buttonName'
                  value={slide?.buttonName}
                  onChange={(e) =>
                    setSlide({
                      ...slide,
                      buttonName: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Input
                type='text'
                label='لینک'
                name='link'
                value={slide?.link}
                onChange={(e) =>
                  setSlide({
                    ...slide,
                    link: e.target.value,
                  })
                }
              />
            </Row>
            <Row>
              <div className='textarea'>
                <label htmlFor='description'>توضیحات</label>
                <TextArea
                  name='description'
                  value={slide?.description}
                  onChange={(e) =>
                    setSlide({ ...slide, description: e.target.value })
                  }
                />
              </div>
            </Row>
            <Row>
              <div className='browse-field'>
                <label htmlFor='documents-photo'>تصویر</label>

                <div id='documents-photo' className='browse'>
                  <Upload
                    onPreview={() => null}
                    name='avatar'
                    listType='text'
                    className='file-uploader'
                    showUploadList
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    onRemove={onRemoveFile}
                  >
                    <button onClick={(el) => el.preventDefault()}>
                      آپلود فایل
                    </button>
                  </Upload>
                </div>
              </div>
            </Row>

            <div className='buttons'>
              <Button
                htmlType='button'
                type='info'
                text='افزودن'
                onClick={() => {
                  createSlide(setLoading, slide, () => navigate("/slides"));
                }}
                loading={loading}
                disabled={
                  loading ||
                  !slide?.file ||
                    !slide.title
                }
              />

              <Button
                htmlType='button'
                type='outlined'
                text='بازگشت'
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
