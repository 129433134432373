import {
  MessageOutlined,
  SecurityScanOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import { API } from "apis";
import { Card } from "components";
import {
  Dashboard,
  FullReport,
  GatewayReport,
  TrxUsdtReport,
} from "core/domains/dashboard/dashboard";
import * as React from "react";
import { Link } from "react-router-dom";
import { getViaAuth, responseValidator } from "utils/scripts/api";
import { digitSeparator } from "utils/scripts/decimal";
import "./main.scss";
import { Table } from "components/table/table";
import { TableColumn } from "react-data-table-component";

export const Main: React.FC = () => {
  const [indexes, setIndexes] = React.useState<Dashboard>();
  const [gateways, setGateways] = React.useState<GatewayReport>();
  const [trxUsdtData, setTrxUsdtData] = React.useState<TrxUsdtReport>();
  const [gatewaysResult, setGatewaysResult] = React.useState<any[]>([]);
  const [trxUsdtResult, setTrxUsdtResult] = React.useState<any[]>([]);
  const [fullReports, setFullReports] = React.useState<FullReport>();
  const [fullReportsResult, setFullReportsResult] = React.useState<any[]>([]);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);

  const getIndexes = () => {
    setCardLoading(true);
    getViaAuth(API.dashboard.index).then((data: any) => {
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setIndexes(data.value);
      }
    });
  };

  const getGatewaysReport = () => {
    setCardLoading(true);
    getViaAuth(API.dashboard.gateways).then((data: any) => {
      const keys = Object.keys(data.value);
      const values = Object.values(data.value);
      const arr: any[] = [];
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setGateways(data.value);
        keys.forEach((key, index) => {
          values.forEach((value, idx) => {
            index === idx &&
              arr.push({
                gateway:
                  keys[idx] === "vandarTransactionsSum"
                    ? "درگاه وندار"
                    : keys[idx] === "zibalTransactionsSum"
                    ? "درگاه زیبال"
                    : keys[idx] === "jibimoTransactionsSum"
                    ? "درگاه جیبیمو"
                    : keys[idx] === "payStarTransactionsSum"
                    ? "درگاه پی استار"
                    : keys[idx] === "jibitTransactionsSum"
                    ? "درگاه جیبیت"
                    : keys[idx] === "nextPayTransactionsSum"
                    ? "درگاه نکست پی"
                    : keys[idx] === "jibimoCashInTransactionsSum"
                    ? "شناسه دار جیبیمو"
                    : keys[idx] === "vandarCashInTransactionsSum"
                    ? "شناسه دار وندار"
                    : "",
                balance: values[index],
              });
          });
        });
        setGatewaysResult(arr);
      }
    });
  };
  const getTrxUsdtReport = () => {
    setCardLoading(true);
    getViaAuth(API.dashboard.trxUsdtReport).then((data: any) => {
      const keys = Object.keys(data.value);
      const values = Object.values(data.value);
      const arr: any[] = [];
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setTrxUsdtData(data.value);
        keys.forEach((key, index) => {
          values.forEach((value, idx) => {
            index === idx &&
              arr.push({
                title:
                  keys[idx] === "sumTronWithdrawals"
                    ? "برداشت حجمی ترون"
                    : keys[idx] === "countTronWithdrawals"
                    ? "تعداد برداشت ترون"
                    : keys[idx] === "sumTronWithdrawalsFee"
                    ? "کارمزد دریافتی ترون"
                    : keys[idx] === "sumUSDTWithdrawals"
                    ? "برداشت حجمی تتر"
                    : keys[idx] === "countUSDTWithdrawals"
                    ? "تعداد برداشت تتر"
                    : keys[idx] === "sumUSDTWithdrawalsFee"
                    ? "کارمزد دریافتی تتر"
                    : "",
                value: digitSeparator(values[index]),
              });
          });
        });
        setTrxUsdtResult(arr);
      }
    });
  };
  const getFullReport = () => {
    setCardLoading(true);
    getViaAuth(API.order.fullReport).then((data: any) => {
      const keys = Object.keys(data.value);
      const values = Object.values(data.value);
      const arr: any[] = [];
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setFullReports(data.value);
        keys.forEach((key, index) => {
          values.forEach((value, idx) => {
            index === idx &&
              arr.push({
                title:
                  keys[idx] === "countSellPm"
                    ? "تعداد سفارش فروش پرفکت به ما"
                    : keys[idx] === "countBuyPm"
                    ? "تعداد سفارش خرید پرفکت از ما"
                    : keys[idx] === "sumSellPm"
                    ? "جمع تومانی فروش پرفکت به ما"
                    : keys[idx] === "sumBuyPm"
                    ? "جمع تومانی خرید پرفکت از ما"
                    : keys[idx] === "sumSellPmUsdt"
                    ? "جمع دلاری فروش پرفکت به ما"
                    : keys[idx] === "sumBuyPmUsdt"
                    ? "جمع دلاری خرید پرفکت از ما"
                    : keys[idx] === "countSellCrypto"
                    ? "تعداد سفارش فروش کریپتو به ما"
                    : keys[idx] === "countBuyCrypto"
                    ? "تعداد سفارش خرید کریپتو از ما"
                    : keys[idx] === "sumSellCrypto"
                    ? "جمع تومانی فروش کریپتو به ما"
                    : keys[idx] === "sumBuyCrypto"
                    ? "جمع تومانی خرید کریپتو از ما"
                    : keys[idx] === "sumBuyCryptoUsdt"
                    ? "جمع دلاری فروش کریپتو به ما"
                    : keys[idx] === "sumSellCryptoUsdt"
                    ? "جمع دلاری خرید کریپتو از ما"
                    : "",
                value: digitSeparator((values[index] as number).toFixed(0)),
              });
          });
        });
        setFullReportsResult(arr);
      }
    });
  };

  const columns: TableColumn<any>[] = [
    {
      name: "نام درگاه",
      id: "gateway",
      selector: (row: any) => row?.gateway,
      width: "60%;",
    },
    {
      name: "موجودی",
      id: "balance",
      selector: (row: any) => digitSeparator(row?.balance),
      width: "40%",
    },
  ];

  const trxUsdtTableColumns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: any) => row?.title,
      width: "60%;",
    },
    {
      name: "مقدار",
      id: "value",
      selector: (row: any) => row?.value,
      width: "40%",
    },
  ];

  const fullReportTableColumns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: any) => row?.title,
      width: "60%;",
    },
    {
      name: "مقدار",
      id: "value",
      selector: (row: any) => row?.value,
      width: "40%",
    },
  ];

  React.useEffect(() => {
    getIndexes();
    getGatewaysReport();
    getTrxUsdtReport();
    getFullReport();
  }, []);

  return (
    <div className="vt-dashboard-main">
      <Spin spinning={cardLoading}>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<SwapOutlined />}
              title="موجودی پرفکت مانی"
            >
              <p>{digitSeparator(indexes?.perfectMoneyBalance.toFixed(2))}</p>
              <p className="prices">
                <span>
                  قیمت خرید :{digitSeparator(indexes?.perfectMoneyBuyPrice)}{" "}
                  تومان
                </span>
                <span className="divider"> | </span>
                <span>
                  قیمت فروش :{digitSeparator(indexes?.perfectMoneySellPrice)}{" "}
                  تومان
                </span>
              </p>
            </Card>
          </Col>

          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<ShoppingCartOutlined />}
              title="موجودی تتر و ترون"
            >
              <p className="balances">
                <span>
                  موجودی تتر هات:
                  {" " + digitSeparator(indexes?.usdtBalance.toFixed(2)) + " "}
                </span>
                <span className="divider">|</span>
                <span>
                  مازاد هات :
                  {" " +
                    digitSeparator(indexes?.exteraUsdtBalance?.toFixed(2)) ?? 0}
                </span>{" "}
                <span className="divider">|</span>{" "}
                <span>
                  موجودی تتر کریپتو :
                  {" " +
                    digitSeparator(
                      indexes?.activeExchangeUsdtBalance?.toFixed(2) ?? 0
                    ) +
                    " "}
                </span>
              </p>
              <p className="balances">
                <span className="divider">|</span>
                <span>
                  ترون هات
                  {" " +
                    digitSeparator(indexes?.trxBalance?.toFixed(2) ?? 0) +
                    " "}
                </span>{" "}
                <span className="divider">|</span>{" "}
                <span>
                  ترون کوکوین :
                  {" " +
                    digitSeparator(
                      indexes?.activeExchangeTrxBalance?.toFixed(2) ?? 0
                    ) +
                    " "}
                </span>
              </p>
              <p className="prices">
                <span>
                  قیمت خرید تتر:{digitSeparator(indexes?.usdtBuyPrice)} تومان
                </span>
                <span className="divider"> | </span>
                <span>
                  قیمت فروش تتر :{digitSeparator(indexes?.usdtSellPrice)} تومان
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Link to={"/wallets/users-balance-list"}>
              <Card tags={<UsergroupAddOutlined />} title="موجودی کل کاربران">
                {digitSeparator(indexes?.allUsersBalance.toFixed(0))} تومان
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<UsergroupAddOutlined />} title="تعداد کل کاربران">
              {digitSeparator(indexes?.allUsersCount)}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SecurityScanOutlined />} title="کاربران">
              <p className="users-count">
                <span>
                  {" "}
                  جدید:
                  {indexes?.allDayUsersCount ? indexes.allDayUsersCount : ""}
                </span>
                <span className="divider"> | </span>
                <span>
                  احراز شده :
                  {indexes?.allAuthenticatedUsers
                    ? indexes.allAuthenticatedUsers
                    : ""}
                </span>
                <span className="divider"> | </span>
                <span>
                  با تراکنش :
                  {indexes?.allUsersWithTransaction
                    ? indexes.allUsersWithTransaction
                    : ""}
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<MessageOutlined />} title="تعداد کل خریدها">
              {indexes?.allBuyOrdersCount}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="تعداد کل فروش ها">
              {indexes?.allSellOrdersCount}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<ShoppingCartOutlined />} title="تعداد کل سفارشات">
              {indexes?.allOrdersCount}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="مبلغ کل خرید ها">
              {digitSeparator(indexes?.allBuyOrdersAmountSum.toFixed(0))} تومان
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="مبلغ کل فروش ها">
              {digitSeparator(indexes?.allSellOrdersAmountSum.toFixed(0))} تومان
            </Card>
          </Col>

          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="مبلغ کل سفارشات">
              {digitSeparator(indexes?.allOrdersAmountSum.toFixed(0))} تومان
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<ShoppingCartOutlined />} title="مجموع برداشت ریالی">
              {digitSeparator(indexes?.irtWithdrawalTransactionsSum.toFixed(0))}{" "}
              تومان
            </Card>
          </Col>

          <Col className="gutter-row" span={6}>
            <Card tags={<ShoppingCartOutlined />} title="مجموع کارمزد ها">
              {digitSeparator(indexes?.commissionAmount.toFixed(0))} تومان
            </Card>
          </Col>
        </Row>
        <Row>
          {gatewaysResult.length > 0 && (
            <>
              <Table
                className="gateways-table"
                column={columns}
                data={gatewaysResult.slice(0, gatewaysResult.length / 2)}
                blur={cardLoading}
              />
              <Table
                className="gateways-table"
                column={columns}
                data={gatewaysResult.slice(gatewaysResult.length / 2)}
                blur={cardLoading}
              />
            </>
          )}
        </Row>
        <Row>
          {trxUsdtResult.length > 0 && (
            <>
              <Table
                className="trxUsdt-table"
                column={trxUsdtTableColumns}
                data={trxUsdtResult.slice(0, trxUsdtResult.length / 2)}
                blur={cardLoading}
              />
              <Table
                className="trxUsdt-table"
                column={trxUsdtTableColumns}
                data={trxUsdtResult.slice(trxUsdtResult.length / 2)}
                blur={cardLoading}
              />
            </>
          )}
        </Row>
        <Row>
          {fullReportsResult.length > 0 && (
            <>
              <Table
                className="fullReport-table"
                column={fullReportTableColumns}
                data={fullReportsResult?.slice(0, fullReportsResult.length / 2)}
                blur={cardLoading}
              />
              <Table
                className="fullReport-table"
                column={fullReportTableColumns}
                data={fullReportsResult?.slice(fullReportsResult.length / 2)}
                blur={cardLoading}
              />
            </>
          )}
        </Row>
      </Spin>
    </div>
  );
};
