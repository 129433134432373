import React from "react";
import { getIconPath } from "../../../utils/scripts/getIconPath";
import { copyToClipboard } from "../../../utils/scripts/copyToClipboard";
import { Order, OrderSide, OrderState } from "../../../core/domains";
import { ReactComponent as CopyOutlined } from "assets/icons/copy-outlined.svg";
import moment from "jalali-moment";
import { digitSeparator } from "../../../utils/scripts/decimal";

interface Props {
  orderItem: Order;
}

export const OrderDetailModalData: React.FC<Props> = ({ orderItem }) => {
  return (
    <>
      <div className="row double-column ">
        {orderItem.pair && (
          <div className="col">
            <span>نام ارز</span>
            <div>
              <img src={getIconPath(orderItem.pair.base)} />
              <span>
                <span>{orderItem.pair.base}</span>
              </span>
            </div>
          </div>
        )}
        {orderItem?.onPrice !== null && (
          <div className="col">
            <span>قیمت واحد</span>
            <div>
              <span>
                {orderItem.onPrice &&
                  digitSeparator(orderItem?.onPrice?.toFixed(0))}{" "}
                {"تومان"}
              </span>
            </div>
          </div>
        )}
      </div>

      {orderItem.destinationQuantity && orderItem.sourceQuantity && (
        <div className="row double-column has-divider">
          <div className="col">
            <span>مقدار مبداء</span>
            <div className={orderItem.side.id === OrderSide.Buy ? "" : "en"}>
              <span>
                {digitSeparator(orderItem.sourceQuantity)}{" "}
                {orderItem.side.id === OrderSide.Buy
                  ? "تومان"
                  : orderItem.side.id === OrderSide.Buy
                  ? orderItem.pair?.quote
                  : orderItem.pair?.base}
              </span>
            </div>
          </div>
          <div className="col">
            <span>مقدار مقصد</span>
            <div className={orderItem.side.id === OrderSide.Sell ? "" : "en"}>
              <span>
                {digitSeparator(orderItem.destinationQuantity)}{" "}
                {orderItem.side.id === OrderSide.Sell
                  ? "تومان"
                  : orderItem.side.id === OrderSide.Sell
                  ? orderItem.pair?.quote
                  : orderItem.pair?.base}
              </span>
            </div>
          </div>
        </div>
      )}
      {orderItem.baseBalanceAfterOrder !== null &&
        orderItem.quoteBalanceAfterOrder !== null && (
          <div className="row double-column has-divider">
            <div className="col">
              <span>موجودی مبداء پس از سفارش</span>
              <div className={orderItem.side.id === OrderSide.Buy ? "" : "en"}>
                <span>
                  {orderItem.side.id === OrderSide.Buy
                    ? digitSeparator(orderItem.quoteBalanceAfterOrder)
                    : orderItem.baseBalanceAfterOrder}{" "}
                  {orderItem.side.id === OrderSide.Buy
                    ? "تومان"
                    : orderItem.side.id === OrderSide.Buy
                    ? orderItem.pair?.quote
                    : orderItem.pair?.base}
                </span>
              </div>
            </div>
            <div className="col">
              <span>موجودی مقصد پس از سفارش</span>
              <div className={orderItem.side.id === OrderSide.Sell ? "" : "en"}>
                <span>
                  {orderItem.side.id === OrderSide.Sell
                    ? digitSeparator(orderItem.quoteBalanceAfterOrder)
                    : orderItem.baseBalanceAfterOrder}
                  {orderItem.side.id === OrderSide.Sell
                    ? "تومان"
                    : orderItem.side.id === OrderSide.Sell
                    ? orderItem.pair?.quote
                    : orderItem.pair?.base}
                </span>
              </div>
            </div>
          </div>
        )}

      {orderItem.commission && (
        <div className="row has-divider">
          <span>کارمزد</span>
          <div>
            <span>{digitSeparator(orderItem.commission)} تومان</span>
          </div>
        </div>
      )}
      {orderItem.trackingCode && (
        <div className="row has-divider">
          <span>شناسه سفارش</span>
          <div>
            <span>{orderItem.trackingCode}</span>
            <CopyOutlined
              onClick={() =>
                copyToClipboard(orderItem.trackingCode, "شناسه سفارش")
              }
            />
          </div>
        </div>
      )}
      {orderItem.type && (
        <div className="row has-divider">
          <span>نوع سفارش</span>
          <span>{orderItem.type.title}</span>
        </div>
      )}
      {orderItem.side && (
        <div className="row has-divider">
          <span>سمت سفارش</span>
          <span>{orderItem.side.title}</span>
        </div>
      )}
      {orderItem.createdAt && (
        <div className="row has-divider">
          <span>تاریخ و ساعت</span>
          <span>
            {moment
              .utc(orderItem.createdAt)
              .locale("fa")
              .local()
              .format("  HH:mm  -  jYYYY/MM/DD  ")}
          </span>
        </div>
      )}
      {orderItem.state && (
        <div className="row has-divider">
          <span>وضعیت</span>
          <span
            className={`${
              orderItem.state.id == OrderState.Filled
                ? "success"
                : orderItem.state.id == OrderState.Rejected ||
                  orderItem.state.id == OrderState.Canceled
                ? "error"
                : "waiting"
            }`}
          >
            {orderItem.state.title}
          </span>
        </div>
      )}
    </>
  );
};
