import {
  DownOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import { Button, Card, Input } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import {
  Enum,
  FilterValues,
  ManualValidation,
  ManualValidationListItem,
  PaginationList,
} from "core/domains";
import {
  getAllSurveys,
  getSurveyStates,
  getSurveyTypes,
} from "core/repos/manual-validations";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import "./index.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";

const { useState, useRef, useEffect } = React;

const defaultStateItems: Enum[] = [
  {
    title: "همه",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "همه",
    id: -1,
  },
];

export const ManualValidationManagement: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<PaginationList<ManualValidation>>();
  const [query, setQuery] = useState("");

  const [types, setTypes] = useState<Enum[]>(defaultTypeItems);
  const [states, setStates] = useState<Enum[]>(defaultStateItems);
  const [pageSize, setPageSize] = useState<number>(20);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
  });

  useEffect(() => {
    !surveys &&
      getAllSurveys(setCardLoading, setSurveys, getPageNum(), pageSize, "");
    states.length === 1 && getSurveyStates(setCardLoading, setStates);
    types.length === 1 && getSurveyTypes(setCardLoading, setTypes);
  }, []);

  const onFilterHandler = () => {
    var _query = "";

    if (filterValues?.type?.id !== -1)
      _query += `${_query.includes("?") ? "&" : "?"}entity=${
        filterValues?.type?.id
      }`;

    if (filterValues?.state?.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}state=${
        filterValues?.state?.id
      }`;
    if (filterValues?.keywords)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues?.keywords
      }`;
    setQuery(_query);
    getAllSurveys(setLoading, setSurveys, 1, pageSize, _query);
  };
  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: ManualValidationListItem) => row?.fullName,
      cell: (row: ManualValidationListItem) => (
        <div className="column-createdBy">
          <span className="day">{row.fullName}</span>
          <p className="">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "نوع",
      id: "entity",
      selector: (row: ManualValidationListItem) => row.entity.title,
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row: ManualValidationListItem) => row.state.title,
      cell: (row: ManualValidationListItem) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 1
              ? "success"
              : row.state.id == 2
              ? "error"
              : "waiting"
          }
        />
      ),
    },
    {
      name: "زمان",
      id: "createdOn",
      selector: (row: ManualValidationListItem) => row.createdOn,
      cell: (row: ManualValidationListItem) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: ManualValidation) => (
        <div className="actions">
          <Link to={`details/${record.id}`}>
            <Tooltip placement="top" title="اصلاح مدرک">
              <FileSearchOutlined />
            </Tooltip>
          </Link>
          {record.state.id === 2 && (
            <Tooltip
              placement="top"
              title={
                <>
                  دلیل رد:
                  <br />
                  {record?.message}
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card
      title="در انتظار تایید ارتقاء سطح"
      className="vt-dashboard-manualValidation"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            onPressEnter={() => onFilterHandler()}
            className="search"
            name="search"
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />
          {types.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    types.map((type) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, type })
                        }
                      >
                        {type.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.type?.title}
              />
            </Dropdown>
          )}

          {states.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    states.map((state) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, state })
                        }
                      >
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.state?.title}
              />
            </Dropdown>
          )}
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
                type: defaultTypeItems[0],
                state: defaultStateItems[0],
              });
              getAllSurveys(
                setCardLoading,
                setSurveys,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      {surveys && (
        <Table
          className="manualValidation-table"
          data={surveys.items}
          pagination={surveys.pagination}
          column={columns}
          pageSize={pageSize}
          setPageSize={setPageSize}
          getData={getAllSurveys}
          filters={query}
          setData={setSurveys}
          setLoader={setCardLoading}
        />
      )}
    </Card>
  );
};
