import { DeleteOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import { __API } from "apis/api";
import { Button, Card } from "components";
import { Modal } from "components/modal/modal";
import { Permission } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { del, get, responseValidator } from "utils/scripts/api";
import "./permissions.scss";

const { useState, useRef, useEffect } = React;

export const Permissions: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [permissionsList, setPermissionsList] = useState<Permission[]>([]);
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState<{
    state: boolean;
    record: Permission | null;
  }>({
    state: false,
    record: null,
  });

  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState<{
    state: boolean;
    record: Permission | null;
  }>({
    state: false,
    record: null,
  });

  const removePermission = () => {
    setLoading(true);
    removeModalIsOpen.state &&
      del(`${__API.roles.deleteRole}/${removeModalIsOpen.record?.id}`, {}).then(
        (response: any) => {
          setLoading(false);
          if (responseValidator(response.status)) {
            setRemoveModalIsOpen({
              record: null,
              state: false,
            });
            message.success("دسترسی مورد نظر با موفقیت حذف شد.");
          }
        }
      );
  };

  const columns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "displayText",
      selector: (row: Permission) => row.displayText,
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: Permission) => (
        <div className="actions">
          <Tooltip placement="top" title="حذف آیتم">
            <DeleteOutlined
              onClick={() =>
                setRemoveModalIsOpen({ record: record, state: true })
              }
            />
          </Tooltip>
        </div>
      ),
      width: "100px",
    },
  ];

  useEffect(() => {
    (async () => {
      setCardLoading(true);
      await get(__API.roles.getAllPermissions).then((response: any) => {
        if (responseValidator(response.status)) {
          setPermissionsList(response.data.value);
        }
      });
      setCardLoading(false);
    })();
  }, []);

  useEffect(() => {}, [permissionsList]);

  return (
    <Card
      title="مدیریت دسترسی"
      blur={cardLoading}
      className="vt-dashboard-permissions"
    >
      <Modal
        isOpen={removeModalIsOpen.state}
        setIsOpen={(e: any) => ({ ...removeModalIsOpen, state: e })}
        className="remove-permission-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>آیا در حذف دسترسی مطمئنید؟</h4>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => removePermission()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() =>
                setRemoveModalIsOpen({ ...removeModalIsOpen, state: false })
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={detailsModalIsOpen.state}
        setIsOpen={(e: any) =>
          setDetailsModalIsOpen({ ...detailsModalIsOpen, state: e })
        }
        className="permission-details-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>اطلاعات دسترسی</h4>
          </div>
          <div className="content">
            <ul>
              <li>
                <span></span>
                <span>{detailsModalIsOpen.record?.displayText}</span>
              </li>
            </ul>
          </div>
          <div className="buttons">
            <Button
              type="secondary"
              text="بستن"
              onClick={() =>
                setDetailsModalIsOpen({ ...detailsModalIsOpen, state: false })
              }
            />
          </div>
        </div>
      </Modal>

      {/* {permissionsList.length > 0 && (
        <Table
          className='permissions-table'
          data={permissionsList}
          column={columns}
        />
      )} */}
    </Card>
  );
};
