import * as React from "react";
import { __API } from "apis/api";
import { Button, Input } from "components";
import "./create.scss";
import {
  get,
  getValidationErrors,
  hasValidationError,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { message, Select, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { Level, Role, UserAdd } from "core/domains";
import { faNumToEnNum, IsNumeric } from "utils/scripts/decimal";
import { IsPersianChar } from "utils/scripts/stringExtentions";

const { useState, useEffect } = React;

export const CreateUser: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserAdd>();
  const [roleData, setRoleData] = useState<Role[]>();
  const [levelData, setLevelData] = useState<any[]>();

  const location = useLocation();

  function handleChangeNum(value: string, field: string) {
    // all number Fa to En
    if (value !== "") value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== "" && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setUserData({
      ...userData,
      [field]: value,
    });
  }

  function handleChangeFa(value: string, field: string) {
    if (value !== "" && !IsPersianChar(value)) return;

    setUserData({
      ...userData,
      [field]: value,
    });
  }

  const createUserHandle = async () => {
    setLoading(true);
    await postViaAuth<UserAdd>(__API.users.create, {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      userName: userData?.userName,
      email: userData?.emailAddress,
      nationalCode: userData?.nationalCode,
      roles: userData?.roles,
      level: userData?.level,
      password: userData?.password,
      phoneNumber: userData?.phoneNumber,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("کاربر مورد نظر با موفقیت ایجاد شد.");
        window.open("/users/", "_self");
      }
    });
  };

  useEffect(() => {
    setIsCardLoading(true);
    (async () => {
      await get(__API.roles.getAllRoles).then((response: any) => {
        setIsCardLoading(false);
        if (responseValidator(response.status)) {
          setRoleData(response.data.value.items);
        }
      });
    })();
  }, []);

  useEffect(() => {
    setIsCardLoading(true);
    (async () => {
      await postViaAuth(__API.levels.getAllLevels, {}).then((response: any) => {
        setIsCardLoading(false);
        if (responseValidator(response.status)) {
          setLevelData(response.data.value.items);
        } else {
          if (hasValidationError(response.status) && response.data.errors) {
            return getValidationErrors(response.data.errors).map((error) =>
              message.error(error)
            );
          } else message.error(response.data.message);
        }
      });
    })();
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-users-create">
          <h3></h3>
          <form>
            <div className="row">
              <Input
                type="text"
                label="نام"
                name="firstName"
                value={userData?.firstName}
                onChange={(e) => handleChangeFa(e.target.value, "firstName")}
                maxLength={20}
              />
              <Input
                label="نام خانوادگی"
                type="text"
                name="lastName"
                value={userData?.lastName}
                onChange={(e) => handleChangeFa(e.target.value, "lastName")}
                dir="ltr"
                maxLength={20}
              />
            </div>

            <div className="row">
              <Input
                type="text"
                label="نام کاربری"
                name="userName"
                value={userData?.userName}
                onChange={(e) =>
                  userData &&
                  setUserData({
                    ...userData,
                    userName: e.target.value,
                  })
                }
                maxLength={20}
              />
              <Input
                label="ایمیل"
                type="text"
                name="emailAddress"
                value={userData?.emailAddress}
                onChange={(e) =>
                  userData &&
                  setUserData({
                    ...userData,
                    emailAddress: e.target.value,
                  })
                }
                dir="ltr"
                maxLength={20}
              />
            </div>

            <div className="row">
              <Input
                label="تلفن ثابت"
                type="text"
                name="landline"
                value={userData?.landline}
                onChange={(e) => handleChangeNum(e.target.value, "landline")}
                dir="ltr"
                maxLength={12}
              />
              <Input
                label="کد ملی"
                type="text"
                name="nationalCode"
                value={userData?.nationalCode}
                onChange={(e) =>
                  handleChangeNum(e.target.value, "nationalCode")
                }
                dir="ltr"
                maxLength={12}
              />
            </div>

            <div className="row">
              <Input
                label="موبایل"
                type="text"
                name="phoneNumber"
                value={userData?.phoneNumber}
                onChange={(e) => handleChangeNum(e.target.value, "phoneNumber")}
                dir="ltr"
                maxLength={12}
              />
              <Input
                label="رمز عبور"
                type="text"
                name="password"
                value={userData?.password}
                onChange={(e) =>
                  userData &&
                  setUserData({ ...userData, password: e.target.value })
                }
                dir="ltr"
                maxLength={12}
              />
            </div>

            <div className="row">
              <Select placeholder="سطح کاربری" defaultValue={"انتخاب"}>
                {levelData &&
                  levelData?.map((level: Level) => (
                    <Select.Option>{level.name}</Select.Option>
                  ))}
              </Select>
              {roleData && (
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="انتخاب نقش"
                  defaultValue={[]}
                  onChange={(value: string[]) =>
                    userData &&
                    setUserData({
                      ...userData,
                      roles: [...value],
                    })
                  }
                  value={userData?.roles}
                >
                  {roleData &&
                    roleData.map((role: any, index) => (
                      <Select.Option key={`role-${index}`}>
                        {role.name}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </div>

            <div className="row">
              <Button
                htmlType="button"
                type="info"
                text="ایجاد"
                onClick={() => createUserHandle()}
                loading={loading}
              />

              <Button
                htmlType="button"
                type="outlined"
                text="بازگشت"
                onClick={() => window.open("/users/", "_self")}
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
