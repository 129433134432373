import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { FilterValues, PaginationList } from "core/domains";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import "./index.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { getAllUsersPersonalWallets } from "core/repos/users-personal-wallets";
import { PersonalWallet } from "core/domains/users-personal-wallets/users-personal-wallets";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "utils/scripts/copyToClipboard";

const { useState, useRef, useEffect } = React;

export const UsersPersonalWallets: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [userWallets, setUserWallets] =
    useState<PaginationList<PersonalWallet>>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [query, setQuery] = useState("");

  const [filterValues, setFilterValues] = useState<FilterValues>({});

  useEffect(() => {
    !userWallets &&
      getAllUsersPersonalWallets(
        setCardLoading,
        setUserWallets,
        getPageNum(),
        pageSize,
        ""
      );
  }, []);

  const onFilterHandler = () => {
    let query = "";
    if (filterValues?.keywords) {
      query = `?keywords=${filterValues.keywords}`;
    }
    setQuery(query);
    getAllUsersPersonalWallets(setLoading, setUserWallets, 1, pageSize, query);
  };

  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: PersonalWallet) => row?.createdBy?.fullName ?? "-",
      cell: (row: PersonalWallet) => (
        <div className="column-createdBy">
          <span className="day">{row.createdBy?.fullName ?? "-"}</span>
          <p className="name">{row.createdBy?.userName ?? "-"}</p>
        </div>
      ),
    },
    {
      name: "آدرس کیف پول",
      id: "walletAddress",
      selector: (row: PersonalWallet) => row?.walletAddress,
      cell: (row: PersonalWallet) => (
        <div className="column-wallet-address">
          <span className="wallet-address">
            <CopyOutlined
              onClick={() => copyToClipboard(row.walletAddress, "آدرس کیف پول")}
            />
            <span>{row.walletAddress}</span>
          </span>
        </div>
      ),
    },
    {
      name: "زمان",
      id: "createdOn",
      selector: (row: PersonalWallet) => row?.createdOn,
      cell: (row: PersonalWallet) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    // {
    //   name: "عملیات",
    //   id: "action",
    //   cell: (record: PersonalWallet) => (
    //     <div className="actions">
    //       <Link to={`details/${record.id}`}>
    //         <Tooltip placement="top" title="اصلاح مدرک">
    //           <FileSearchOutlined />
    //         </Tooltip>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  return (
    <Card
      title="مدیریت کیف پول های شخصی کاربران"
      className="vt-dashboard-usersPersonalWallets"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            onPressEnter={() => onFilterHandler()}
            className="search"
            name="search"
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
              });
              getAllUsersPersonalWallets(
                setCardLoading,
                setUserWallets,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="usersPersonalWallets-table"
        data={userWallets?.items}
        pagination={userWallets?.pagination}
        column={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setData={setUserWallets}
        getData={getAllUsersPersonalWallets}
        setLoader={setCardLoading}
        filters={query}
      />
    </Card>
  );
};
