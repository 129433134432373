import * as React from "react";
import { Button } from "components";
import "./edit.scss";
import { Spin, UploadProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { postViaAuth, responseValidator } from "utils/scripts/api";
import { __API } from "apis/api";
import Dragger from "antd/lib/upload/Dragger";
import { RcFile } from "antd/lib/upload";
import { message } from "../../../../../../../components/toast/toast";

const { useState, useEffect } = React;

export type File = {
  file: RcFile;
  type: string;
  uid: string;
  name: string;
};

export type Level3FieldValueType = {
  documentFile: File[];
  selfiePic: File[];
  handWriteSelfie: File[];
};

export const EditSurvey: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [isOpenPic, setIsOpenPic] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [surveyData, setSurveyData] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [isOpenCart, setIsOpenCart] = useState<boolean>(false);
  const [formData, setFormData] = useState<Level3FieldValueType>({
    documentFile: [],
    selfiePic: [],
    handWriteSelfie: [],
  });

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const _id = urlSections[urlSections.length - 1];

  const location = useLocation();
  const navigate = useNavigate();

  const hwUploadProps: UploadProps = {
    name: "handwrite-uploader",
    className: "handwrite-uploader",
    showUploadList: true,
    onRemove: (file) => {
      setFormData({
        ...formData,
        handWriteSelfie: formData.handWriteSelfie.filter(
          (m) => m.uid !== file.uid
        ),
      });
    },
    beforeUpload: (file: RcFile) => {
      const isCorrectFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isCorrectFormat)
        message.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
      else
        setFormData({
          ...formData,
          handWriteSelfie: [
            ...formData.handWriteSelfie,
            {
              file: file,
              type: "HandwritePic",
              uid: file.uid,
              name: file.name,
            },
          ],
        });

      return false;
    },
    multiple: false,
    fileList: formData.handWriteSelfie,
  };
  const selfieUploadProps: UploadProps = {
    name: "selfie-uploader",
    className: "selfie-uploader",
    showUploadList: true,
    onRemove: (file) => {
      setFormData({
        ...formData,
        selfiePic: formData.selfiePic.filter((m) => m.uid !== file.uid),
      });
    },
    beforeUpload: (file: RcFile) => {
      const isCorrectFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isCorrectFormat)
        message.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
      else
        setFormData({
          ...formData,
          selfiePic: [
            ...formData.selfiePic,
            { file: file, type: "SelfiePic", uid: file.uid, name: file.name },
          ],
        });

      return false;
    },
    multiple: false,
    fileList: formData.selfiePic,
  };
  const docUploadProps: UploadProps = {
    name: "doc-uploader",
    className: "doc-uploader",
    onRemove: (file) => {
      setFormData({
        ...formData,
        documentFile: formData.documentFile.filter((m) => m.uid !== file.uid),
      });
    },
    beforeUpload: (file: RcFile) => {
      const isCorrectFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isCorrectFormat)
        message.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
      else
        setFormData({
          ...formData,
          documentFile: [
            ...formData.documentFile,
            { file: file, type: "DocPic", uid: file.uid, name: file.name },
          ],
        });

      return false;
    },
    multiple: false,
    fileList: formData.documentFile,
    showUploadList: true,
  };

  const editSurveyHandle = async () => {
    const form = new FormData();
    formData.documentFile.filter((f) => f.type === "DocPic").length > 0 &&
      form.append(
        "DocumentFile",
        formData.documentFile.filter((f) => f.type === "DocPic")[0].file
      );
    formData.selfiePic.filter((f) => f.type === "SelfiePic").length > 0 &&
      form.append(
        "SelfiePic",
        formData.selfiePic.filter((f) => f.type === "SelfiePic")[0].file
      );
    formData.handWriteSelfie.filter((f) => f.type === "HandwritePic").length >
      0 &&
      form.append(
        "HandWriteSelfie",
        formData.handWriteSelfie.filter((f) => f.type === "HandwritePic")[0]
          .file
      );
    form.append("Id", _id);
    setLoading(true);
    setUploading(true);

    await postViaAuth(__API.users.editDoc, form, true).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        window.location.reload();
      } else {
        if (response.status === 403 || response.status === 401)
          navigate("/signin");
      }
    });
  };

  useEffect(() => {
    message.success("مدارک مورد نظر با موفقیت اصلاح شد.");
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-surveys-edit">
          <h3>اصلاح مدارک کاربر</h3>
          {
            <form>
              <div className="row">
                <div className="input-row">
                  <div className="browse-field">
                    <div id="selfie-photo" className="browse">
                      <Dragger {...selfieUploadProps} maxCount={1}>
                        <button onClick={(el) => el.preventDefault()}>
                          آپلود تصویر سلفی (سطح ۳)
                        </button>
                      </Dragger>
                    </div>
                  </div>
                </div>
                <div className="input-row">
                  <div className="browse-field">
                    <div id="hw-photo" className="browse">
                      <Dragger {...hwUploadProps} maxCount={1}>
                        <button onClick={(el) => el.preventDefault()}>
                          آپلود تصویر مدارک (سطح ۳)
                        </button>
                      </Dragger>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <Button
                  htmlType="button"
                  type="info"
                  text="آپلود"
                  onClick={() => editSurveyHandle()}
                  loading={loading}
                />
              </div>
            </form>
          }
        </div>
      </Spin>
    </>
  );
};
