import * as React from "react";
import "./xpay.scss";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "routes/dashboard/dashboard";
import { AllUsers } from "routes/dashboard/users/index";
import { UserDetails } from "./dashboard/users/details/details";
import { Roles } from "./dashboard/staff/roles/roles";
import { Permissions } from "./dashboard/staff/permissions/permissions";
import { SignIn } from "./pages/signin/signin";
import { CreateRole } from "./dashboard/staff/roles/create/create";
import { Gateways } from "./dashboard/gateways";
import { EditRole } from "./dashboard/staff/roles/edit/edit";
import { Levels } from "./dashboard/users/levels/levels";
import { EditLevel } from "./dashboard/users/levels/edit/edit";
import { MarketManagement } from "./dashboard/market/market";
import { EditMarket } from "./dashboard/market/edit/edit";
import { EditFiats } from "./dashboard/fiats/fiats";
import { Wallets } from "./dashboard/wallets";
import { WalletsDetail } from "./dashboard/wallets/detail/detail";
import { SiteSetting } from "./dashboard/setting/setting";
import { Main } from "./dashboard/main/main";
import { CreateUser } from "./dashboard/users/create/create";
import { TicketManagement } from "./dashboard/tickets";
import { BankAccountsManagement } from "./dashboard/bank-acconts";
import { ManualValidationManagement } from "./dashboard/manual-validations";
import { CurrencyTransactions } from "./dashboard/transactions/currency";
import { EditBankAccount } from "./dashboard/bank-acconts/edit/edit";
import { UpdateTransaction } from "./dashboard/transactions/updates/update";
import { PerfectMoneyTransactions } from "./dashboard/transactions/perfcet-money";
import { ManualValidationDetails } from "./dashboard/manual-validations/stages";
import { CryptoTransactionAction } from "./dashboard/actions/crypto-withdrawal";
import { ManualValidationAction } from "./dashboard/actions/auth-survey";
import { BasicUsers } from "./dashboard/users/basics";
import { TicketDetail } from "./dashboard/tickets/detail/detail";
import { BankAccountsPending } from "./dashboard/actions/bank-account";
import { BlackListManagement } from "./dashboard/black-list";
import { CreateBlackList } from "./dashboard/black-list/create/create";
import { VIPUsers } from "./dashboard/users/vips";
import { AllTransactions } from "./dashboard/transactions/all";
import { Orders } from "./dashboard/orders";
import { BankAccountTracking } from "./dashboard/bank-account-tracking/bank-account-tracking";
import { PerfectmoneyVoucherTracking } from "./dashboard/perfectmoney-voucher-tracking/perfectmoney-voucher-tracking";
import { EditBlackList } from "./dashboard/black-list/edit/edit";
import { PerfectmoneyVoucherCharge } from "./dashboard/charge-perfectmoney/charge-perfectmoney";
import { ReferralWithdrawalApproval } from "./dashboard/actions/referral-withdrawal";
import { ReferralActions } from "./dashboard/referral/actions";
import { ReferralLevels } from "./dashboard/referral/levels";
import { EditLevelReferral } from "./dashboard/referral/levels/edit/edit";
import { AddLevelReferral } from "./dashboard/referral/levels/add/add";
import { ReferralCoWorkers } from "./dashboard/referral/co-workers";
import { ReferralWithdrawals } from "./dashboard/referral/withdrawals";
import { KucoinHistories } from "./dashboard/kucoin/kucoin-history";
import { Popups } from "./dashboard/pop-ups/pop-ups";
import { CreatePopUp } from "./dashboard/pop-ups/add/add";
import { UpdatePopUp } from "./dashboard/pop-ups/edit/edit";
import { GatewaySetting } from "./dashboard/gateway-setting/gateway-setting";
import { CryptoSetting } from "./dashboard/crypto-setting/crypto-setting";
import { Notifications } from "./dashboard/notifications/notifications";
import { ServiceLogs } from "./dashboard/service-logs/service-logs";
import { CreateNotification } from "./dashboard/notifications/add/add";
import { NotificationDetails } from "./dashboard/notifications/details/notification-details";
import { EditNotification } from "./dashboard/notifications/edit/edit";
import {
  TransactionState,
  TransactionType,
} from "../core/domains/transaction/transaction";
import { Sliders } from "./dashboard/sliders/sliders";
import { UpdateSlider } from "./dashboard/sliders/edit/edit";
import { AddSlider } from "./dashboard/sliders/add/add";
import { Slides } from "./dashboard/slides/slides";
import { CreateSlide } from "./dashboard/slides/add/add";
import { UpdateSlide } from "./dashboard/slides/edit/edit";
import { EditBonus } from "./dashboard/bonus/edit/edit";
import { Bonuses } from "./dashboard/bonus";
import { SecuritySettings } from "./dashboard/security-settings/security-settings";
import { CashInSettings } from "./dashboard/cash-in";
import { CashInManagement } from "./dashboard/cash-in-settings/cash-in-management";
import { UsersPersonalWallets } from "./dashboard/users-personal-wallets";
import { AuthenticationScores } from "./dashboard/authentication-scores";
import { AuthenticationScoresHistory } from "./dashboard/authentication-scores-history";
import { WithdrawalDisabledUSers } from "./dashboard/withdrawal-disabled-users";
import { MarketingQueries } from "./dashboard/marketing-queries/marketing-queries";
import { ReportsGateway } from "./dashboard/reports-gateway";

export const Xpay: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="signin" element={<SignIn />} />
        <Route path="histories">
          <Route
            path=""
            element={<Dashboard component={<AllTransactions />} />}
          />
          <Route
            path="currency"
            element={<Dashboard component={<CurrencyTransactions />} />}
          />
        </Route>

        <Route path="popups">
          <Route path="" element={<Dashboard component={<Popups />} />} />
          <Route
            path="add"
            element={<Dashboard component={<CreatePopUp />} />}
          />
          <Route
            path="edit/:id"
            element={<Dashboard component={<UpdatePopUp />} />}
          />
        </Route>

        <Route path="bonuses">
          <Route path="" element={<Dashboard component={<Bonuses />} />} />
          <Route
            path="add"
            element={<Dashboard component={<CreatePopUp />} />}
          />
          <Route
            path="edit/:id"
            element={<Dashboard component={<EditBonus />} />}
          />
        </Route>

        <Route path="" element={<Dashboard component={<Main />} />} />
        <Route
          path="gateways"
          element={<Dashboard component={<Gateways />} />}
        />
        <Route
          path="pm-tracking"
          element={<Dashboard component={<PerfectmoneyVoucherTracking />} />}
        />
        <Route
          path="pm-charge"
          element={<Dashboard component={<PerfectmoneyVoucherCharge />} />}
        />
        <Route path="users">
          <Route path="" element={<Dashboard component={<AllUsers />} />} />
          <Route path="vip" element={<Dashboard component={<VIPUsers />} />} />
          <Route
            path="no-auth"
            element={<Dashboard component={<BasicUsers />} />}
          />

          <Route
            path="details/:id"
            element={<Dashboard component={<UserDetails />} />}
          />
          <Route
            path="details/:id/survey/:id"
            element={<Dashboard component={<UserDetails />} />}
          />
          <Route
            path="add-manual"
            element={<Dashboard component={<CreateUser />} />}
          />

          <Route path="today-births" element={<Dashboard />} />
          <Route path="active-disable" element={<Dashboard />} />
          <Route path="levels">
            <Route path="" element={<Dashboard component={<Levels />} />} />

            <Route
              path="edit/:id"
              element={<Dashboard component={<EditLevel />} />}
            />
          </Route>
          <Route path="roles">
            <Route path="" element={<Dashboard component={<Roles />} />} />
            <Route
              path="create"
              element={<Dashboard component={<CreateRole />} />}
            />
            <Route
              path="edit/:id"
              element={<Dashboard component={<EditRole />} />}
            />
          </Route>
          <Route
            path="permissions"
            element={<Dashboard component={<Permissions />} />}
          />
        </Route>

        <Route
          path="setting"
          element={<Dashboard component={<SiteSetting />} />}
        />
        <Route
          path="crypto-setting"
          element={<Dashboard component={<CryptoSetting />} />}
        />
        <Route
          path="gateway-setting"
          element={<Dashboard component={<GatewaySetting />} />}
        />
        <Route
          path="cash-in-setting"
          element={<Dashboard component={<CashInSettings />} />}
        />

        <Route
          path="service-logs"
          element={<Dashboard component={<ServiceLogs />} />}
        />

        <Route
          path="security-setting"
          element={<Dashboard component={<SecuritySettings />} />}
        />
        <Route path="notifications">
          <Route
            path=""
            element={<Dashboard component={<Notifications />} />}
          />
          <Route
            path="details/:message"
            element={<Dashboard component={<NotificationDetails />} />}
          />
          <Route
            path="edit/:id"
            element={<Dashboard component={<EditNotification />} />}
          />
          <Route
            path="add"
            element={<Dashboard component={<CreateNotification />} />}
          />
        </Route>

        <Route path="actions">
          <Route
            path="rial-waiting-withdrawal"
            element={
              <Dashboard
                component={
                  <CurrencyTransactions
                    state={TransactionState.WaitingForAdmin}
                    type={TransactionType.Withdrawal}
                  />
                }
              />
            }
          />
          <Route
            path="crypto-waiting-withdrawal"
            element={<Dashboard component={<CryptoTransactionAction />} />}
          />
          <Route path="waiting-orders" element={<Dashboard />} />
          <Route path="waiting-level-apply">
            <Route
              path=""
              element={<Dashboard component={<ManualValidationAction />} />}
            />
            <Route
              path="details/:id"
              element={<Dashboard component={<ManualValidationDetails />} />}
            />
          </Route>
          <Route
            path="waiting-bank-accounts"
            element={<Dashboard component={<BankAccountsPending />} />}
          />
          <Route
            path="referral-withdrawal-to-wallet-approval"
            element={<Dashboard component={<ReferralWithdrawalApproval />} />}
          />
        </Route>
        <Route path="kucoin">
          <Route
            path="history"
            element={<Dashboard component={<KucoinHistories />} />}
          />
        </Route>
        <Route path="referral">
          <Route
            path="actions"
            element={<Dashboard component={<ReferralActions />} />}
          />
          <Route
            path="co-workers"
            element={<Dashboard component={<ReferralCoWorkers />} />}
          />
          <Route
            path="withdrawals"
            element={<Dashboard component={<ReferralWithdrawals />} />}
          />
          <Route path="levels">
            <Route
              path=""
              element={<Dashboard component={<ReferralLevels />} />}
            />
            <Route
              path="edit/:id"
              element={<Dashboard component={<EditLevelReferral />} />}
            />
            <Route
              path="add"
              element={<Dashboard component={<AddLevelReferral />} />}
            />
          </Route>
        </Route>

        <Route path="tickets">
          <Route
            path=""
            element={<Dashboard component={<TicketManagement />} />}
          />
          {
            <Route
              path="detail/:id"
              element={<Dashboard component={<TicketDetail />} />}
            />
          }
        </Route>

        <Route path="wallets">
          <Route path="users-balance-list">
            <Route path="" element={<Dashboard component={<Wallets />} />} />
            <Route
              path="detail/:symbol"
              element={<Dashboard component={<WalletsDetail />} />}
            />
          </Route>
          <Route
            path="withdrawal-deposit-toman"
            element={<Dashboard component={<CurrencyTransactions />} />}
          />
        </Route>

        <Route path="bank-accounts">
          <Route
            path="tracking"
            element={<Dashboard component={<BankAccountTracking />} />}
          />
          <Route
            path=""
            element={<Dashboard component={<BankAccountsManagement />} />}
          />
          <Route
            path="edit/:id"
            element={<Dashboard component={<EditBankAccount />} />}
          />
        </Route>

        <Route path="manual-validations">
          <Route
            path=""
            element={<Dashboard component={<ManualValidationManagement />} />}
          />
          <Route
            path="details/:id"
            element={<Dashboard component={<ManualValidationDetails />} />}
          />
        </Route>

        <Route
          path="users-personal-wallets"
          element={<Dashboard component={<UsersPersonalWallets />} />}
        />

        <Route
          path="authentication-scores"
          element={<Dashboard component={<AuthenticationScores />} />}
        />

        <Route
          path="authentication-scores-history"
          element={<Dashboard component={<AuthenticationScoresHistory />} />}
        />

        <Route
          path="withdrawal-disabled-users"
          element={<Dashboard component={<WithdrawalDisabledUSers />} />}
        />

        <Route path="orders">
          <Route path="all" element={<Dashboard component={<Orders />} />} />
          <Route path="orders-in-review" element={<Dashboard />} />
          <Route path="perfect-orders" element={<Dashboard />} />
          <Route path="crypto-orders" element={<Dashboard />} />
        </Route>

        <Route path="transactions">
          <Route
            path="all"
            element={<Dashboard component={<AllTransactions />} />}
          />
          <Route
            path="currency"
            element={<Dashboard component={<CurrencyTransactions />} />}
          />
          <Route
            path="perfect-money"
            element={<Dashboard component={<PerfectMoneyTransactions />} />}
          />

          <Route
            path="edit/:id"
            element={<Dashboard component={<UpdateTransaction />} />}
          />
        </Route>

        <Route path="notifications" element={<Dashboard />} />

        <Route path="marketing" element={<Dashboard />} />

        <Route path="market">
          <Route
            path=""
            element={<Dashboard component={<MarketManagement />} />}
          />

          <Route
            path="edit/:id"
            element={<Dashboard component={<EditMarket />} />}
          />
        </Route>

        <Route path="sliders">
          <Route path="" element={<Dashboard component={<Sliders />} />} />
          <Route
            path="edit/:id"
            element={<Dashboard component={<UpdateSlider />} />}
          />
          <Route path="add" element={<Dashboard component={<AddSlider />} />} />
        </Route>
        <Route path="slides">
          <Route path="" element={<Dashboard component={<Slides />} />} />
          <Route
            path="add"
            element={<Dashboard component={<CreateSlide />} />}
          />
          <Route
            path="edit/:id"
            element={<Dashboard component={<UpdateSlide />} />}
          />
        </Route>
        <Route path="sliders">
          <Route path="" element={<Dashboard component={<Sliders />} />} />
          <Route
            path="edit/:id"
            element={<Dashboard component={<UpdateSlider />} />}
          />
          <Route path="add" element={<Dashboard component={<AddSlider />} />} />
        </Route>

        <Route path="reports">
          <Route
            path="marketing"
            element={<Dashboard component={<MarketingQueries />} />}
          />
          <Route
            path="gateway"
            element={<Dashboard component={<ReportsGateway />} />}
          />
        </Route>

        <Route path="blacklist">
          <Route
            path=""
            element={<Dashboard component={<BlackListManagement />} />}
          />

          <Route
            path="edit/:id"
            element={<Dashboard component={<EditBlackList />} />}
          />

          <Route
            path="create"
            element={<Dashboard component={<CreateBlackList />} />}
          />
        </Route>

        <Route
          path="cash-in-management"
          element={<Dashboard component={<CashInManagement />} />}
        />

        {/*<Route*/}
        {/*  path="perfect-money-settings"*/}
        {/*  element={<Dashboard component={<PerfectMoneySettings />} />}*/}
        {/*/>*/}

        <Route path="fiats">
          <Route path="" element={<Dashboard component={<EditFiats />} />} />
        </Route>

        <Route path="reports">
          <Route path="orders" element={<Dashboard />} />
          <Route path="user-attraction" element={<Dashboard />} />
          <Route path="login-history" element={<Dashboard />} />
          <Route path="deposit-ip-rial" element={<Dashboard />} />
          <Route path="total-transactions-zibal" element={<Dashboard />} />
          <Route path="total-transactions-vandar" element={<Dashboard />} />
        </Route>

        <Route path="perfect-money">
          <Route path="voucher-deposit" element={<Dashboard />} />
          <Route path="voucher-withdrawal" element={<Dashboard />} />
          <Route path="inquiry-voucher" element={<Dashboard />} />
        </Route>

        <Route path="binance">
          <Route path="deposit-history" element={<Dashboard />} />
          <Route path="widthdrawal-history" element={<Dashboard />} />
        </Route>

        <Route path="staff">
          <Route path="list" element={<Dashboard />} />
          <Route path="operator-grouping" element={<Dashboard />} />
          <Route path="transaction-list" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
