import { API } from "apis";
import { message } from "antd";
import { Asset, Role, UserDetail } from "core/domains";
import {
  AssetUpdateDetailModal,
  AssetUpdateDetailModalError,
} from "core/domains/users/detail/assets";
import React from "react";
import { Location } from "react-router-dom";
import {
  getViaAuth,
  isNotFound,
  postViaAuth,
  put,
  responseValidator,
} from "utils/scripts/api";
import { faNumToEnNum } from "utils/scripts/decimal";
import { getPageNum } from "../../utils/scripts/paggingHelper";

export const getAllUsers = (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  getViaAuth(`${API.users.getAllUsers}/${page}/${pageSize}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      } else {
        isNotFound(response.status) && setdata([]);
      }
    }
  );
};

export const getUserById = (setloader: any, setdata: any, id: string) => {
  setloader(true);
  getViaAuth<UserDetail>(API.users.get + `/${id}`).then((data: any) => {
    setloader(false);
    if (responseValidator(data.status) && data.value) {
      data.value && setdata({ ...data.value, levelId: data.value.level.id });
    }
  });
};

export const getRoles = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(`${API.users.roles}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.data.value);
    }
  });
};

export const getLevels = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(`${API.users.levels}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.data.value);
    }
  });
};

export const getAllGenders = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(`${API.enums.gender}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateUserActivation = (
  setloader: any,
  data: any,
  setdata: any,
  oncomplete?: any,
  queries?: string
) => {
  setloader(true);
  postViaAuth(API.users.editActivation, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("وضعیت حساب کاربر تغییر یافت");
      getAllUsers(setloader, setdata, getPageNum(), 10, queries ?? "");
      oncomplete && oncomplete();
    }
  });
};

export const updateUserWithdrawalEnable = (
  setloader: any,
  data: any,
  setdata: any,
  oncomplete?: any,
  queries?: string
) => {
  setloader(true);
  postViaAuth(API.users.editWithdrawalEnable, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("وضعیت برداشت کاربر تغییر یافت");
      getAllUsers(setloader, setdata, getPageNum(), 10, queries ?? "");
      oncomplete && oncomplete();
    }
  });
};

export const updateUserWhiteList = (
  setloader: any,
  data: any,
  setdata: any,
  oncomplete?: any,
  queries?: string
) => {
  setloader(true);
  postViaAuth(API.users.changeWhiteList, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("وضعیت وایت لیست کاربر تغییر یافت");
      getAllUsers(setloader, setdata, getPageNum(), 10, queries ?? "");
      oncomplete && oncomplete();
    }
  });
};

export const updateUserLockout = (
  setmodalloader: any,
  setloader: any,
  setisopen: any,
  data: any,
  setdata: any,
  queries?: string
) => {
  setmodalloader(true);
  postViaAuth(API.users.editLockoutUser, data).then((response: any) => {
    setmodalloader(false);
    if (responseValidator(response.status)) {
      message.success("وضعیت ورود کاربر تغییر یافت");
      getAllUsers(setloader, setdata, getPageNum(), 10, queries ?? "");
      setisopen(false);
    }
  });
};

export const updateUserAuthenticationScore = (
  setmodalloader: any,
  setloader: any,
  setisopen: any,
  data: any,
  setdata: any,
  queries?: string
) => {
  setmodalloader(true);
  postViaAuth(API.authenticationScores.editUser, data).then((response: any) => {
    setmodalloader(false);
    if (responseValidator(response.status)) {
      message.success("امتیاز کاربر ویرایش شد");
      getAllUsers(setloader, setdata, getPageNum(), 10, queries ?? "");
      setisopen(false);
    }
  });
};

export const getUserDocuments = (
  setLoader: any,
  setResponse: any,
  id: string
) => {
  setLoader(true);
  getViaAuth(`${API.surveys.get}/${id}`).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    }
  });
};

export const updateUserBalance = (
  setLoader: any,
  assets: Asset | undefined,
  data: AssetUpdateDetailModal | undefined,
  rowId: string | undefined,
  setData: any,
  error: AssetUpdateDetailModalError | undefined,
  setError: any,
  setModal: any,
  setStage: any,
  getWallets: any,
  location: Location,
  userid: any,
  googleAuthCode: string
) => {
  setLoader(true);
  if (googleAuthCode && googleAuthCode.length === 6) {
    put(API.wallet.updateWallet, {
      userId: userid,
      assets: [
        {
          symbol: assets?.assets.filter((asset) => asset.id === rowId)[0]
            .symbol,
          amount: data?.balance,
        },
      ],
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        message.success("موجودی کاربر با موفقیت اصلاح شد");
        setModal(false);
        setData({});
        setStage("DETAIL");
        getWallets();
      }
    });
  } else {
    setLoader(false);
    setError({
      ...error,
      googleAuthCode: "کد تایید باید دارای ۶ رقم باشد",
    });
  }
};

export const updateUser = (
  setLoader: any,
  data: UserDetail | undefined,
  id: string,
  atuhdataCode?: any,
  setauthdata?: React.Dispatch<any>,
  setopen?: React.Dispatch<boolean>,
  rolesList?: Role[]
) => {
  setLoader(true);
  var roles: any = [];

  if (data?.roles)
    data?.roles?.map((role) => {
      roles.push(
        rolesList?.filter(
          (q) => q.title?.toLowerCase() === role.toLowerCase()
        )[0].id
      );
    });

  postViaAuth(API.users.edit, {
    id: id,
    firstName: data?.firstName,
    lastName: data?.lastName,
    phoneNumber: data?.userName,
    email: data?.emailAddress,
    levelId: data?.level && data.level.id,
    roles: roles,
    nationalCode: data?.nationalCode && faNumToEnNum(data.nationalCode),
    birthDate: data?.birthDate,
    landline: data?.landline && faNumToEnNum(data.landline),
    isVip: data?.isVip,
    address: data?.address,
    googleAuthCode: atuhdataCode,
    password: data?.password,
    fatherName: data?.fatherName,
    isTwoStepActive: data?.isTwoStepActive,
    isWhiteList: data?.isWhiteList,
    twoFactorMethodType: data?.twoFactorType?.id,
    note: data?.note,
    introducer: data?.introducer,
    referralUniqueName: data?.referralUniqueName,
    withdrawalDisableTill: data?.withdrawalDisableTill,
    gender: data?.gender?.id,
    isCheckKYC: data?.isCheckKYC,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      message.success("اطلالاعات کاربر با موفقیت اصلاح شد.");
      setopen && setopen(false);
    }
  });
};
