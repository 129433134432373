import { API } from "apis";
import { message } from "antd";
import { BlackList } from "core/domains";
import { NavigateFunction } from "react-router-dom";
import {
  del,
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  put,
  responseValidator,
} from "utils/scripts/api";
import { getPageNum } from "../../utils/scripts/paggingHelper";

export const getAllBlackLists = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(API.blacklist.list + `/${page}/${size}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getBlackItemById = (setloader: any, setdata: any, id: string) => {
  setloader(true);
  getViaAuth(API.blacklist.getById + `/${id}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateBlackList = (
  setcardloader: any,
  data: any,
  navigate: any
) => {
  setcardloader(true);
  put(API.blacklist.update, {
    type: data?.type?.id,
    description: data?.description,
    value: data?.value,
    enabled: data?.enabled,
    id: data?.id,
  }).then((response: any) => {
    setcardloader(false);
    if (responseValidator(response.status)) {
      message.success("بلک لیست مورد نظر با موفقیت اصلاح شد.");
      navigate("/blacklist");
    }
  });
};

export const deleteBlackList = (setcardloader: any, id: any, setdata: any) => {
  setcardloader(true);
  del(API.blacklist.delete + `/${id}`, {}).then((response: any) => {
    setcardloader(false);
    if (responseValidator(response.status)) {
      message.success("بلک لیست مورد نظر با موفقیت حذف شد.");
      getAllBlackLists(setcardloader, setdata, 1, 20, "");
    }
  });
};

export const createBlackList = (
  setcardloader: any,
  setloader: any,
  data: BlackList | undefined,
  navigate: NavigateFunction
) => {
  setcardloader(true);
  postViaAuth(API.blacklist.add, {
    type: data?.type?.id,
    description: data?.description,
    value: data?.value,
    enabled: data?.enabled,
  }).then((response: any) => {
    setcardloader(false);
    if (responseValidator(response.status)) {
      message.success("نقش مورد نظر با موفقیت ایجاد شد.");
      navigate("/blacklist");
    }
  });
};
