import { Button, Card } from "components";
import "./index.scss";
import { useEffect, useState } from "react";
import { FilterValues } from "core/domains";
import fa_IR from "antd/lib/locale/fa_IR";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import "moment/locale/fa";
import { ConfigProvider } from "antd";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import { ReportsGateway as ReportsGatewayType } from "core/domains/reports-gateway/reports-gateway";
import { getAllReportsGateway } from "core/repos/reports-gateway";
import { digitSeparator } from "../../../utils/scripts/decimal";

export const ReportsGateway = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gateways, setGateways] = useState<ReportsGatewayType>();
  const [query, setQuery] = useState("");
  const [filterValues, setFilterValues] = useState<FilterValues>({});

  useEffect(() => {
    getAllReportsGateway(setLoading, setGateways);
  }, []);

  const onFilterHandler = () => {
    var _query = "";
    if (filterValues.startDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }from=${convertFaToEnCalendar(filterValues.startDate)}`;
    if (filterValues.endDate)
      _query += `${_query.includes("?") ? "&" : "?"}to=${convertFaToEnCalendar(
        filterValues.endDate
      )}`;
    setQuery(_query);
    getAllReportsGateway(setLoading, setGateways, _query);
  };

  return (
    <Card
      title="گزارش درگاه"
      className="vt-dashboard-reports-gateway"
      blur={loading}
    >
      <div className="filter-row">
        <div className="fields">
          <ConfigProvider locale={fa_IR} direction={"rtl"}>
            <JalaliLocaleListener />
            <DatePickerJalali.RangePicker
              onChange={(date: any) => {
                date !== null &&
                  setFilterValues({
                    ...filterValues,
                    startDate: date[0]!.format("YYYY/MM/DD"),
                    endDate: date[1]!.format("YYYY/MM/DD"),
                  });
              }}
            />
          </ConfigProvider>
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
              });
              getAllReportsGateway(setLoading, setGateways, "");
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>

      <div className="tables">
        <div className="table-container">
          <table className="reports-gateway-table">
            <thead>
              <tr>
                <th>عنوان</th>
                <th>مقدار</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>درگاه وندار</td>
                <td>{digitSeparator(gateways?.vandarTransactionsSum)}</td>
              </tr>
              <tr>
                <td>درگاه زیبال</td>
                <td>{digitSeparator(gateways?.zibalTransactionsSum)}</td>
              </tr>
              <tr>
                <td>درگاه جیبیمو</td>
                <td>{digitSeparator(gateways?.jibimoTransactionsSum)}</td>
              </tr>
              <tr>
                <td>درگاه پی استار</td>
                <td>{digitSeparator(gateways?.payStarTransactionsSum)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-container">
          <table className="reports-gateway-table">
            <thead>
              <tr>
                <th>عنوان</th>
                <th>مقدار</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>درگاه جیبیت</td>
                <td>{digitSeparator(gateways?.jibitTransactionsSum)}</td>
              </tr>
              <tr>
                <td>درگاه نکست پی</td>
                <td>{digitSeparator(gateways?.nextPayTransactionsSum)}</td>
              </tr>
              <tr>
                <td>شناسه دار جیبیمو</td>
                <td>{digitSeparator(gateways?.jibimoCashInTransactionsSum)}</td>
              </tr>
              <tr>
                <td>شناسه دار وندار</td>
                <td>{digitSeparator(gateways?.vandarCashInTransactionsSum)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};
