import { Button, Card } from "components";
import { Table } from "components/table/table";
import { Enum, FilterValues } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import "./marketing-queries.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { getAllUsersPersonalWallets } from "core/repos/users-personal-wallets";
import { getViaAuth, responseValidator } from "../../../utils/scripts/api";
import { API } from "../../../apis";
import { ConfigProvider } from "antd";

import fa_IR from "antd/lib/locale/fa_IR";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";

import "moment/locale/fa";
import moment from "jalali-moment";
import { digitSeparator } from "../../../utils/scripts/decimal"; // Import the locale you want

const { useState, useRef, useEffect } = React;
export const MarketingQueries: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<Enum[]>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [query, setQuery] = useState("");
  const [filterValues, setFilterValues] = useState<FilterValues>({});

  useEffect(() => {
    getMarketingQueries();
  }, []);
  const getMarketingQueries = (query: string = "") => {
    setCardLoading(true);
    getViaAuth(API.marketing.queries + query).then((data: any) => {
      setCardLoading(false);
      if (data.value) {
        const keys = Object.keys(data.value);
        const values = Object.values(data.value);
        const arr: any[] = [];
        if (responseValidator(data.status)) {
          setMarketing(data.value);
          keys.forEach((key, index) => {
            values.forEach((value, idx) => {
              index === idx &&
                arr.push({
                  title:
                    keys[idx] === "numberOfEnrollmentUsers"
                      ? "تعداد کاربر ثبت‌نام کرده"
                      : keys[idx] === "numberOfRegisteredUsers"
                      ? "تعداد کاربر احرازهویت کرده"
                      : keys[idx] === "numberOfFirstTimeTransactionsCrypto"
                      ? "تعداد کاربر کریپتویی"
                      : keys[idx] === "amountOfFirstTimeTransactionsCrypto"
                      ? "حجم کاربر کریپتویی"
                      : keys[idx] === "numberOfFirstTimeTransactionsPm"
                      ? "تعدادکاربر پرفکتی"
                      : keys[idx] === "amountOfFirstTimeTransactionsPm"
                      ? "حجم کاربر پرفکتی"
                      : keys[idx] === "numberOfTransactions"
                      ? "تعداد معاملات"
                      : keys[idx] === "amountOfBuyVolume"
                      ? "حجم معاملات خرید"
                      : keys[idx] === "amountOfSellVolume"
                      ? "حجم معاملات فروش"
                      : keys[idx] === "transactionVolumePerUser"
                      ? "میانگین حجم معامله‌ی هر کاربر"
                      : keys[idx] === "averageTransactionSize"
                      ? "میانگین حجم هر معامله"
                      : "",
                  value: values[index],
                });
            });
          });
          setMarketing(arr);
        }
      }
    });
  };

  const onFilterHandler = () => {
    let _query = "";

    if (filterValues?.startDate != "") {
      var from = moment(filterValues.startDate, "jYYYY/jMM/jDD");
      _query += `?from=${from.format("YYYY/MM/DD")}`;
    }
    if (filterValues?.endDate != "") {
      var to = moment(filterValues.endDate, "jYYYY/jMM/jDD");

      _query += `&to=${to.format("YYYY/MM/DD")}`;
    }
    setQuery(_query);
    getMarketingQueries(_query);
  };

  const columns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: any) => row?.title,
      width: "60%;",
    },
    {
      name: "مقدار",
      id: "value",
      selector: (row: any) => digitSeparator(row?.value),
      width: "40%",
    },
  ];

  return (
    <Card
      title="مدیریت بازاریابی"
      className="vt-dashboard-marketing-queries"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <ConfigProvider locale={fa_IR} direction={"rtl"}>
            <JalaliLocaleListener />
            <DatePickerJalali.RangePicker
              onChange={(date: any) => {
                date !== null &&
                  setFilterValues({
                    startDate: date[0]!.format("YYYY/MM/DD"),
                    endDate: date[1]!.format("YYYY/MM/DD"),
                  });
              }}
            />
          </ConfigProvider>

          {/*
          <ConfigProvider locale={fa_IR} direction={"rtl"}>
            <DatePickerJalali.RangePicker
              locale={locale}
              className="rangePicker"
              onChange={(date: any) => {
                console.log(date);
                date !== null &&
                  setFilterValues({
                    startDate: date[0]!.format("yyyy/MM/DD"),
                    endDate: date[1]!.format("yyyy/MM/DD"),
                  });
                console.log(filterValues);
              }}
            />
          </ConfigProvider>
*/}
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
              });
              getAllUsersPersonalWallets(
                setCardLoading,
                setMarketing,
                getPageNum(),
                pageSize,
                ""
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="marektingQueries-table"
        data={marketing}
        column={columns}
        setData={setMarketing}
        getData={getAllUsersPersonalWallets}
        setLoader={setCardLoading}
        filters={query}
      />
    </Card>
  );
};
