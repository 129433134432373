import * as React from "react";
import {Link, useNavigate} from "react-router-dom";

import "./sidebar.scss";
import SidebarItem from "components/sidebar/sidebar-item/sidebar-item";
import quitIcon from "assets/icons/quit.svg";
import {RootState} from "core/redux/store";
import {connect, ConnectedProps} from "react-redux";
import {Modal} from "components/modal/modal";
import {Button} from "components/button/button";
import {waitingCounts} from "core/domains";
import {MenuItem} from "utils/constants/common";
import {Badge} from "components/badge/badge";
import {Logo} from "../../apis/constants";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PropTypes extends PropsFromRedux {
  waitingCounts?: waitingCounts;
  items: MenuItem[];
}

const { useState } = React;

const Sidebar: React.FC<PropTypes> = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const totalWaitingCounts = {
    ...props.waitingCounts,
    actions:
      props.waitingCounts &&
      props.waitingCounts.approveIRTTransaction +
        props.waitingCounts.approveCryptoTransaction +
        props.waitingCounts.approveToAddBankAccount +
        props.waitingCounts.approveToUpgradeLevel,
  };
  var waitingCountKeys = Object.keys(totalWaitingCounts);
  var waitingCountValues = Object.values(totalWaitingCounts);

  const navigate = useNavigate();

  function handleModalSubmit() {
    setModalIsOpen(false);
    localStorage.clear();
    navigate("/signin");
  }

  return (
    <div
      className={`vt-sidebar ${
        props.state.sidebar === "close" ? "disActive" : ""
      }`}
    >
      <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
        <div className='confirm-box'>
          <div className='head'>
            <h4>آیا می‌خواهید خارج شوید؟</h4>
          </div>
          <div className='buttons'>
            <Button
              type='info'
              text='بله مطمئنم'
              htmlType='button'
              onClick={() => handleModalSubmit()}
            />
            <Button
              type='outlined'
              text='بازگشت'
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <Link to='/' className='sidebar-head'>
          <img src={Logo}/>
      </Link>
      <div className='sidebar-main'>
        {props.items.map((item) => {
          return (
            <SidebarItem
              id={item.id}
              title={item.title}
              badge={item.badge}
              waitingCountBadge={
                item.itemKey && (
                  <Badge
                      style={{"fontSize":"15px"}}
                    type='waiting'
                    text={
                      waitingCountValues?.filter(
                        (i, index) =>
                          waitingCountKeys &&
                          waitingCountKeys.filter(
                            (a, idx) => a === item.itemKey && index === idx
                          )[0]
                      )[0]
                    }
                  />
                )
              }
              waitingCounts={props.waitingCounts}
              icon={item.icon}
              link={item.link}
              childrens={item?.childrens}
              filled={item.filled ? item.filled : false}
            />
          );
        })}
      </div>

      <div className='sidebar-footer'>
        <div className='sidebar-item' onClick={() => setModalIsOpen(true)}>
          <img src={quitIcon} alt='quit' />
          <p className='title'>خروج</p>
        </div>
      </div>
    </div>
  );
};

export default connector(Sidebar);
