import * as React from "react";
import "./pop-ups.scss";
import { Button, Card } from "components";
import { Table } from "components/table/table";
import { PopUp } from "core/domains/popup/popup";
import { Link, useNavigate } from "react-router-dom";
import { FileSearchOutlined, PlusOutlined } from "@ant-design/icons";
import { TableColumn } from "react-data-table-component";
import { getAllPopUps } from "core/repos/popup";
import { PaginationList } from "core/domains";
import { getPageNum } from "utils/scripts/paggingHelper";
import { Popover, Tag, Tooltip } from "antd";
import { Badge } from "../../../components/badge/badge";

export const Popups: React.FC = () => {
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [popups, setPopups] = React.useState<PaginationList<PopUp>>();
  const [pageSize, setPageSize] = React.useState<number>(10);

  const navigate = useNavigate();

  React.useEffect(() => {
    !popups &&
      getAllPopUps(setCardLoading, setPopups, getPageNum(), pageSize, "");
  }, []);

  const columns: TableColumn<PopUp>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row) => row?.title,
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row) => row.description || "",
      cell: (record) => (
        <Popover content={record.description}>
          <span className="description-column">{record.description}</span>
        </Popover>
      ),
    },
    {
      name: "تعداد نمایش",
      id: "viewCount",
      selector: (row) => row.viewCount,
    },
    {
      name: "فاصله بین هر نمایش",
      id: "intervalView",
      selector: (row) => row.intervalView,
    },
    {
      name: "نمایش بعد از (ثانیه)",
      id: "viewAfterSecond",
      selector: (row) => row.viewAfterSecond,
    },
    {
      name: "نمایش برای سطح",
      id: "forLevelUniqueName",
      selector: (row) => row.forLevelUniqname.map((i) => i.title).join(","),
      cell: (record) => (
        <div className="levels-column">
          {record.forLevelUniqname.map((i) => (
            <Tag color="blue">
              {i.id == 1
                ? "سطح پایه"
                : i.id == 2
                ? "سطح 1"
                : i.id == 3
                ? "سطح 2"
                : i.id == 4
                ? "سطح 3"
                : i.id == 5
                ? "سطح 4"
                : ""}
            </Tag>
          ))}
        </div>
      ),
      width: "30%",
    },
    {
      name: "وضعیت",
      id: "isActive",
      width: "14%",
      selector: (row) => row?.isActive,
      cell: (record) => (
        <Badge
          text={record.isActive ? "فعال" : "غیر فعال"}
          type={record.isActive ? "success" : "error"}
        />
      ),
    },
    {
      name: "عملیات",
      id: "actions",
      cell: (record) => (
        <div className="actions">
          <Link to={`edit/${record.id}`}>
            <Tooltip placement="top" title="اصلاح پاپ‌آپ">
              <FileSearchOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="پاپ آپ ها"
      className="vt-dashboard-popup-popups"
      blur={cardLoading}
    >
      <div className="row">
        <Button
          type="primary"
          text="افزودن پاپ آپ"
          icon={<PlusOutlined />}
          onClick={() => navigate("add")}
        />
      </div>
      {popups && (
        <Table
          className="popup-popups-table"
          data={popups.items}
          column={columns}
          pagination={popups.pagination}
          getData={getAllPopUps}
          setData={setPopups}
          setLoader={setCardLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
