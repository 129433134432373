import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";
import {
  digitSeparator,
  faNumToEnNum,
} from "../../../../utils/scripts/decimal";
import { deleteCommaSperator } from "../../../../utils/scripts/exchange";

interface DelaySetting {
  hours?: number;
  totalBuyRequiredForDelay?: string;
}

export const DelaySettings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [delaySettings, setDelaySettings] = React.useState<DelaySetting>();

  const getDelaySettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.firstBuyDelay).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setDelaySettings(response.value);
      }
    });
  };

  const onChangeDelayConfirm = () => {
    setLoading(true);
    put(API.settings.changeFirstBuyDelay, {
      hours: delaySettings?.hours,
      totalBuyRequiredForDelay: delaySettings?.totalBuyRequiredForDelay,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getDelaySettings();
      }
    });
  };

  React.useEffect(() => {
    getDelaySettings();
  }, []);

  return (
    <>
      <Card blur={cardLoading} className="vt-dashboard-delay-settings">
        <Card title="تنظیمات تایمر اولین خرید" className="delay-item">
          <Input
            label="زمان تاخیر اولین برداشت (ساعت)"
            type="number"
            name="withdrawal-delay-pm"
            value={delaySettings?.hours?.toString()}
            onChange={(e) =>
              setDelaySettings({
                ...delaySettings,
                hours: parseInt(faNumToEnNum(e.target.value)),
              })
            }
            dir="ltr"
            maxLength={10}
          />
          <Input
            label="حداکثر مبلغ خریدهای یوزر بدون تایمر (تومان)"
            type="text"
            name="withdrawal-delay-pm"
            value={digitSeparator(
              delaySettings?.totalBuyRequiredForDelay?.toString() ?? "0"
            )}
            onChange={(e) =>
              setDelaySettings({
                ...delaySettings,
                totalBuyRequiredForDelay: deleteCommaSperator(
                  faNumToEnNum(e.target.value)
                ),
              })
            }
            dir="ltr"
            maxLength={18}
          />
          <Button
            htmlType="button"
            type="info"
            onClick={() => onChangeDelayConfirm()}
            loading={loading}
            disabled={loading}
            text={"ثبت تغییرات"}
          />
        </Card>
      </Card>
    </>
  );
};
