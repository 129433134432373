import * as React from "react";
import { Button, Card, Input } from "components";
import "./charge-perfectmoney.scss";
import { postViaAuth, responseValidator } from "utils/scripts/api";
import { Col, DatePicker, message, Row } from "antd";
import { API } from "apis";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../utils/scripts/copyToClipboard";

const { RangePicker } = DatePicker;

type VoucherCharge = {
  batchNum: string;
  amount: number;
  voucherNum: string;
};

const { useState, useEffect } = React;

export const PerfectmoneyVoucherCharge: React.FC = () => {
  // States
  const [voucherCode, setVoucherCode] = useState<string>();
  const [activationCode, setActivationCode] = useState<string>();
  const [voucherInfo, setVoucherInfo] = useState<VoucherCharge>();
  const [loading, setLoading] = useState<boolean>(false);

  const pmCharge = async () => {
    if (voucherCode) {
      setLoading(true);
      await postViaAuth<VoucherCharge>(API.pm.charge, {
        activationCode,
        voucher: voucherCode,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          message.success("شارژ با موفقیت انجام شد.");
          setVoucherInfo(response.value);
        } else {
        }
      });
    }
  };
  useEffect(() => {}, [voucherInfo]);
  return (
    <div className="vt-dashboard-charge-perfectmoney">
      <h3>شارژ ووچر پرفکت مانی</h3>
      <div className="tracking-info">
        <Card className="tracking">
          <div className="form">
            <Input
              label="کد ووچر"
              type="text"
              name="voucher"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              dir="ltr"
              onPressEnter={() => pmCharge()}
              maxLength={80}
            />
            <Input
              label="کد فعال ساز"
              type="text"
              name="activation"
              value={activationCode}
              onChange={(e) => setActivationCode(e.target.value)}
              dir="ltr"
              onPressEnter={() => pmCharge()}
              maxLength={80}
            />
            <Button
              htmlType="button"
              type="info"
              text="شارژ"
              onClick={() => pmCharge()}
              loading={loading}
              disabled={loading}
            />
          </div>
        </Card>
        <Card className="card-info" title="نتیجه شارژ ">
          <Row>
            <Col>
              <span>بچ</span>
            </Col>
            <Col>
              {voucherInfo && (
                <>
                  {" "}
                  <span>{voucherInfo.batchNum}</span>
                  <CopyOutlined
                    onClick={() => copyToClipboard(voucherInfo.batchNum, "بچ")}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>شماره ووچر</span>
            </Col>
            <Col>
              {voucherInfo && (
                <div className="d-flex">
                  <span>{voucherInfo.voucherNum}</span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(voucherInfo.batchNum.toString(), "مقدار")
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>مقدار</span>
            </Col>
            <Col>
              {voucherInfo && (
                <div className="d-flex">
                  <span>{voucherInfo.amount}</span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(voucherInfo.amount.toString(), "مقدار")
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
