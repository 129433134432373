import * as React from "react";
import Transfer, { TransferDirection } from "antd/lib/transfer";
import { __API } from "apis/api";
import { Button, Input } from "components";
import { Permission, BlackList, Enum } from "core/domains";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";

import "./edit.scss";

import { Col, Dropdown, Menu, Row, Spin, Switch } from "antd";
import { message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createBlackList,
  getBlackItemById,
  updateBlackList,
} from "core/repos/black-list";
import { DownOutlined } from "@ant-design/icons";

const { useState, useRef, useEffect } = React;

const initial_blacklist = [
  {
    id: 99,
    title: "نوع",
  },
  {
    id: 0,
    title: "کد ملی",
  },
  {
    id: 1,
    title: "tx-id",
  },
];

export const EditBlackList: React.FC = () => {
  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [blacklistData, setBlackListData] = useState<BlackList>();
  const [blacklistTypes, setBlacklistTypes] =
    useState<Enum[]>(initial_blacklist);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getBlackItemById(setIsCardLoading, setBlackListData, id);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-blacklists-create'>
          <h3>اصلاح بلک آیتم</h3>

          <form>
            <Row>
              <Input
                type='text'
                label='عنوان'
                value={blacklistData?.value}
                onChange={(e) =>
                  setBlackListData({ ...blacklistData, value: e.target.value })
                }
                name='title'
                placeholder='مقدار را وارد کنید'
              />
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {blacklistTypes &&
                      blacklistTypes.map((blacklistType) => (
                        <Menu.Item
                          onClick={() =>
                            setBlackListData({
                              ...blacklistData,
                              type: blacklistType,
                            })
                          }
                        >
                          {blacklistType.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement='bottom'
              >
                <Button
                  icon={<DownOutlined />}
                  type='outlined'
                  text={blacklistData?.type?.title || ""}
                />
              </Dropdown>
            </Row>

            <TextArea
              name='description'
              value={blacklistData?.description}
              onChange={(e) =>
                setBlackListData({
                  ...blacklistData,
                  description: e.target.value,
                })
              }
              placeholder='توضیحات را وارد کنید'
              max-maxLength={1000}
            />

            <Row>
              <Switch
                checkedChildren={"فعال"}
                unCheckedChildren={"غیر فعال"}
                checked={blacklistData?.enabled ? true : false}
                onChange={(checked) =>
                  setBlackListData({ ...blacklistData, enabled: checked })
                }
              />
            </Row>

            <div className='buttons'>
              <Button
                type='info'
                text='اصلاح'
                onClick={() =>
                  updateBlackList(setIsCardLoading, blacklistData, navigate)
                }
                loading={loading}
                htmlType='button'
              />
              <Button
                type='outlined'
                text='بازگشت'
                onClick={() => navigate("/blacklist")}
                htmlType='button'
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
