import React, { useEffect, useState } from "react";
import "styles/global.scss";
import { Provider } from "react-redux";
import { store } from "core/redux/store";
import { Xpay } from "routes/xpay";
import { APPNAME } from "apis/constants";
import { SidebarItems } from "utils/constants/common";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Xpay />
    </Provider>
  );
};

export default App;
