import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Input } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import { PaginationList, Ticket } from "core/domains";
import { getAllTickets } from "core/repos/ticket";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import "./index.scss";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { Tooltip } from "antd";

const { useState, useRef, useEffect } = React;

export const TicketManagement: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [ticketList, setTicketList] = useState<PaginationList<Ticket>>();

  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    !ticketList &&
      getAllTickets(setCardLoading, setTicketList, getPageNum(), pageSize, "");
  }, []);

  const onFilterHandler = () => {
    var query = "";
    if (searchValue !== "") {
      query += `${query.includes("?") ? "&" : "?"}keywords=${searchValue}`;
    }
    getAllTickets(setCardLoading, setTicketList, 1, pageSize, query);
  };

  const columns: TableColumn<Ticket>[] = [
    {
      name: "شماره تیکت",
      id: "ticketNumber",
      selector: (row) => row.ticketNumber,
    },
    {
      name: "موضوع تیکت",
      id: "title",
      selector: (row) => row.title,
    },
    {
      name: "فرستنده",
      id: "createdBy",
      selector: (row) => row.createdBy,
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row) => row.state.id,
      cell: (row) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 1 ? "success" : row.state.id == 2 ? "info" : "error"
          }
        />
      ),
    },
    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row: Ticket) => row.createdOn,
      cell: (row: Ticket) => (
        <div className="column-date">
          <span className="day">
            {moment.utc(row.createdOn).locale("fa").format("YYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).locale("fa").format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: Ticket) => (
        <div className="actions">
          <Link to={`detail/${record.id}`}>
            <Tooltip placement="top" title="جزئیات تیکت">
              <EditOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Card blur={cardLoading} title="تیکت ها" className="vt-dashboard-tickets">
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              className="search"
              value={searchValue}
              placeholder="جستجو کنید ..."
              name="search"
              onPressEnter={() => onFilterHandler()}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => onFilterHandler()}
            />
            <span
              onClick={() => {
                setSearchValue("");
                getAllTickets(
                  setCardLoading,
                  setTicketList,
                  getPageNum(),
                  pageSize,
                  ""
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {ticketList && (
          <Table
            className="ticket-table"
            data={ticketList.items}
            pagination={ticketList.pagination}
            setLoader={setCardLoading}
            setData={setTicketList}
            getData={getAllTickets}
            column={columns}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Card>
    </>
  );
};
