import {
  CaretDownOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Checkbox, Row, Spin } from "antd";
import { Button, Input, Modal } from "components";
import * as React from "react";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
  IsNumeric,
} from "utils/scripts/decimal";
import "./assets.scss";
import { Link, useLocation } from "react-router-dom";
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from "../../../../../../utils/scripts/api";
import { API } from "../../../../../../apis";
import SkeletonAsset from "./skeletons/skeleton-asset";
import { Asset, AssetItem } from "core/domains/markets/asset";
import ReactCodeInput from "react-verification-code-input";
import {
  AssetUpdateDetailModal,
  AssetUpdateDetailModalError,
} from "core/domains/users/detail/assets";
import { updateUserBalance } from "core/repos/user";
import { AssetCart } from "./detail/components/asset-cart";

export const UserAssetsList: React.FC = () => {
  const [syncBtnLoading, setSyncBtnLoading] = React.useState<boolean>(false);
  const [chartIsVisible, setChartIsVisible] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<"asc" | "desc">();
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [results, setResults] = React.useState<AssetItem[]>();
  const [assets, setAssets] = React.useState<Asset>();

  const [loading, setLoading] = React.useState<boolean>();

  const [authCode, setAuthCode] = React.useState<string>();

  const [detailInput, setDetailInput] =
    React.useState<AssetUpdateDetailModal>();
  const [detailError, setDetailError] =
    React.useState<AssetUpdateDetailModalError>();
  const [selectedRow, setSelectedRow] = React.useState<string>();
  const [stage, setStage] = React.useState<"CODE" | "DETAIL">("DETAIL");
  const [checked, setChecked] = React.useState<boolean>(false);

  const location = useLocation();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  const onChangeSortHandler = (value: string) => {
    setSort("asc");
    setResults(
      results && results.sort((a, b) => (a.balance > b.balance ? 1 : -1))
    );
  };

  function filterSingleCoin(symbol: string) {
    return assets?.assets.filter(
      (asset) => asset.symbol.toLowerCase() === symbol.toLowerCase()
    )[0];
  }

  const handleNumChange = (value: string, field: string) => {
    // all number Fa to En
    if (value !== "") value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== "" && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setDetailInput({
      ...detailInput,
      balance: value,
    });
  };

  const onSearchChangeHandler = (value: string) => {
    setSearch(value);

    setResults(
      assets?.assets &&
        assets?.assets.filter(
          (s) =>
            s.name.includes(value) ||
            s.symbol.toUpperCase().includes(value.toUpperCase())
        )
    );
  };
  React.useEffect(() => {
    getWallets();
  }, []);

  React.useEffect(() => {
    assets && setResults(assets.assets);
  }, [assets]);

  const getWallets = async () => {
    setLoading(true);
    await getViaAuth<Asset>(
      API.wallet.userWallet + `${location.pathname.slice(14)}`
    ).then((data: any) => {
      setLoading(false);
      if (responseValidator(data.status) && data.value) {
        data.value && setAssets(data.value);
      }
    });
  };

  const detailsHandle = (id: string) => {
    setModalIsOpen(true);
    setSelectedRow(id);
    setDetailInput({
      ...detailInput,
      balance: assets?.assets
        .filter((asset) => asset.id === id)[0]
        .balance.toString(),
    });
  };

  const syncWalletHandle = async () => {
    setSyncBtnLoading(true);
    await postViaAuth(API.wallet.syncWallet, {
      userId: id,
    }).then((response: any) => {
      setSyncBtnLoading(false);
      if (responseValidator(response.status)) {
        getWallets();
      }
    });
  };

  const closeDetailsHandle = () => {
    setModalIsOpen(false);
    setStage("DETAIL");
  };

  const onVerifyGoogleAuth = (googleCode: string) => {
    updateUserBalance(
      setLoading,
      assets,
      detailInput,
      selectedRow,
      setDetailInput,
      detailError,
      setDetailError,
      setModalIsOpen,
      setStage,
      getWallets,
      location,
      id,
      googleCode
    );
  };

  const onUpdateUserBalance = () => {
    detailInput && setStage("CODE");
  };

  const Head = (pr: any): React.ReactElement => {
    return (
      <div className="head">
        <div>
          <h4>{pr.title}</h4>
          <svg
            onClick={() => closeDetailsHandle()}
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0711 1.26477C11.29 0.483723 10.0237 0.483723 9.24264 1.26477L6.25006 4.25735L3.41421 1.42151C2.63316 0.640462 1.36683 0.640462 0.585786 1.42151C-0.195262 2.20256 -0.195262 3.46889 0.585786 4.24994L3.42163 7.08578L0.757359 9.75005C-0.0236896 10.5311 -0.0236896 11.7974 0.757359 12.5785C1.53841 13.3595 2.80474 13.3595 3.58579 12.5785L6.25006 9.91421L9.07107 12.7352C9.85212 13.5163 11.1184 13.5163 11.8995 12.7352C12.6805 11.9542 12.6805 10.6878 11.8995 9.90679L9.07848 7.08578L12.0711 4.0932C12.8521 3.31215 12.8521 2.04582 12.0711 1.26477Z"
              fill="#383952"
            />
          </svg>
        </div>
        <p>{pr.subtitle}</p>
      </div>
    );
  };

  function renderStage() {
    switch (stage) {
      case "CODE":
        return (
          <>
            <div className="google-auth code app-code">
              <ReactCodeInput
                className={`input `}
                onComplete={(e) => {
                  onVerifyGoogleAuth(e);
                }}
                fieldWidth={48}
                values={
                  detailInput?.googleAuthCode
                    ? detailInput?.googleAuthCode.split("")
                    : []
                }
                autoFocus={true}
                onChange={(value: any) => {
                  setAuthCode(value);
                }}
              />
              <div className="stats">
                <div className="errors">
                  {detailError?.googleAuthCode && (
                    <>
                      <QuestionCircleOutlined />
                      <span>{detailError?.googleAuthCode}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="buttons">
              <Button
                type="info"
                text="تایید"
                onClick={() => authCode && onVerifyGoogleAuth(authCode)}
                disabled={loading ? true : false}
                loading={loading}
              />
              <Button
                type="outlined"
                text="بازگشت"
                onClick={() => setStage("DETAIL")}
              />
            </div>
          </>
        );
      case "DETAIL":
        return (
          <>
            <Spin spinning={modalLoading}>
              <div className="content">
                <p>از این قسمت می‌توانید موجودی کاربر را تغییر دهید</p>
                <Input
                  type="text"
                  name="maxIrtWithdrawal"
                  value={digitSeparator(detailInput?.balance)}
                  onChange={(e) =>
                    handleNumChange(
                      deleteCommaSeparator(e.target.value),
                      "maxIrtWithdrawl"
                    )
                  }
                  label={`موجودی 
                `}
                  placeholder="موجودی جدید را وارد کنید"
                  dir={detailInput?.balance?.length ? "ltr" : "rtl"}
                  maxLength={30}
                />
              </div>

              <div className="buttons">
                <Button
                  type="info"
                  text="ویرایش"
                  htmlType="button"
                  onClick={() => onUpdateUserBalance()}
                  disabled={!detailInput?.balance || loading ? true : false}
                  loading={loading}
                />
                <Button
                  type="outlined"
                  text="بستن"
                  onClick={() => closeDetailsHandle()}
                />
              </div>
            </Spin>
          </>
        );

      default:
        return <>invalid stage</>;
    }
  }

  return (
    <div className="vt-user-assets">
      <Modal
        closeAble
        title={
          stage === "CODE"
            ? "کد تایید Google Atuh"
            : `موجودی ${
                (selectedRow &&
                  assets?.assets.filter((asset) => asset.id === selectedRow)[0]
                    .name) ??
                "ارز"
              }`
        }
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        className="user-assets-modal"
      >
        {renderStage()}
      </Modal>

      <div className="sync-ro">
        <Button
          type="primary"
          onClick={() => syncWalletHandle()}
          text="به روز رسانی کیف پول"
          loading={syncBtnLoading}
          disabled={syncBtnLoading}
        />
      </div>

      <Spin spinning={loading}>
        {assets && (
          <Row className="assets-row">
            <div className="head">
              <div className="mobile-show">
                <h4>سبد دارایی ها</h4>
              </div>
              <Input
                type="text"
                value={search}
                placeholder="ارز مورد نظر خود را جست و جو کنید"
                onChange={(e) => onSearchChangeHandler(e.target.value)}
                name="searchValue"
                adorments={{
                  startAdornment: {
                    adornmentIcon: <SearchOutlined />,
                  },
                  endAdornment: {
                    adornmentIcon: <CaretDownOutlined color="#3f62fe" />,
                  },
                }}
              />
              <div className="checkbox" onClick={(e) => setChecked(!checked)}>
                <Checkbox checked={checked} />
                <span>نمایش ارز های دارای موجودی</span>
              </div>
            </div>
            <div className="mine-assets-list">
              <ul className="desktop-show">
                {search === "" && (
                  <>
                    <AssetCart
                      device="desktop"
                      asset={filterSingleCoin("irt")}
                      isPinned={true}
                      detailsHandle={detailsHandle}
                    />
                  </>
                )}
                {results ? (
                  results
                    .filter((s) => (checked ? s.balance !== 0 : true))
                    .filter((s) => s.symbol.toLowerCase() !== "irt")
                    .map((coin) => {
                      return (
                        <li>
                          <span className="coin-info">
                            <img
                              src={`/coins/${coin.symbol.toLowerCase()}.svg`}
                              alt=""
                            />
                            <span>{coin.name}</span>
                            <span>{coin.symbol.toUpperCase()}</span>
                          </span>

                          <span className="amount-balance">
                            موجودی : <b>{digitSeparator(coin.balance)}</b>
                          </span>

                          <span className="price">
                            قیمت جهانی : <b>{coin.lastPrice}</b>
                          </span>

                          <span className="irt-balance">
                            معادل تومانی موجودی:{" "}
                            <b>
                              {" "}
                              {digitSeparator(coin?.value.toFixed(0))} تومان
                            </b>
                          </span>

                          <span className="actions">
                            <Button
                              className="detail-button"
                              type={"info"}
                              text={"جزئیات"}
                              onClick={() => detailsHandle(coin.id)}
                            />
                          </span>
                        </li>
                      );
                    })
                ) : (
                  <>
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                  </>
                )}
              </ul>
              <ul className="mobile-show">
                {results ? (
                  results
                    .filter((s) => s.balance !== 0)
                    .map((coin) => {
                      return (
                        <li>
                          <div>
                            <div className="right">
                              <img src={`/coins/${coin.symbol}.svg`} alt="" />
                              <p>{coin.name}</p>
                              <span>{coin.symbol}</span>
                            </div>
                            {coin.symbol === "irt" ? (
                              <div className="left">
                                <span>موجودی:</span>
                                <p>{digitSeparator(coin.balance.toFixed(0))}</p>
                              </div>
                            ) : (
                              <div className="left">
                                <span>قیمت جهانی:</span>
                                <p>{coin.lastPrice}$</p>
                              </div>
                            )}
                          </div>
                          {coin.symbol !== "irt" && (
                            <>
                              <div>
                                <p className="right">موجودی شما:</p>
                                <p className="left">{coin.balance}</p>
                              </div>
                            </>
                          )}

                          <div className="actions">
                            <Link
                              className="detail"
                              to={`detail/${coin.symbol}`}
                            >
                              <Button
                                className="detail-button"
                                type={"info"}
                                text={"جزئیات"}
                              />
                            </Link>
                          </div>
                        </li>
                      );
                    })
                ) : (
                  <>
                    <SkeletonAsset device="mobile" />
                    <SkeletonAsset device="mobile" />
                    <SkeletonAsset device="mobile" />
                    <SkeletonAsset device="mobile" />
                    <SkeletonAsset device="mobile" />
                  </>
                )}
              </ul>
              {/* {assets && assets.assets && (
              <Pagination
                data={results ? results : []}
                setData={(e: any) => setResults(e)}
                size={10}
              />
            )} */}
            </div>
          </Row>
        )}
      </Spin>
    </div>
  );
};
