import * as React from "react";
import "./service-logs.scss";
import { Card } from "components";
import { Table } from "components/table/table";
import { ServiceLog } from "core/domains/service-log/service-log";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { getAllServiceLogs } from "core/repos/service-log";
import { PaginationList } from "core/domains";
import { getPageNum } from "utils/scripts/paggingHelper";
import moment from "jalali-moment";

export const ServiceLogs: React.FC = () => {
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [serviceLogs, setServiceLogs] =
    React.useState<PaginationList<ServiceLog>>();

  const navigate = useNavigate();

  React.useEffect(() => {
    !serviceLogs &&
      getAllServiceLogs(
        setCardLoading,
        setServiceLogs,
        getPageNum(),
        pageSize,
        ""
      );
  }, []);

  const columns: TableColumn<ServiceLog>[] = [
    {
      name: "نام سرویس",
      id: "serviceName",
      selector: (row) => row?.serviceName,
      width: "150px",
    },
    {
      name: "آدرس",
      id: "url",
      selector: (row) => row.url,
      width: "200px",
    },
    {
      name: "Request Body",
      id: "requestBody",
      selector: (row) => row.requestBody,
      width: "200px",
    },
    {
      name: "Response Body",
      id: "responseBody",
      selector: (row) => row.responseBody,
      width: "200px",
    },
    {
      name: "Request Header",
      id: "requestHeader",
      selector: (row) => row.requestHeader,
      width: "200px",
    },
    {
      name: "ایجاد شده توسط",
      id: "createdBy",
      selector: (row) => row.createdBy,
      width: "100px",
    },
    {
      name: "ایجاد شده در",
      id: "createdOn",
      selector: (row) => row.createdOn,
      width: "200px",
      cell: (row) => (
        <div className="column-date">
          <span className="day">
            {row.createdOn &&
              moment
                .utc(row.createdOn)
                .locale("fa")
                .local()
                .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {row.createdOn && moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="گزارشات فراخوانی سرویس‌ها"
      className="vt-dashboard-service-logs"
      blur={cardLoading}
    >
      {serviceLogs && (
        <Table
          className="service-logs-table"
          data={serviceLogs.items}
          column={columns}
          pagination={serviceLogs.pagination}
          getData={getAllServiceLogs}
          setData={setServiceLogs}
          setLoader={setCardLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
