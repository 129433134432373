import { EditOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import { __API } from "apis/api";
import { Card } from "components";
import { Table } from "components/table/table";
import { Level, Role } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import "./levels.scss";

const { useState, useEffect } = React;

export const Levels: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [levelsList, setLevelsList] = useState<Role[]>([]);

  useEffect(() => {
    (async () => {
      setCardLoading(true);
      await getViaAuth(__API.levels.getAllLevels, {}).then((response: any) => {
        if (responseValidator(response.status)) {
          setLevelsList(response.value);
        }
      });
      setCardLoading(false);
    })();
  }, []);

  const columns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "name",
      selector: (row: Level) => (row?.name ? row.name : ""),
      width: "100px",
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row: Level) => (row?.description ? row.description : ""),
      width: "750px",
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: Role) => (
        <div className='actions'>
          <Link to={`edit/${record.id}`}>
            <Tooltip placement='top' title='اصلاح'>
              <EditOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <Card
      title='مدیریت سطوح احراز هویت'
      className='vt-dashboard-levels'
      blur={cardLoading}
    >
      {levelsList && (
        <Table className='levels-table' data={levelsList} column={columns} />
      )}
    </Card>
  );
};
