import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  SettingTwoTone,
} from "@ant-design/icons";
import { Divider, Dropdown, Menu, message, Row, Select, Switch } from "antd";
import { __API } from "apis/api";
import { Button, Card, Input } from "components";

import * as React from "react";
import { Fragment } from "react";
import { get, getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";
import { Enum, EnumAsString, Level, SettingGoogleAuthType } from "core/domains";
import { SettingGoogleAuth } from "./google-auth/google-auth";
import { getAlllLevels } from "../../../core/repos/level";
import { API } from "../../../apis";
import {
  deleteCommaSeparator,
  digitSeparator,
} from "../../../utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

interface CashInSetting {
  isActive?: boolean;
  cashInItemSettings?: CashInItemSettings[];
  minimumLevelForCashIn?: number;
  hour?: number | string;
  amount?: number | string;
}

interface CashInItemSettings {
  bankAccounts: CashInBankAccount[];
  mode: number;
}

interface CashInBankAccount {
  isDepositActive: boolean;
  iBan: string;
  accountNumber: string;
  bank: EnumAsString;
  bankAccountOwner: string;
  isDefault: boolean;
}

export const CashInSettings: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [dropDownLoading, setDropDownLoading] = useState<boolean>(false);

  const [loadingCashInActivation, setLoadingCashInActivation] =
    useState<boolean>(false);
  const [banks, setBanks] = useState<any>();

  const [levelList, setLevelList] = React.useState<Level[]>([]);

  const [cashInModes, setCashInModes] = useState<Enum[]>();
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [selectedItem, setSelectedItem] = useState<CashInItemSettings>();

  const [cashInSettingsList, setCashInSettingsList] = useState<CashInSetting>({
    cashInItemSettings: [],
    isActive: false,
    minimumLevelForCashIn: 3,
    hour: 0,
    amount: 0,
  });

  const onAddBankAccountHandle = (newItemMode: number) => {
    const selectedCashInItem = cashInSettingsList.cashInItemSettings?.find(
      (cashInItem) => cashInItem.mode === newItemMode
    );
    const otherCashInItems = cashInSettingsList.cashInItemSettings?.filter(
      (cashInItem) => cashInItem.mode !== newItemMode
    );

    var newEmptyItem = {
      accountNumber: "",
      bank: {
        id: "choose",
        title: "انتخاب بانک",
      },
      bankAccountOwner: "",
      iBan: "",
      isDepositActive: false,
      isDefault: false,
    };

    selectedCashInItem?.bankAccounts &&
      selectedCashInItem?.bankAccounts.push(newEmptyItem);

    selectedCashInItem?.bankAccounts &&
      otherCashInItems?.push({
        mode: newItemMode,
        bankAccounts: selectedCashInItem?.bankAccounts,
      });

    setCashInSettingsList({
      cashInItemSettings: otherCashInItems?.sort((a, b) => a.mode - b.mode),
      isActive: cashInSettingsList.isActive,
      minimumLevelForCashIn: cashInSettingsList.minimumLevelForCashIn,
    });
  };
  const onChangeMinLevelHandler = (level: number) => {
    setDropDownLoading(true);
    put(API.settings.changeCashInMinLevel, {
      MinimumLevel: level,
    }).then((response: any) => {
      if (responseValidator(response.status)) {
        setDropDownLoading(false);
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
      }
    });
  };
  const onChangeHandle = (
    newItemMode: number,
    id: number,
    field: string,
    value: string | boolean
  ) => {
    var newData: CashInSetting | undefined;

    var copyOfCashinSettings = cashInSettingsList;
    var copyOfCashinItems = cashInSettingsList.cashInItemSettings;

    var selectedCashInItem = copyOfCashinItems?.find(
      (cashInItem) => cashInItem.mode === newItemMode
    );

    var otherCashinItems = copyOfCashinItems?.filter(
      (cashinItem) => cashinItem.mode !== newItemMode
    );

    var otherBankAccounts = selectedCashInItem?.bankAccounts?.filter(
      (_, index) => index !== id
    );

    var selectedBankAccount =
      selectedCashInItem?.bankAccounts && selectedCashInItem?.bankAccounts[id];

    var newInputData: CashInBankAccount | undefined = selectedBankAccount && {
      ...selectedBankAccount,
      [field]: value,
    };

    newInputData && otherBankAccounts?.push(newInputData);

    selectedCashInItem = {
      mode: selectedCashInItem?.mode,
      bankAccounts: otherBankAccounts?.sort((a, b) =>
        a.bankAccountOwner > b.bankAccountOwner ? -1 : 1
      ),
    } as CashInItemSettings;

    selectedCashInItem && otherCashinItems?.push(selectedCashInItem);

    newData = {
      cashInItemSettings: otherCashinItems?.sort((a, b) => a.mode - b.mode),
      isActive: copyOfCashinSettings.isActive,
      minimumLevelForCashIn: copyOfCashinSettings.minimumLevelForCashIn,
    };

    setCashInSettingsList(newData);
  };

  const onRemoveHandle = (newItemMode: number, id: number) => {
    var newData: CashInSetting | undefined;

    var copyOfCashinSettings = cashInSettingsList;
    var copyOfCashinItems = cashInSettingsList.cashInItemSettings;

    var selectedCashInItem = copyOfCashinItems?.find(
      (cashInItem) => cashInItem.mode === newItemMode
    );

    var otherCashinItems = copyOfCashinItems?.filter(
      (cashinItem) => cashinItem.mode !== newItemMode
    );

    var otherBankAccounts = selectedCashInItem?.bankAccounts?.filter(
      (_, index) => index !== id
    );

    selectedCashInItem = {
      mode: selectedCashInItem?.mode,
      bankAccounts: otherBankAccounts,
    } as CashInItemSettings;

    selectedCashInItem && otherCashinItems?.push(selectedCashInItem);

    newData = {
      cashInItemSettings: otherCashinItems?.sort((a, b) => a.mode - b.mode),
      isActive: copyOfCashinSettings.isActive,
      minimumLevelForCashIn: copyOfCashinSettings.minimumLevelForCashIn,
    };

    setCashInSettingsList(newData);
  };

  const onIsActiveChangeHandler = async (value: boolean) => {
    await updateCashInSettingActivation(value);

    setCashInSettingsList({ ...cashInSettingsList, isActive: value });
  };

  const getAllCashInSettings = async () => {
    var data: CashInSetting = {};
    setCardLoading(true);
    await getViaAuth<CashInSetting>(__API.settings.cashInSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          Object.assign(data, {
            ...response.value,

            cashInItemSettings: response.value.cashInItemSettings.map(
              (ci: CashInItemSettings) => ({
                ...ci,
                bankAccounts: ci.bankAccounts.map((account: any) => ({
                  ...account,
                  bank: {
                    id: account.bank,
                    title: banks?.find(
                      (e: any) =>
                        e.id.toLowerCase() == account.bank.toLowerCase()
                    )?.title,
                  },
                })),
              })
            ),
          });

          setCashInSettingsList(data);
        }
      }
    );
  };

  const getAllBanks = async () => {
    setCardLoading(true);
    await get(__API.banks.allBanks + `/${1}/50`).then((response: any) => {
      setCardLoading(false);
      var arr: any = [];
      if (responseValidator(response.status)) {
        response.data.value.items.forEach((e: any) =>
          arr.push({
            id: e.englishName,
            title: e.name,
          })
        );
        setBanks(arr);
      }
    });
  };

  const getAllCashInSettingsModes = async () => {
    setCardLoading(true);
    await get(__API.enums.cashinModes).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setCashInModes(response.data.value);
      }
    });
  };

  const updateCashInSetting = async (googleAuthCode: string) => {
    setLoading(true);
    await put(`${__API.settings.changeCashInSettings}`, {
      hour: Number(cashInSettingsList.hour),
      amount: Number(cashInSettingsList.amount),
      mode: selectedItem?.mode,
      bankAccounts: selectedItem?.bankAccounts.map((e) => ({
        ...e,
        bank: e.bank.id,
      })),
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        getAllCashInSettings();
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
        setModalIsOpen(false);
        setGoogleAuth({
          code: "",
        });
      }
    });
  };

  const updateCashInSettingActivation = async (value: boolean) => {
    setLoadingCashInActivation(true);
    await put(`${__API.settings.changeCashInSettingsActivation}`, {
      isActive: value,
    }).then((response: any) => {
      setLoadingCashInActivation(false);
      if (responseValidator(response.status)) {
        getAllCashInSettings();
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
      }
    });
  };

  const openGoogleAuthModal = (item: CashInItemSettings) => {
    setModalIsOpen(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    levelList.length === 0 && getAlllLevels(setCardLoading, setLevelList);

    getAllBanks();
  }, []);

  useEffect(() => {
    if (banks && banks.length > 0) {
      getAllCashInSettingsModes();
      getAllCashInSettings();
    }
  }, [banks]);
  useEffect(() => {}, [levelList]);
  return (
    <>
      <SettingGoogleAuth
        submitData={updateCashInSetting}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-cash-in-settings">
        <h3>تنظیمات واریز شناسه دار</h3>

        <Row className="title">
          <div>
            <h4>وضعیت واریز شناسه دار</h4>

            <div className="vt-switch-row">
              <Switch
                title="وضعیت"
                checked={cashInSettingsList.isActive}
                checkedChildren="فعال"
                unCheckedChildren="غیر فعال"
                loading={loadingCashInActivation}
                onChange={(e) => onIsActiveChangeHandler(e)}
              />
            </div>
          </div>
          <div>
            <label>حداقل سطح کاربری : </label>
            {levelList &&
              levelList.length > 0 &&
              cashInSettingsList.minimumLevelForCashIn && (
                <Select
                  loading={dropDownLoading}
                  value={
                    levelList.filter(
                      (level) =>
                        level.uniqueName?.id ==
                        cashInSettingsList.minimumLevelForCashIn
                    )[0].name
                  }
                  onChange={(value) => {
                    onChangeMinLevelHandler(parseInt(value));

                    setCashInSettingsList({
                      ...cashInSettingsList,
                      minimumLevelForCashIn: parseInt(value),
                    });
                  }}
                >
                  {levelList.map((level) => (
                    <Select.Option key={level.uniqueName?.id.toString()}>
                      {level.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
          </div>
        </Row>
        <Row className={"title"} style={{ marginTop: "32px" }}>
          <Input
            label={"مدت زمان قفل شدن کاربر بعد از تایید واریز شناسه دار"}
            name={"hour"}
            type={"text"}
            value={cashInSettingsList.hour?.toString()}
            onChange={(e) =>
              setCashInSettingsList({
                ...cashInSettingsList,
                hour: e.target.value,
              })
            }
          />
          <Input
            label={"حداکثر مقدار واریز شناسه دار بدون اعمال شدن زمان"}
            name={"amount"}
            type={"text"}
            value={digitSeparator(cashInSettingsList.amount)?.toString()}
            onChange={(e) =>
              setCashInSettingsList({
                ...cashInSettingsList,
                amount: deleteCommaSeparator(e.target.value),
              })
            }
          />
        </Row>
        <Divider />
        {(cashInSettingsList.cashInItemSettings?.length ?? 0) > 0 && (
          <div className="cash-in-settings-list">
            {cashInSettingsList.cashInItemSettings?.map(
              (cashInSetting, index) => {
                return (
                  <div className="cash-in-setting-item-card">
                    <Row>
                      <div className="cash-in-setting-info">
                        <SettingTwoTone />
                        <span>
                          {
                            cashInModes?.filter(
                              (e) => e.id === cashInSetting.mode
                            )[0]?.title
                          }
                        </span>
                      </div>
                      <div className="bank-accounts">
                        {cashInSetting.bankAccounts?.map(
                          (bankAccount: CashInBankAccount, idx) => {
                            return (
                              <div className="bank-item">
                                <Button
                                  type="error"
                                  icon={<DeleteOutlined />}
                                  text="حذف حساب بانکی"
                                  onClick={() =>
                                    onRemoveHandle(cashInSetting.mode, idx)
                                  }
                                />
                                <Input
                                  type="text"
                                  name="iBan"
                                  label="شماره شبا"
                                  value={bankAccount.iBan}
                                  onChange={(e) =>
                                    onChangeHandle(
                                      cashInSetting.mode,
                                      idx,
                                      "iBan",
                                      e.target.value
                                    )
                                  }
                                  maxLength={24}
                                />
                                <Input
                                  type="text"
                                  name="accountNumber"
                                  label="شماره حساب"
                                  value={bankAccount.accountNumber}
                                  onChange={(e) =>
                                    onChangeHandle(
                                      cashInSetting.mode,
                                      idx,
                                      "accountNumber",
                                      e.target.value
                                    )
                                  }
                                />
                                <Input
                                  type="text"
                                  name="bankAccountOwner"
                                  label="نام صاحب حساب"
                                  value={bankAccount.bankAccountOwner}
                                  onChange={(e) =>
                                    onChangeHandle(
                                      cashInSetting.mode,
                                      idx,
                                      "bankAccountOwner",
                                      e.target.value
                                    )
                                  }
                                />
                                {banks && (
                                  <Dropdown
                                    trigger={["click", "hover"]}
                                    overlay={
                                      <Menu className="bank-dropdown">
                                        {banks &&
                                          [
                                            ...banks,
                                            { title: "همه", id: 100 },
                                          ].map((bank) => (
                                            <Menu.Item
                                              onClick={() => {
                                                onChangeHandle(
                                                  cashInSetting.mode,
                                                  idx,
                                                  "bank",
                                                  bank
                                                );
                                              }}
                                            >
                                              {bank.title}
                                            </Menu.Item>
                                          ))}
                                      </Menu>
                                    }
                                    placement="bottom"
                                  >
                                    <Button
                                      icon={<DownOutlined />}
                                      type="outlined"
                                      text={bankAccount.bank.title}
                                    />
                                  </Dropdown>
                                )}

                                <div className="vt-switch-row">
                                  <label htmlFor="">وضعیت واریز</label>
                                  <Switch
                                    title="وضعیت واریز"
                                    checked={bankAccount.isDepositActive}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(e) =>
                                      onChangeHandle(
                                        cashInSetting.mode,
                                        idx,
                                        "isDepositActive",
                                        e
                                      )
                                    }
                                  />
                                </div>
                                <div className="vt-switch-row">
                                  <label htmlFor="">حساب پیشفرض</label>
                                  <Switch
                                    title="حساب پیشفرض"
                                    checked={bankAccount.isDefault}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(e) =>
                                      onChangeHandle(
                                        cashInSetting.mode,
                                        idx,
                                        "isDefault",
                                        e
                                      )
                                    }
                                  />
                                </div>
                                <Divider type="horizontal" />
                              </div>
                            );
                          }
                        )}
                        <Button
                          type="info"
                          icon={<PlusOutlined />}
                          text="افزودن حساب بانکی"
                          onClick={() =>
                            onAddBankAccountHandle(cashInSetting.mode)
                          }
                          htmlType="button"
                        />
                      </div>
                      <Button
                        onClick={() => openGoogleAuthModal(cashInSetting)}
                        text="ویرایش تنظیمات"
                        type="primary"
                        htmlType="button"
                      />
                    </Row>
                  </div>
                );
              }
            )}
          </div>
        )}
      </Card>
    </>
  );
};
