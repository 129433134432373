import * as React from "react";
import "./notifications.scss";
import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import {
  Notification,
  NotificationFilters,
} from "core/domains/notification/notification";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { TableColumn } from "react-data-table-component";
import {
  getAllNotifications,
  getNotificationReasons,
} from "core/repos/notification";
import { Enum, PaginationList } from "core/domains";
import { Dropdown, Menu, Popover, Tooltip } from "antd";
import moment from "jalali-moment";
import { Badge } from "components/badge/badge";

const defaultNotificationReason = {
  id: 0,
  title: "همه",
};

const isReadFilterItems = [
  { id: 0, title: "همه" },
  { id: 1, title: "خوانده شده" },
  { id: 2, title: "خوانده نشده" },
];

const empty_filters = {
  search: "",
  reason: defaultNotificationReason,
  isRead: isReadFilterItems[0],
};

export const Notifications: React.FC = () => {
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filterButtonLoading, setFilterButtonLoading] =
    React.useState<boolean>(false);
  const [notifications, setNotifications] =
    React.useState<PaginationList<Notification>>();
  const [reasons, setReasons] = React.useState<Enum[]>();
  const [filters, setFilters] =
    React.useState<NotificationFilters>(empty_filters);
  const [query, setQuery] = React.useState<string>("");
  const [pageSize, setPageSize] = React.useState<number>(20);

  function onReasonTagClick(selected: Enum) {
    setFilters({ ...filters, reason: selected });
  }

  const filterHandle = (newFilters: NotificationFilters) => {
    var q = "";

    if (newFilters) {
      if (newFilters.reason?.id !== 0)
        q += `${q.includes("?") ? "&" : "?"}reason=${newFilters.reason?.id}`;
      if (newFilters.search !== "")
        q += `${q.includes("?") ? "&" : "?"}keywords=${newFilters.search}`;
      if (newFilters.isRead.id !== 0)
        q += `${q.includes("?") ? "&" : "?"}isread=${
          newFilters.isRead.id == 1 ? true : false
        }`;
    }
    setQuery(q);
    getAllNotifications(setCardLoading, setNotifications, 1, pageSize, q);
  };

  const handleFilterButtonClick = (newFilters: NotificationFilters) => {
    var q = "";

    if (newFilters) {
      if (newFilters.reason?.id !== 0)
        q += `${q.includes("?") ? "&" : "?"}reason=${newFilters.reason?.id}`;
      if (newFilters.search !== "")
        q += `${q.includes("?") ? "&" : "?"}keywords=${newFilters.search}`;
      if (newFilters.isRead.id !== 0)
        q += `${q.includes("?") ? "&" : "?"}isread=${
          newFilters.isRead.id == 1 ? true : false
        }`;
    }
    setQuery(q);
    getAllNotifications(
      setFilterButtonLoading,
      setNotifications,
      1,
      pageSize,
      q
    );
  };

  React.useEffect(() => {
    getNotificationReasons(setCardLoading, setReasons);
  }, []);

  React.useEffect(() => {
    filterHandle(filters);
  }, []);

  const navigate = useNavigate();

  const columns: TableColumn<Notification>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row) => row?.title,
    },
    {
      name: "پیام",
      id: "message",
      selector: (row) => row.message,
      cell: (record) => (
        <Popover content={record.message}>
          <span className="message-column">{record.message}</span>
        </Popover>
      ),
    },
    {
      name: "ایجاد توسط",
      id: "createdBy",
      selector: (row) => row.createdBy,
    },
    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row) => row.createdOn,
      cell: (row) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "تعداد",
      id: "count",
      selector: (row) => row.count,
    },
    {
      name: "دلیل",
      id: "reason",
      selector: (row) => row.reason.title,
      cell: (record) => (
        <Badge
          type={
            record.reason.id == 1
              ? "info"
              : record.reason.id == 2
              ? "primary"
              : record.reason.id == 3
              ? "waiting"
              : "error"
          }
          text={record.reason.title}
        />
      ),
    },
    {
      name: "عملیات",
      id: "actions",
      cell: (record) => (
        <div className="actions">
          <Link to={`details/${record.message}`}>
            <Tooltip placement="top" title="جزییات اعلان">
              <EditOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="مدیریت اعلانات"
      className="vt-dashboard-notifications"
      blur={cardLoading}
    >
      <div className="row">
        <Button
          type="primary"
          text="افزودن اعلان"
          icon={<PlusOutlined />}
          onClick={() => navigate("add")}
        />
      </div>
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            className="search"
            value={filters.search}
            placeholder="جستجو کنید ..."
            name="search"
            onPressEnter={() => filterHandle(filters)}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
          {reasons && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {reasons.length &&
                    reasons.map((reason) => (
                      <Menu.Item
                        onClick={() =>
                          setFilters({
                            ...filters,
                            reason: reason,
                          })
                        }
                      >
                        {reason.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filters.reason?.title}
              />
            </Dropdown>
          )}

          <Dropdown
            trigger={["click", "hover"]}
            overlay={
              <Menu>
                {isReadFilterItems.length &&
                  isReadFilterItems.map((item) => (
                    <Menu.Item
                      onClick={() =>
                        setFilters({
                          ...filters,
                          isRead: item,
                        })
                      }
                    >
                      {item.title}
                    </Menu.Item>
                  ))}
              </Menu>
            }
            placement="bottom"
          >
            <Button
              icon={<DownOutlined />}
              type="outlined"
              text={filters.isRead?.title}
            />
          </Dropdown>
        </div>
        <div className="buttons">
          <Button
            loading={filterButtonLoading}
            disabled={filterButtonLoading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => handleFilterButtonClick(filters)}
          />
          <span
            onClick={() => {
              filterHandle({
                search: "",
                reason: defaultNotificationReason,
                isRead: isReadFilterItems[0],
              });
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      {notifications && (
        <Table
          className="notification-notifications-table"
          data={notifications.items}
          column={columns}
          pagination={notifications.pagination}
          getData={getAllNotifications}
          setData={setNotifications}
          setLoader={setCardLoading}
          setPageSize={setPageSize}
          pageSize={pageSize}
        />
      )}
    </Card>
  );
};
