import * as React from "react";
import {__API} from "apis/api";
import {Button, Input} from "components";
import {Market} from "core/domains";
import "./edit.scss";
import {get, getValidationErrors, hasValidationError, postViaAuth, responseValidator,} from "utils/scripts/api";
import {message, Select, Spin, Switch} from "antd";
import {useNavigate} from "react-router-dom";

const { useState, useEffect } = React;

const brokersList = [
  {
    title: "پیش‌فرض",
    id: 0,
  },
  {
    id: 1,
    title: "Kucoin",
  },
  {
    id: 2,
    title: "Binance",
  },
];

export const EditMarket: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [marketData, setMarketData] = useState<Market>();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  const navigate = useNavigate();

  const editMarketHandle = async () => {
    setLoading(true);
    await postViaAuth(__API.market.editMarket, {
      id: id,
      name: marketData?.name,
      symbol: marketData?.symbol,
      isSellActive: marketData?.isSellActive,
      isBuyActive: marketData?.isBuyActive,
      exchangeName:
          marketData?.exchangeName?.id !== 0 && marketData?.exchangeName?.title,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("ارز مورد نظر با موفقیت اصلاح شد.");
        window.open("/market/", "_self");
      }
    });
  };

  useEffect(() => {
    setIsCardLoading(true);
    (async () => {
      await get(__API.market.getMarketById + `/${id}`).then((response: any) => {
        setIsCardLoading(false);
        if (responseValidator(response.status)) {
          setMarketData(response.data.value);
        }
      });
    })();
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-markets-edit'>
          <h3>اصلاح ارز</h3>
          {marketData && (
            <form>
              <div className='row'>
                <Input
                  type='text'
                  label='نام'
                  name='name'
                  value={marketData?.name}
                  onChange={(e) =>
                    setMarketData({ ...marketData, name: e.target.value })
                  }
                  maxLength={25}
                />

                <Input
                  label='نماد'
                  type='text'
                  name='symbol'
                  value={marketData?.symbol}
                  onChange={(e) =>
                    setMarketData({ ...marketData, symbol: e.target.value })
                  }
                  maxLength={10}
                />
              </div>

              <div className='row'>
                <div className='switch'>
                  <span>قابل خرید</span>
                  <Switch
                    checked={marketData?.isBuyActive ? true : false}
                    onChange={(e) =>
                      setMarketData({ ...marketData, isBuyActive: e })
                    }
                  />
                </div>
                <div className='switch'>
                  <span>قابل فروش</span>
                  <Switch
                    checked={marketData?.isSellActive ? true : false}
                    onChange={(e) =>
                      setMarketData({ ...marketData, isSellActive: e })
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <Select
                  value={marketData?.exchangeName}
                  onChange={(value) => {
                    setMarketData({
                      ...marketData,
                      exchangeName: {
                        title: brokersList.filter((i) => i.id == value.id)[0]
                          .title,
                        id: brokersList.filter((i) => i.id == value.id)[0].id,
                      },
                    });
                  }}
                >
                  {brokersList.map((item) => (
                    <Select.Option key={item.id}>{item.title}</Select.Option>
                  ))}
                </Select>
              </div>

              <div className='buttons'>
                <Button
                  htmlType='button'
                  type='info'
                  text='اصلاح'
                  onClick={() => editMarketHandle()}
                  loading={loading}
                />

                <Button
                  htmlType='button'
                  type='outlined'
                  text='بازگشت'
                  onClick={() => navigate("/market")}
                />
              </div>
            </form>
          )}
        </div>
      </Spin>
    </>
  );
};
