import {CheckOutlined, CloseOutlined, SettingTwoTone} from "@ant-design/icons";
import {message, Row, Spin, Switch} from "antd";
import {__API} from "apis/api";
import {Button, Input} from "components";
import {Gateway} from "core/domains/gateways/gateway";
import * as React from "react";
import {get, postViaAuth, responseValidator,} from "utils/scripts/api";
import "./index.scss";

const { useState, useRef, useEffect } = React;

export const Gateways: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [gatewaysList, setGatewaysList] = useState<Gateway[]>([]);

  const onGatewayApiKeyChangeHandler = (gateway: Gateway, value: string) => {
    const gateways = gatewaysList.map((item) => {
      if (item.id === gateway.id) {
        return { ...item, apiKey: value };
      }
      return item;
    });
    setGatewaysList(gateways);
  };

  const onDepositChangeHandler = (gateway: Gateway, value: boolean) => {
    const gateways = gatewaysList.map((item) => {
      if (item.id === gateway.id) {
        return { ...item, isDepositActive: value };
      }
      return item;
    });
    setGatewaysList(gateways);
  };
  const onWidthrawlChangeHandler = (gateway: Gateway, value: boolean) => {
    const gateways = gatewaysList.map((item) => {
      if (item.id === gateway.id) {
        return { ...item, isWidthrawlActive: value };
      }
      return item;
    });
    setGatewaysList(gateways);
  };

  const getAllGateways = async () => {
    setCardLoading(true);
    await get(__API.gateways.getAllGateways).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setGatewaysList(response.data.value);
      }
    });
  };
  const updateGateway = async (gateway: Gateway) => {
    setLoading(true);
    await postViaAuth(`${__API.gateways.editGateway}`, {
      id: gateway.id,
      apiKey: gateway.apiKey,
      isDepositActive: gateway.isDepositActive,
      isWidthdrawActive: gateway.isWidthrawlActive,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        getAllGateways();
        message.success("درگاه مورد نظر با موفقیت ویرایش شد.");
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    (async () => await getAllGateways())();
  }, []);

  return (
    <Spin spinning={cardLoading} tip='در حال بارگذاری...'>
      <div className='vt-dashboard-gateways'>
        {gatewaysList.length > 0 && (
          <div className='gateways-list'>
            {gatewaysList.map((gateway) => {
              return (
                <div className='gateway-item-card'>
                  <Row>
                    <div className='gateway-info'>
                      <SettingTwoTone />
                      <span>{gateway.name}</span>
                    </div>
                    <Input
                      type='text'
                      name='apiKey'
                      label='ای پی آِی کی'
                      value={gateway.apiKey}
                      onChange={(e) =>
                        onGatewayApiKeyChangeHandler(gateway, e.target.value)
                      }
                    />
                    <div className='vt-switch-row'>
                      <label htmlFor=''>وضعیت برداشت</label>
                      <Switch
                        title='وضعیت برداشت'
                        checked={gateway.isDepositActive}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        onChange={(e) =>
                          onDepositChangeHandler(
                            gateway,
                            !gateway.isDepositActive
                          )
                        }
                      />
                    </div>
                    <div className='vt-switch-row'>
                      <label htmlFor=''>وضعیت واریز</label>
                      <Switch
                        title='وضعیت واریز'
                        checked={gateway.isWidthrawlActive}
                        onChange={(e) =>
                          onWidthrawlChangeHandler(
                            gateway,
                            !gateway.isWidthrawlActive
                          )
                        }
                      />
                    </div>
                    <Button
                      onClick={() => updateGateway(gateway)}
                      text='ویرایش درگاه'
                      type='primary'
                    />
                  </Row>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Spin>
  );
};
