import { API } from "apis";
import { PersonalWallet } from "core/domains/users-personal-wallets/users-personal-wallets";
import { getViaAuth, responseValidator } from "utils/scripts/api";

export const getAllUsersPersonalWallets = (
    setLoader: any,
    setData: any,
    page: number,
    size: number,
    query?: string
  ) => {
    setLoader(true);
    getViaAuth(API.usersPersonalWallets.getAll + `/${page}/${size}${query}`, {}).then(
      (response: any) => {
        setLoader(false);
        if (responseValidator(response.status)) {
            const personalWallets: PersonalWallet[] = response.value;
            setData(personalWallets);
        }
      }
    );
  };