import * as React from "react";
import LoginStage from "./stages/login/login";
import "routes/pages/signin/signin.scss";
import "antd/dist/reset.css";
import {Helmet} from "react-helmet";
import {APPNAME, Fav} from "apis/constants";

const { useState } = React;

type fieldErrorType = {
  password: string[];
  phone: string[];
};

type hasErrorType = {
  password: boolean;
  phone: boolean;
};

enum Stages {
  LOGIN = "LOGIN",
}

export const SignIn: React.FC = () => {
  // States
  const [stage, setStage] = useState<string>(Stages.LOGIN);
  const [rememberMeIsChecked, setRememberMeIsChecked] =
    useState<boolean>(false);
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true);
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [fieldError, setFieldError] = useState<fieldErrorType>({
    password: [""],
    phone: [""],
  });
  const [hasError, setHasError] = useState<hasErrorType>({
    phone: false,
    password: false,
  });

  function renderStages() {
    switch (stage) {
      case Stages.LOGIN:
        return (
          <LoginStage
            actions={{
              setHasError,
              setPasswordValue,
              setRememberMeIsChecked,
              setPasswordIsHidden,
              setPhoneValue,
              setFieldError,
              setStage,
            }}
            values={{
              hasError,
              rememberMeIsChecked,
              passwordIsHidden,
              phoneValue,
              passwordValue,
              fieldError,
            }}
          />
        );

      default:
        <>invalid</>;
    }
  }

  return (
    <>

      <Helmet>
        <title>{APPNAME} | پلتفرم معاملاتی ارز های دیجیتال | ورود</title>
          <link rel="icon" href={Fav}/>
          <meta
          name='description'
          content='خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد'
        />
      </Helmet>
      <div className='vt-signin'>
        <div className='signin-left'>{renderStages()}</div>
      </div>
    </>
  );
};
