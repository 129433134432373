import { Enum } from "../commons/common";

export type Market = {
  id: string;
  name: string;
  symbol: string;
  isActive: boolean;
  isSellActive?: boolean;
  isBuyActive?: boolean;
  type?: {
    id: number;
    title: string;
  };
  exchangeName?: Enum;
  dollarPrice: number;
  sellPrice: number;
  buyPrice: number;
  priority: string;
  depositEnabled: boolean;
  widthdrawEnabled: boolean;
};
export interface PriceTableMarket {
  id: string;
  name: string;
  symbol: string;
  buy_price: number;
  sell_price: number;
  last_usdt_price: number;
  change24h: number;
  price_data: number[];
}

export interface Network {
  name: string;
  fee: number;
  network: string;
}
export interface Wallet {
  walletAddress: string;
  memo: string;
}

export type MarketEditBody = {
  id?: string;
  name?: string;
  isSellActive?: boolean;
  isBuyActive?: boolean;
  widthrawEnabled?: boolean;
  depositEnabled?: boolean;
  isActive?: boolean;
  exchange?: string;
  priority?: number;
};

export enum MarketAction {
  ActivateDeposit = 1,
  DeActivateDeposit = 2,
  ActivateWithdrawal = 3,
  DeActivateWithdrawal = 4,
  ActivateBuy = 5,
  DeActivateBuy = 6,
  ActivateSell = 7,
  DeActivateSell = 8,
}

export const MarketActionLabel = new Map<number, string>([
  [MarketAction.ActivateDeposit, "فعالسازی واریز"],
  [MarketAction.ActivateWithdrawal, "فعالسازی برداشت"],
  [MarketAction.DeActivateDeposit, "غیر فعالسازی واریز"],
  [MarketAction.DeActivateWithdrawal, "غیر فعالسازی برداشت"],
  [MarketAction.ActivateBuy, "فعالسازی خرید"],
  [MarketAction.DeActivateBuy, "غیر فعالسازی خرید"],
  [MarketAction.ActivateSell, "فعالسازی فروش"],
  [MarketAction.DeActivateSell, "غیر فعالسازی فروش"],
]);
