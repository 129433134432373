import { USER_DATA, ORDER_DATA, MENU, SIDEBAR, EXPANDED_MENU } from "./actions";

export const initial_state = {
  menu: 2,
  expandedMenu: -1,
  userData: {
    landline: "",
  },
  orderData: {
    stage: "PAIRS",

    source: {
      symbol: "IRT",
      amount: 0,
      name: "تومان",
      iconUrl: "/coins/irt.svg",
    },
    destination: {
      symbol: "PM",
      amount: 0,
      name: "ووچر پرفکت مانی",
      iconUrl: "/coins/pm.svg",
    },
  },
  sidebar: "close",
};

function reducer(state = initial_state, action: any) {
  switch (action.type) {
    case MENU:
      return { ...state, menu: action.payload };
    case EXPANDED_MENU:
      return { ...state, expandedMenu: action.payload };
    case USER_DATA:
      return { ...state, userData: action.payload };
    case ORDER_DATA:
      return { ...state, orderData: action.payload };
    case SIDEBAR:
      return { ...state, sidebar: action.payload };
    default:
      return state;
  }
}

export default reducer;
