import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  put,
  responseValidator,
} from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface Tron24Setting {
  secretKey?: string;
  hotWalletAddress?: string;
  apiKey?: string;
  commissionWalletAddress?: string;
}

export const Tron24Settings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [tronSettings, setTron24Settings] = React.useState<Tron24Setting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getTron24Settings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.tron24Setting).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setTron24Settings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeTron24Setting, {
      ...tronSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getTron24Settings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getTron24Settings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-tron24-settings">
        <Card title={`کانفیگ ترون 24`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={tronSettings?.apiKey}
            onChange={(e) =>
              setTron24Settings({ ...tronSettings, apiKey: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="سکرت"
            type="text"
            name="secretKey"
            value={tronSettings?.secretKey}
            onChange={(e) =>
              setTron24Settings({
                ...tronSettings,
                secretKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="آدرس کیف پول گرم"
            type="text"
            name="hotWalletAddress"
            value={tronSettings?.hotWalletAddress}
            onChange={(e) =>
              setTron24Settings({
                ...tronSettings,
                hotWalletAddress: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="آدرس کیف پول کارمزد"
            type="text"
            name="commissionWalletAddress"
            value={tronSettings?.commissionWalletAddress}
            onChange={(e) =>
              setTron24Settings({
                ...tronSettings,
                commissionWalletAddress: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              tronSettings?.apiKey === "" ||
              tronSettings?.secretKey === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
