import { Row, message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";
import TextArea from "antd/es/input/TextArea";

interface IpSetting {
  ip: string;
}

export const AllowedIpsSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [ipSettings, setIpSettings] = React.useState<IpSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getIpSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.allowedIpSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setIpSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeIpSettings, {
      allowedIps: ipSettings?.ip,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getIpSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getIpSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className='vt-dashboard-ip-settings'>
        <Card title={`آیپی های مجاز`} className='setting-item'>
          <div className='textarea'>
            <label htmlFor='ip'>آیپی (آیپی های مجاز رو با کاما جدا کنید)</label>
            <TextArea
              name='ip'
              value={ipSettings?.ip}
              onChange={(e) =>
                setIpSettings({
                  ...ipSettings,
                  ip: e.target.value,
                })
              }
            />
            <p>* درصورت خالی بودن ورودی، همه آیپی ها مجاز می‌شود</p>
          </div>
          <Button
            htmlType='button'
            type='info'
            text='ثبت تغییرات'
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={loading}
          />
        </Card>
      </Card>
    </>
  );
};
