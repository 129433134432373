import * as React from "react";
import { Setting, SettingError, SettingResults } from "core/domains";
import "./setting.scss";
import { Divider, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { CommisionSettings } from "./commision";
import { DelaySettings } from "./delay";
import { MinDepositSettings } from "./litmitation";
import { AutomationSettings } from "./automation";
import { DangerousAmountSettings } from "./dangerous-amounts";
import { WithdrawCommissionSettings } from "./withdrawal-commission";
import { CryptoWithdrawalCommissionSettings } from "./crypto-withdrawal-commission";
import { FiatLimitsSettings } from "./fiat-limits";
import { TransferTronSettings } from "./transfer-tron";
import { EveryoneFinancialSettings } from "./everyone-financial";

const { useState, useEffect } = React;

export const SiteSetting: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);

  const location = useLocation();

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-setting">
          <h3>تنظیمات سایت</h3>

          {
            <form>
              <AutomationSettings />
              <Divider />
              <MinDepositSettings />
              <Divider />
              <FiatLimitsSettings />
              <Divider />
              <CommisionSettings />
              <Divider />
              <DelaySettings />
              <Divider />
              <DangerousAmountSettings />
              <Divider />
              <h3>تنظیمات کارمزدهای برداشت</h3>
              <div className={"withdrawal-commission-setting-row"}>
                <WithdrawCommissionSettings />
                <CryptoWithdrawalCommissionSettings />
              </div>
              <Divider />
              <TransferTronSettings />
              <Divider />
              <EveryoneFinancialSettings />
            </form>
          }
        </div>
      </Spin>
    </>
  );
};
