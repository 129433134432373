import * as React from "react";
import { Button, Card, Input } from "components";
import "./bank-account-tracking.scss";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  responseValidator,
} from "utils/scripts/api";
import { Col, message, Row } from "antd";
import { faNumToEnNum } from "utils/scripts/decimal";
import { formatCardNumber } from "utils/scripts/bankDetecter";
import { API } from "apis";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../utils/scripts/copyToClipboard";

type Cart = {
  cardNumber: string;
  firstName: string;
  lastName: string;
  bankName: string;
  iban: string;
};

const defaultValues = {
  firstName: "",
  bankName: "",
  lastName: "",
  cardNumber: "",
  iban: "",
};

const { useState, useEffect } = React;

export const BankAccountTracking: React.FC = () => {
  // States
  const [cartInfo, setCartInfo] = useState<Cart>(defaultValues);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreditCardChange = (value: any = "") => {
    const cardNumber =
      formatCardNumber(faNumToEnNum(value)) ?? faNumToEnNum(value);
    setCartInfo({ ...cartInfo, cardNumber });
  };

  const getBankAccountInfo = async () => {
    if (cartInfo.cardNumber) {
      setLoading(true);
      await getViaAuth<Cart>(
        API.bankAccountTracking.cardInfo +
          `/${cartInfo.cardNumber.replaceAll("-", "")}`
      ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          message.success("استعلام با موفقیت انجام شد.");
          setCartInfo({ ...cartInfo, ...response.value });
        } else {
        }
      });
    }
  };

  return (
    <div className='vt-dashboard-bank-account-tracking'>
      <h3>استعلام کارت بانکی</h3>
      <div className='tracking-info'>
        <Card className='tracking' title={`استعلام`}>
          <div className='form'>
            <Input
              label='شماره کارت'
              type='text'
              name='cardNumber'
              value={cartInfo?.cardNumber}
              onChange={(e) => handleCreditCardChange(e.target.value)}
              dir='ltr'
              maxLength={80}
              onPressEnter={() => getBankAccountInfo()}
            />

            <Button
              htmlType='button'
              type='info'
              text='استعلام'
              onClick={() => getBankAccountInfo()}
              loading={loading}
            />
          </div>
        </Card>

        <Card className='card-info' title='اطلاعات کارت بانکی'>
          <Row>
            <Col>
              <span>نام</span>
            </Col>
            {cartInfo.firstName && (
              <Col>
                <span>{cartInfo.firstName}</span>
                <CopyOutlined
                  onClick={() => copyToClipboard(cartInfo.firstName, "نام")}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <span>نام خانوادگی</span>
            </Col>
            {cartInfo.lastName && (
              <Col>
                <span>{cartInfo.lastName}</span>
                <CopyOutlined
                  onClick={() =>
                    copyToClipboard(cartInfo.lastName, "نام خانوادگی")
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <span>بانک</span>
            </Col>
            {cartInfo.bankName && (
              <Col>
                <div className='d-flex'>
                  <img
                    width='30px'
                    src={`/banks/${cartInfo.bankName.toLowerCase()}.svg`}
                  />

                  <span className='en'>{cartInfo.bankName}</span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(cartInfo.bankName, "نام بانک")
                    }
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <span>شماره شبا</span>
            </Col>
            {cartInfo.iban && (
              <Col>
                <span className='en'>{cartInfo.iban}</span>
                <CopyOutlined
                  onClick={() => copyToClipboard(cartInfo.iban, "شماره شبا")}
                />
              </Col>
            )}
          </Row>
        </Card>
      </div>
    </div>
  );
};
