import {
  InputNumber,
  message,
  Row,
  Segmented,
  Select,
  Spin,
  Switch,
} from "antd";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { Button, Input } from "components";
import { Enum, Level, Role, UserDetail } from "core/domains";
import * as React from "react";
import "./details.scss";
import { UserAssetsList } from "./stages/assets/assets";
import { UserCartsList } from "./stages/carts/user-carts";
import { UserSurveys } from "./stages/surveys/surveys";
import { getAlllLevels } from "core/repos/level";
import { getAllRolesAsEnum } from "core/repos/role";
import { getAllGenders, getUserById, updateUser } from "core/repos/user";
import { Modal } from "components/modal/modal";
import ReactCodeInput from "react-verification-code-input";
import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { UserOrderReports } from "./stages/order-reports/order-reports";
import TextArea from "antd/lib/input/TextArea";
import { ReferralDetails } from "./stages/referral/referral-details";
import { faNumToEnNum } from "../../../../utils/scripts/decimal";
import moment from "jalali-moment";
import { postViaAuth, responseValidator } from "../../../../utils/scripts/api";
import { API } from "../../../../apis";
import { useState } from "react";

type GoogleAuth = {
  code?: string;
  error?: string;
};

const stages: SegmentedLabeledOption[] = [
  {
    value: "main",
    label: "اطلاعات اصلی",
  },
  {
    value: "auth",
    label: "اطلاعات هویتی",
  },
  {
    value: "referral",
    label: "رفرال",
  },
  {
    value: "surveys",
    label: "مدارک ارسالی",
  },
  {
    value: "banks",
    label: "حساب های بانکی",
  },
  {
    value: "assets",
    label: "موجودی",
  },
  {
    value: "reports",
    label: "گزارشات معاملات کاربر",
  },
];

const authEnumList = [
  {
    id: 0,
    title: "Google Authenticator",
  },
  {
    id: 1,
    title: "SMS",
  },
];

export const UserDetails: React.FC = () => {
  const [googleAuthData, setGoogleAthData] = React.useState<GoogleAuth>();
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [stage, setStage] = React.useState<SegmentedLabeledOption>(stages[0]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [userInfo, setUserInfo] = React.useState<UserDetail>();
  const [roleList, setRoleList] = React.useState<Role[]>([]);
  const [levelList, setLevelList] = React.useState<Level[]>([]);
  const [authList, setAuthList] = React.useState<Enum[]>(authEnumList);
  const [genderList, setGenderList] = React.useState<Enum[]>([]);
  const [
    isWithdrawalDisabledTillModalOpen,
    setIsWithdrawalDisabledTillModalOpen,
  ] = React.useState<boolean>(false);
  const [withdrawalHour, setWithdrawalHour] = React.useState<number | null>(0);
  const [withdrawalMinute, setWithdrawalMinute] = React.useState<number | null>(
    0
  );
  const [disableTime, setDisableTime] = React.useState<number | null>(null);

  const onOpenModalHanlde = () => {
    setGoogleAthData({
      code: "",
      error: "",
    });
    setModalIsOpen(true);
  };
  const [withdrawalDisabledLoading, setWithdrawalDisabledLoading] =
    React.useState<boolean>(false);

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  React.useEffect(() => {
    !userInfo &&
      getUserById(
        setCardLoading,
        setUserInfo,
        window.location.href.split("/")[5]
      );
    genderList.length === 0 && getAllGenders(setCardLoading, setGenderList);
    levelList.length === 0 && getAlllLevels(setCardLoading, setLevelList);
    roleList?.length === 0 && getAllRolesAsEnum(setCardLoading, setRoleList);
  }, []);

  React.useEffect(() => {
    if (withdrawalHour !== null || withdrawalMinute !== null) {
      const nullableWithdrawalHour = withdrawalHour ? withdrawalHour : 0;
      const nullableWithdrawalMinute = withdrawalMinute ? withdrawalMinute : 0;
      const newDisableTime =
        nullableWithdrawalHour * 60 + nullableWithdrawalMinute;
      setDisableTime(newDisableTime);
    } else {
      setDisableTime(null);
    }
  }, [withdrawalHour, withdrawalMinute]);

  const onSaveWithdrawalDisableTime = () => {
    setWithdrawalDisabledLoading(true);
    postViaAuth(API.users.editWithdrawalDisabledTill, {
      userId: userInfo?.id,
      disableFor: disableTime,
    }).then((response: any) => {
      setWithdrawalDisabledLoading(false);
      if (responseValidator(response.status)) {
        message.success("مقدار غیر فعال بودن زمان برداشت اعمال شد");
        setIsWithdrawalDisabledTillModalOpen(false);
      }
    });
  };

  return (
    <>
      <Modal
        closeAble
        title={"کد تایید Google Auth"}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        className="edit-user-data-modal"
      >
        <div className="google-auth code app-code">
          <ReactCodeInput
            className={`input `}
            fieldWidth={42}
            values={googleAuthData?.code ? googleAuthData?.code?.split("") : []}
            autoFocus={true}
            onChange={(value: any) => {
              setGoogleAthData({
                ...googleAuthData,
                code: value,
              });
            }}
            onComplete={(code) =>
              updateUser(
                setCardLoading,
                userInfo,
                id,
                code,
                setGoogleAthData,
                setModalIsOpen,
                roleList
              )
            }
          />
          <div className="stats">
            <div className="errors">
              {googleAuthData?.error && (
                <>
                  <QuestionCircleOutlined />
                  <span>{googleAuthData?.error}</span>
                </>
              )}
            </div>
          </div>
        </div>

        <Button
          type="info"
          text="تایید"
          onClick={() =>
            updateUser(
              setCardLoading,
              userInfo,
              id,
              googleAuthData?.code,
              setGoogleAthData,
              setModalIsOpen,
              roleList
            )
          }
          disabled={cardLoading ? true : false}
          loading={cardLoading}
        />
      </Modal>
      <div className="vt-user-details">
        <div className="head">
          <h3>جزئیات کاربر</h3>
          <Segmented
            onChange={(e) => setStage(stages.filter((s) => s.value === e)[0])}
            selected
            options={stages}
          />
        </div>
        <Spin spinning={cardLoading}>
          <div className={`stage-content ${stage.value}`}>
            {stage.value === "main" ? (
              <Spin className="main-stage" spinning={cardLoading}>
                <div className="vt-field">
                  <Input
                    type="text"
                    label="نام"
                    name="firstName"
                    value={userInfo?.firstName}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="vt-field">
                  <Input
                    type="text"
                    label="نام خانوادگی"
                    name="lastName"
                    value={userInfo?.lastName}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, lastName: e.target.value })
                    }
                  />
                </div>
                <div className="vt-field">
                  <label>سطح کاربری</label>
                  {levelList && levelList.length > 0 && userInfo && (
                    <Select
                      value={
                        levelList.filter(
                          (level) => level.uniqueName?.id == userInfo.level?.id
                        )[0].name
                      }
                      onChange={(value) => {
                        setUserInfo({
                          ...userInfo,
                          level: {
                            title: levelList.filter(
                              (level) => level.id == value
                            )[0].uniqueName?.title,
                            id: levelList.filter(
                              (level) => level.id == value
                            )[0].uniqueName?.id,
                          },
                        });
                      }}
                    >
                      {levelList.map((level) => (
                        <Select.Option key={level.id}>
                          {level.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </div>
                <div className="vt-field">
                  <label>نقش ها</label>
                  <Select
                    mode="multiple"
                    value={userInfo?.roles}
                    onChange={(value) =>
                      setUserInfo({ ...userInfo, roles: value })
                    }
                  >
                    {roleList?.map((role) => (
                      <Select.Option key={role.title}>
                        {role.title ?? role.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="vt-field">
                  <Input
                    type="text"
                    name="emailAddress"
                    label="آدرس ایمیل"
                    value={userInfo?.emailAddress}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, emailAddress: e.target.value })
                    }
                  />
                </div>
                <div className="vt-field">
                  <Input
                    type="text"
                    name="userName"
                    label="نام کاربری"
                    value={userInfo?.userName}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        userName: faNumToEnNum(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="vt-field">
                  <Input
                    type="text"
                    name="password"
                    label="پسورد"
                    value={userInfo?.password}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, password: e.target.value })
                    }
                  />
                </div>
                <div className="vt-field">
                  <label>نوع ورود ۲مرحله ای</label>
                  {userInfo && (
                    <Select
                      onChange={(value) => {
                        setUserInfo({
                          ...userInfo,
                          twoFactorType: {
                            title: authList.filter(
                              (item) => item.id.toString() == value
                            )[0].title,
                            id: authList.filter(
                              (item) => item.id.toString() == value
                            )[0].id,
                          },
                        });
                      }}
                      defaultValue={userInfo.twoFactorType?.title}
                    >
                      {authList.map((item) => (
                        <Select.Option key={item.id}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </div>

                <div className="vt-field">
                  <label>یادداشت</label>
                  <TextArea
                    name="note"
                    value={userInfo?.note}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        note: e.target.value,
                      })
                    }
                    placeholder="توضیحات را وارد کنید"
                    max-maxLength={1000}
                  ></TextArea>
                </div>
                <div className={"vt-field"}>
                  <Row>
                    <label>برداشت غیر فعال تا تاریخ:</label>
                    <label>
                      {userInfo?.withdrawalDisableTill &&
                        moment
                          .utc(userInfo?.withdrawalDisableTill)
                          .locale("fa")
                          .local()
                          .format("HH:mm:ss - jYYYY/MM/DD")}
                    </label>
                  </Row>
                  <Button
                    type={"primary"}
                    text={"تعیین تاریخ غیر فعال بودن برداشت"}
                    onClick={() => setIsWithdrawalDisabledTillModalOpen(true)}
                    className={"withdrawal-disabled-button"}
                  />
                  <Modal
                    isOpen={isWithdrawalDisabledTillModalOpen}
                    setIsOpen={setIsWithdrawalDisabledTillModalOpen}
                    title="تعیین تاریخ غیر فعال بودن برداشت"
                    className="withdrawal-disabled-till-modal"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                        maxWidth: "180px",
                        marginInline: "auto",
                      }}
                    >
                      <div
                        className="input-hour"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label>ساعت</label>
                        <InputNumber
                          min={0}
                          placeholder="ساعت"
                          onChange={(value) => setWithdrawalHour(value)}
                        />
                      </div>

                      <div
                        className="input-minute"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label>دقیقه</label>
                        <InputNumber
                          min={0}
                          max={59}
                          placeholder="دقیقه"
                          onChange={(value) => setWithdrawalMinute(value)}
                        />
                      </div>
                    </div>
                    {(withdrawalMinute !== 0 || withdrawalHour !== 0) &&
                      disableTime !== null && (
                        <div className="disable-time-info">
                          تاریخ غیر فعال شدن برداشت کاربر پس از اعمال:
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginInline: "auto",
                              gap: "16px",
                              marginTop: "16px",
                            }}
                          >
                            <span>
                              {moment(Date.now() + disableTime * 60 * 1000)
                                .locale("fa")
                                .format("jYYYY/MM/DD")}
                            </span>

                            <span>
                              ساعت{" "}
                              {moment(Date.now() + disableTime * 60 * 1000)
                                .local()
                                .format("HH:mm")}
                            </span>
                          </p>
                        </div>
                      )}

                    <Button
                      type="primary"
                      text="اعمال"
                      onClick={onSaveWithdrawalDisableTime}
                      loading={withdrawalDisabledLoading}
                    />
                  </Modal>
                </div>
                <div className="vt-field">
                  <label>جنسیت</label>
                  {userInfo && (
                    <Select
                      value={userInfo.gender?.id}
                      onChange={(value) => {
                        setUserInfo({
                          ...userInfo,
                          gender: {
                            id: value,
                            title:
                              genderList.find((gender) => gender.id === value)
                                ?.title ?? "",
                          },
                        });
                      }}
                    >
                      {genderList?.map((gender) => (
                        <Select.Option key={gender.id} value={gender.id}>
                          {gender.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </div>
                <div className="vt-field">
                  <label>کاربر وی آی پی</label>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    onChange={(checked) =>
                      setUserInfo({ ...userInfo, isVip: checked })
                    }
                  />
                </div>
                <div className="vt-field">
                  <label>وضعیت ورود ۲مرحله ای</label>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={userInfo?.isTwoStepActive}
                    onChange={(checked) =>
                      setUserInfo({ ...userInfo, isTwoStepActive: checked })
                    }
                  />
                </div>

                <div className="vt-field">
                  <label>وضعیت وایت لیست</label>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={userInfo?.isWhiteList}
                    onChange={(checked) =>
                      setUserInfo({ ...userInfo, isWhiteList: checked })
                    }
                  />
                </div>

                <div className="vt-field">
                  <label>استعلام کد ملی</label>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={userInfo?.isCheckKYC || false}
                    onChange={(checked) =>
                      setUserInfo({ ...userInfo, isCheckKYC: checked })
                    }
                  />
                </div>

                <Row>
                  <Button
                    loading={loading}
                    type={"info"}
                    text={"ویرایش اطلاعات"}
                    onClick={() => onOpenModalHanlde()}
                  />
                </Row>
              </Spin>
            ) : stage.value === "auth" ? (
              <Spin spinning={cardLoading}>
                <Row>
                  <Input
                    type="text"
                    label="کد ملی"
                    name="nationalCode"
                    value={userInfo?.nationalCode}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, nationalCode: e.target.value })
                    }
                  />
                  <Input
                    type="text"
                    name="birthDate"
                    label="تاریخ تولد"
                    value={userInfo?.birthDate}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, birthDate: e.target.value })
                    }
                  />
                </Row>
                <Row>
                  <Input
                    type="text"
                    label="تلفن محل سکونت"
                    name="landline"
                    value={userInfo?.landline}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, landline: e.target.value })
                    }
                  />
                  <Input
                    type="text"
                    label="نام پدر"
                    name="fatherName"
                    value={userInfo?.fatherName}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, fatherName: e.target.value })
                    }
                  />
                </Row>
                <Row>
                  <Input
                    type="text"
                    name="address"
                    label="آدرس محل سکونت"
                    value={userInfo?.address}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, address: e.target.value })
                    }
                  />
                </Row>

                <Row>
                  <Button
                    loading={loading}
                    type={"info"}
                    text={"ویرایش اطلاعات هویتی"}
                    onClick={() => {
                      onOpenModalHanlde();
                    }}
                  />
                </Row>
              </Spin>
            ) : stage.value === "referral" ? (
              <ReferralDetails
                cardLoading={cardLoading}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                roleList={roleList}
              />
            ) : stage.value === "surveys" ? (
              <UserSurveys userInfo={userInfo} />
            ) : stage.value === "banks" ? (
              <UserCartsList id={userInfo?.id || ""} />
            ) : stage.value === "assets" ? (
              <UserAssetsList />
            ) : stage.value === "reports" ? (
              <UserOrderReports />
            ) : (
              <></>
            )}
          </div>
        </Spin>
      </div>
    </>
  );
};
