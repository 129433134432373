import { API } from "apis";
import { message } from "antd";
import { Enum, Level } from "core/domains";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { DefaultOptionType } from "antd/es/select";

export const getAllLevelsAsEnum = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.levels.getAllLevels).then((response: any) => {
    setloader(false);
    const allData: DefaultOptionType[] = [];
    if (responseValidator(response.status)) {
      response.value.map((level: Level) => {
        allData.push({
          key: level.uniqueName?.id || 0,
          label: level.name || "",
        });
      });
    }
    setdata(allData);
  });
};

export const getAllLevelsAsSelectOption = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.levels.getAllLevels).then((response: any) => {
    setloader(false);
    const allData: DefaultOptionType[] = [];
    if (responseValidator(response.status)) {
      response.value.map((level: Level) => {
        allData.push({
          value: level.uniqueName?.id || 0,
          label: level.name || "",
        });
      });
    }
    setdata(allData);
  });
};

export const getAlllLevels = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth<Level[]>(API.levels.getAllLevels).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getLevelById = async (
  setloader: any,
  setdata: any,
  id: string
) => {
  setloader(true);
  await getViaAuth(API.levels.getLevelById + id).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const updateLevel = (setloader: any, level: any) => {
  setloader(true);
  postViaAuth(API.levels.editLevel, level).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("سطح مورد نظر با موفقیت اصلاح شد.");
      window.open("/users/levels/", "_self");
    }
  });
};
