import { Button, Card, Input, Modal } from "components";
import { Table } from "components/table/table";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import "./index.scss";
import { AuthenticationScore } from "core/domains/authentication-score/authentication-score";
import {
  editAuthenticationScorePoint,
  getAllAuthenticationScores,
} from "core/repos/authentication-score";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { useState, useRef, useEffect } = React;

export const AuthenticationScores: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [authenticationScores, setAuthenticationScores] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<AuthenticationScore>();
  const [scoreModalLoading, setScoreModalLoading] = useState<boolean>(false);
  const [scoreModalIsOpen, setScoreModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    !authenticationScores &&
      getAllAuthenticationScores(setCardLoading, setAuthenticationScores);
  }, []);

  const onEditAuthenticationScore = (item: AuthenticationScore) => {
    setSelectedItem(item);
    setScoreModalIsOpen(true);
  };

  const onAuthenticationScoreEditClick = async (value: any) => {
    await editAuthenticationScorePoint(
      setScoreModalLoading,
      () => setScoreModalIsOpen(false),
      { id: selectedItem?.id, point: Number(value) },
      () => getAllAuthenticationScores(setCardLoading, setAuthenticationScores)
    );
  };

  const columns: TableColumn<any>[] = [
    {
      name: "عنوان امتیاز",
      id: "score-title",
      selector: (row: AuthenticationScore) => row?.type?.title,
      cell: (row: AuthenticationScore) => <span>{row?.type?.title}</span>,
    },
    {
      name: "مقدار امتیاز",
      id: "score-point",
      selector: (row: AuthenticationScore) => row?.point,
      cell: (row: AuthenticationScore) => <span dir="ltr">{row?.point}</span>,
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: AuthenticationScore) => (
        <div className="actions">
          <Tooltip placement="top" title="ویرایش امتیاز کاربر">
            <EditOutlined onClick={() => onEditAuthenticationScore(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {selectedItem && (
        <Modal
          setIsOpen={setScoreModalIsOpen}
          isOpen={scoreModalIsOpen}
          title={`ویرایش امتیاز ${selectedItem.type.title}`}
          closeAble
          className="authentication-score-modal"
        >
          <Input
            value={selectedItem?.point.toString()}
            type="text"
            name="point"
            dir="ltr"
            onChange={(e) =>
              setSelectedItem({ ...selectedItem, point: e.target.value })
            }
            placeholder="امتیاز جهت ویرایش را وارد کنید"
          />
          <div className="buttons">
            <Button
              type="info"
              text="ویرایش"
              loading={scoreModalLoading}
              disabled={scoreModalLoading}
              htmlType="button"
              onClick={(e) =>
                onAuthenticationScoreEditClick(selectedItem?.point)
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setScoreModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      <Card
        title="امتیازات"
        className="vt-dashboard-authentication-scores"
        blur={cardLoading}
      >
        {authenticationScores && (
          <Table
            className="authentication-scores-table"
            data={authenticationScores}
            column={columns}
            setData={setAuthenticationScores}
            getData={getAllAuthenticationScores}
            setLoader={setCardLoading}
          />
        )}
      </Card>
    </>
  );
};
