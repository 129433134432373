import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
  LockOutlined,
  StarOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  ConfigProvider,
  Dropdown,
  Menu,
  MenuProps,
  message,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { API } from "apis";
import { __API } from "apis/api";
import { Button, Card, Input } from "components";
import { Modal } from "components/modal/modal";
import { Table } from "components/table/table";
import {
  Enum,
  EnumAsString,
  FinancialSettings,
  PaginationList,
  SortDirection,
  User,
} from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
  IsNumeric,
} from "utils/scripts/decimal";
import "./index.scss";
import {
  getAllUsers,
  updateUserActivation,
  updateUserAuthenticationScore,
  updateUserLockout,
  updateUserWhiteList,
  updateUserWithdrawalEnable,
} from "core/repos/user";
import { getAllRolesAsEnum, getAllUserCategories } from "core/repos/role";
import { getAllLevelsAsEnum } from "core/repos/level";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import moment from "jalali-moment";
import TextArea from "antd/lib/input/TextArea";
import { DefaultOptionType } from "antd/es/select";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";

const { useState, useRef, useEffect } = React;

export interface FilterValues {
  sort?: SortDirection;
  levels: DefaultOptionType[];
  role: EnumAsString;
  userCategory: Enum;
  keywords?: string;
  startDate?: string;
  endDate?: string;
}

const defaultLevelItems: DefaultOptionType[] = [
  {
    key: "0",
    label: "همه سطوح",
  },
];

const defaultCategoryItems: Enum[] = [
  {
    title: "همه کاربران",
    id: 0,
  },
];
const defaultRoleItems: EnumAsString[] = [
  {
    title: "همه نقش ها",
    id: "",
  },
];

export const AllUsers: React.FC = () => {
  const [financialModal, setFinancialModal] = useState<boolean>(false);
  const [lockoutModalIsOpen, setLockoutModalIsOpen] = useState<boolean>(false);
  const [lockoutModalLoading, setLockoutModalLoading] =
    useState<boolean>(false);
  const [banUserModalIsOpen, setBanUserModalIsOpen] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [scoreModalIsOpen, setScoreModalIsOpen] = useState<boolean>(false);
  const [scoreModalLoading, setScoreModalLoading] = useState<boolean>(false);
  const [financialSettings, setFinancialSettings] =
    useState<FinancialSettings>();
  const [loading, setLoading] = useState<boolean>(false);
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<PaginationList<User>>();
  const [banModalNote, setBanModalNote] = useState<string>("");
  const [roles, setRoles] = useState<EnumAsString[]>([]);
  const [userCategories, setUserCategories] = useState<Enum[]>([]);
  const [levels, setLevels] = React.useState<DefaultOptionType[]>([]);

  const [query, setQuery] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    role: defaultRoleItems[0],
    levels: [],
    userCategory: defaultCategoryItems[0],
  });
  const [selectedItem, setSelectedItem] = useState<User>();

  const [removeModalIsOpen, setRemoveModalIsOpen] = useState<{
    state: boolean;
    record: User | null;
  }>({
    state: false,
    record: null,
  });

  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState<{
    state: boolean;
    record: User | null;
  }>({
    state: false,
    record: null,
  });

  const onRoleClick: MenuProps["onClick"] = ({ key }) => {
    setFilterValues({
      ...filterValues,
      role: roles?.filter((t) => t.id === key)[0],
    });
  };

  const handleNumChange = (value: string, field: string, enField?: boolean) => {
    // all number Fa to En
    if (value !== "") value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== "" && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setFinancialSettings({
      ...financialSettings,
      [field]: enField ? value : digitSeparator(value),
    });
  };

  const removeUser = () => {
    postViaAuth(__API.users.get, {}).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت اصلاح شد.");
      }
    });
  };

  const openFinancialSettingsModal = () => setFinancialModal(true);
  const closeFinancialSettingsModal = () => {
    setFinancialSettings({});
    setFinancialModal(false);
  };

  const onFinancialSettingsHandler = (id: string) => {
    setModalLoading(true);
    openFinancialSettingsModal();
    getViaAuth<FinancialSettings>(API.financialSettings.get + `/${id}`).then(
      (data: any) => {
        setModalLoading(false);
        if (responseValidator(data.status) && data.value) {
          data.value && setFinancialSettings(data.value);
          data.value.lockAllWithdrawal == null &&
            setFinancialSettings({ ...data.value, lockAllWithdrawal: false });
        }
      }
    );
  };

  const onBanUserHandle = () => {
    selectedItem &&
      updateUserActivation(
        setCardLoading,
        {
          id: selectedItem.id,
          isActive: false,
          note: banModalNote,
        },
        setUsersList,
        closeBanModal(),
        query
      );
  };

  const closeBanModal = () => {
    setBanModalNote("");
    setBanUserModalIsOpen(false);
    setSelectedItem(undefined);
  };

  const isActiveHandle = (item: User, checked: boolean) => {
    setSelectedItem(item);
    checked == false
      ? setBanUserModalIsOpen(true)
      : updateUserActivation(
          setCardLoading,
          { id: item.id, isActive: checked },
          setUsersList,
          query
        );
  };

  const withdrawalEnableHandle = (item: User, checked: boolean) => {
    setSelectedItem(item);
    updateUserWithdrawalEnable(
      setCardLoading,
      { userId: item?.id, withdrawalEnable: checked },
      setUsersList,
      () => {},
      query
    );
  };

  const whiteListHandle = (item: User, checked: boolean) => {
    setSelectedItem(item);
    updateUserWhiteList(
      setCardLoading,
      { id: item?.id, isUserInWhiteList: checked },
      setUsersList,
      () => {},
      query
    );
  };

  const onLockoutUpdateClick = (value: boolean) => {
    updateUserLockout(
      setLockoutModalLoading,
      setCardLoading,
      () => setLockoutModalIsOpen(false),
      { id: selectedItem?.id, lockout: value },
      setUsersList,
      query
    );
  };

  const onLockoutIconClick = (item: User) => {
    setSelectedItem(item);
    setLockoutModalIsOpen(true);
  };

  const onEditAuthenticationScore = (item: User) => {
    setSelectedItem(item);
    setScoreModalIsOpen(true);
  };

  const onAuthenticationScoreEditClick = (value: any) => {
    updateUserAuthenticationScore(
      setScoreModalLoading,
      setCardLoading,
      () => setScoreModalIsOpen(false),
      { userId: selectedItem?.id, score: Number(value) },
      setUsersList,
      query
    );
  };

  const onUpdateFinancialSettings = (id: string) => {
    setLoading(true);

    postViaAuth(__API.financialSettings.update, {
      userId: id,
      maxIrtDepositInDay: parseFloat(
        deleteCommaSeparator(financialSettings?.maxIrtDepositInDay || 0)
      ),
      maxIrtWithdrawlInDay: parseFloat(
        deleteCommaSeparator(financialSettings?.maxIrtWithdrawlInDay || 0)
      ),
      maxDollarWithdrawlInDay: parseFloat(
        deleteCommaSeparator(financialSettings?.maxDollarWithdrawlInDay || 0)
      ),
      lockAllWithdrawal: financialSettings?.lockAllWithdrawal,
      description: financialSettings?.description,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت اصلاح شد.");
      }
    });
  };

  const onRemoveFinancialSettings = (id: string) => {
    setLoading(true);

    postViaAuth(__API.financialSettings.remove, {
      userId: id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت حذف شد.");
      }
    });
  };
  const filterHandle = () => {
    var _query = "";
    if (filterValues?.role.id !== "")
      _query += `${_query.includes("?") ? "&" : "?"}role=${
        filterValues?.role.title
      }`;
    if (filterValues?.levels.length !== 0)
      filterValues.levels.forEach(
        (e) => (_query += `${_query.includes("?") ? "&" : "?"}level=${e.key}`)
      );
    if (filterValues?.userCategory.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}userCategory=${
        filterValues?.userCategory.id
      }`;
    if (filterValues?.keywords) {
      _query +=
        `${_query.includes("?") ? "&" : "?"}keywords=` + filterValues?.keywords;
    }
    if (filterValues.startDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }registerFrom=${convertFaToEnCalendar(filterValues.startDate)}`;
    if (filterValues.endDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }registerTill=${convertFaToEnCalendar(filterValues.endDate)}`;
    setQuery(_query);
    getAllUsers(setLoading, setUsersList, 1, pageSize, _query);
  };

  /*
    const checkQuery = () => {
      var query = "";
      if (filterValues?.role.id !== "")
        query += `${query.includes("?") ? "&" : "?"}role=${
          filterValues?.role.title
        }`;
      if (filterValues?.level.id !== 0)
        query += `${query.includes("?") ? "&" : "?"}level=${
          filterValues?.level.id
        }`;
      if (filterValues?.keywords) {
        query += `${query.includes("?") ? "&" : "?"}keywords=`;
        query += filterValues?.keywords;
      }

      return query;
    };
  */

  const columns: TableColumn<User>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: User) => row.fullName,
      cell: (record: User) =>
        record.fullName === "" ? "احراز نشده" : record.fullName,
    },
    {
      name: "نام کاربری",
      id: "userName",
      selector: (row: User) => row?.userName,
    },
    {
      name: "نقش ها",
      id: "roles",
      cell: (record: User) =>
        record?.roles?.map((role) => (
          <Tag color={role === "کاربر" ? "green" : "gold"}>{role}</Tag>
        )),
    },
    {
      name: "سطح کاربری",
      id: "level",
      selector: (row: User) => row.level,
      cell: (record: User) => <Tag color={"orange"}>{record.level}</Tag>,
    },

    {
      name: "تاریخ عضویت",
      id: "registeredOn",
      cell: (row: User) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.registeredOn)
              .local()
              .toDate()
              .toLocaleDateString("fa-IR")}
          </span>
          <span className="time">
            {moment.utc(row.registeredOn).local().format("HH:mm:ss")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت حساب",
      id: "isActive",
      cell: (record: User) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.isActive}
          onChange={(checked) => isActiveHandle(record, checked)}
        />
      ),
    },
    {
      name: "وضعیت برداشت",
      id: "withdrawalEnable",
      cell: (record: User) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.withdrawalEnable}
          onChange={(checked) => withdrawalEnableHandle(record, checked)}
        />
      ),
    },
    {
      name: "وضعیت وایت لیست",
      id: "isUserInWhiteList",
      cell: (record: User) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.isWhiteList}
          onChange={(checked) => whiteListHandle(record, checked)}
        />
      ),
    },
    {
      name: "مجموع امتیاز",
      id: "scoreSum",
      cell: (record: User) => <span>{record?.scoreSum}</span>,
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: User) => (
        <div className="actions">
          <Link to={`/users/details/${record.id}`}>
            <Tooltip placement="top" title="ویرایش">
              {" "}
              <UserOutlined />
            </Tooltip>
          </Link>

          <Tooltip placement="top" title="تنظیمات مالی">
            {" "}
            <FileSearchOutlined
              onClick={() => onFinancialSettingsHandler(record.id)}
            />
          </Tooltip>
          {record.lockoutEnabled ? (
            <Tooltip placement="top" title="باز کردن لاگین">
              {" "}
              <LockOutlined onClick={() => onLockoutIconClick(record)} />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="ققل کردن لاگین">
              {" "}
              <UnlockOutlined onClick={() => onLockoutIconClick(record)} />
            </Tooltip>
          )}
          <Tooltip placement="top" title="ویرایش امتیاز کاربر">
            <StarOutlined onClick={() => onEditAuthenticationScore(record)} />
          </Tooltip>
          {record.note && (
            <Tooltip
              placement="top"
              title={
                <>
                  توضیحات:
                  <br />
                  {record?.note}
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    !usersList &&
      getAllUsers(setCardLoading, setUsersList, getPageNum(), pageSize, query);
    roles.length === 0 && getAllRolesAsEnum(setCardLoading, setRoles);
    userCategories.length === 0 &&
      getAllUserCategories(setCardLoading, setUserCategories);
    levels.length === 0 && getAllLevelsAsEnum(setCardLoading, setLevels);
  }, []);

  return (
    <>
      {selectedItem && (
        <Modal
          setIsOpen={setLockoutModalIsOpen}
          isOpen={lockoutModalIsOpen}
          title={`آیا از تغییر وضعیت امکان ورود کاربر ${
            selectedItem.fullName || selectedItem.userName
          } به ${
            selectedItem.lockoutEnabled == true ? "باز شدن قفل" : "قفل"
          } اطمینان دارید ؟`}
          closeAble
          className="lockout-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={lockoutModalLoading}
              disabled={lockoutModalLoading}
              htmlType="button"
              onClick={(e) =>
                onLockoutUpdateClick(selectedItem.lockoutEnabled ? false : true)
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setLockoutModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setScoreModalIsOpen}
          isOpen={scoreModalIsOpen}
          title="ویرایش امتیاز کاربر"
          closeAble
          className="authentication-score-modal"
        >
          <Input
            value={selectedItem?.scoreSum?.toString()}
            type="text"
            name="score"
            onChange={(e) =>
              setSelectedItem({ ...selectedItem, scoreSum: e.target.value })
            }
            placeholder="امتیاز جهت ویرایش را وارد کنید"
          />
          <div className="buttons">
            <Button
              type="info"
              text="ویرایش"
              loading={scoreModalLoading}
              disabled={scoreModalLoading}
              htmlType="button"
              onClick={(e) =>
                onAuthenticationScoreEditClick(selectedItem?.scoreSum)
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setScoreModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      <Modal
        isOpen={financialModal}
        setIsOpen={(e: any) => setFinancialModal(e)}
        className="financial-settings-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>تنظیمات مالی کاربر</h4>
          </div>
          <Spin spinning={modalLoading}>
            <div className="content">
              <p>
                از این قسمت می توانید حداکثر مقدار برداشتی کاربر را محدود نمایید
              </p>
              <Input
                type="text"
                name="maxIrtWithdrawlInDay"
                value={digitSeparator(financialSettings?.maxIrtWithdrawlInDay)}
                onChange={(e) =>
                  handleNumChange(e.target.value, "maxIrtWithdrawlInDay")
                }
                label="حداکثر مقدار برداشت تومانی در روز"
                placeholder="به تومان وارد کنید"
                dir={
                  financialSettings?.maxIrtWithdrawlInDay?.length
                    ? "ltr"
                    : "rtl"
                }
              />
              <Input
                type="text"
                name="maxIrtDepositInDay"
                placeholder="به تومان وارد کنید"
                value={digitSeparator(financialSettings?.maxIrtDepositInDay)}
                onChange={(e) =>
                  handleNumChange(e.target.value, "maxIrtDepositInDay")
                }
                label="حداکثر مقدار واریز تومانی در روز"
                dir={
                  financialSettings?.maxIrtDepositInDay?.length ? "ltr" : "rtl"
                }
              />

              <Input
                type="text"
                name="maxDollarWithdrawlInDay"
                placeholder="به دلار وارد کنید"
                value={financialSettings?.maxDollarWithdrawlInDay}
                onChange={(e) =>
                  handleNumChange(
                    e.target.value,
                    "maxDollarWithdrawlInDay",
                    true
                  )
                }
                label="حداکثر مقدار برداشت دلار در یک روز"
                dir={
                  financialSettings?.maxDollarWithdrawlInDay?.length
                    ? "ltr"
                    : "rtl"
                }
              />

              <Input
                type={"text"}
                name={"description"}
                placeholder={"توضیحات را وارد کنید"}
                value={financialSettings?.description}
                onChange={(e) =>
                  setFinancialSettings({
                    ...financialSettings,
                    description: e.target.value,
                  })
                }
                label={"توضیحات"}
                dir={"rtl"}
              />

              <Switch
                checked={financialSettings?.lockAllWithdrawal}
                checkedChildren={"قفل برداشت ها فعال است"}
                unCheckedChildren={"قفل برداشت ها غیر فعال است "}
                onChange={(checked) =>
                  setFinancialSettings({
                    ...financialSettings,
                    lockAllWithdrawal: checked,
                  })
                }
              />

              <Switch
                checked={financialSettings?.manualWithdrawal}
                unCheckedChildren={"برداشت‌های دستی غیرفعال است"}
                checkedChildren={"برداشت‌های دستی فعال است"}
                onChange={(checked) =>
                  setFinancialSettings({
                    ...financialSettings,
                    manualWithdrawal: checked,
                  })
                }
              />

              <Button
                type="error"
                onClick={() =>
                  onRemoveFinancialSettings(financialSettings?.userId || "")
                }
                text="حذف تنظیمات مالی کاربر"
              />
            </div>
            <div className="buttons">
              <Button
                type="info"
                loading={loading}
                disabled={loading}
                text="ویرایش"
                htmlType="button"
                onClick={() =>
                  onUpdateFinancialSettings(financialSettings?.userId || "")
                }
              />
              <Button
                type="outlined"
                text="بستن"
                onClick={() => closeFinancialSettingsModal()}
              />
            </div>
          </Spin>
        </div>
      </Modal>

      {selectedItem && (
        <Modal
          setIsOpen={setBanUserModalIsOpen}
          isOpen={banUserModalIsOpen}
          title={`آیا از مسدود کردن کاربر ${
            selectedItem.fullName || selectedItem.userName
          } مطمئن هستید؟`}
          closeAble
          className="ban-user-modal"
          onClose={closeBanModal}
        >
          <div className="textarea">
            <label htmlFor="description">توضیحات</label>
            <TextArea
              name="description"
              placeholder="توضیحات را وارد کنید"
              onChange={(e) => setBanModalNote(e.target.value)}
              value={banModalNote}
            />
          </div>

          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={loading}
              disabled={loading}
              htmlType="button"
              onClick={onBanUserHandle}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closeBanModal()}
            />
          </div>
        </Modal>
      )}

      <Modal
        isOpen={detailsModalIsOpen.state}
        setIsOpen={(e: any) =>
          setDetailsModalIsOpen({ ...detailsModalIsOpen, state: e })
        }
        className="user-details-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>اطلاعات کاربر</h4>
          </div>
          <div className="content">
            <ul>
              <li>
                <span>نام و نام خانوادگی</span>
                <span>{detailsModalIsOpen.record?.fullName}</span>
              </li>
              <li>
                <span>نام کاربری</span>
                <span>{detailsModalIsOpen.record?.userName}</span>
              </li>
              <li>
                <span>سطح کاربری</span>
                <span>{detailsModalIsOpen.record?.level}</span>
              </li>
              <li>
                <span>نقش ها</span>
                <span>{detailsModalIsOpen.record?.roles[0]}</span>
              </li>
              <li>
                <span>تاریخ عضویت</span>
                <span>
                  {moment
                    .utc(detailsModalIsOpen.record?.registeredOn)
                    .local()
                    .toString()}
                </span>
              </li>
            </ul>
          </div>
          <div className="buttons">
            <Button
              type="secondary"
              text="بستن"
              onClick={() =>
                setDetailsModalIsOpen({ ...detailsModalIsOpen, state: false })
              }
            />
          </div>
        </div>
      </Modal>

      <Card
        blur={cardLoading}
        title="مدیریت کاربران"
        className="vt-dashboard-users"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues?.keywords}
              className="search"
              placeholder="جستجو کنید ..."
              name="search"
              onPressEnter={() => filterHandle()}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            {levels.length != 0 && (
              <div className="vt-field">
                <Select
                  mode="multiple"
                  placeholder={"سطح کاربر"}
                  value={filterValues?.levels}
                  onChange={(value, option) => {
                    setFilterValues({
                      ...filterValues,
                      levels: option as DefaultOptionType[],
                    });
                  }}
                >
                  {levels?.map((level) => (
                    <Select.Option key={level.key}>
                      {level?.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}

            {roles.length != 0 && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {roles &&
                      roles.map((role) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({ ...filterValues, role })
                          }
                        >
                          {role.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues?.role.title}
                />
              </Dropdown>
            )}
            {userCategories.length != 0 && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {userCategories &&
                      userCategories.map((category) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({
                              ...filterValues,
                              userCategory: category,
                            })
                          }
                        >
                          {category.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues?.userCategory.title}
                />
              </Dropdown>
            )}
            {/* <Calendar
              value={filterValues.startDate}
              label="ثبت نام از تاریخ"
              // placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues.endDate}
              label="ثبت نام تا تاریخ"
              // placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            /> */}

            <ConfigProvider locale={fa_IR} direction={"rtl"}>
              <JalaliLocaleListener />
              <DatePickerJalali.RangePicker
                onChange={(date: any) => {
                  date === null
                    ? setFilterValues({
                        ...filterValues,
                        startDate: "",
                        endDate: "",
                      })
                    : setFilterValues({
                        ...filterValues,
                        startDate: date[0]!.format("YYYY/MM/DD"),
                        endDate: date[1]!.format("YYYY/MM/DD"),
                      });
                }}
              />
            </ConfigProvider>
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => filterHandle()}
            />
            <span
              onClick={() => {
                setFilterValues({
                  role: defaultRoleItems[0],
                  levels: [],
                  keywords: "",
                  userCategory: defaultCategoryItems[0],
                });
                getAllUsers(
                  setCardLoading,
                  setUsersList,
                  getPageNum(),
                  pageSize,
                  ""
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>

        {usersList && (
          <Table
            className="users-table"
            data={usersList.items}
            pagination={usersList.pagination}
            setData={setUsersList}
            getData={getAllUsers}
            setLoader={setCardLoading}
            column={columns}
            filters={query}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Card>
    </>
  );
};
