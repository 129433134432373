import { API } from "apis";
import { message } from "antd";
import { ManualValidation } from "core/domains";
import { NavigateFunction } from "react-router-dom";
import { getViaAuth, postViaAuth, responseValidator } from "utils/scripts/api";

export const getManualValidationDetail = (
  setLoader: any,
  setResponse: any,
  id: string
) => {
  setLoader(true);
  getViaAuth(API.manualValidation.details + `?Id=${id}`).then(
    (response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        setResponse(response.value);
      }
    }
  );
};

export const approveManualValidation = (
  setLoader: any,
  data: ManualValidation | undefined,
  id: string,
  navigate: NavigateFunction
) => {
  setLoader(true);
  postViaAuth(API.manualValidation.accept, {
    id: id,
    message: data?.message,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      message.success("سطح مورد نظر با موفقیت اصلاح شد.");
      navigate("/actions/waiting-level-apply?page=1");
    }
  });
};

export const rejectManualValidation = (
  setLoader: any,
  data: ManualValidation | undefined,
  id: string,
  navigate: NavigateFunction
) => {
  setLoader(true);
  postViaAuth(API.manualValidation.reject, {
    id: id,
    message: data?.message,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      message.success("سطح مورد نظر با موفقیت اصلاح شد.");
      navigate("/actions/waiting-level-apply?page=1");
    }
  });
};

export const getAllSurveys = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(API.manualValidation.list + `/${page}/${size}${query}`, {}).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getSurveyStates = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.manualValidation.states).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: 0, title: "همه" }, ...response.value]);
    }
  });
};

export const getSurveyTypes = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.manualValidation.types).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: -1, title: "همه" }, ...response.value]);
    }
  });
};
