import { API } from "apis";
import { message } from "antd";
import { Enum, EnumAsString, Role } from "core/domains";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  responseValidator,
} from "utils/scripts/api";

export const getAllRoles = (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  getViaAuth(API.roles.getAllRoles).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setloader(response.value);
    }
  });
};

export const getAllRolesAsEnum = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.roles.getAllRoles).then((response: any) => {
    setloader(false);
    const allData: EnumAsString[] = [];
    if (responseValidator(response.status)) {
      response.value.items.map((role: Role) => {
        allData.push({ id: role.id || "", title: role.name || "" });
      });
      setdata([{ id: 0, title: "همه نقش ها" }, ...allData]);
    }
  });
};

export const getAllUserCategories = (setloader: any, setData: any) => {
  setloader(true);
  getViaAuth(API.enums.userCategories).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setData(response.value);
    }
  });
};
