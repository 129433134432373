import { DownOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import { Button, Card, Input } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import {
  Enum,
  ManualValidation,
  ManualValidationListItem,
  PaginationList,
  SortDirection,
} from "core/domains";
import {
  getAllSurveys,
  getSurveyStates,
  getSurveyTypes,
} from "core/repos/manual-validations";
import moment from "jalali-moment";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import "./index.scss";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";

const { useState, useRef, useEffect } = React;

const defaultTypeItems: Enum[] = [
  {
    title: "همه",
    id: -1,
  },
];
const defaultStateItems: Enum[] = [
  {
    title: "همه",
    id: -1,
  },
];
export interface FilterValues {
  startDate?: string;
  endDate?: string;
  sort?: SortDirection;
  type: Enum;
  state: Enum;
  keywords?: string;
}

export const ManualValidationAction: React.FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<PaginationList<ManualValidation>>();

  const [types, setTypes] = useState<Enum[]>(defaultTypeItems);
  const [pageSize, setPageSize] = useState<number>(10);
  const [states, setStates] = useState<Enum[]>(defaultStateItems);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
  });

  useEffect(() => {
    !surveys &&
      getAllSurveys(
        setCardLoading,
        setSurveys,
        getPageNum(),
        pageSize,
        "?state=0"
      );
    types.length === 1 && getSurveyTypes(setCardLoading, setTypes);
    states.length === 1 && getSurveyStates(setCardLoading, setStates);
  }, []);

  const onFilterHandler = () => {
    var query = ``;
    if (filterValues?.type?.id !== -1)
      query += `${query.includes("?") ? "&" : "?"}entity=${
        filterValues?.type?.id
      }`;
    if (filterValues?.state?.id !== -1)
      query += `${query.includes("?") ? "&" : "?"}state=${
        filterValues?.state?.id
      }`;
    if (filterValues?.keywords)
      query += `${query.includes("?") ? "&" : "?"}keywords=${
        filterValues?.keywords
      }`;
    if (!query.includes("state"))
      query += `${query.includes("?") ? "&" : "?"}state=0`;
    getAllSurveys(setLoading, setSurveys, 1, pageSize, query);
  };
  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام خانوادگی",
      id: "fullName",
      selector: (row: ManualValidationListItem) => row?.fullName,
      cell: (row: ManualValidationListItem) => (
        <div className="column-createdBy">
          <span className="day">{row.fullName}</span>
          <p className="">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "نوع",
      id: "entity",
      selector: (row: ManualValidationListItem) => row.entity.title,
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row: ManualValidationListItem) => row.state.title,
      cell: (row: ManualValidationListItem) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 1
              ? "success"
              : row.state.id == 2
              ? "error"
              : "waiting"
          }
        />
      ),
    },
    {
      name: "زمان",
      id: "createdOn",
      selector: (row: ManualValidationListItem) => row.createdOn,
      cell: (row: ManualValidationListItem) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record: ManualValidationListItem) => (
        <div className="actions">
          <Link to={`details/${record.id}`}>
            <Tooltip placement="top" title="جزئیات مدارک">
              <FileSearchOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="در انتظار تایید ارتقاء سطح"
      className="vt-dashboard-manualValidation-action"
      blur={cardLoading}
    >
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            name="search"
            className="search"
            onPressEnter={() => onFilterHandler()}
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />

          <Dropdown
            trigger={["click", "hover"]}
            overlay={
              <Menu>
                {types &&
                  [...types, { title: "همه", id: -1 }].map((type) => (
                    <Menu.Item
                      onClick={() => setFilterValues({ ...filterValues, type })}
                    >
                      {type.title}
                    </Menu.Item>
                  ))}
              </Menu>
            }
            placement="bottom"
          >
            <Button
              icon={<DownOutlined />}
              type="outlined"
              text={filterValues?.type?.title}
            />
          </Dropdown>

          {states.length != 0 && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {states &&
                    [...states, { title: "همه", id: -1 }].map((state) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, state })
                        }
                      >
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues?.state?.title}
              />
            </Dropdown>
          )}
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => onFilterHandler()}
          />
          <span
            onClick={() => {
              setCardLoading(true);
              setFilterValues({
                keywords: "",
                startDate: "",
                endDate: "",
                type: defaultTypeItems[0],
                state: defaultStateItems[0],
              });
              getAllSurveys(
                setCardLoading,
                setSurveys,
                getPageNum(),
                pageSize,
                "?state=0"
              );
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      <Table
        className="manualValidation-table"
        data={surveys?.items}
        pagination={surveys?.pagination}
        column={columns}
        pageSize={pageSize}
        getData={getAllSurveys}
        setData={setSurveys}
        filters="?state=0"
        query="?state=0"
        setPageSize={setPageSize}
        setLoading={setCardLoading}
        setLoader={setCardLoading}
        blur={loading}
      />
    </Card>
  );
};
