import { Empty, Spin } from "antd";
import * as React from "react";
import { useEffect } from "react";
import "./table.scss";
import DataTable, { Direction, TableColumn } from "react-data-table-component";
import { Pagination as Paging, XpayComponent } from "core/domains";
import { Pagination } from "components/pagination/pagination";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

interface Props extends XpayComponent {
  data: any;
  setLoader?: any;
  setData?: any;
  getData?: any;
  column: TableColumn<any>[];
  pagination?: Paging;
  pageSize?: number;
  expandableRowsComponent?: ({ data }: any) => JSX.Element;
  blur?: boolean;
  url?: string;
  filters?: string;
  query?: string;
  setLoading?: any;
  sort?: { setsort?: any; sort?: any };
  conditionalRowStyles?: any;
  selectableRows?: boolean;
  onSelectRowsChange?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  setCount?: any;
}

export const Table: React.FC<Props> = (props) => {
  const [data, setData] = React.useState<any[]>(props.data);
  const [loading, setLoading] = React.useState<boolean>();
  useEffect(() => {}, []);
  return (
    <div className={`vt-data-table ${props.className ? props.className : ""}`}>
      <DataTable
        selectableRows={props.selectableRows}
        onSelectedRowsChange={props.onSelectRowsChange}
        columns={props.column}
        noDataComponent={<Empty description="رکوردی یافت نشد" />}
        progressComponent={
          <Spin tip="در حال بارگذاری..." spinning={loading}></Spin>
        }
        conditionalRowStyles={props?.conditionalRowStyles}
        data={props.data}
        expandableRows={props.expandableRowsComponent ? true : false}
        expandableRowsComponent={props.expandableRowsComponent}
        progressPending={loading}
        direction={Direction.RTL}
        sortIcon={
          props.sort?.sort === "asc" ? <CaretUpFilled /> : <CaretDownFilled />
        }
        sortServer={props?.sort ? true : false}
        onSort={() => {
          props.sort?.sort === "asc"
            ? props.sort?.setsort("desc")
            : props.sort?.setsort("asc");
          props.getData(
            props.setLoading,
            props.setData,
            1,
            10,
            `?SortDirection=${props.sort?.sort === "asc" ? 0 : 1}`
          );
        }}
      />
      {props.pagination && props.data.length > 0 && (
        <Pagination
          page={props.pagination.pageIndex || 0}
          data={data}
          setdata={props.setData}
          setloader={props.setLoader}
          getdata={props.getData}
          size={props.pageSize || 10}
          query={props.filters}
          totalRows={props.pagination.totalCount}
          setPageSize={props.setPageSize}
          setCount={props.setCount}
        />
      )}
    </div>
  );
};
