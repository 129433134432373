import { message, Spin } from "antd";
import * as React from "react";
import "./input.scss";
import {
  CopyOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

type props = {
  name: string;
  disabled?: boolean;
  type: string;
  label?: string;
  dir?: "ltr" | "rtl";
  autoFocus?: boolean;
  style?: object;
  value: string | undefined;
  onKeyChange?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number;
  onkeyDownChange?: any;
  spinning?: boolean;
  copyAble?: boolean;
  onPressEnter?: () => void;
  icon?: React.ReactNode;
  className?: string;
  required?: boolean;
  onClick?: () => void;
  adorments?: {
    startAdornment?: {
      adornmentIcon: React.ReactNode | null;
    };
    endAdornment?: {
      adornmentIcon: React.ReactNode | null;
    };
  };
  hasError?: boolean;
  errors?: string[];
  helperText?: string[];
  greenHelperText?: string[];
};

export const Input: React.FC<props> = (props) => {
  const copyToClipboard = () => {
    props.value && navigator.clipboard.writeText(props.value);
    message.success(
      props.label ? `${props.label} با موفقیت کپی شد` : `با موفقیت کپی شد`
    );
  };

  return (
    <React.Fragment>
      <div className={`vt-input ${props.className}`} onClick={props.onClick}>
        <div className='input-field'>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          {props.adorments?.startAdornment &&
            props.adorments?.startAdornment?.adornmentIcon}
          <input
            onKeyUp={(event) =>
              event.key === "Enter" &&
              props.onPressEnter &&
              props.onPressEnter()
            }
            autoFocus={props.autoFocus}
            maxLength={props.maxLength}
            style={{ ...props.style, direction: props.dir }}
            name={props.name}
            type={props.type}
            value={props.value}
            onKeyDown={props.onKeyChange}
            onChange={props.onChange}
            placeholder={props.placeholder}
            onKeyDownCapture={props.onkeyDownChange}
            disabled={props?.disabled}
            dir={props?.dir}
          />
          {props.adorments?.endAdornment?.adornmentIcon &&
            props.adorments?.endAdornment?.adornmentIcon}
          {props.icon && <span className='input-icon'>{props.icon}</span>}
          {props.copyAble && (
            <span onClick={copyToClipboard} className='input-copy-icon'>
              <CopyOutlined />
            </span>
          )}
          {props.spinning && <Spin />}
        </div>
        {props.errors ? (
          props.errors.map((error, index) => (
            <div className='input-error' key={`input-errors-${index}`}>
              {error.length > 0 ? (
                <>
                  <QuestionCircleOutlined />
                  <span>{error}</span>
                </>
              ) : (
                <span></span>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
        {props.greenHelperText &&
          props.greenHelperText.map((text, index) => (
            <div
              className='input-greenhelpertext'
              key={`input-greenhelpertext-${index}`}
            >
              {text.length > 0 ? (
                <>
                  <CheckCircleOutlined />
                  <span>{text}</span>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        {props.helperText &&
          props.helperText.map((text, index) => (
            <div className='input-helpertext' key={`input-helpertext-${index}`}>
              {text.length > 0 ? (
                <>
                  <QuestionCircleOutlined />
                  <span>{text}</span>
                </>
              ) : (
                <span></span>
              )}
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};
