import * as React from "react";
import avatar from "assets/images/avatar.png";

import "components/navbar/navbar.scss";
import useOnBlur from "utils/scripts/useOnBlur";
import {useLocation, useNavigate} from "react-router-dom";
import {Modal} from "components/modal/modal";
import {Button} from "components/button/button";
import moment from "jalali-moment";
import {APP_VERSION} from "../../apis/constants";

interface Props {
}

const {useState, useRef, useEffect} = React;

export const Navbar: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const dropDownRef = useRef<any>(null);

  const location = useLocation();

  useOnBlur(dropDownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  const navigate = useNavigate();

  function handleModalSubmit() {
    setModalIsOpen(false);
    localStorage.clear();
    navigate("/signin");
  }

  return (
    <div
      className={`vt-navbar ${
        location.pathname.replaceAll("/", "") === "dashboard" ? "active" : ""
      } `}
    >
      <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
        <div className='confirm-box'>
          <div className='head'>
            <h4>آیا می‌خواهید خارج شوید؟</h4>
          </div>
          <div className='buttons'>
            <Button
              type='info'
              text='بله مطمئنم'
              htmlType='button'
              onClick={() => handleModalSubmit()}
            />
            <Button
              type='outlined'
              text='بازگشت'
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>

      <div className='navbar-welcome'>
        <h4>ادمین عزیز، خوش آمدی.</h4>{" "}
      </div>
      <div className='navbar-date'>
        <span>
          {moment().format("jYYYY/jM/jD")} ، ساعت {moment().format("HH:mm")}
        </span>
        <span>|</span>
        <span className='en'>{APP_VERSION}</span>
      </div>
      <div className='navbar-user'>
        <div className='mobile-leftside'>
          <>
            <div className='navbar-search'>
              <img src='assets/icons/search.svg'/>
            </div>
          </>
        </div>

        <div
          className='navbar-profile'
          ref={dropDownRef}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className='avatar'>
            <img src={avatar}></img>
          </div>
          <div className='dropdown'>
            <span>خوش آمدی</span>
            <p className='userinfo'>{localStorage.getItem('name')
                ? localStorage.getItem('name')
                : localStorage.getItem('phone')}{' '}</p>
            <div>
              <svg
                  width='10'
                  height='6'
                  viewBox='0 0 10 6'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    transform: `rotate(${isOpen ? 180 : 0}deg)`,
                  }}
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M9.80113 1.22462L5.48011 5.78989C5.21495 6.07004 4.78505 6.07004 4.51989 5.78989L0.19887 1.22462C-0.0662899 0.944468 -0.0662899 0.490258 0.19887 0.210111C0.464029 -0.0700374 0.893937 -0.0700374 1.1591 0.210111L5 4.26813L8.8409 0.210111C9.10606 -0.070037 9.53597 -0.070037 9.80113 0.210111C10.0663 0.490259 10.0663 0.944468 9.80113 1.22462Z'
                  fill='#2C3E60'
                />
              </svg>
            </div>
            {isOpen && (
              <div className='dropdown-body'>
                <svg
                  width='14'
                  height='11'
                  viewBox='0 0 14 11'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.13398 0.499999C6.51888 -0.166667 7.48113 -0.166667 7.86603 0.5L13.0622 9.5C13.4471 10.1667 12.966 11 12.1962 11H1.80385C1.03405 11 0.552922 10.1667 0.937822 9.5L6.13398 0.499999Z'
                    fill='white'
                  />
                </svg>

                <div className='item' onClick={() => setModalIsOpen(true)}>
                  <svg
                    width='23'
                    height='24'
                    viewBox='0 0 23 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M5.94858 12.9239H1.13776C0.626226 12.9239 0.199951 12.5159 0.199951 11.9999C0.199951 11.4959 0.626226 11.0759 1.13776 11.0759H5.94858L5.94894 5.34C5.94894 2.4 8.42729 0 11.4633 0H17.5104C20.534 0 23 2.4 23 5.328V18.672C23 21.612 20.534 24 17.4856 24H11.4509C8.42729 24 5.94894 21.612 5.94894 18.684V12.924H13.6318L11.6491 14.844C11.2774 15.204 11.2774 15.792 11.6491 16.152C11.835 16.332 12.0828 16.416 12.3307 16.416C12.5661 16.416 12.814 16.332 12.9998 16.152L16.6182 12.66C16.8041 12.492 16.9032 12.252 16.9032 12C16.9032 11.76 16.8041 11.52 16.6182 11.352L12.9998 7.86C12.6281 7.5 12.0209 7.5 11.6491 7.86C11.2774 8.22 11.2774 8.808 11.6491 9.168L13.6318 11.076H5.94894L5.94858 12.9239Z'
                      fill='#859ABC'
                    />
                  </svg>
                  <span>خروج</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
