import { Button, Card, Input } from "components";
import { Table } from "components/table/table";
import { Enum, PaginationList } from "core/domains";
import * as React from "react";
import "./kucoin-history.scss";

import { Transaction } from "../../../core/domains/transaction/transaction";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { KucoinHistory } from "../../../core/domains/kucoin/kucoin-history";
import { getViaAuth, responseValidator } from "../../../utils/scripts/api";
import { API } from "../../../apis";
import moment from "jalali-moment";
import { getIconPath } from "../../../utils/scripts/getIconPath";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { KucoinTransactionModal } from "./transaction-modal/transaction-modal";
import { getTransactionStates } from "core/repos";
import { digitSeparator } from "utils/scripts/decimal";
import { copyToClipboard } from "../../../utils/scripts/copyToClipboard";

const { useState, useEffect } = React;

export const KucoinHistories: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [data, setData] = useState<PaginationList<KucoinHistory>>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionModalIsOpen, setTransacionModalIsOpen] =
    useState<boolean>(false);
  const [transactionStates, setTransactionStates] = useState<Enum[]>();
  const [selectedItem, setSelectedItem] = useState<KucoinHistory>();
  const [query, setQuery] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);

  function openTransactionModal(item: KucoinHistory) {
    setSelectedItem(item);
    setTransacionModalIsOpen(true);
  }

  function getAllKucoinHistory(
    setloader: any,
    setdata: any,
    page: number,
    pageSize: number,
    query: string
  ) {
    setloader(true);

    getViaAuth<PaginationList<KucoinHistory>>(
      `${API.kucoin.kucoinHistory}/${page.toString()}/${pageSize}${query}`
    ).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    });
  }

  async function fetchAllData() {
    await getTransactionStates(setLoading, setTransactionStates);
    !data &&
      getAllKucoinHistory(
        setCardLoading,
        setData,
        getPageNum(),
        pageSize,
        query
      );
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  const filterHandle = () => {
    var _query = "";
    if (searchValue) {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += searchValue;
    }

    setQuery(_query);
    getAllKucoinHistory(setLoading, setData, getPageNum(), pageSize, _query);
  };

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "5%",
    },
    {
      name: "ارز",
      id: "asset",
      selector: (row: KucoinHistory) => row?.asset,
      cell: (row: KucoinHistory) => (
        <>
          <img src={getIconPath(row?.asset ?? "")} alt={row.asset} />
          <span>{row.asset}</span>
        </>
      ),
    },
    {
      name: "مقدار",
      id: "quantity",
      width: "15%",
      selector: (row: KucoinHistory) => digitSeparator(row?.quantity),
    },
    {
      name: "هش",
      id: "txid",
      width: "23%",
      selector: (row: KucoinHistory) => row.txid,
      cell: (row: KucoinHistory) => (
        <div className="txid-col">
          <span className="long-text">{row.txid}</span>
          <CopyOutlined
            onClick={() => row.txid && copyToClipboard(row?.txid, "هش")}
          />
        </div>
      ),
    },
    {
      name: "شبکه",
      id: "network",
      width: "8%",
      selector: (row: KucoinHistory) => row.network.toUpperCase(),
    },
    {
      name: "تراکنش مرتبط",
      id: "transaction",
      selector: (row: KucoinHistory) => row.transaction,
      cell: (record: KucoinHistory) =>
        !record.transaction ? (
          "شارژ نشده"
        ) : (
          <InfoCircleOutlined onClick={() => openTransactionModal(record)} />
        ),
    },
    {
      name: "تاریخ و ساعت",
      id: "createTime",
      selector: (row: KucoinHistory) => row.createTime,
      cell: (row: KucoinHistory) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createTime)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createTime).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      {selectedItem && transactionStates && (
        <KucoinTransactionModal
          isOpen={transactionModalIsOpen}
          setIsOpen={setTransacionModalIsOpen}
          item={selectedItem}
          loading={loading}
          states={transactionStates}
        />
      )}
      <Card
        blur={cardLoading}
        title="تاریخچه کوکوین"
        className="vt-dashboard-kucoin"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={searchValue}
              className="search"
              placeholder="جستجو کنید ..."
              name="search"
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={() => filterHandle()}
            />
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => filterHandle()}
            />
            <span
              onClick={() => {
                setSearchValue("");
                getAllKucoinHistory(
                  setCardLoading,
                  setData,
                  getPageNum(),
                  pageSize,
                  ""
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {data && (
          <Table
            blur={cardLoading}
            setLoader={setCardLoading}
            getData={getAllKucoinHistory}
            setData={setData}
            pagination={data.pagination}
            className="history-table"
            data={data.items}
            column={columns}
            filters={query}
            pageSize={pageSize}
            setPageSize={setPageSize}
            conditionalRowStyles={[
              {
                when: (row: KucoinHistory) => !row.transaction,
                style: {
                  backgroundColor: "#fac89861",
                },
              },
            ]}
          />
        )}
      </Card>
    </>
  );
};
