import { message, Switch } from "antd";
import { API } from "apis";
import { Button, Card } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

type ButtonLoading = {
  cryptoWithdrawSetting: boolean;
  authSettings: boolean;
  currencyWithdrawlSetting: boolean;
  callOrOtpSetting: boolean;
  telegramSetting: boolean;
  manualConfirmTxidSetting: boolean;
  registerActionvationSetting: boolean;
};

export const AutomationSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<ButtonLoading>({
    cryptoWithdrawSetting: false,
    authSettings: false,
    currencyWithdrawlSetting: false,
    callOrOtpSetting: false,
    telegramSetting: false,
    manualConfirmTxidSetting: false,
    registerActionvationSetting: false,
  });
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [cryptoWithdrawSetting, setCryptoWithdrawSetting] =
    React.useState<number>(0);
  const [authSettings, setAuthSettings] = React.useState<number>(0);
  const [currencyWithdrawlSetting, setCurrencyWithdrawlSetting] =
    React.useState<number>(0);
  const [callOrOtpSetting, setCallOrOtpSetting] =
    React.useState<boolean>(false);
  const [telegramSetting, setTelegramSetting] = React.useState<boolean>(false);
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [stage, setStage] = React.useState<number>(1);

  const [manualConfirmTxidSetting, setManualConfirmTxidSetting] =
    React.useState<boolean>(false);
  const [registerActivationSetting, setRegisterActivationSetting] =
    React.useState<boolean>(false);

  const getRegisterActivationSetting = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.registerActivationSetting).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setRegisterActivationSetting(response.value.isActive);
        }
      }
    );
  };
  const getManualConfirmTxidSetting = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.manualConfirmTxid).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setCallOrOtpSetting(response.value.isActive);
      }
    });
  };
  const changeManualConfirmTxidHandle = async () => {
    setLoading({ ...loading, manualConfirmTxidSetting: true });
    await put(API.settings.changeManualConfirmTxid, {
      isActive: manualConfirmTxidSetting,
    }).then((response: any) => {
      setLoading({ ...loading, manualConfirmTxidSetting: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success("تنظیمات مورد نظر اعمال شد.");
        getManualConfirmTxidSetting();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const onConfirmHandle = (side: number) => {
    setGoogleAuth({
      code: "",
      error: "",
    });
    setStage(side);
    setModalIsOpen(true);
  };

  const getCryptoSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.cryptoWithdrawlMethodSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setCryptoWithdrawSetting(response.value.type);
        }
      }
    );
  };

  const getCallOrOtpSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.callOrOtpSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setCallOrOtpSetting(response.value.isCall);
      }
    });
  };

  const getTelegramSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.telegramSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setTelegramSetting(response.value.isActive);
      }
    });
  };

  const getUserValidationSetting = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.getUserValidationSetting).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setAuthSettings(response.value.validationType.id);
        }
      }
    );
  };

  const changeUserValidationHandle = async (googleAuthCode: string) => {
    setLoading({ ...loading, authSettings: true });
    await put(API.settings.changeUserValidation, {
      validationType: authSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading({ ...loading, authSettings: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success("تنظیمات مورد نظر اعمال شد.");
        getUserValidationSetting();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };
  const changeRegisterActivationSettings = async () => {
    setLoading({ ...loading, registerActionvationSetting: true });
    await put(API.settings.changeRegisterActivationSetting, {
      isActive: registerActivationSetting,
    }).then((response: any) => {
      setLoading({ ...loading, registerActionvationSetting: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success("تنظیمات مورد نظر اعمال شد.");
        getRegisterActivationSetting();
      }
    });
  };
  const changeTelegramSettings = async (googleAuthCode: string) => {
    setLoading({ ...loading, authSettings: true });
    await put(API.settings.changeTelegramSettings, {
      isActive: telegramSetting,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading({ ...loading, authSettings: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success("تنظیمات مورد نظر اعمال شد.");
        getTelegramSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const getCurrencySettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.currencyWithdrawlMethodSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          setCurrencyWithdrawlSetting(response.value.widthrawlType);
        }
      }
    );
  };

  const onCryptoConfirmHandler = (googleAuthCode: string) => {
    setLoading({ ...loading, cryptoWithdrawSetting: true });
    put(API.settings.changeCryptoWithdraw, {
      type: cryptoWithdrawSetting,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading({ ...loading, cryptoWithdrawSetting: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getCryptoSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const onCallOrOtpConfirmHandle = (googleAuthCode: string) => {
    setLoading({ ...loading, callOrOtpSetting: true });
    put(API.settings.changeCallOrOtpSettings, {
      isCall: callOrOtpSetting,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading({ ...loading, callOrOtpSetting: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getCallOrOtpSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const onCurrencyConfirmHandler = (googleAuthCode: string) => {
    setLoading({ ...loading, currencyWithdrawlSetting: true });
    put(API.settings.changeCurrencyWithdraw, {
      type: currencyWithdrawlSetting,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading({ ...loading, currencyWithdrawlSetting: false });
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getCurrencySettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const fetchAllData = async () => {
    getCryptoSettings();
    getCurrencySettings();
    getUserValidationSetting();
    getCallOrOtpSettings();
    getTelegramSettings();
    getManualConfirmTxidSetting();
    getRegisterActivationSetting();
  };

  React.useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={
          stage == 1
            ? onCryptoConfirmHandler
            : stage == 2
            ? onCurrencyConfirmHandler
            : stage === 3
            ? changeUserValidationHandle
            : stage === 4
            ? onCallOrOtpConfirmHandle
            : stage === 5
            ? changeTelegramSettings
            : changeRegisterActivationSettings
        }
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={
          loading.callOrOtpSetting ||
          loading.authSettings ||
          loading.cryptoWithdrawSetting ||
          loading.currencyWithdrawlSetting ||
          loading.telegramSetting
        }
      />
      <Card blur={cardLoading} className="vt-dashboard-automation-settings">
        <Card className="automation-item">
          <span>برداشت ارزی :</span>
          <Switch
            checkedChildren={"وضغیت اتوماتیک می یاشد"}
            unCheckedChildren={"وضغیت دستی می باشد"}
            checked={cryptoWithdrawSetting == 1}
            onChange={(checked) =>
              checked
                ? setCryptoWithdrawSetting(1)
                : setCryptoWithdrawSetting(0)
            }
          />
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandle(1)}
            loading={loading.cryptoWithdrawSetting}
            disabled={loading.cryptoWithdrawSetting}
          />
        </Card>
        <Card className="automation-item">
          <span>وضعیت ثبت نام :</span>
          <Switch
            checkedChildren={"ثبت نام روشن می یاشد"}
            unCheckedChildren={"ثبت نام خاموش می باشد"}
            checked={registerActivationSetting}
            onChange={(checked) =>
              checked
                ? setRegisterActivationSetting(true)
                : setRegisterActivationSetting(false)
            }
          />
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => changeRegisterActivationSettings()}
            loading={loading.registerActionvationSetting}
            disabled={loading.registerActionvationSetting}
          />
        </Card>

        <Card className="automation-item">
          <span>برداشت ریالی :</span>
          <Switch
            checkedChildren={"وضغیت اتوماتیک می یاشد"}
            unCheckedChildren={"وضغیت دستی می باشد"}
            checked={currencyWithdrawlSetting == 0 ? true : false}
            onChange={(checked) =>
              checked
                ? setCurrencyWithdrawlSetting(0)
                : setCurrencyWithdrawlSetting(1)
            }
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandle(2)}
            loading={loading.currencyWithdrawlSetting}
            disabled={loading.currencyWithdrawlSetting}
          />
        </Card>
        <Card className="automation-item">
          <span>احراز هویت :</span>
          <Switch
            checkedChildren={"وضغیت اتوماتیک می یاشد"}
            unCheckedChildren={"وضغیت دستی می باشد"}
            checked={authSettings == 1 ? true : false}
            onChange={(checked) =>
              checked ? setAuthSettings(1) : setAuthSettings(0)
            }
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandle(3)}
            loading={loading.authSettings}
            disabled={loading.authSettings}
          />
        </Card>
        <Card className="automation-item">
          <span> تماس ثبت نام:</span>
          <Switch
            checkedChildren={"تماس فعال می‌باشد"}
            unCheckedChildren={"Otp فعال می باشد"}
            checked={callOrOtpSetting}
            onChange={(checked) => setCallOrOtpSetting(checked)}
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandle(4)}
            loading={loading.callOrOtpSetting}
            disabled={loading.callOrOtpSetting}
          />
        </Card>
        <Card className="automation-item">
          <span> فعال بودن ربات:</span>
          <Switch
            title={"فعال"}
            checkedChildren={"ربات تلگرام فعال است"}
            unCheckedChildren={"ربات تلگرام غیر فعال است"}
            checked={telegramSetting}
            onChange={(checked) => setTelegramSetting(checked)}
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandle(5)}
            loading={loading.telegramSetting}
            disabled={loading.telegramSetting}
          />
        </Card>
        <Card className="automation-item">
          <span>تایید دستی هش:</span>
          <Switch
            title={"فعال"}
            checkedChildren={"تایید دستی روشن است"}
            unCheckedChildren={"تایید دستی خاموش است"}
            checked={manualConfirmTxidSetting}
            onChange={(checked) => setManualConfirmTxidSetting(checked)}
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => changeManualConfirmTxidHandle()}
            loading={loading.manualConfirmTxidSetting}
            disabled={loading.manualConfirmTxidSetting}
          />
        </Card>
      </Card>
    </>
  );
};
