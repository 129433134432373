import * as React from "react";
import {Button, Input} from "components";
import {Bonus, Enum} from "core/domains";

import "./edit.scss";

import {Col, Dropdown, Menu, Row, Spin, Switch} from "antd";
import {useNavigate} from "react-router-dom";
import {getBonusById, getBonusTypes, updateBonus} from "core/repos/bonus";
import {DownOutlined} from "@ant-design/icons";
import {digitSeparator, faNumToEnNum, IsNumeric} from "utils/scripts/decimal";
import {IsPersianChar} from "utils/scripts/stringExtentions";
import {isNumeric} from "utils/scripts/exchange";
import moment from "jalali-moment";

const { useState, useRef, useEffect } = React;

const initial_bonus = [
  {
    id: 99,
    title: "نوع",
  },
];

export const EditBonus: React.FC = () => {
  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [bonusData, setBonusData] = useState<Bonus>();
  const [bonusActionTypes, setBonusActionTypes] =
    useState<Enum[]>(initial_bonus);

  const navigate = useNavigate();

  const onNumberChange = (value: string, field: string) => {
    if (value !== "") {
      value = faNumToEnNum(value);
    }

    if (
      value !== "" &&
      !IsNumeric(faNumToEnNum(value.split("")[value.split("").length - 1]))
    ) {
      return;
    }

    setBonusData({
      ...bonusData,
      [field]: value,
    });
  };

  const onChangeMarket = (value: string, field: string) => {
    if (value !== "" && IsPersianChar(value)) {
      return;
    }

    if (value !== "" && isNumeric(faNumToEnNum(value))) {
      return;
    }

    setBonusData({
      ...bonusData,
      [field]: value,
    });
  };

  useEffect(() => {
    getBonusById(setIsCardLoading, setBonusData, id);
    getBonusTypes(setIsCardLoading, setBonusActionTypes);
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='vt-dashboard-bonus-edit'>
          <h3>اصلاح پاداش</h3>

          <form>
            <Row>
              <Col>
                <Input
                  type='text'
                  label='مقدار پاداش'
                  value={digitSeparator(bonusData?.bonusAmount)}
                  onChange={(e) =>
                    onNumberChange(e.target.value, "bonusAmount")
                  }
                  name='bonusAmount'
                  placeholder='مقدار را وارد کنید'
                />
              </Col>
              <Col>
                <Input
                  type='text'
                  label='نماد ارز'
                  value={bonusData?.bonusMarket}
                  onChange={(e) =>
                    onChangeMarket(e.target.value, "bonusMarket")
                  }
                  name='bonusMarket'
                  placeholder='نماد ارز را وارد کنید'
                  maxLength={10}
                />
              </Col>
            </Row>

            <Row>

              <Col>
                  <label>نوع عملیات</label>
                <Dropdown
                  trigger={["click", "hover"]}
                  overlay={
                    <Menu>
                      {bonusActionTypes &&
                          bonusActionTypes.map((bonusAction, index) => (
                          <Menu.Item
                              key={index}
                            onClick={() =>
                              setBonusData({
                                ...bonusData,
                                bonusAction: bonusAction,
                              })
                            }
                          >
                            {bonusAction.title}
                          </Menu.Item>
                        ))}
                    </Menu>
                  }
                  placement='bottom'
                >
                  <Button
                    icon={<DownOutlined />}
                    type='outlined'
                    text={bonusData?.bonusAction?.title || ""}
                  />
                </Dropdown>
              </Col>
              <Col>
                <div className='vt-switch-row'>
                  <label>وضعیت: </label>
                  <Switch
                    checkedChildren={"فعال"}
                    unCheckedChildren={"غیر فعال"}
                    checked={bonusData?.isActive ? true : false}
                    onChange={(checked) =>
                      setBonusData({ ...bonusData, isActive: checked })
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                  <span>ویرایش توسط :</span>
                  <span>
                {bonusData?.modifiedBy}
                      {" - "}
              </span>
                <span>
                  {" "}
                  ویرایش شده در تاریخ :
                  {bonusData?.lastModifiedDate &&
                    moment
                      .utc(bonusData?.lastModifiedDate)
                      .locale("fa")
                      .local()
                      .format("jYYYY/MM/DD")}
                    |{"  "}
                  {bonusData?.lastModifiedDate &&
                    moment
                      .utc(bonusData?.lastModifiedDate)
                      .local()
                      .format("HH:mm")}
                </span>
              </Col>
            </Row>
            <div className='buttons'>
              <Button
                type='info'
                text='اصلاح'
                onClick={() =>
                  updateBonus(
                    setLoading,
                    {
                      id: parseFloat(id),
                      isActive: bonusData?.isActive,
                      bonusAmount: parseInt(bonusData?.bonusAmount || ""),
                      bonusMarket: bonusData?.bonusMarket,
                      bonusAction: bonusData?.bonusAction?.id,
                    },
                    navigate
                  )
                }
                loading={loading}
                htmlType='button'
              />

              <Button
                type='outlined'
                text='بازگشت'
                onClick={() => navigate(-1)}
                htmlType='button'
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
