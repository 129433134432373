import { EditOutlined } from "@ant-design/icons";
import { Card } from "components";
import { Table } from "components/table/table";
import { Bonus, PaginationList } from "core/domains";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss";
import { Tag, Tooltip } from "antd";
import { getAllBonuses } from "core/repos/bonus";
import moment from "jalali-moment";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { digitSeparator } from "../../../utils/scripts/decimal";

const { useState, useEffect } = React;

export const Bonuses: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [bonuses, setBonuses] = useState<PaginationList<Bonus>>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const navigate = useNavigate();

  useEffect(() => {
    !bonuses &&
      getAllBonuses(setCardLoading, setBonuses, getPageNum(), pageSize, "");
  }, []);

  const onFilterHandler = () => {
    var query = "";
    if (searchValue !== "") {
      query += `${query.includes("?") ? "&" : "?"}keywords=${searchValue}`;
    }
    getAllBonuses(setCardLoading, setBonuses, 1, pageSize, query);
  };

  const columns: TableColumn<Bonus>[] = [
    {
      name: "نوع عملیات",
      id: "bonusAction",
      selector: (row) => row?.bonusAction?.id || "",
      cell: (row) => <Tag color="blue">{row.bonusAction?.title}</Tag>,
    },
    {
      name: "نماد ارز",
      id: "bonusMarket",
      selector: (row) => row?.bonusMarket || "",
      cell: (row) => <span className="en">{row.bonusMarket}</span>,
    },
    {
      name: "مقدار پاداش",
      id: "bonusAmount",
      selector: (row) => digitSeparator(row?.bonusAmount) || "",
    },
    {
      name: "وضعیت",
      id: "isActive",
      selector: (row) => (row.isActive ? "فعال" : "غیرفعال"),
      cell: (row) => (
        <Tag color={row.isActive ? "green" : "red"}>
          {row.isActive ? "فعال" : "غیرفعال"}
        </Tag>
      ),
    },
    {
      name: "آخرین تغییرات",
      id: "lastModifiedDate",
      selector: (row: Bonus) => row.lastModifiedDate || "",
      cell: (row: Bonus) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.lastModifiedDate)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.lastModifiedDate).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "عملیات",
      id: "action",
      cell: (record) => (
        <div className="actions">
          <Link to={`edit/${record.id}`}>
            <Tooltip placement="top" title="اصلاح پاداش">
              <EditOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Card
        blur={cardLoading}
        title="پاداش ها"
        className="vt-dashboard-bonuses"
      >
        {bonuses && (
          <Table
            blur={cardLoading}
            setLoader={setCardLoading}
            getData={getAllBonuses}
            setData={setBonuses}
            pagination={bonuses.pagination}
            className="bonus-datatable"
            data={bonuses.items}
            column={columns}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Card>
    </>
  );
};
